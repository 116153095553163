import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  Tabs,
  Text,
  Link,
  Subheading,
  formatDateString,
  Tooltip,
  Divider,
  Loading,
} from '@forward-financing/fast-forward';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import { FFLogger } from 'api/LogClient';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import { toError } from 'helpers/errorUtils';
import { toDate } from 'helpers/string/dateUtils';
import { isParseableDate } from 'helpers/date/dateUtils';
import { BasicOwnerInfoTableFragmentFragment } from '__generated__/graphql';
import { useUserContext } from 'contexts/UserContext';
import { InquiryOverview } from 'types/api/underwriting/types';
import { InternalLink } from '../../shared/InternalLink';
import { FraudScoringTable } from '../FraudScoring/FraudScoringTable';
import { TradelinesContainer } from './TradelinesContainer';
import { InquiriesContainer } from './InquiriesContainer';
import { CreditReportTable } from './CreditReport';
import { BasicOwnerInfoTable } from './BasicOwnerInfoTable';
import { PublicRecordsCount } from './PublicRecordsCount';
import { CriminalFilingsCount } from './CriminalFilingsCount';
import { UccTable } from './UccTable';
import { ReportDeleteConfirmationModal } from './ReportDeleteConfirmationModal';
import { LexisNexisSearchResults } from './LexisNexisSearchResults';
import {
  PublicRecordsOverview,
  CriminalFilingsOverview,
  TradelinesOverview,
  LexisNexisPersonalPublicDocument,
  ReportSearchLink,
  CreditReport,
  LexisNexisSearchResult,
  ExperianConsumer,
} from './types';
import {
  usePullLexisNexis,
  useExperianConsumer,
  useExperianConsumerV2Summary,
} from './ownerOverviewHooks';
import { pullCreditForOwners, updateDocument } from './ownerOverviewFetchUtils';
import { InquiriesContainerV2 } from './InquiriesContainerV2';

type NoResultsMessageProps = {
  title: string;
};

const NoResultsMessage = ({ title }: NoResultsMessageProps): JSX.Element => (
  <Box>
    <Subheading variant="section">{title}</Subheading>
    <Text>There are no {title}</Text>
  </Box>
);

export type OwnerOverviewProps = {
  uuid: string;
  ownerNumber: number;
  submissionUuid: string;
  submissionType: string | undefined;
  customerUuid?: string;
  publicRecord: PublicRecordsOverview | null;
  criminalFiling: CriminalFilingsOverview | null;
  tradelines: TradelinesOverview | null;
  ownerPublicDocuments: LexisNexisPersonalPublicDocument[] | undefined;
  manualReportSearchLink:
    | ReportSearchLink['manualReportSearchLink']
    | undefined;
  owner: BasicOwnerInfoTableFragmentFragment | undefined;
  creditReport: CreditReport | undefined;
  ownerLexisNexisSearchResult: LexisNexisSearchResult | undefined;
  currentUserCanSeePartialSsn: boolean;
  currentUserCanSeeDateOfBirth: boolean;
  // TODO: There is a better way to type state setters.
  setLexisNexisPullError: (error: string | undefined) => void;
  setPullCreditError: (error: string | undefined) => void;
  publicDocumentsLoading: boolean;
  lexisNexisPullDate: string | undefined;
  inquiries: InquiryOverview[];
  lexisNexisForOwnersLoading: boolean;
  canArchiveCreditReports: boolean;
  refetchPublicDocuments: () => void;
};

export const getCreditReportDate = (
  creditData: CreditReport | ExperianConsumer | undefined
): string | undefined => {
  return creditData?.createdAt?.split('T')[0];
};

export const shouldShowPullCreditWarning = (
  creditReportDate: string | undefined,
  submissionType: string | undefined
): boolean => {
  const today = Date.now();
  const isDate30daysAgoOrNotPresent =
    !creditReportDate ||
    isBefore(toDate(creditReportDate), sub(today, { days: 30 }));

  return submissionType !== 'Renewal' && isDate30daysAgoOrNotPresent;
};

// We made a decision to extract this component from OwnerOverviewSection.
// This component is responsible for rendering one owner. Any properties
// that are passed to this component related to owners should be pre-filtered
// to only include the owner that this component is responsible for. In some
// cases it might be possible to fetch data for one owner, in which case the
// fetching logic should be in this component.
export const OwnerOverview = ({
  uuid,
  ownerNumber,
  submissionUuid,
  submissionType,
  customerUuid,
  publicRecord,
  criminalFiling,
  tradelines,
  ownerPublicDocuments,
  manualReportSearchLink,
  owner,
  creditReport,
  ownerLexisNexisSearchResult,
  currentUserCanSeePartialSsn,
  currentUserCanSeeDateOfBirth,
  setLexisNexisPullError,
  setPullCreditError,
  publicDocumentsLoading,
  lexisNexisPullDate,
  inquiries,
  lexisNexisForOwnersLoading,
  canArchiveCreditReports,
  refetchPublicDocuments,
}: OwnerOverviewProps): JSX.Element => {
  const { role } = useUserContext();

  const [experianSuccess, setExperianSuccess] = useState<boolean>(false);
  const [documentsUpdateError, setDocumentsUpdateError] = useState<string>();
  const { data: experianConsumerV2data } = useExperianConsumerV2Summary(
    submissionUuid,
    uuid
  );

  const [
    pullLexisNexis,
    { error: lexisNexisPullError, success: lexisNexisPullSuccess },
  ] = usePullLexisNexis();

  const { data: experianConsumerData } = useExperianConsumer(
    submissionUuid,
    uuid
  );

  useEffect(() => {
    setLexisNexisPullError(lexisNexisPullError);
  }, [lexisNexisPullError, setLexisNexisPullError]);

  const lexisNexisOwnerDocument =
    ownerPublicDocuments && ownerPublicDocuments.length > 0
      ? ownerPublicDocuments.find((doc) => doc.primary === true) ||
        ownerPublicDocuments[0]
      : undefined;

  const lexisNexisOwnerUrl =
    lexisNexisOwnerDocument &&
    new URL(
      `/admin/documents/${lexisNexisOwnerDocument.documentId}/lexis_nexis`,
      UNDERWRITING_BASE_URL()
    );

  const manualReportNewSearchLink = featureFlags.show_new_owner_ln_search ? (
    <InternalLink
      to={`/dashboard/${submissionUuid}/owner_report_search/${uuid}`}
      target="_blank"
    >
      Manual Search Report
    </InternalLink>
  ) : (
    manualReportSearchLink && (
      <Link
        newTab
        href={new URL(manualReportSearchLink, UNDERWRITING_BASE_URL())}
      >
        Manual Search Report
      </Link>
    )
  );

  const lexisNexisOwnerPageUrl = `/dashboard/submission/${submissionUuid}/owner-lexis-nexis/${uuid}`;

  const hasOwnerSsn = !!(owner?.ssn || owner?.ssnLastFour);

  const ficoScore = featureFlags.experian_consumer_3pi_owner
    ? experianConsumerData?.ficoScore
    : creditReport?.fico;

  const hasFicoScore = ficoScore !== undefined;

  const pullCredit = async (
    ownerUuid: string,
    force: boolean
  ): Promise<void> => {
    try {
      await pullCreditForOwners(submissionUuid, ownerUuid, force);
      setExperianSuccess(true);
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setPullCreditError(error.message);
    }
  };

  const handleUpdateDocument = async (
    documentId: number,
    primary: boolean
  ): Promise<void> => {
    try {
      await updateDocument(documentId, primary);
      void refetchPublicDocuments();
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setDocumentsUpdateError(error.message);
    }
  };

  const experianPullDate =
    experianConsumerData &&
    experianConsumerData?.createdAt &&
    isParseableDate(experianConsumerData?.createdAt)
      ? formatDateString(experianConsumerData.createdAt)
      : '';

  const creditReportDate = featureFlags.experian_consumer_3pi_owner
    ? getCreditReportDate(experianConsumerData)
    : getCreditReportDate(creditReport);

  // The report tab we want open by default. This will be the primary
  // report if one was set, or the most recent report if not.
  const defaultReport =
    ownerPublicDocuments?.find((report) => report.primary) ||
    ownerPublicDocuments?.[0];

  const tradelinesData = featureFlags.experian_consumer_3pi_tradelines_summary
    ? experianConsumerV2data?.tradelines
    : tradelines;

  return (
    <Card
      title={`Owner ${ownerNumber} Overview: ${owner?.fullName}${
        owner?.ownershipPercentage
          ? ` - ${owner.ownershipPercentage?.toFixed(1)}%`
          : ''
      }`}
      collapsible={true}
    >
      <Grid gutter>
        <Grid.Item m={12}>
          {ownerLexisNexisSearchResult &&
            owner &&
            (featureFlags.graphql_enabled ? (
              <LexisNexisSearchResults
                submissionUuid={submissionUuid}
                results={ownerLexisNexisSearchResult.results}
                ownerPublicDocuments={ownerPublicDocuments}
                owner={owner}
                currentUserCanSeePartialSsn={currentUserCanSeePartialSsn}
                currentUserCanSeeDateOfBirth={currentUserCanSeeDateOfBirth}
              />
            ) : (
              // Permission props are hardcoded here because this component
              // is only rendered when fetching without GraphQL enabled.
              <LexisNexisSearchResults
                submissionUuid={submissionUuid}
                ownerPublicDocuments={ownerPublicDocuments}
                results={ownerLexisNexisSearchResult.results}
                owner={owner}
                currentUserCanSeeDateOfBirth={true}
                currentUserCanSeePartialSsn={true}
              />
            ))}
        </Grid.Item>
        <Grid.Item xs={12} m={12} xl={12}>
          {shouldShowPullCreditWarning(creditReportDate, submissionType) && (
            <Banner dismissable={false} variant="warning">
              <Flex flexDirection={'row'} gap={2}>
                <Icon name="warning" />
                <Text>
                  Before proceeding to pull the Credit Report please make sure
                  the the Original Application has the owner&apos;s signature
                  and that it was signed in the last 30 days.
                </Text>
              </Flex>
            </Banner>
          )}

          {featureFlags.experian_consumer_3pi_owner
            ? experianConsumerData?.notes &&
              !hasFicoScore && (
                <Banner dismissable={false} variant="warning">
                  <Text>{`Credit Report Warnings: ${experianConsumerData.notes}`}</Text>
                </Banner>
              )
            : creditReport?.note &&
              !hasFicoScore && (
                <Banner dismissable={false} variant="warning">
                  <Text>{`Credit Report Warnings: ${creditReport.note}`}</Text>
                </Banner>
              )}

          {experianSuccess && (
            <Banner dismissable={false} variant="success">
              <Flex flexDirection={'row'} gap={2}>
                <Icon name="circle-check" size="2x" />
                <Text>
                  Credit report was pulled successfully! It takes anywhere from
                  5 seconds to 4 minutes to process the data, so please allow a
                  little bit of time before refreshing the page. If you are
                  still not able to view the report after 4 minutes, please
                  contact the tech support.
                </Text>
              </Flex>
            </Banner>
          )}
          {lexisNexisPullSuccess && (
            <Banner dismissable={false} variant="success">
              <Flex flexDirection={'row'} gap={2}>
                <Icon name="circle-check" size="2x" />
                <Text>
                  LexisNexis was pulled successfully! It takes anywhere from 5
                  seconds to 4 minutes to process the data, so please allow a
                  little bit of time before refreshing the page. If you are
                  still not able to view the report after 4 minutes, please
                  contact the tech support.
                </Text>
              </Flex>
            </Banner>
          )}
          <Flex flexDirection={'row'} gap={2} alignItems="center">
            <Button
              startIcon={'square-poll-vertical'}
              onClick={() => pullLexisNexis(submissionUuid, uuid, false)}
            >
              Pull LexisNexis
            </Button>
            <Button
              startIcon={'refresh'}
              onClick={() => pullLexisNexis(submissionUuid, uuid, true)}
            >
              Refresh LexisNexis
            </Button>

            {hasOwnerSsn ? (
              <Button
                startIcon={'square-poll-vertical'}
                onClick={() => pullCredit(uuid, false)}
                endIcon={
                  shouldShowPullCreditWarning(creditReportDate, submissionType)
                    ? 'warning'
                    : undefined
                }
              >
                Pull Credit
              </Button>
            ) : (
              <Tooltip
                position="top"
                content={<>Owner does not have SSN</>}
                trigger={
                  <span>
                    <Button startIcon={'square-poll-vertical'} disabled>
                      Pull Credit
                    </Button>
                  </span>
                }
              />
            )}

            {hasOwnerSsn ? (
              <Button
                startIcon={'refresh'}
                onClick={() => pullCredit(uuid, true)}
              >
                Refresh Credit
              </Button>
            ) : (
              <Tooltip
                position="top"
                content={<>Owner does not have SSN</>}
                trigger={
                  <span>
                    <Button startIcon={'refresh'} disabled>
                      Refresh Credit
                    </Button>
                  </span>
                }
              />
            )}
          </Flex>
          <Box mt={3}>{manualReportNewSearchLink}</Box>
        </Grid.Item>

        <Grid.Item m={12} l={4}>
          <Flex
            flexDirection={'row'}
            gap={1}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Flex alignItems={'center'} gap={1}>
              <Text>Lexis Nexis</Text>
              {publicDocumentsLoading && <Loading size="small" />}
              {lexisNexisPullDate ? (
                <Tooltip
                  content={<>Last Pulled Date {lexisNexisPullDate}</>}
                  trigger={
                    <span data-testid={'ln-check'}>
                      <Icon name="check-circle" color="success" />
                    </span>
                  }
                />
              ) : (
                !publicDocumentsLoading && (
                  <span data-testid={'not-present'}>
                    <Icon name="circle-minus" />
                  </span>
                )
              )}
            </Flex>

            <Divider orientation="vertical" />

            <Flex alignItems={'center'} gap={1}>
              <Text>Credit</Text>
              {experianPullDate ? (
                <Tooltip
                  content={<>Last Pulled Date {experianPullDate}</>}
                  trigger={
                    <span data-testid={'ex-check'}>
                      <Icon name="check-circle" color="success" />
                    </span>
                  }
                />
              ) : (
                <span data-testid={'not-present'}>
                  <Icon name="circle-minus" />
                </span>
              )}
            </Flex>
          </Flex>
          <Box backgroundColor="gray-200" padding={3}>
            {featureFlags.graphql_enabled ? (
              <BasicOwnerInfoTable
                owner={owner}
                currentUserCanSeePartialSsn={currentUserCanSeePartialSsn}
                currentUserCanSeeDateOfBirth={currentUserCanSeeDateOfBirth}
                lexisNexisReportUrl={lexisNexisOwnerUrl}
                lexisNexisOwnerPageUrl={lexisNexisOwnerPageUrl}
              />
            ) : (
              // Permission props are hardcoded here because this component
              // is only rendered when fetching without GraphQL enabled.
              <BasicOwnerInfoTable
                owner={owner}
                currentUserCanSeeDateOfBirth={true}
                currentUserCanSeePartialSsn={true}
                lexisNexisReportUrl={lexisNexisOwnerUrl}
                lexisNexisOwnerPageUrl={lexisNexisOwnerPageUrl}
              />
            )}
          </Box>
        </Grid.Item>

        <Grid.Item m={12} l={4}>
          <Grid gutter>
            <Grid.Item xl={12}>
              <Box backgroundColor="gray-200" padding={3}>
                <CreditReportTable
                  submissionUuid={submissionUuid}
                  ownerUuid={uuid}
                  creditReport={creditReport}
                  canArchive={canArchiveCreditReports}
                />
              </Box>
            </Grid.Item>

            <Grid.Item xl={12}>
              <Box backgroundColor="gray-200" padding={3}>
                <Subheading variant="section">Fraud Score</Subheading>
                <FraudScoringTable
                  submission={{ uuid: submissionUuid, ownerUuids: [uuid] }}
                />
              </Box>
            </Grid.Item>
          </Grid>
        </Grid.Item>

        <Grid.Item m={12} l={4}>
          <Box backgroundColor="gray-200" padding={3}>
            <Flex flexDirection="column" gap={2}>
              <TradelinesContainer
                tradelines={tradelinesData}
                tradelinesUrl={creditReport?.tradeLinesPath}
                ownerUuid={uuid}
                submissionUuid={submissionUuid}
              />
              {/**
               * TODO: once feature flag is enabled, rename InquiriesContainerV2 to InquiriesContainer
               */}
              {featureFlags.experian_consumer_3pi_inquiries_summary ? (
                <InquiriesContainerV2
                  inquiries={experianConsumerV2data?.inquiries ?? []}
                  submissionUuid={submissionUuid}
                  ownerUuid={uuid}
                />
              ) : (
                <InquiriesContainer
                  inquiries={inquiries}
                  submissionUuid={submissionUuid}
                  ownerUuid={uuid}
                />
              )}

              {lexisNexisForOwnersLoading && <Loading />}
              {publicRecord ? (
                <PublicRecordsCount
                  publicRecord={publicRecord}
                  submissionUuid={submissionUuid}
                />
              ) : (
                !lexisNexisForOwnersLoading && (
                  <NoResultsMessage title="Public records" />
                )
              )}

              {criminalFiling ? (
                <CriminalFilingsCount
                  {...criminalFiling}
                  submissionUuid={submissionUuid}
                />
              ) : (
                <NoResultsMessage title="Criminal filings" />
              )}
            </Flex>
          </Box>
        </Grid.Item>
        <Grid.Item>
          <Subheading variant="section">Public Documents</Subheading>
          {!ownerPublicDocuments || ownerPublicDocuments.length === 0 ? (
            <Text>There are no public documents</Text>
          ) : (
            <Tabs
              defaultValue={defaultReport?.documentId?.toString()}
              onValueChange={() => setDocumentsUpdateError('')}
            >
              <Tabs.List>
                {ownerPublicDocuments.map((doc) => (
                  <Tabs.Trigger
                    key={doc.documentId}
                    value={doc.documentId.toString()}
                  >
                    <Flex gap={2}>
                      {doc.primary && <Icon name="flag" />}
                      {doc.individualBestName
                        ? `${doc.individualBestName} - ${formatDateString(
                            doc.createdAt
                          )}`
                        : `No Name available - ${formatDateString(
                            doc.createdAt
                          )}`}
                    </Flex>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
              {ownerPublicDocuments.map((doc) => (
                <Tabs.Content
                  key={doc.documentId}
                  value={doc.documentId.toString()}
                >
                  {documentsUpdateError && (
                    <Banner dismissable={false} variant="error">
                      {documentsUpdateError}
                    </Banner>
                  )}
                  <Flex flexDirection={'row'} gap={2}>
                    <Button
                      startIcon={'flag'}
                      onClick={() => handleUpdateDocument(doc.documentId, true)}
                      disabled={doc.primary}
                    >
                      {doc.primary
                        ? 'Flagged as Primary Report'
                        : 'Flag Report as Primary'}
                    </Button>

                    {role === 'admin' && (
                      <ReportDeleteConfirmationModal
                        document={doc}
                        afterDelete={refetchPublicDocuments}
                      />
                    )}
                  </Flex>
                  <Box mt={3}>
                    <Flex gap={2}>
                      <Icon name="eye" />
                      {featureFlags.use_owner_ln_page ? (
                        <InternalLink
                          to={`/dashboard/submission/${submissionUuid}/owner-lexis-nexis/report-id/${doc.reportIdentifier}`}
                          target="_blank"
                        >
                          View LexisNexis Report
                        </InternalLink>
                      ) : (
                        <Link
                          newTab
                          href={
                            new URL(
                              `/admin/documents/${doc.documentId}/lexis_nexis`,
                              UNDERWRITING_BASE_URL()
                            )
                          }
                        >
                          View LexisNexis Report
                        </Link>
                      )}
                    </Flex>
                  </Box>
                  <Flex flexDirection="column" gap={3} mt={3}>
                    <Box backgroundColor="gray-200" padding={3}>
                      <UccTable
                        uccs={doc.uccs}
                        reportId={doc.documentId}
                        customerUuid={customerUuid}
                        submissionUuid={submissionUuid}
                        ownerUuid={uuid}
                      />
                    </Box>
                  </Flex>
                </Tabs.Content>
              ))}
            </Tabs>
          )}
        </Grid.Item>
      </Grid>
    </Card>
  );
};
