import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { GenericLedgerApiParams } from 'components/DealScoring/Ledger/ledger.types';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { BANK_BASE_URL } from 'constants/globals';
import {
  BuyRatesResponse,
  LedgerBankingPricingSuggestionsRaw,
  LedgerOfferRaw,
  LedgerResponse,
  PatchLedgerRequestBody,
  UpdateLedgerBody,
} from 'types/api/banking/types';
import {
  CLONE_LEDGER_URL,
  CREATE_LEDGER_URL,
  DELETE_LEDGER_URL,
  FETCH_BUY_RATES_URL,
  FETCH_LEDGERS_URL,
  FETCH_OFFERS_URL,
  SAVE_LEDGER_URL,
  SEND_LEDGER_TO_CREDIT_COMMITTEE_URL,
} from 'api/constants';

export const updateLedger = async (
  submissionUuid: string,
  updateBody: UpdateLedgerBody
): Promise<{ success: boolean }> => {
  const url = new URL(`/api/v2/ledgers/${submissionUuid}`, BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void, UpdateLedgerBody>(
    url,
    'PATCH',
    updateBody
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update ledger');
  }

  return { success: true };
};

export const fetchLedgers = async (
  submissionUuid: string
): Promise<LedgerResponse[]> => {
  const url = new URL(FETCH_LEDGERS_URL(submissionUuid), BANK_BASE_URL());

  const response = await makeInternalAPIRequest<LedgerResponse[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Ledgers');
  }

  return response.json();
};

export const patchLedger = async ({
  ledgerId,
  requestBody,
  submissionUuid,
}: GenericLedgerApiParams & {
  requestBody: PatchLedgerRequestBody;
}): Promise<MutationResponse> => {
  const url = new URL(
    SAVE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest(url, 'PATCH', requestBody);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update Ledger');
  }

  return { success: true };
};

export const sentToCreditCommittee = async ({
  submissionUuid,
  ledgerId,
}: Omit<GenericLedgerApiParams, 'customerUuid'>): Promise<MutationResponse> => {
  const url = new URL(
    SEND_LEDGER_TO_CREDIT_COMMITTEE_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<null, Record<string, never>>(
    url,
    'PATCH',
    {}
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to send To Credit Committee'
    );
  }

  return { success: true };
};

export const cloneLedger = async ({
  ledgerId,
  submissionUuid,
}: GenericLedgerApiParams): Promise<MutationResponse> => {
  const url = new URL(
    CLONE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<MutationResponse>(url, 'POST');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to clone Ledger');
  }

  return { success: true };
};

export const fetchBuyRates = async ({
  submissionUuid,
  ledgerId,
}: GenericLedgerApiParams): Promise<BuyRatesResponse> => {
  const url = new URL(
    FETCH_BUY_RATES_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<BuyRatesResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Buy Rates');
  }

  return response.json();
};

export const deleteLedger = async ({
  ledgerId,
  submissionUuid,
}: GenericLedgerApiParams): Promise<MutationResponse> => {
  const url = new URL(
    DELETE_LEDGER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<void>(url, 'DELETE');

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to delete ledger');
};

export const createLedger = async ({
  submissionUuid,
}: Omit<GenericLedgerApiParams, 'ledgerId'>): Promise<MutationResponse> => {
  const url = new URL(CREATE_LEDGER_URL(submissionUuid), BANK_BASE_URL());

  const response = await makeInternalAPIRequest<void>(url, 'POST');

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(response.status, 'Failed to create ledger');
};

export const fetchLedgerOffers = async ({
  ledgerId,
  submissionUuid,
}: Omit<GenericLedgerApiParams, 'customerUuid'>): Promise<LedgerOfferRaw[]> => {
  const url = new URL(
    FETCH_OFFERS_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LedgerOfferRaw[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Offers');
  }

  return response.json();
};

export const fetchLedgerBankingSuggestedPricing = async (
  ledgerId: number
): Promise<LedgerBankingPricingSuggestionsRaw> => {
  const url = new URL(
    `/api/v2/auto_pricing/suggestions/${ledgerId}`,
    BANK_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LedgerBankingPricingSuggestionsRaw>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Banking Suggested Pricing'
    );
  }

  return response.json();
};
