import { useCallback } from 'react';
import { useLogError } from 'apiHooks/useLogError';
import { useUpdateApiApplication as useApiUpdateUnderwritingApplication } from 'apiHooks/underwriting/applicationFetchHooks';
import {
  useApiAssignNextApplication,
  useApiGetApplication,
  useApiUpdateApplication,
  useLazyGetApiApplicationQueue,
} from 'apiHooks/waitingRoom/applicationFetchHooks';
import {
  ApplicationQueueItemResponse,
  ApplicationResponse,
  ApplicationResponseOnboardingStatus,
  AttachmentsResponse,
  SubmissionOnboardingQueueResponse,
} from 'types/api/waitingRoom/types';
import { UserByRoleResponse } from 'types/api/auth/types';
import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiUsersByRole } from 'apiHooks/auth/userFetchHooks';
import { useApiGetAttachmentsForApplication } from 'apiHooks/waitingRoom/attachmentFetchHooks';
import {
  Application,
  Attachment,
  SubmissionOnboardingQueueApplication,
  SubmissionOnboardingQueueData,
  User,
} from './SubmissionOnboardingQueue.types';

const toQueueApplication = (
  application?: ApplicationQueueItemResponse
): SubmissionOnboardingQueueApplication | undefined => {
  if (!application) {
    return undefined;
  }
  return {
    uuid: application.application_uuid,
    createdAt: application.created_at,
    emailSubject: application.email_subject,
    emailSender: application.email_from,
    emailBody: application.email_body,
    status: application.onboarding_status,
    prequalFormUrl: application.prequal_form_url,
    onboardingAnalystUuid: application.onboarding_analyst_uuid ?? undefined,
    underwritingApplicationUuid: application.uw_application_uuid,
  };
};

const toQueueData = (
  queueData: SubmissionOnboardingQueueResponse
): SubmissionOnboardingQueueData => {
  return {
    metadata: {
      pagination: {
        page: queueData.pagination.page,
        totalItems: queueData.pagination.total_items,
        totalPages: queueData.pagination.total_pages,
      },
    },
    queuedApplications: queueData.results
      .map(toQueueApplication)
      .filter(Boolean) as SubmissionOnboardingQueueApplication[],
  };
};

const toAttachments = (attachments?: AttachmentsResponse): Attachment[] => {
  if (!attachments) {
    return [];
  }
  return attachments.map((attachment) => {
    return {
      uuid: attachment.uuid,
      createdAt: attachment.created_at,
      updatedAt: attachment.updated_at,
      deletedAt: attachment.deleted_at ?? undefined,
      applicationUuid: attachment.application_uuid,
      presignedUrl: attachment.presigned_url ?? undefined,
      binaryFile: attachment.binary_file ?? undefined,
      originalFilename: attachment.original_filename,
    };
  });
};

export type UseFetchQueuedApplicationsResponse = [
  (applicationStatus: string, page: number, search?: string) => Promise<void>,
  {
    data?: SubmissionOnboardingQueueData;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

export const useFetchQueuedApplications =
  (): UseFetchQueuedApplicationsResponse => {
    const [fetchQueueData, { error, data, ...rest }] =
      useLazyGetApiApplicationQueue();

    const fetchFunction = useCallback(
      async (
        applicationStatus: string,
        page: number,
        search?: string
      ): Promise<void> => {
        return fetchQueueData({ applicationStatus, page, search });
      },
      [fetchQueueData]
    );

    useLogError(error);

    return [
      fetchFunction,
      {
        data: data && toQueueData(data),
        error,
        ...rest,
      },
    ];
  };

const toUser = (user: UserByRoleResponse): User => {
  return {
    firstName: user.first_name,
    lastName: user.last_name,
    uuid: user.uuid,
  };
};

const toUsers = (users: UserByRoleResponse[] | undefined): User[] =>
  users?.map(toUser) ?? [];

export const useGetUsersByRole = (
  role?: string
): UseGenericQueryResponse<User[]> =>
  useGenericFeatureQuery(useGetApiUsersByRole, toUsers, role);

export type UseAssignUserNextApplicationResponse = [
  (
    prequalUserUuid: string | null,
    applicationUuid?: string
  ) => Promise<MutationResponse>,
  {
    data?: SubmissionOnboardingQueueApplication;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

export const useAssignUserNextApplication =
  (): UseAssignUserNextApplicationResponse => {
    const [assignNextApplication, { data, error, ...rest }] =
      useApiAssignNextApplication();

    const fetchFunction = useCallback(
      async (
        onboardingAnalystUuid: string | null,
        applicationUuid?: string
      ): Promise<MutationResponse> => {
        return assignNextApplication({
          onboardingAnalystUuid,
          applicationUuid,
        });
      },
      [assignNextApplication]
    );

    useLogError(error);

    return [
      fetchFunction,
      {
        data: data && toQueueApplication(data),
        error,
        ...rest,
      },
    ];
  };

export type UpdateApplicationBody = {
  onboardingAnalystUuid?: string;
  onboardingStatus?: ApplicationResponseOnboardingStatus;
};

const toApplication = (app: ApplicationResponse): Application => {
  return {
    uuid: app.uuid,
    onboardingAnalystUuid: app.onboarding_analyst_uuid ?? undefined,
    onboardingStatus: app.onboarding_status,
  };
};

type UseUpdateApplicationResult = [
  (input: UpdateApplicationBody) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<Application>, 'responseReady'>
];

export const useUpdateApplication = (
  applicationUuid: string
): UseUpdateApplicationResult => {
  const [updateApplication, { data, error, loading }] =
    useApiUpdateApplication();

  const updateFunction = (
    args: UpdateApplicationBody
  ): Promise<MutationResponse> => {
    return updateApplication({
      applicationUuid,
      updateBody: {
        uuid: applicationUuid,
        onboarding_analyst_uuid: args.onboardingAnalystUuid,
        onboarding_status: args.onboardingStatus,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      data: data && toApplication(data),
      error,
      loading,
    },
  ];
};
export const useAttachments = (
  applicationUuid?: string
): UseGenericQueryResponse<Attachment[]> =>
  useGenericFeatureQuery(
    useApiGetAttachmentsForApplication,
    toAttachments,
    applicationUuid
  );

export const useQueueItem = (
  applicationUuid?: string
): UseGenericQueryResponse<SubmissionOnboardingQueueApplication> =>
  useGenericFeatureQuery(
    useApiGetApplication,
    toQueueApplication,
    applicationUuid
  );

export type UpdateUnderwritingApplicationBody = {
  applicationUuid: string;
  prequalAnalystName?: string;
};

type UseUpdateUnderwritingApplicationResult = [
  (input: UpdateUnderwritingApplicationBody) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<boolean>, 'responseReady'>
];

export const useUpdateUnderwritingApplication =
  (): UseUpdateUnderwritingApplicationResult => {
    const [updateUnderwritingApplication, { data, error, loading }] =
      useApiUpdateUnderwritingApplication();

    const updateFunction = (
      args: UpdateUnderwritingApplicationBody
    ): Promise<MutationResponse> => {
      return updateUnderwritingApplication({
        applicationUuid: args.applicationUuid,
        updateBody: {
          prequal_analyst_name: args.prequalAnalystName,
        },
      });
    };

    useLogError(error);

    return [
      updateFunction,
      {
        data: data,
        error,
        loading,
      },
    ];
  };
