import {
  FraudScoringDocument,
  OwnerResponse,
} from 'types/api/underwriting/types';
import { Owner, OwnerFraudScores } from './fraudScoringContainer.types';
import { useGetApiFraudScoring } from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { useGetBatchApiOwners } from 'apiHooks/underwriting/ownerFetchHooks';

const toFraudScoring = (fraudScore: FraudScoringDocument): OwnerFraudScores => {
  return {
    ownerUuid: fraudScore.owner_uuid,
    abuseScore:
      (fraudScore.abuse_score && {
        score: fraudScore.abuse_score.score,
        underwriterBucket: fraudScore.abuse_score.uw_bucket,
      }) ||
      undefined,
    firstPartySyntheticScore:
      (fraudScore.first_party_synthetic_score && {
        score: fraudScore.first_party_synthetic_score.score,
        underwriterBucket: fraudScore.first_party_synthetic_score.uw_bucket,
      }) ||
      undefined,
    thirdPartySyntheticScore:
      (fraudScore.third_party_synthetic_score && {
        score: fraudScore.third_party_synthetic_score.score,
        underwriterBucket: fraudScore.third_party_synthetic_score.uw_bucket,
      }) ||
      undefined,
  };
};

interface UseFraudScoringResult {
  fraudScores: OwnerFraudScores[] | undefined;
  error: Error | undefined;
  loading: boolean;
}

export const useGetFraudScoring = (
  submissionUuid?: string
): UseFraudScoringResult => {
  const { data, error, ...rest } = useGetApiFraudScoring(submissionUuid);

  const fraudScores = data && data.documents.map(toFraudScoring);

  useLogError(error);

  return {
    fraudScores,
    error,
    ...rest,
  };
};

// add hooks for owner and submission

const toOwner = (owners?: OwnerResponse[]): Owner[] => {
  if (!owners) {
    return [];
  }
  return owners.map((owner) => {
    return {
      fullName: `${owner.first_name} ${owner.last_name}`,
      ownerUuid: owner.owner_uuid,
      uuid: owner.uuid,
    };
  });
};

interface UseOwnerResult {
  owners: Owner[] | undefined;
  error: Error | undefined;
  loading: boolean;
}

export const useGetOwnersBatch = (ownerUuids?: string[]): UseOwnerResult => {
  const { data, error, ...rest } = useGetBatchApiOwners(ownerUuids);

  const owners = toOwner(data);

  useLogError(error);

  return {
    owners,
    error,
    ...rest,
  };
};
