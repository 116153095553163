import { useApiCreateBulkAdjustment } from 'apiHooks/funding/bulkAdjustmentFetchHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { BulkAdjustmentsCreateAttributes } from 'types/api/funding/types';
import { NewBulkAdjustmentsForm } from './NewBulkAdjustments';

export type UseCreateBulkAdjustmentResponse = [
  (args: CreateBulkAdjustmentArgs) => Promise<MutationResponse>,
  { data?: { success: boolean }; error?: Error; loading: boolean }
];

interface CreateBulkAdjustmentArgs {
  advanceRecordIds: number[];
  createBody: NewBulkAdjustmentsForm[];
}

const toBulkAdjustmentsAttributes = (
  bulkAdjustment: NewBulkAdjustmentsForm
): BulkAdjustmentsCreateAttributes => {
  if (bulkAdjustment.percentageSwitch) {
    return {
      start_date: bulkAdjustment.firstPaymentDate?.toString() ?? '',
      end_date: bulkAdjustment.lastPaymentDate?.toString() ?? '',
      // bulkAdjustment.adjustment will never actually be undefined
      amount_percentage: bulkAdjustment.adjustment ?? 0,
      notes: bulkAdjustment.notes,
      frequency: bulkAdjustment.frequency,
      ach_provider: bulkAdjustment.processor,
    };
  } else {
    return {
      start_date: bulkAdjustment.firstPaymentDate?.toString() ?? '',
      end_date: bulkAdjustment.lastPaymentDate?.toString() ?? '',
      // bulkAdjustment.adjustment will never actually be undefined
      amount: bulkAdjustment.adjustment ?? 0,
      notes: bulkAdjustment.notes,
      frequency: bulkAdjustment.frequency,
      ach_provider: bulkAdjustment.processor,
    };
  }
};

export const useCreateBulkAdjustment = (): UseCreateBulkAdjustmentResponse => {
  const [createFn, { error, ...rest }] = useApiCreateBulkAdjustment();

  const createFunction = async (
    args: CreateBulkAdjustmentArgs
  ): Promise<MutationResponse> => {
    return createFn({
      advanceRecordIds: args.advanceRecordIds,
      createBody: args.createBody.map(toBulkAdjustmentsAttributes),
    });
  };

  useLogError(error);

  return [
    createFunction,
    {
      error,
      ...rest,
    },
  ];
};
