import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Icon,
  QualityIcon,
  Table,
  Tooltip,
} from '@forward-financing/fast-forward';
import {
  BucketValue,
  FraudScore,
  FraudScoringSubmission,
} from './fraudScoringContainer.types';
import {
  useGetFraudScoring,
  useGetOwnersBatch,
} from './fraudScoringContainerHooks';

export interface FraudScoringTableProps {
  submission?: FraudScoringSubmission;
}

const bucketToQuality = (bucket?: BucketValue): 'bad' | 'neutral' => {
  switch (bucket) {
    case 'high':
      return 'bad';
    case 'medium':
    default:
      return 'neutral';
  }
};

const bucketToCellColor = (
  bucket?: BucketValue
): 'red-100' | 'yellow-100' | undefined => {
  switch (bucket) {
    case 'high':
      return 'red-100';
    case 'medium':
      return 'yellow-100';
    default:
      return undefined;
  }
};

const showQualityIcon = (score?: FraudScore): boolean => {
  if (!score) {
    return false;
  }
  return (
    score.underwriterBucket === 'medium' || score.underwriterBucket === 'high'
  );
};

export interface FraudScoreCellProps {
  scoreType: string;
  fraudScore?: FraudScore;
}

const FraudScoreCell = ({
  fraudScore,
  scoreType,
}: FraudScoreCellProps): JSX.Element => {
  return (
    <Table.Cell
      backgroundColor={bucketToCellColor(fraudScore?.underwriterBucket)}
    >
      <Flex flexDirection="row" gap={2} alignItems="center">
        {fraudScore?.score ?? 'N/A'}
        {showQualityIcon(fraudScore) && (
          <Box data-testid={`quality-icon-${scoreType}`}>
            <QualityIcon
              size="lg"
              quality={bucketToQuality(fraudScore?.underwriterBucket)}
            />
          </Box>
        )}
      </Flex>
    </Table.Cell>
  );
};

export const FraudScoringTable = ({
  submission,
}: FraudScoringTableProps): JSX.Element => {
  const { fraudScores, error: fraudScoresError } = useGetFraudScoring(
    submission?.uuid
  );

  const { owners, error: ownersError } = useGetOwnersBatch(
    submission?.ownerUuids
  );

  return (
    <Box>
      {ownersError && (
        <Banner dismissable={false} variant="error">
          Failed to fetch owners.
        </Banner>
      )}
      {fraudScoresError && (
        <Banner dismissable={false} variant="error">
          Failed to fetch fraud scores.
        </Banner>
      )}

      {!!owners?.length && (
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Owner</Table.ColumnHeader>
            <Table.ColumnHeader>
              <Flex gap={2}>
                Abuse Score{' '}
                <Tooltip
                  content={
                    'A composite synthetic fraud score that indicates whether an identity is likely synthetic'
                  }
                  position="top"
                  trigger={
                    <span>
                      <Icon name="info-circle" />
                    </span>
                  }
                />
              </Flex>
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              <Flex gap={2}>
                First Party Synthetic Score
                <Tooltip
                  content={
                    'Possibility that an applicant is using their true name, DOB, but the SSN doesn’t belong to them (manipulating their true identity)'
                  }
                  position="top"
                  trigger={
                    <span>
                      <Icon name="info-circle" />
                    </span>
                  }
                />
              </Flex>
            </Table.ColumnHeader>
            <Table.ColumnHeader>
              <Flex gap={2}>
                Third Party Synthetic Score
                <Tooltip
                  content={
                    'Possibility that an applicant is using a name, DOB, and SSN combo that is fabricated'
                  }
                  position="top"
                  trigger={
                    <span>
                      <Icon name="info-circle" />
                    </span>
                  }
                />
              </Flex>
            </Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {owners.map((owner) => {
              const ownerFraudScores = fraudScores?.find(
                (o) => o.ownerUuid === owner.ownerUuid
              );
              return (
                <Table.Row key={owner.uuid}>
                  <Table.Cell>{owner.fullName}</Table.Cell>
                  <FraudScoreCell
                    fraudScore={ownerFraudScores?.abuseScore}
                    scoreType="abuse"
                  />
                  <FraudScoreCell
                    fraudScore={ownerFraudScores?.firstPartySyntheticScore}
                    scoreType="first-party"
                  />
                  <FraudScoreCell
                    fraudScore={ownerFraudScores?.thirdPartySyntheticScore}
                    scoreType="third-party"
                  />
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Box>
  );
};
