/* eslint-disable promise/prefer-await-to-then */
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Loading, Subheading } from '@forward-financing/fast-forward';
import defaultTo from 'lodash/defaultTo';
import { toError } from 'helpers/errorUtils';
import {
  usePullRelativity6IndustryPrediction,
  useGetIndustryNaicsIndustryId,
} from 'components/PrequalForm/WizardFetchHooks';
import { useCreateRelativity6ArtifactsSubmission } from 'components/PrequalForm/AdditionalInformationForm/AdditionalInformationFormFetchHooks';
import { ErrorPage } from '../PrequalForm/ErrorPage';
import { Toast } from '../shared/Toast';
import {
  validate,
  validateZipCode,
  validateSsn,
  validatePhone,
  validateFein,
  validateEmail,
  validateURL,
  validateCapitalNeeded,
  validateDate,
} from '../../helpers/validations/FieldValidator';
import {
  isRequired,
  isNumber,
  isInBetween,
  isRoutingNumber,
  textHasNoSpecialChar,
  addressHasNoSpecialChar,
} from '../../helpers/validations/ValidationHelpers';

import { Partner } from '../../api/FundingClient/codecs';
import { User } from '../../api/AuthClient/codecs';
import {
  generateEmptyOpportunity,
  generateEmptySubmission,
  generateEmptyBankInfo,
  SubmissionObject,
} from '../../api/UnderwritingClient/utils/index';
import {
  UsState,
  Application,
  Account,
  Contact,
  BankInfo,
  Submission,
  WebPresences,
  WebPresenceResponse,
  Owner,
  Ownership,
  Opportunity,
  IndustryType,
  Bank,
} from '../../api/UnderwritingClient/codecs';
import { HTMLFormChangeOrMouseEvent } from '../../types/form';

import {
  setOwnershipPercentageForContacts,
  buildSubmission,
} from '../../api/UnderwritingClient/utils/DataBuilder';

import {
  sanitizeTargetValue,
  isPriorToApproval as isPriorToApprovalHelper,
} from '../../helpers/utils';
import { AccountInformationForm } from '../PrequalForm/AccountInformationForm/AccountInformationForm';
import { OwnerInformationForm } from '../PrequalForm/OwnerInformationForm';
import { BankInformationForm } from '../PrequalForm/BankInformationForm/BankInformationForm';
import { AdditionalInformationForm } from '../PrequalForm/AdditionalInformationForm/AdditionalInformationForm';
import { FFLogger } from '../../api/LogClient';
import { AssignmentInformationForm } from '../PrequalForm/AssignmentInformationForm/AssignmentInformationForm';
import { LogData } from '../../api/AnalyticsGatewayClient/codecs';
import { featureFlags } from '../../helpers/featureFlags';
import { createBank, fetchBanks } from './BankClientUtils';

export interface SubmissionsProps {
  getPartners: () => Promise<Partner[]>;
  getIndustryTypes: () => Promise<IndustryType[]>;
  getUsStates: () => Promise<UsState[]>;
  getAuthenticatedUser: () => Promise<User>;

  getCustomer: (customerUuid: string) => Promise<Account>;
  getApplication: (applicationUuid: string) => Promise<Application>;
  getContacts: (applicationUuid: string) => Promise<Contact[]>;
  getWebPresence: (accountUuid: string) => Promise<WebPresenceResponse>;
  getOpportunity: (opportunityUuid: string) => Promise<Opportunity>;
  fetchCustomerOwners: (customerUuid: string) => Promise<Owner[]>;
  upsertApplicationContacts: (
    applicationUuid: string,
    owners: Contact[]
  ) => Promise<Contact[]>;
  updateApplication: (
    application: Application,
    syncToCrmGW: boolean
  ) => Promise<Application>;
  updateCustomer: (account: Account, syncToCrmGW: boolean) => Promise<Account>;
  updateCustomerOwner: (owner: Owner) => Promise<Owner>;
  createCustomerOwner: (owner: Owner, customer_uuid: string) => Promise<Owner>;
  updateOwnership: (
    applicationUuid: string,
    ownerships: Ownership[]
  ) => Promise<void>;
  updateRenewalSubmitterEmail: (
    opportunityId: string,
    email: string
  ) => Promise<Opportunity>;
  bulkUpdateWebPresence: (
    accountUuid: string,
    webPresences?: WebPresences
  ) => Promise<WebPresenceResponse>;
  applicationUuid: string;
  sendUnmaskedFieldLogs: (
    user: User,
    source: string
  ) => (data: LogData) => Promise<void>;

  loadGooglePlacesLibrary: () => void;
}

export const Submissions = ({
  getPartners,
  getIndustryTypes,
  getUsStates,
  getAuthenticatedUser,
  getApplication,
  getCustomer,
  getContacts,
  getWebPresence,
  getOpportunity,
  fetchCustomerOwners,
  upsertApplicationContacts,
  updateApplication,
  updateCustomer,
  updateCustomerOwner,
  createCustomerOwner,
  updateOwnership,
  updateRenewalSubmitterEmail,
  bulkUpdateWebPresence,
  applicationUuid,
  sendUnmaskedFieldLogs,
  loadGooglePlacesLibrary,
}: SubmissionsProps): JSX.Element => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [industryTypes, setIndustryTypes] = useState<IndustryType[]>([]);
  const [usStates, setUsStates] = useState<UsState[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [submission, setSubmission] = useState<Submission>(
    generateEmptySubmission()
  );
  const [opportunity, setOpportunity] = useState<Opportunity>(
    generateEmptyOpportunity()
  );
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedInUser, setLoggedInUser] = useState<User>({
    id: 0,
    first_name: '',
    last_name: '',
    role: '',
    sub_role: null,
    email: '',
    uuid: '',
    anonymized_id: '',
    api_key: '',
    cell_phone: null,
    created_by: '',
    current_ip_address: null,
    home_phone: null,
    img_src: null,
    inserted_at: null,
    is_activated: null,
    last_signed_in_at: null,
    record_id: null,
    role_id: 0,
    sign_in_count: 0,
  });
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [bankError, setBankError] = useState<boolean>(false);
  const [hasUpdatedBusinessType, setHasUpdatedBusinessType] =
    useState<boolean>(false);

  const application: Application = {
    uuid: submission.uuid,
    record_id: submission.record_id,
    loan_use: submission.loan_use,
    capital_needed: submission.capital_needed,
    partner_id: submission.partner_id,
    partner_uuid: submission.partner_uuid,
    prequal_state_attributes: submission.prequal_state_attributes,
    submission_source: submission.submission_source,
    capitalNeededNotPresent: submission.capitalNeededNotPresent,
    ownership_percentages: submission.ownership_percentages,
    notes: submission.notes,
    sales_rep_email: submission.sales_rep_email,
    prequal_analyst_name: submission.prequal_analyst_name,
    customer_uuid: submission.customer_uuid,
    account_uuid: submission.account_uuid,
    available_renewal_url: submission.available_renewal_url,
  };

  const isPriorToApproval = (): boolean =>
    isPriorToApprovalHelper(opportunity.stage_name);

  // args: e: React.MouseEvent<HTMLButtonElement>
  const toggleToast = (value: boolean) => (): void => {
    setShowToast(value);
  };

  const isAccountInfoComplete = (account: Account): boolean => {
    const { bank_account } = account;

    return [
      validate([isRequired], account.legal_name).isValid,
      validate([isRequired], account.name).isValid,
      validate([addressHasNoSpecialChar, isRequired], account.address.street1)
        .isValid,
      validate([addressHasNoSpecialChar, isRequired], account.address.city)
        .isValid,
      validate([isRequired], account.address.state).isValid,
      validateZipCode(account.address.zip).isValid,
      validate([isRequired], account.entity_type).isValid,
      validate(
        [isRequired],
        account.industry_id ? account.industry_id.toString() : ''
      ).isValid,
      account.phoneNotPresent || validatePhone(account.phone).isValid,
      account.feinNotPresent || validateFein(account.fein || '').isValid,
      !account.started_on ||
        validateDate(account.started_on || '', 'business').isValid,
      validateURL(account.web_presences?.business_website?.url).isValid,
      validateURL(account.web_presences?.facebook?.url).isValid,
      validateURL(account.web_presences?.instagram?.url).isValid,
      validateURL(account.web_presences?.other?.url).isValid,
      validateURL(account.web_presences?.yelp?.url).isValid,

      // Routing number is allowed to be empty IFF the current server value is
      // also empty. Otherwise, it's required.
      bank_account && bank_account.routing_number
        ? isRoutingNumber(bank_account.routing_number || '')
        : true,
    ].every(Boolean);
  };

  const isApplicationInfoComplete = (
    sub: Submission,
    opp: Opportunity
  ): boolean => {
    return [
      validate([isRequired], sub.partner_id ? sub.partner_id.toString() : '')
        .isValid,
      sub.capitalNeededNotPresent ||
        validateCapitalNeeded(sub.capital_needed).isValid,
      !sub.sales_rep_email || validateEmail(sub.sales_rep_email || '').isValid,
      opp.type === 'Renewal'
        ? validateEmail(opp.renewal_submitter_email || '').isValid
        : true,
    ].every(Boolean);
  };

  const isBankInfoComplete = (bankAccount: BankInfo | null): boolean => {
    if (bankAccount) {
      return !bankAccount.zip || validateZipCode(bankAccount.zip).isValid;
    } else {
      return true;
    }
  };

  const isOwnerInfoComplete = (owners: (Owner | Contact)[]): boolean => {
    return owners
      .filter((element) => {
        if ('_destroy' in element) {
          return !element._destroy;
        }
        if ('deleted_at' in element) {
          return !element.deleted_at;
        }
        return true;
      })
      .map((owner) => {
        return [
          validate([isRequired, textHasNoSpecialChar], owner.first_name)
            .isValid,
          validate([isRequired, textHasNoSpecialChar], owner.last_name).isValid,
          validate(
            [isRequired, addressHasNoSpecialChar],
            owner.address?.street1
          ).isValid,
          validate([isRequired, addressHasNoSpecialChar], owner.address?.city)
            .isValid,
          validate([isRequired], owner.address?.state).isValid,
          owner.bornOnNotPresent ||
            validateDate(owner.born_on || '', 'owner').isValid,
          validateZipCode(owner.address?.zip).isValid,
          validate(
            [isNumber, isRequired, isInBetween(1, 100)],
            owner.ownership_percentage?.toString()
          ).isValid,
          owner.ssnNotPresent || validateSsn(owner.ssn).isValid,
          !owner.home_phone || validatePhone(owner.home_phone, false).isValid,
          !owner.cell_phone || validatePhone(owner.cell_phone, false).isValid,
          !owner.email || validateEmail(owner.email).isValid,
        ].every(Boolean);
      })
      .every(Boolean);
  };

  // Used for the Relativity 6 checkbox in AdditionalInformationForm
  const [hasVerifiedIndustry, setHasVerifiedIndustry] =
    useState<boolean>(false);

  const isValid = [
    isOwnerInfoComplete(submission.contacts),
    isAccountInfoComplete(submission.account),
    isApplicationInfoComplete(submission, opportunity),
    isBankInfoComplete(submission.account.bank_account),
    hasVerifiedIndustry || !featureFlags.show_relativity_6_industry_prediction,
  ].every(Boolean);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        document.title = 'Update Account/Customer Information';

        const [
          banksData,
          partnersData,
          industryTypesData,
          usStatesData,
          loggedInUserData,
          submissionData,
        ] = await Promise.all([
          fetchBanks(),
          getPartners(),
          getIndustryTypes(),
          getUsStates(),
          getAuthenticatedUser(),
          buildSubmission(
            applicationUuid,
            getApplication,
            getCustomer,
            getContacts,
            getWebPresence,
            fetchCustomerOwners,
            true
          ),
          loadGooglePlacesLibrary(),
        ]);

        const opportunityData = await getOpportunity(
          submissionData.opportunity_uuid || ''
        );

        setBanks(banksData);
        setPartners(partnersData);
        setIndustryTypes(industryTypesData);
        setUsStates(usStatesData);
        setLoggedInUser(loggedInUserData);
        setSubmission(submissionData);
        setOpportunity(opportunityData);
        setError(false);
        setLoading(false);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    };

    void fetchData();
  }, [
    applicationUuid,
    fetchCustomerOwners,
    getApplication,
    getAuthenticatedUser,
    getContacts,
    getCustomer,
    getIndustryTypes,
    getOpportunity,
    getPartners,
    getUsStates,
    getWebPresence,
    loadGooglePlacesLibrary,
    submission.opportunity_uuid,
  ]);

  // This was triggering an infinite re-render when not memoized
  // because it's a dependency in OwnerInformationForm.
  const storeOwners = useCallback((owners: Contact[]): void => {
    setSubmission((sub) => ({
      ...sub,
      contacts: owners,
    }));
  }, []);

  // This was triggering an infinite re-render when not memoized
  // because it's a dependency in BankInformationForm.
  const storeBankAccount = useCallback(
    (bankAccount: BankInfo): void => {
      const bank = banks.find(
        (bankFromState) => bankFromState.name === bankAccount.bank_name
      );

      bankAccount.bank_id = bank?.id || '';

      setSubmission((sub) => ({
        ...sub,
        account: {
          ...sub.account,
          bank_account: bankAccount,
        },
      }));
    },
    [banks]
  );

  const handleOpportunityChange = (e: HTMLFormChangeOrMouseEvent): void => {
    const target = (e.currentTarget || e.target) as HTMLInputElement;
    const { name, value } = target;

    setOpportunity((opp) => ({
      ...opp,
      [name]: value,
    }));
  };

  const handleApplicationChange = (
    object: SubmissionObject
  ): ((e: HTMLFormChangeOrMouseEvent) => void) => {
    return (e) => {
      e.persist();

      const target = (
        e.currentTarget ? e.currentTarget : e.target
      ) as HTMLInputElement;
      const value =
        target.type && target.type === 'checkbox'
          ? target.checked
          : sanitizeTargetValue(target);

      switch (object) {
        case SubmissionObject.Application:
          setSubmission((sub) => ({
            ...sub,
            [target.name]: value,
          }));
          break;
        case SubmissionObject.Account:
          setSubmission((sub) => ({
            ...sub,
            account: {
              ...sub.account,
              [target.name]: value,
            },
          }));
          break;
        case SubmissionObject.AccountAddress:
          setSubmission((sub) => ({
            ...sub,
            account: {
              ...sub.account,
              address: {
                ...sub.account.address,
                [target.name]: value,
              },
            },
          }));
          break;
        case SubmissionObject.WebPresence: {
          const webPresence =
            submission.account.web_presences?.[
              target.name as keyof WebPresences
            ];
          const previous_url = webPresence?.url;
          setSubmission((sub) => ({
            ...sub,
            account: {
              ...sub.account,
              web_presences: {
                ...sub.account.web_presences,
                [target.name]: {
                  ...webPresence,
                  url: value,
                  urlChanged: previous_url !== value,
                },
              },
            },
          }));
          break;
        }
      }
    };
  };

  const [
    pullRelativity6IndustryPrediction,
    {
      data: relativity6IndustryPredictionData,
      error: relativity6IndustryPredictionError,
      loading: relativity6IndustryPredictionLoading,
      responseReady: relativity6IndustryPredictionResponseReady,
    },
  ] = usePullRelativity6IndustryPrediction();

  useEffect(() => {
    const account = submission.account;

    const pullIndustryPrediction = async (): Promise<void> => {
      if (
        featureFlags.show_relativity_6_industry_prediction &&
        !relativity6IndustryPredictionResponseReady &&
        !relativity6IndustryPredictionError &&
        !relativity6IndustryPredictionLoading &&
        account.name &&
        account.address &&
        account.address.state &&
        submission.opportunity_uuid
      ) {
        await pullRelativity6IndustryPrediction({
          body: {
            businessName: account.name,
            street: [account.address.street1, account.address.street2]
              .filter(Boolean)
              .join(' '),
            state: account.address.state,
            country: 'USA', // Default to USA as we only serve US businesses
            ...(submission.opportunity_uuid && {
              referenceIds: [submission.opportunity_uuid], // This is actually the submissionUuid
            }),
          },
        });
      }
    };

    void pullIndustryPrediction();
  }, [
    pullRelativity6IndustryPrediction,
    relativity6IndustryPredictionLoading,
    relativity6IndustryPredictionResponseReady,
    relativity6IndustryPredictionError,
    submission.account,
    submission.opportunity_uuid,
  ]);

  // Filter by rank 0 in industry_prediction,
  // which will give us the most confident prediction
  const mostConfidentIndustry =
    relativity6IndustryPredictionData?.industryPredictions.find(
      (industry) => industry.rank === 0
    );

  const { data: industryNaicsIndustryIdData } = useGetIndustryNaicsIndustryId(
    mostConfidentIndustry?.naicsCode || '',
    '2017'
  );

  const suggestedIndustry = industryTypes.find(
    (industry) =>
      industry.id === Number(industryNaicsIndustryIdData?.industryId)
  );

  // Api Handlers

  const redirectToUW = (): void => {
    window.location.replace(
      `${window.location.origin}/submissions/${submission.opportunity_uuid}`
    );
  };

  const updateOrCreateCustomerOwner = (owners: Contact[]): Promise<Owner[]> => {
    return Promise.all(
      owners.map((o) => {
        const createCustomerIfCustomerUuid = (): Promise<Owner> => {
          if (submission.customer_uuid) {
            return createCustomerOwner(o, submission.customer_uuid);
          } else {
            throw new Error('Customer uuid is missing');
          }
        };

        return o.uuid ? updateCustomerOwner(o) : createCustomerIfCustomerUuid();
      })
    );
  };

  const createNewBank = async (bankName: string): Promise<void> => {
    try {
      const bank = await createBank(bankName);
      setBanks((prevState) => [...prevState, bank]);
      setSubmission((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          bank_account: {
            id: prevState.account.bank_account?.id || null,
            account_holder_full_name:
              prevState.account.bank_account?.account_holder_full_name ?? null,
            account_number:
              prevState.account.bank_account?.account_number ?? null,
            city: prevState.account.bank_account?.city ?? null,
            state: prevState.account.bank_account?.state ?? null,
            zip: prevState.account.bank_account?.zip ?? null,
            routing_number:
              prevState.account.bank_account?.routing_number ?? null,
            bank_id: bank.id,
            bank_name: bank.name,
          },
        },
      }));
    } catch (e: unknown) {
      const err = toError(e);
      setBankError(true);
      FFLogger.error(err);
    }
  };

  const [createArtifacts] = useCreateRelativity6ArtifactsSubmission();

  const onSubmit = async (): Promise<void> => {
    setIsSubmitting(true);

    const account = submission.account;
    const owners = submission.contacts;

    const maybeUpdateRenewalSubmitterEmail =
      opportunity.type === 'Renewal'
        ? updateRenewalSubmitterEmail(
            opportunity.uuid,
            opportunity.renewal_submitter_email || ''
          )
        : Promise.resolve(null);

    const isBusinessTypeUpdated =
      Number(account.industry_id) !== Number(suggestedIndustry?.id) &&
      hasUpdatedBusinessType;

    if (
      featureFlags.show_relativity_6_industry_prediction &&
      isBusinessTypeUpdated
    ) {
      try {
        await createArtifacts({
          submissionUuid: submission.opportunity_uuid || '',
          artifactData: {
            predictedIndustry: defaultTo(suggestedIndustry?.id?.toString(), ''),
            assignedIndustry: defaultTo(account.industry_id?.toString(), ''),
            confidenceScore: defaultTo(
              mostConfidentIndustry?.confidenceScore?.toString(),
              ''
            ),
            naicsCode: defaultTo(mostConfidentIndustry?.naicsCode, ''),
            naicsVersion: '2017',
            artifactCase: 'Predicted Industry',
            artifactType: 'relativity',
            naicsCodeTitle: defaultTo(mostConfidentIndustry?.industryName, ''),
          },
        });
      } catch (e: unknown) {
        const err = toError(e);
        FFLogger.error(err);
      }
    }
    try {
      await updateApplication(application, true);

      const webPresencesPromise = bulkUpdateWebPresence(
        application.account_uuid || '',
        account.web_presences
      );
      const contactsPromise = featureFlags.use_owner_not_contact
        ? []
        : upsertApplicationContacts(application.uuid || '', owners);
      const ownerPromise = featureFlags.use_owner_not_contact
        ? updateOrCreateCustomerOwner(owners)
        : [];

      const [, contacts] = await Promise.all([
        webPresencesPromise,
        contactsPromise,
        ownerPromise,
      ]);

      if (featureFlags.use_owner_not_contact) {
        try {
          await Promise.all([
            updateCustomer(account, true), // account and additional info
            maybeUpdateRenewalSubmitterEmail,
          ]);
          redirectToUW();
        } catch (e) {
          const err = toError(e);
          FFLogger.error(err);
          throw err;
        }
      } else {
        const modifiedContacts: Contact[] = setOwnershipPercentageForContacts(
          contacts,
          owners
        );
        const ownerships: Ownership[] = modifiedContacts.map(
          (contact): Ownership => ({
            contact_uuid: contact.uuid || '',
            ownership_percentage: (contact.ownership_percentage || 0) * 1,
          })
        );

        try {
          await updateOwnership(application.uuid || '', ownerships);
          await Promise.all([
            updateApplication(application, true),
            updateCustomer(account, true), // account and additional info
            maybeUpdateRenewalSubmitterEmail,
          ]);
          redirectToUW();
        } catch (e) {
          const err = toError(e);
          setShowToast(true);
          FFLogger.error(err);
        }
      }
    } catch (e) {
      const err = toError(e);
      setShowToast(true);
      setIsSubmitting(false);
      FFLogger.error(err);
    }
  };

  const handleCancel = (e: React.MouseEvent): void => {
    e.preventDefault();

    setIsCancelling(true);
    redirectToUW();
  };

  // Admins, super admins, and prequal team leads can reassign PQ analysts & UWs
  // Prequal Analysts can reassign UWs
  const canUpdateAssignments = (): boolean => {
    const isAdminOrSuperAdmin = ['admin', 'super_admin'].includes(
      loggedInUser.role || ''
    );

    const isPrequalTeamLead =
      loggedInUser.role === 'processing' &&
      loggedInUser.sub_role === 'team_lead';

    const isPrequalAnalyst = loggedInUser.sub_role === 'prequal';

    const isProcessing = loggedInUser.sub_role === 'processing';

    return (
      isAdminOrSuperAdmin ||
      isPrequalTeamLead ||
      isPrequalAnalyst ||
      isProcessing
    );
  };

  const messageOnErrorState = (): JSX.Element => {
    return opportunity.uuid ? (
      <>
        <Subheading>Something went wrong...</Subheading>
        <Subheading variant="section">Please try again</Subheading>
      </>
    ) : (
      <>
        <Subheading>You cannot edit this submission now</Subheading>
        <Subheading variant="section">Please try again in a minute</Subheading>
      </>
    );
  };

  const renderComponents = (): JSX.Element => {
    return (
      <div className="submission-edit-container">
        <div className="submission-edit">
          <h2 className="has-text-centered main-header">
            Update Account/Customer Information
          </h2>
          <AccountInformationForm
            partner_id={submission.partner_id}
            account={submission.account}
            usStates={usStates}
            handleAccountChange={handleApplicationChange(
              SubmissionObject.Account
            )}
            handleAccountAddressChange={handleApplicationChange(
              SubmissionObject.AccountAddress
            )}
            onSubmit={
              /* istanbul ignore next */
              () => null
            }
            forPrequal={false}
          />
          <OwnerInformationForm
            submitActionLabel=""
            storeOwners={storeOwners}
            accountAddress={submission.account.address}
            usStates={usStates}
            owners={submission.contacts}
            onBack={
              /* istanbul ignore next */
              () => null
            }
            onSubmit={
              /* istanbul ignore next */
              () => null
            }
            declinedIcon={
              /* istanbul ignore next */
              () => <></>
            }
            forPrequal={false}
            sendUnmaskedFieldLogs={sendUnmaskedFieldLogs(
              loggedInUser,
              'Submission Editing'
            )}
          />
          <BankInformationForm
            storeBankAccount={storeBankAccount}
            usStates={usStates}
            setBankError={(value: boolean): void => setBankError(value)}
            bankError={bankError}
            fullBankInfoIsVisible={isPriorToApproval()}
            banks={banks}
            stageName={opportunity.stage_name}
            bankAccount={
              submission.account.bank_account || generateEmptyBankInfo()
            }
            createBank={createNewBank}
            sendUnmaskedFieldLogs={sendUnmaskedFieldLogs(
              loggedInUser,
              'Submission Editing'
            )}
            accountUuid={submission.account.uuid}
          />
          <AdditionalInformationForm
            partner_id={submission.partner_id}
            submission={submission}
            opportunity={opportunity}
            industryTypes={industryTypes}
            partners={partners}
            loggedInUser={loggedInUser}
            handleApplicationChange={handleApplicationChange(
              SubmissionObject.Application
            )}
            handleAccountChange={handleApplicationChange(
              SubmissionObject.Account
            )}
            handleOpportunityChange={handleOpportunityChange}
            handleWebPresenceChange={handleApplicationChange(
              SubmissionObject.WebPresence
            )}
            onBack={
              /* istanbul ignore next */
              () => null
            }
            onSubmit={
              /* istanbul ignore next */
              () => null
            }
            declinedIcon={
              /* istanbul ignore next */
              () => <></>
            }
            forPrequal={false}
            mostConfidentIndustry={mostConfidentIndustry}
            suggestedIndustry={suggestedIndustry}
            hasVerifiedIndustry={hasVerifiedIndustry}
            setHasVerifiedIndustry={setHasVerifiedIndustry}
            hasUpdatedBusinessType={hasUpdatedBusinessType}
            setHasUpdatedBusinessType={setHasUpdatedBusinessType}
          />
          {canUpdateAssignments() ? (
            <AssignmentInformationForm
              opportunity={opportunity}
              submission={submission}
              handleApplicationChange={handleApplicationChange(
                SubmissionObject.Application
              )}
              isSubmitting={isSubmitting}
            />
          ) : null}
        </div>
        <div className="columns submission-buttons">
          <div className="column has-text-right">
            {isCancelling ? (
              <Loading size="small" />
            ) : (
              <Button
                variant="secondary"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            )}
            {isSubmitting ? (
              <Loading size="small" />
            ) : (
              <Button disabled={!isValid || isCancelling} onClick={onSubmit}>
                Update Application
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="submissions-form">
      <div className="submission-header">
        {showToast ? (
          <Toast
            mainMessage="Something went wrong, please try again."
            onClose={toggleToast(false)}
            leadingIcon="fas fa-exclamation-triangle fa-2x"
          />
        ) : null}
      </div>
      <div className="form-background">
        <div className="form-container">
          {loading && <Loading text="Loading Submission Data" />}
          {error || (!opportunity && !loading) ? (
            <ErrorPage
              mainMessage="Oops!"
              secondaryMessages={messageOnErrorState()}
              buttonText="Reload the page"
              buttonIcon="sync"
              onClick={(): void => {
                window.location.reload();
              }}
            />
          ) : (
            !loading && renderComponents()
          )}
        </div>
      </div>
    </div>
  );
};
