import React, { useState } from 'react';

// Components
import {
  Button,
  Flex,
  Loading,
  formatPhoneNumber,
  formatCurrency,
  formatDateString,
  Text,
} from '@forward-financing/fast-forward';
import defaultTo from 'lodash/defaultTo';
import { UnderwritingClient } from 'api/UnderwritingClient';
import { featureFlags } from 'helpers/featureFlags';
import { BorderedContainer } from '../../shared/BorderedContainer';
import { DeclineModal } from '../DeclineModal/DeclineModal';
import {
  validate,
  validateZipCode,
  validateSsn,
  validateOwnership,
} from '../../../helpers/validations/FieldValidator';
import { isRequired } from '../../../helpers/validations/ValidationHelpers';
import { HashMap } from '../../../api/codecs';
import { Partner } from '../../../api/FundingClient/codecs';
import {
  Submission,
  Decline,
} from '../../../api/UnderwritingClient/codecs/submission';
import { IndustryType } from '../../../api/UnderwritingClient/codecs/index';
import { DeclineDrivers } from '../../../api/BankingClient/codecs';

import { findPartner, totalOwnership } from '../../../helpers/utils';
import { LogData } from '../../../api/AnalyticsGatewayClient/codecs';
import { IndustryPredictionResponse } from '../Wizard.types';
import { OwnerSection } from './OwnerSection';
import { AdditionalInformationSection } from './AdditionalInformationSection';
import { AccountInformationSection } from './AccountInformationSection';

export interface OverviewPageProps {
  submission: Submission;
  partners: Partner[];
  industryTypes: IndustryType[];
  handleEditAccountInformation: () => void;
  handleEditAdditionalInformation: () => void;
  handleEditOwnerInformation: () => void;
  handlePrequalComplete: () => Promise<void>;
  declineDriversList: DeclineDrivers;
  toggleDeclineModal: () => void;
  setDecline: (decline: Decline) => void;
  shouldDisplayDeclineModal: boolean;
  isDeclined: boolean;
  handleDeclineAndSubmit: () => Promise<void>;
  declinedIcon: () => JSX.Element;
  sendUnmaskedFieldLogs: (data: LogData) => Promise<void>;
  mostConfidentIndustry?: IndustryPredictionResponse;
  suggestedIndustry?: IndustryType;
}
export type ValidationHash = HashMap<HashMap<string | boolean | number>>;
export type Row = {
  id: string;
  left?: {
    name?: string;
    value?: string | number;
    isValid: boolean;
    shouldBeMasked?: boolean;
    ariaLabel?: string;
  };
  right?: {
    name?: string;
    leadingIcon?: string;
    value?: string | number;
    isValid: boolean;
    shouldBeMasked?: boolean;
    ariaLabel?: string;
  };
};

export type OwnerRow = {
  objectUuid: string;
  fields: Row[];
};

export const OverviewPage = (props: OverviewPageProps): JSX.Element => {
  const submission = props.submission;

  const partner = findPartner(props.partners, +submission.partner_id);
  const partnerName: string = partner ? partner.label : '';

  const industry = props.industryTypes.find(
    (industryType) => industryType.id === submission.account.industry_id
  );
  const industryName: string = industry ? industry.name : '';

  const [isLoadingSubmitAndDecline, setIsLoadingSubmitAndDecline] =
    useState(false);
  const [isLoadingCompleteApplication, setIsLoadingCompleteApplication] =
    useState(false);

  // args: e: React.MouseEvent<HTMLButtonElement>
  const handleSubmit = async (): Promise<void> => {
    if (isOverviewPageComplete) {
      setIsLoadingCompleteApplication(true);
      await props.handlePrequalComplete();
      setIsLoadingCompleteApplication(false);
    }
  };

  const naicsConfidenceScore = props.mostConfidentIndustry?.confidenceScore
    ? `${Math.round(props.mostConfidentIndustry.confidenceScore * 100)}%`
    : 'N/A';

  const accountFields: Row[] = [
    {
      id: 'accountFields-1',
      left: {
        name: 'Legal Name',
        value: submission.account.legal_name,
        isValid: validate([isRequired], submission.account.legal_name).hasValue,
      },
      right: {
        name: 'Street 1',
        value: submission.account.address.street1,
        isValid: validate([isRequired], submission.account.address.street1)
          .hasValue,
      },
    },
    {
      id: 'accountFields-2',
      left: {
        name: 'DBA',
        value: submission.account.name,
        isValid: validate([isRequired], submission.account.name).hasValue,
      },
      right: {
        name: 'City',
        value: submission.account.address.city,
        isValid: validate([isRequired], submission.account.address.city)
          .hasValue,
      },
    },
    {
      id: 'accountFields-3',
      left: {
        name: 'Phone',
        value: formatPhoneNumber(submission.account.phone || ''),
        isValid: submission.account.phoneNotPresent
          ? true
          : validate([isRequired], submission.account.phone).hasValue,
      },
      right: {
        name: 'State',
        value: submission.account.address.state,
        isValid: validate([isRequired], submission.account.address.state)
          .hasValue,
      },
    },
    {
      id: 'accountFields-4',
      left: {
        name: 'Federal Tax Id',
        value: submission.account.fein || '',
        isValid: submission.account.feinNotPresent
          ? true
          : validate([isRequired], submission.account.fein).hasValue,
      },
      right: {
        name: 'Zip Code',
        value: submission.account.address.zip,
        isValid: validateZipCode(submission.account.address.zip).hasValue,
      },
    },
  ];

  const additionalFieldsWithNaics: Row[] = [
    {
      id: 'additionalFields-1',
      left: {
        name: 'Amount Needed',
        value: props.submission.capital_needed
          ? formatCurrency(parseFloat(props.submission.capital_needed))
          : '',
        isValid: submission.capitalNeededNotPresent
          ? true
          : validate([isRequired], submission.capital_needed).hasValue,
      },
    },
    {
      id: 'additionalFields-2',
      left: { name: 'Use of Funds', value: submission.loan_use, isValid: true },
    },
    {
      id: 'additionalFields-3',
      left: {
        name: 'NAICS Code',
        value: defaultTo(props.mostConfidentIndustry?.naicsCode, 'N/A'),
        isValid: true,
      },
    },
    {
      id: 'additionalFields-4',
      left: {
        name: 'NAICS Confidence Score',
        value: naicsConfidenceScore,
        isValid: true,
      },
    },
    {
      id: 'additionalFields-5',
      left: {
        name: 'NAICS Industry',
        value: defaultTo(props.mostConfidentIndustry?.industryName, 'N/A'),
        isValid: true,
      },
    },
    {
      id: 'additionalFields-6',
      left: {
        name: 'Suggested Business Type',
        value: defaultTo(props.suggestedIndustry?.name, 'N/A'),
        isValid: true,
      },
    },
    {
      id: 'additionalFields-7',
      left: {
        name: 'Business Type',
        value: industryName,
        isValid: validate(
          [isRequired],
          submission.account.industry_id
            ? submission.account.industry_id.toString()
            : ''
        ).hasValue,
      },
    },
    {
      id: 'additionalFields-8',
      left: {
        name: 'Entity Type',
        value: submission.account.entity_type,
        isValid: validate([isRequired], submission.account.entity_type)
          .hasValue,
      },
    },
    {
      id: 'additionalFields-9',
      left: {
        name: 'Started On',
        value: submission.account.started_on
          ? formatDateString(submission.account.started_on)
          : '',
        isValid: true,
      },
    },
    {
      id: 'additionalFields-10',
      left: {
        name: 'Email Content',
        value: submission.notes,
        isValid: validate([isRequired], submission.notes).hasValue,
      },
    },
    {
      id: 'additionalFields-11',
      left: {
        name: 'Partner Sales Rep Email',
        value: submission.sales_rep_email || '',
        isValid: true,
      },
    },
    {
      id: 'additionalFields-12',
      left: {
        name: 'Partner',
        value: partnerName,
        isValid: validate(
          [isRequired],
          submission.partner_id ? submission.partner_id.toString() : ''
        ).hasValue,
      },
    },
  ];

  const additionalFieldsWithoutNaics: Row[] = [
    {
      id: 'additionalFields-1',
      left: {
        name: 'Amount Needed',
        value: props.submission.capital_needed
          ? formatCurrency(parseFloat(props.submission.capital_needed))
          : '',
        isValid: submission.capitalNeededNotPresent
          ? true
          : validate([isRequired], submission.capital_needed).hasValue,
      },
    },
    {
      id: 'additionalFields-2',
      left: { name: 'Use of Funds', value: submission.loan_use, isValid: true },
    },
    {
      id: 'additionalFields-3',
      left: {
        name: 'Business Type',
        value: industryName,
        isValid: validate(
          [isRequired],
          submission.account.industry_id
            ? submission.account.industry_id.toString()
            : ''
        ).hasValue,
      },
    },
    {
      id: 'additionalFields-4',
      left: {
        name: 'Entity Type',
        value: submission.account.entity_type,
        isValid: validate([isRequired], submission.account.entity_type)
          .hasValue,
      },
    },
    {
      id: 'additionalFields-5',
      left: {
        name: 'Started On',
        value: submission.account.started_on
          ? formatDateString(submission.account.started_on)
          : '',
        isValid: true,
      },
    },
    {
      id: 'additionalFields-6',
      left: {
        name: 'Email Content',
        value: submission.notes,
        isValid: validate([isRequired], submission.notes).hasValue,
      },
    },
    {
      id: 'additionalFields-7',
      left: {
        name: 'Partner Sales Rep Email',
        value: submission.sales_rep_email || '',
        isValid: true,
      },
    },
    {
      id: 'additionalFields-8',
      left: {
        name: 'Partner',
        value: partnerName,
        isValid: validate(
          [isRequired],
          submission.partner_id ? submission.partner_id.toString() : ''
        ).hasValue,
      },
    },
  ];

  const additionalFields = featureFlags.show_relativity_6_industry_prediction
    ? additionalFieldsWithNaics
    : additionalFieldsWithoutNaics;

  const contactsFields: OwnerRow[] = submission.contacts.map((owner) => {
    const fieldsRow: Row[] = [
      {
        id: `contactsFields-1${owner.id}`,
        left: {
          name: 'First Name',
          value: owner.first_name,
          isValid: validate([isRequired], owner.first_name).hasValue,
        },
        right: {
          name: 'Street Address 1',
          value: owner.address?.street1 || '',
          isValid: validate([isRequired], owner.address?.street1).hasValue,
        },
      },
      {
        id: `contactsFields-2${owner.id}`,
        left: {
          name: 'Last Name',
          value: owner.last_name,
          isValid: validate([isRequired], owner.last_name).hasValue,
        },
        right: {
          name: 'City',
          value: owner.address?.city || '',
          isValid: validate([isRequired], owner.address?.city).hasValue,
        },
      },
      {
        id: `contactsFields-3${owner.id}`,
        left: {
          name: 'SSN',
          value: owner.ssn || '',
          isValid: owner.ssnNotPresent ? true : validateSsn(owner.ssn).hasValue,
          shouldBeMasked: true,
        },
        right: {
          name: 'State',
          value: owner.address?.state || '',
          isValid: validate([isRequired], owner.address?.state).hasValue,
        },
      },
      {
        id: `contactsFields-4${owner.id}`,
        left: {
          name: 'Date of Birth',
          value: owner.born_on || '',
          isValid: owner.bornOnNotPresent
            ? true
            : validate([isRequired], owner.born_on).hasValue,
          shouldBeMasked: true,
        },
        right: {
          name: 'Zip Code',
          value: owner.address?.zip || '',
          isValid: validateZipCode(owner.address?.zip || '').hasValue,
        },
      },
      {
        id: `contactsFields-5${owner.id}`,
        left: {
          name: 'Title',
          value: owner.title || '',
          isValid: true,
        },
        right: {
          name: 'Home Phone',
          value: formatPhoneNumber(owner.home_phone || ''),
          isValid: true,
        },
      },
      {
        id: `contactsFields-6${owner.id}`,
        left: {
          name: 'Ownership %',
          value: owner.ownership_percentage,
          isValid: validateOwnership(
            owner.ownership_percentage?.toString() || '',
            totalOwnership(submission.contacts)
          ).isValid,
        },
        right: {
          name: 'Mobile Phone',
          value: formatPhoneNumber(owner.cell_phone || ''),
          isValid: true,
        },
      },
      {
        id: `contactsFields-7${owner.id}`,
        right: {
          name: 'Email Address',
          value: owner.email || '',
          isValid: true,
        },
      },
    ];
    return {
      objectUuid: owner.uuid || '',
      fields: fieldsRow,
    };
  });

  const isSectionComplete = (fields: Row[]): boolean => {
    return fields.every((row) => {
      const rightValid = !row.right || (row.right && row.right.isValid);
      const leftValid = !row.left || (row.left && row.left.isValid);
      return leftValid && rightValid;
    });
  };

  const isAccountInfoComplete = isSectionComplete(accountFields);
  const isAdditionalInfoComplete = isSectionComplete(additionalFields);
  const isOwnerSectionComplete = contactsFields.map((owner) => {
    return isSectionComplete(owner.fields);
  });

  const isOverviewPageComplete = [
    isOwnerSectionComplete.every(Boolean),
    isAccountInfoComplete,
    isAdditionalInfoComplete,
  ].every(Boolean);

  const setAndSubmitDecline = (decline: Decline): void => {
    props.setDecline(decline);
    void submitDecline();
  };

  const submitDecline = async (): Promise<void> => {
    setIsLoadingSubmitAndDecline(true);
    await props.handleDeclineAndSubmit();
    setIsLoadingSubmitAndDecline(false);
  };

  const handleRenewalRedirect = async (): Promise<void> => {
    const applicationUuid = props.submission.uuid;
    const accountUuid = props.submission.account_uuid;
    const contactUuids = props.submission.contacts.map(
      (contact) => contact.uuid
    );
    const analystName = props.submission.prequal_analyst_name;

    await UnderwritingClient.deleteOrphanedApplication(applicationUuid);

    await UnderwritingClient.deleteOrphanedAccount(accountUuid);

    contactUuids.forEach(async (id) => {
      await UnderwritingClient.deleteOrphanedContact(id);
    });

    const submissionUrl =
      props.submission.available_renewal_url?.split('/') || [];
    const submissionUuid = submissionUrl[submissionUrl.length - 1];
    await UnderwritingClient.updateAnalystName(submissionUuid, analystName);

    props.submission.available_renewal_url &&
      window.location.replace(props.submission.available_renewal_url);
  };

  const handleSendLogs = async (
    field_name?: string,
    objectUuid?: string,
    object_type?: string
  ): Promise<void> => {
    const owner = props.submission.contacts.find(
      (contact) => contact.uuid === objectUuid
    );

    await props.sendUnmaskedFieldLogs?.({
      field_name: field_name,
      object_uuid: objectUuid || '',
      name: `${owner?.first_name} ${owner?.last_name}`,
      action: 'show',
      user_uuid: '',
      user_email: '',
      source: '',
      timestamp: '',
      object_type: object_type,
    });
  };

  return (
    <div>
      <BorderedContainer
        label="Overview"
        labelIcon={props.declinedIcon()}
        alertMessage="Verify that all information is correct before submitting the form."
      >
        <div className="overview-page">
          <AccountInformationSection
            submissionFields={accountFields}
            isSectionComplete={isAccountInfoComplete}
            handleNavigation={props.handleEditAccountInformation}
          />
          <AdditionalInformationSection
            submissionFields={additionalFields}
            isSectionComplete={isAdditionalInfoComplete}
            handleNavigation={props.handleEditAdditionalInformation}
          />
          <OwnerSection
            submissionFields={contactsFields}
            isSectionComplete={isOwnerSectionComplete.every(Boolean)}
            handleNavigation={props.handleEditOwnerInformation}
            handleSendLogs={handleSendLogs}
          />
        </div>
      </BorderedContainer>
      <Flex alignItems="center" justifyContent="flex-end" gap={2} px={4} pb={3}>
        {props.submission.available_renewal_url && (
          <Text bold color="danger">
            This customer has an available renewal. Please use existing Renewal
            submission.
          </Text>
        )}
        {isLoadingSubmitAndDecline ? (
          <Loading size="small" />
        ) : (
          <>
            {props.isDeclined ? (
              <Button
                onClick={submitDecline}
                startIcon={['far', 'times-circle']}
                variant="danger"
                disabled={
                  !isOverviewPageComplete || isLoadingCompleteApplication
                }
              >
                Submit and Decline
              </Button>
            ) : (
              <DeclineModal
                declineDriverList={props.declineDriversList}
                closeAction={props.toggleDeclineModal}
                submitAction={setAndSubmitDecline}
                isActive={props.shouldDisplayDeclineModal}
                trigger={
                  <Button
                    startIcon={['far', 'times-circle']}
                    variant="danger"
                    disabled={
                      !isOverviewPageComplete ||
                      isLoadingCompleteApplication ||
                      !!props.submission.available_renewal_url
                    }
                  >
                    Submit and Decline
                  </Button>
                }
              />
            )}
          </>
        )}
        {!props.isDeclined && (
          <>
            {isLoadingCompleteApplication ? (
              <Loading size="small" />
            ) : (
              <>
                {props.submission.available_renewal_url ? (
                  <Button onClick={handleRenewalRedirect}>
                    Go to Existing Renewal
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    disabled={
                      !isOverviewPageComplete || isLoadingSubmitAndDecline
                    }
                  >
                    Complete Application
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Flex>
    </div>
  );
};
