import React from 'react';
import {
  Box,
  Flex,
  formatDateString,
  Link,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { featureFlags } from '../../../helpers/featureFlags';
import { CustomerUcc } from './businessOverview.types';

export interface UccTableProps {
  uccs: CustomerUcc[];
  reportId: number;
  customerUuid: string;
  submissionUuid: string;
}

const isActiveUcc = (ucc: CustomerUcc): boolean =>
  !ucc.isExpired && !ucc.isReleased;

export const UccTable = ({
  uccs,
  reportId,
  customerUuid,
  submissionUuid,
}: UccTableProps): JSX.Element => {
  const activeUccs = uccs.filter(isActiveUcc);

  const reportUrl =
    featureFlags.use_new_business_ucc && customerUuid
      ? new URL(
          `dashboard/submission/${submissionUuid}/customers/${customerUuid}/ucc_report`,
          window.location.origin
        )
      : new URL(`/admin/ucc_documents/${reportId}`, UNDERWRITING_BASE_URL());

  return (
    <Box>
      <Flex gap={3} alignItems="center">
        <Subheading variant="subsection">UCCs</Subheading>
        {uccs.length > 0 && (
          <Link newTab href={reportUrl}>
            View Report
          </Link>
        )}
      </Flex>
      <Text>
        Active: {activeUccs.length}; Closed: {uccs.length - activeUccs.length}
      </Text>
      {activeUccs.length === 0 ? (
        <Text>No UCC Data</Text>
      ) : (
        <Table caption="Customer UCC Documents">
          <Table.Head>
            <Table.ColumnHeader>Secured Party</Table.ColumnHeader>
            <Table.ColumnHeader>Date Filed</Table.ColumnHeader>
            <Table.ColumnHeader>Collateral Description</Table.ColumnHeader>
          </Table.Head>
          <Table.Body>
            {activeUccs.map((ucc) => (
              <Table.Row key={`${ucc.dateFiled}_${ucc.collateralDescription}`}>
                <Table.Cell columnWidth="medium">
                  {ucc.securedParty.map((party) => (
                    <Text key={party}>{party}</Text>
                  ))}
                </Table.Cell>
                <Table.Cell columnWidth="small">
                  {ucc.dateFiled && formatDateString(ucc.dateFiled)}
                </Table.Cell>
                <Table.Cell columnWidth="large" overflowStrategy="wrap">
                  {ucc.collateralDescription}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Box>
  );
};
