import React, { useState, useEffect } from 'react';
import EmailValidator from 'email-validator';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import { UnderwritingClient } from 'api/UnderwritingClient';
import { Contact, UsState, Address } from 'api/UnderwritingClient/codecs';
import { LogData } from 'api/AnalyticsGatewayClient/codecs';
import { FFLogger } from 'api/LogClient';
import { toError } from 'helpers/errorUtils';
import { onlyContainsValidCharacters } from 'helpers/validations/validations';
import {
  Box,
  Flex,
  Banner,
  Card,
  Divider,
  Button,
  Loading,
  IconButton,
  Text,
} from '@forward-financing/fast-forward';
import { OwnerInformationForm } from './OwnerInformationForm';
import {
  createContact,
  deleteContact,
  updateContact,
} from './OwnerInformationFetchUtils';
import { generateEmptyContact } from 'api/UnderwritingClient/utils';

export interface OwnerInformationStepProps {
  contacts: Contact[];
  accountAddress: Address;
  applicationUuid: string;
  onBack: () => void;
  handleOwnerStepCompleted: (contacts: Contact[]) => void;
  proceedToOverview: boolean;
  sendUnmaskedFieldLogs: (data: LogData) => Promise<void>;
}

const isNameValid = (name: string): boolean => {
  return name.trim() !== '';
};

const isEmailValid = (email: string): boolean => {
  return email.trim() !== '' && EmailValidator.validate(email);
};

const isOwnershipValid = (ownership: number): boolean => {
  return !Number.isNaN(ownership) && ownership >= 1 && ownership <= 100;
};

const isTitleValid = (title: string): boolean => {
  return onlyContainsValidCharacters(title);
};

const isStreetValid = (street: string): boolean => {
  return street.trim() !== '' && onlyContainsValidCharacters(street.trim());
};

const isCityValid = (city: string): boolean => {
  return city.trim() !== '' && onlyContainsValidCharacters(city.trim());
};

const isStateValid = (state: string): boolean => {
  return state !== '';
};

const isZipValid = (zip: string): boolean => {
  const sanitized = zip.replace('-', '');
  return sanitized.length === 5 || sanitized.length === 9;
};

const isDobValid = (dob: string): boolean => {
  const bornOn = new Date(dob);
  const today = Date.now();

  // Date value is valid
  // AND Owner's age is greater than 18
  // AND Owner's age is not greater than 100
  return (
    isValid(bornOn) &&
    isBefore(bornOn, sub(today, { years: 18 })) &&
    isAfter(bornOn, sub(today, { years: 100 }))
  );
};

const isSsnValid = (ssn: string): boolean => {
  return ssn.length === 9;
};

const isPhoneValid = (phone: string): boolean => {
  return phone.length === 10;
};

export function OwnerInformationStep(
  props: OwnerInformationStepProps
): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>(props.contacts);
  const [activeOwner, setActiveOwner] = useState<Contact>(props.contacts[0]);
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [states, setStates] = useState<UsState[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStates = async (): Promise<void> => {
      try {
        const response = await UnderwritingClient.fetchStates();

        setStates(response);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchStates();
  }, []);

  useEffect(() => {
    setCurrentIdx(contacts.findIndex((c) => c.key === activeOwner.key));
  }, [contacts, activeOwner]);

  const isContactValid = (contact: Contact): boolean => {
    return (
      isNameValid(contact.first_name ?? '') &&
      isNameValid(contact.last_name ?? '') &&
      (isSsnValid(contact.ssn ?? '') || contact.ssnNotPresent === true) &&
      (isDobValid(contact.born_on ?? '') ||
        contact.bornOnNotPresent === true) &&
      isStreetValid(contact.address?.street1 ?? '') &&
      isCityValid(contact.address?.city ?? '') &&
      isStateValid(contact.address?.state ?? '') &&
      isZipValid(contact.address?.zip ?? '') &&
      isOwnershipValid(contact.ownership_percentage ?? 0) &&
      (!contact.title || isTitleValid(contact.title)) &&
      (!contact.email || isEmailValid(contact.email)) &&
      (!contact.home_phone || isPhoneValid(contact.home_phone)) &&
      (!contact.cell_phone || isPhoneValid(contact.cell_phone))
    );
  };

  const addOwner = (): void => {
    const newOwner = generateEmptyContact();
    setActiveOwner(newOwner);
    setContacts([...contacts, newOwner]);
  };

  const removeOwner = async (): Promise<void> => {
    const removedOwner = activeOwner,
      idx = contacts.findIndex((c) => c.key === removedOwner.key);

    if (removedOwner.uuid) {
      try {
        await deleteContact(removedOwner);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
        return;
      }
    }
    setContacts(contacts.filter((c) => c.key !== removedOwner.key));
    setActiveOwner(contacts[idx - 1]);
  };

  const handleOnChange = (updatedContact: Contact): void => {
    const updatedContacts = contacts.map((contact) => {
      if (contact.key === updatedContact.key) {
        return { ...updatedContact };
      }
      return contact;
    });

    setContacts(updatedContacts);
  };

  const areContactsValid = (): boolean => {
    return contacts.map((contact) => isContactValid(contact)).every(Boolean);
  };

  const totalOwnership = (): number => {
    return contacts.reduce((total, contact) => {
      let ownershipPercentage = contact.ownership_percentage || 0;

      if (typeof ownershipPercentage === 'string') {
        ownershipPercentage = Number.parseInt(ownershipPercentage);
      }

      return ownershipPercentage + total;
    }, 0);
  };

  const handleOnSubmit = async (): Promise<void> => {
    setErrorMessage(null);

    if (areContactsValid() && totalOwnership() <= 100) {
      setIsLoading(true);

      const promises = contacts.map(async (contact) => {
        return await (contact.uuid
          ? updateContact(contact)
          : createContact(contact, props.applicationUuid));
      });

      try {
        const updatedContacts = await Promise.all(promises);

        props.handleOwnerStepCompleted(updatedContacts);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage(
        'Before you try again, please make sure that all required information is present and correct.'
      );
    }
  };

  return (
    <Box>
      {errorMessage && (
        <Box marginBottom={3}>
          <Banner>{errorMessage}</Banner>
        </Box>
      )}
      <Card
        title={`Owner ${currentIdx + 1} Information`}
        actions={
          currentIdx > 0 && (
            <IconButton
              icon="trash"
              label="Remove Owner"
              onClick={removeOwner}
            />
          )
        }
      >
        <OwnerInformationForm
          owner={activeOwner}
          usStates={states}
          accountAddress={props.accountAddress}
          sendUnmaskedFieldLogs={props.sendUnmaskedFieldLogs}
          onChange={handleOnChange}
        />
        <Box marginTop={4}>
          {!isLoading && (
            <Flex justifyContent="space-between">
              <Text bold>Go to Owner: 1</Text>
              <IconButton
                icon="plus"
                label="Save and Add Another Owner"
                onClick={addOwner}
              />
            </Flex>
          )}
          <Divider />
          <Flex gap={4} justifyContent="center" marginTop={3}>
            {isLoading ? (
              <Loading size="small" />
            ) : (
              <>
                <Button
                  variant="secondary"
                  startIcon="arrow-left-long"
                  onClick={props.onBack}
                >
                  Back
                </Button>
                <Button endIcon="arrow-right-long" onClick={handleOnSubmit}>
                  {props.proceedToOverview
                    ? 'Proceed to Overview'
                    : 'Save and Proceed'}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Card>
    </Box>
  );
}
