import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Loading,
  Tabs,
  Card,
} from '@forward-financing/fast-forward';
import { usePreventNavigation } from 'hooks/usePreventNavigation.hook';
import { toError } from 'helpers/errorUtils';
import { useScoringComposite } from '../DealScoringFetchHooks';
import { RenewalScoreComposite } from '../DealScoring.types';
import { V6ScoreDisplay } from '../V6ScoreDisplay/V6ScoreDisplay';
import { PastAdvances } from './PastAdvances/PastAdvances';
import { fetchRenewalScores } from './RenewalScoringFetchUtils';
import { RenewalScoresData } from './RenewalScoring.types';
import { RenewalScoringComposite } from './RenewalScoringComposite';
import {
  useRefreshRenewalCompositeScore,
  useSubmissionFundedDate,
} from './RenewalScoringFetchHooks';

export interface RenewalScoringContainerProps {
  submissionUuid: string;
  previousFundedSubmissionUuid?: string;
  compositeScore?: RenewalScoreComposite;
  shouldShowV6Score?: boolean;
}

export const RenewalScoringContainer = ({
  submissionUuid,
  previousFundedSubmissionUuid,
  compositeScore,
  shouldShowV6Score,
}: RenewalScoringContainerProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [response, setResponse] = useState<RenewalScoresData | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  usePreventNavigation(hasUnsavedChanges);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const scoreResponse = await fetchRenewalScores(submissionUuid);
        setResponse(scoreResponse);
        resetForm();
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Failed to load Renewal Scores: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [submissionUuid]);

  // Fetches the previous composite score for Past Advances tab.
  const { data: previousScoreComposite } = useScoringComposite(
    previousFundedSubmissionUuid
  );

  // Fetches the previous funded submission's funded date.
  const { data: previousFundedDate } = useSubmissionFundedDate(
    previousFundedSubmissionUuid
  );

  // Refetches only when the user clicks the refresh button.
  const [
    refreshCompositeScore,
    {
      renewalScoreCompositeRefreshed,
      error: errorRefreshRenewalCompositeScore,
      loading: loadingRefreshRenewalCompositeScore,
    },
  ] = useRefreshRenewalCompositeScore(submissionUuid);

  const scoreData =
    renewalScoreCompositeRefreshed || compositeScore || undefined;

  const resetForm = (): void => {
    setHasUnsavedChanges(false);
    setErrorMessage(null);
  };

  const uwScoreText =
    scoreData?.compositeScore?.score && scoreData?.compositeScore?.tier
      ? `${scoreData.compositeScore.score.toFixed(1)} / Tier ${
          scoreData.compositeScore.tier
        }`
      : '';

  return (
    <Card
      collapsible
      title={`UW Deal Score ${uwScoreText}`}
      initialOpenState={false}
    >
      {loading ? (
        <Loading size="large" />
      ) : (
        <Box m={3}>
          {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}

          {response && (
            <Tabs
              defaultValue={
                shouldShowV6Score ? 'v6Score' : 'renewalCompositeScore'
              }
            >
              <Tabs.List>
                {shouldShowV6Score && (
                  <Tabs.Trigger value="v6Score">V6 Score</Tabs.Trigger>
                )}

                <Tabs.Trigger value="renewalCompositeScore">
                  Renewal Composite Score
                </Tabs.Trigger>

                <Tabs.Trigger value="pastAdvances">
                  Past Advance(s)
                </Tabs.Trigger>
              </Tabs.List>

              {shouldShowV6Score && (
                <Tabs.Content value="v6Score">
                  <V6ScoreDisplay submissionUuid={submissionUuid} />
                </Tabs.Content>
              )}

              <Tabs.Content value="pastAdvances">
                <PastAdvances
                  originalDealScore={response?.originalDealScore}
                  previousRenewalScores={response?.previousRenewalScores}
                  previousCompositeScore={
                    previousScoreComposite?.type === 'Renewal'
                      ? previousScoreComposite
                      : undefined
                  }
                  previousFundedDate={previousFundedDate}
                />
              </Tabs.Content>

              <Tabs.Content value="renewalCompositeScore">
                <RenewalScoringComposite
                  submissionUuid={submissionUuid}
                  scoreData={scoreData}
                  refreshScoreData={refreshCompositeScore}
                  loading={loadingRefreshRenewalCompositeScore}
                  errorRefresh={errorRefreshRenewalCompositeScore}
                />
              </Tabs.Content>
            </Tabs>
          )}
        </Box>
      )}
    </Card>
  );
};
