import React from 'react';
import { Text, Link } from '@forward-financing/fast-forward';
import { Attachment } from './attachmentManager.types';
import { useAttachmentManagerContext } from './AttachmentManagerContext';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { AnalyticsGatewayClient } from 'api/AnalyticsGatewayClient';
import { useUserContext } from 'contexts/UserContext';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';

export interface AttachmentDownloadLinkProps {
  attachment: Attachment;
}

export const AttachmentDownloadLink = ({
  attachment,
}: AttachmentDownloadLinkProps): JSX.Element => {
  const { primaryId } = useAttachmentManagerContext();
  const user = useUserContext();

  const onDownloadLinkClick = (): void => {
    try {
      const logData = {
        field_name: 'Attachment Title',
        object_uuid: attachment.uuid,
        name: attachment.fileName,
        action: 'Preview/Download',
        object_type: 'Attachment',
      };

      void AnalyticsGatewayClient.createLogSender(
        user,
        'Attachments Manager'
      )(logData);
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
    }
  };

  return (
    <>
      {attachment.fileDeletedAt ? (
        <Text>{attachment.fileName}</Text>
      ) : (
        <Link
          href={
            new URL(
              `/api/v1/applications/${primaryId}/attachments/${attachment.uuid}`,
              UNDERWRITING_BASE_URL()
            )
          }
          newTab
          onClick={onDownloadLinkClick}
        >
          {attachment.fileName}
        </Link>
      )}
    </>
  );
};
