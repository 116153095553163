import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  IconButton,
  IconButtonProps,
  Card,
  Box,
  Text,
  Table,
  Pagination,
  Banner,
  Loading,
  Flex,
  Button,
  formatCurrency,
  formatDateTimeString,
  Divider,
  Currency,
  Tag,
} from '@forward-financing/fast-forward';
import { SelectableOption } from '@forward-financing/fast-forward/dist/Combobox/Combobox';
import { useNavigate } from 'react-router-dom';
import { User } from 'api/AuthClient/codecs';
import { useGoToNextDeal } from 'components/SubmissionUnderwriting/ApplicationSnapshot/applicationSnapshotFetchHooks';
import { GoToNextDealButton } from 'components/shared/GoToNextPrequalDeal/GoToNextDealButton';
import { featureFlags } from 'helpers/featureFlags';
import { useUserContext } from 'contexts/UserContext';
import { IsoDropdownResponse } from 'types/api/funding/types';
import { useAssignNextProcessingDeal } from 'components/ProcessingQueue/ProcessingQueueHooks';
import { GoToNextProcessingDealButton } from 'components/shared/GoToNextProcessingDeal/GoToNextProcessingDealButton';
import { IndustryType } from '../../api/UnderwritingClient/codecs';
import { findPartner, userFullName } from '../../helpers/utils';
import {
  getBusinessTimeDifference,
  timeBetweenDates,
  msToFormattedBusinessHours,
} from '../../helpers/string/dateUtils';
import {
  categoryStatusOptions,
  filterDisplayNames,
  positionBucketOptions,
  revenueBucketOptions,
  slaStatusOptions,
} from './livePipelineConstants';
import { Filters } from './codecs';
import { LivePipelineFilterBox } from './LivePipelineFilterBox';
import {
  FilterDisplayNames,
  Meta,
  SubmissionSearchResult,
} from './LivePipeline.types';
import { useUpdateSubmissionAssignment } from './LivePipelineHooks';

export interface LivePipelineTableProps {
  data: SubmissionSearchResult[];
  isos: IsoDropdownResponse[];
  meta: Meta;
  filters: Filters;
  underwriterView: boolean;
  currentUserUnderwriter: boolean;
  order: 'asc' | 'desc';
  sortingProperty: string;
  loading?: boolean;
  expandedTable: boolean;
  underwriters: SelectableOption[];
  underwriterAssignLoading: boolean;
  industries: IndustryType[];
  updateFilters: (filters: Filters) => void;
  assignDeal: () => void;
  handleOrderChange: (attribute: string) => void;
  handlePageChange: (page: number) => void;
  fetchOpportunities: () => void;
  toggleExpand: () => void;
  processingUsers: User[];
  urlParams: URLSearchParams;
}

const displaySortableColumnHeaderIcon = (
  sortingOrder: 'asc' | 'desc',
  sortingBy: string,
  sortingProperty: string
): 'ascending' | 'descending' | 'none' => {
  if (sortingBy === sortingProperty) {
    return sortingOrder === 'asc' ? 'ascending' : 'descending';
  }

  return 'none';
};

const filterString = (
  filters: Filters,
  isos: IsoDropdownResponse[],
  processingUsers: User[]
): string => {
  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const displayFilterNames = (filter: keyof FilterDisplayNames): string => {
    if (filterDisplayNames[filter] === filterDisplayNames.credit_committee) {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return filterDisplayNames[filter];
  };

  const filterKeys = Object.keys(filters) as Array<keyof typeof filters>;

  const appliedFilters = filterKeys.filter(
    (filter) => filters[filter].length > 0
  );

  if (appliedFilters.length === 0) {
    return 'No filters applied.';
  } else {
    let outputString = '';

    appliedFilters.forEach((filter) => {
      outputString = outputString.concat(`${displayFilterNames(filter)} -`);

      filters[filter].forEach((value: string) => {
        if (filter === 'merchant_partner_id') {
          outputString = outputString.concat(
            ` ${findPartner(isos, parseInt(value))?.label},`
          );
        } else if (filter === 'decision_analyst_id') {
          if (value === 'Unassigned') {
            outputString = outputString.concat(` Unassigned,`);
          } else {
            const foundUser = processingUsers.find(
              (user) => user.id === parseInt(value)
            );
            outputString = outputString.concat(
              ` ${foundUser?.first_name} ${foundUser?.last_name},`
            );
          }
        } else if (filter === 'revenue_bucket') {
          outputString = outputString.concat(
            ` ${
              revenueBucketOptions.find((option) => option.value === value)
                ?.text
            },`
          );
        } else if (filter === 'position_bucket') {
          outputString = outputString.concat(
            ` ${
              positionBucketOptions.find((option) => option.value === value)
                ?.text
            },`
          );
        } else if (filter === 'sla_status_filter') {
          outputString = outputString.concat(
            ` ${
              slaStatusOptions.find((option) => option.value === value)?.text
            },`
          );
        } else if (filter === 'category_filter') {
          outputString = outputString.concat(
            ` ${
              categoryStatusOptions.find((option) => option.value === value)
                ?.text
            },`
          );
        } else if (
          featureFlags.use_decision_committee_naming &&
          filter === 'sub_stage' &&
          value === 'Credit Committee'
        ) {
          outputString = outputString.concat(` Decision Committee,`);
        } else {
          outputString = outputString.concat(` ${value},`);
        }
      });
      outputString = outputString.replace(/.$/, '; ');
    });

    return `Filters: ${outputString}`;
  }
};

const findDecisionAnalystName = (
  prequalificationAnalysts: User[],
  decisionAnalystId?: number
): string => {
  // Avoiding bang here because decision_analyst_id is a number and 0 is falsy in JS
  if (decisionAnalystId === null) {
    return '';
  }

  const matchedUser = prequalificationAnalysts.find(
    (user) => user.id === decisionAnalystId
  );

  // matchedUser will be an object with type User if a prequal user with matching ID is found
  // Otherwise, it'll be undefined
  return matchedUser
    ? userFullName(matchedUser.first_name, matchedUser.last_name)
    : '';
};

/**
 * Takes a datetime string and returns the time in stage, which is based on the underwriterView param.
 * If underwriterView is true, then the time in stage is calculated in terms of business days/hours.
 * Else, the time in stage is calculated in terms of calendar days/hours.
 * @param lastChangeDate - datetime string of the last time the stage/sub-stage was changed
 * @param underwriterView - boolean determing whether to calculate time in stage in terms of business days/hours
 * @returns string representing the time passed since lastChangeDate, broken down into days, hours, and minutes
 */
const timeInStage = (
  lastChangeDate?: string,
  underwriterView?: boolean
): string => {
  if (!lastChangeDate) {
    return '';
  }

  if (underwriterView) {
    return msToFormattedBusinessHours(
      getBusinessTimeDifference(
        new Date(lastChangeDate).getTime(),
        new Date().getTime()
      )
    );
  }

  return timeBetweenDates(new Date(), new Date(lastChangeDate));
};

const displaySlaStatus = (slaStatus?: number): ReactElement => {
  switch (slaStatus) {
    case 2:
      return <Tag bgColor="danger">Exceeded</Tag>;
    case 1:
      return <Tag bgColor="caution">Approaching</Tag>;
    default:
      return <></>;
  }
};

export const LivePipelineTable = ({
  data,
  isos,
  meta,
  filters,
  underwriterView,
  currentUserUnderwriter,
  order,
  sortingProperty,
  loading,
  expandedTable,
  underwriterAssignLoading,
  underwriters,
  industries,
  updateFilters,
  assignDeal,
  handleOrderChange,
  handlePageChange,
  fetchOpportunities,
  toggleExpand,
  processingUsers,
  urlParams,
}: LivePipelineTableProps): JSX.Element => {
  const navigate = useNavigate();

  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    urlParams.get('hidden_columns')?.split(',') || []
  );

  useEffect(() => {
    if (hiddenColumns.length > 0) {
      urlParams.set('hidden_columns', hiddenColumns.join(','));
    } else {
      urlParams.delete('hidden_columns');
    }

    navigate({ search: `?${urlParams}` });
  }, [hiddenColumns, navigate, urlParams]);

  const hideColumn = (column: string): void => {
    if (!hiddenColumns.includes(column)) {
      setHiddenColumns([...hiddenColumns, column]);
    }
  };

  const showColumn = (column: string): void => {
    setHiddenColumns(hiddenColumns.filter((c) => c !== column));
  };

  const expandIcon = expandedTable
    ? 'compress-arrows-alt'
    : 'expand-arrows-alt';

  const isCurrentUnderwriter = (name: string): boolean => {
    return underwriters.some((uw) => uw.text === name);
  };

  const [updateSubmissionAssignment, { error: updateUnderwriterError }] =
    useUpdateSubmissionAssignment();

  const { first_name: loggedUserFirstName, last_name: loggedUserLastName } =
    useUserContext();

  const saveOpportunityUnderwriter = async (
    id: string | number,
    newValue: string | undefined
  ): Promise<void> => {
    const [opportunityUuid] = `${id}`.split(',');
    const redirectUrl = new URL(
      `/submissions/${opportunityUuid}`,
      window.location.origin
    );

    const underwriterName = newValue === 'Unassigned' ? null : newValue;

    const { success } = await updateSubmissionAssignment({
      submissionUuid: `${opportunityUuid}`,
      body: { underwriterName: underwriterName || '' },
    });

    if (success && underwriterName) {
      window.open(`${redirectUrl}`, '_blank');
    }
  };

  const autoAssignCreditCommittee = async (
    submissionUuid: string
  ): Promise<void> => {
    const redirectUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );

    const { success } = await updateSubmissionAssignment({
      submissionUuid: submissionUuid,
      body: {
        creditCommitteeName: `${loggedUserFirstName} ${loggedUserLastName}`,
      },
    });

    if (success) {
      window.open(`${redirectUrl}`, '_blank');
    }
  };

  const saveOpportunityCreditCommittee = async (
    id: string | number,
    newValue: string | undefined
  ): Promise<void> => {
    const [opportunityUuid] = `${id}`.split(',');
    const redirectUrl = new URL(
      `/submissions/${opportunityUuid}`,
      window.location.origin
    );

    const creditCommitteeName = newValue === 'Unassigned' ? null : newValue;

    const { success } = await updateSubmissionAssignment({
      submissionUuid: `${opportunityUuid}`,
      body: { creditCommitteeName: creditCommitteeName || '' },
    });

    if (success && creditCommitteeName) {
      window.open(`${redirectUrl}`, '_blank');
    }
  };

  const [
    gotToNextDealFunction,
    {
      nextDealData,
      error: nextDealError,
      loading: nextDealLoading,
      responseReady: nextDealResponseReady,
    },
  ] = useGoToNextDeal();

  const [
    goToNextProcessingDealFunction,
    {
      data: nextProcessingDealData,
      error: nextProcessingDealError,
      loading: nextProcessingDealLoading,
      responseReady: nextProcessingDealResponseReady,
    },
  ] = useAssignNextProcessingDeal();

  const isNextDealUnavailable =
    nextDealResponseReady && !nextDealError && !nextDealData?.nextDealUrl;

  useEffect(() => {
    if (isNextDealUnavailable) {
      navigate('/prequal-queue-loading');
    }
  }, [isNextDealUnavailable, navigate]);

  const handleRefresh: IconButtonProps['onClick'] = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      fetchOpportunities();
      e.currentTarget.blur();
    },
    [fetchOpportunities]
  );

  const underwritersOptions = (underwriter: string): SelectableOption[] => {
    return isCurrentUnderwriter(underwriter || 'Unassigned')
      ? underwriters
      : [
          ...underwriters,
          {
            text: underwriter || '',
            value: underwriter || '',
          },
        ];
  };

  const primeDealDisplayText = (
    primeDealStatus: number | undefined
  ): string => {
    switch (primeDealStatus) {
      case 0:
        return 'Yes';

      case 2:
        return 'No';

      default:
        return '';
    }
  };

  const renderSubStage = (subStage: string): string => {
    if (subStage === 'Credit Committee') {
      return DECISION_OR_CREDIT_COMMITTEE;
    }

    return subStage;
  };

  return (
    <Box margin={2}>
      <Card
        title={`Live ${underwriterView ? 'Underwriting ' : ''}Pipeline`}
        actions={
          <>
            {featureFlags.processing_queue && (
              <GoToNextProcessingDealButton
                updateFunction={goToNextProcessingDealFunction}
                nextDealSubmissionUuid={nextProcessingDealData?.submissionUuid}
                nextDealCustomerUuid={nextProcessingDealData?.customerUuid}
                nextDealLoading={nextProcessingDealLoading}
                nextDealResponseReady={nextProcessingDealResponseReady}
              />
            )}
            <GoToNextDealButton
              updateFunction={gotToNextDealFunction}
              nextDealUrl={nextDealData?.nextDealUrl}
              nextDealLoading={nextDealLoading}
            />
            {currentUserUnderwriter &&
              (underwriterAssignLoading ? (
                <Loading size="small" />
              ) : (
                <Button variant="cta" onClick={assignDeal}>
                  Go To Next Application
                </Button>
              ))}

            <Divider orientation="vertical" />
            <IconButton
              onClick={toggleExpand}
              icon={expandIcon}
              label={expandedTable ? 'Collapse' : 'Expand'}
              hiddenLabel
            />
          </>
        }
      >
        {updateUnderwriterError && (
          <Banner>
            We were not able to assign the deal as requested. Please try again.
          </Banner>
        )}
        {nextDealError && <Banner>{nextDealError.message}</Banner>}
        {nextProcessingDealError && (
          <Banner>{nextProcessingDealError.message}</Banner>
        )}

        <LivePipelineFilterBox
          underwriters={underwriters.map((uw) => uw.text || '')}
          underwriterView={underwriterView}
          isos={isos}
          processingUsers={processingUsers}
          currentFilters={filters}
          industries={industries}
          updateFilters={updateFilters}
        />
        <Box>
          <Flex flexDirection={'row'} justifyContent="space-between">
            <Box mt={4} mb={3}>
              <Text>
                Results found:{' '}
                <b>
                  {meta.totalCount ? meta.totalCount.toLocaleString() : 'N/A'}
                </b>
              </Text>
              <Text>
                {meta.totalCount > 1000
                  ? 'We can only display 1,000 records at a time. Please use filters section to further narrow your search.'
                  : ''}
              </Text>
              <Text>{filterString(filters, isos, processingUsers)}</Text>
            </Box>
            <IconButton onClick={handleRefresh} label="Refresh" icon="redo" />
          </Flex>
          {loading ? (
            <Loading size="medium" />
          ) : (
            <Table>
              <Table.Head>
                <Table.SortableColumnHeader
                  label="Sub-Stage Time"
                  order={displaySortableColumnHeaderIcon(
                    order,
                    sortingProperty,
                    'total_sub_stage_time'
                  )}
                  onSort={() => handleOrderChange('total_sub_stage_time')}
                  collapsible
                  collapsed={hiddenColumns.includes('Sub-Stage Time')}
                  collapsedLabel="Sub-Stage Time"
                  onCollapse={() => hideColumn('Sub-Stage Time')}
                  onExpand={() => showColumn('Sub-Stage Time')}
                />
                {featureFlags.show_sla_status_and_stage_time && (
                  <>
                    <Table.SortableColumnHeader
                      label="Stage Time"
                      order={displaySortableColumnHeaderIcon(
                        order,
                        sortingProperty,
                        'total_stage_time'
                      )}
                      onSort={() => handleOrderChange('total_stage_time')}
                      collapsible
                      collapsed={hiddenColumns.includes('Stage Time')}
                      collapsedLabel="Stage Time"
                      onCollapse={() => hideColumn('Stage Time')}
                      onExpand={() => showColumn('Stage Time')}
                    >
                      Stage Time
                    </Table.SortableColumnHeader>
                    <Table.SortableColumnHeader
                      label="SLA Status"
                      order={displaySortableColumnHeaderIcon(
                        order,
                        sortingProperty,
                        'sla_status'
                      )}
                      onSort={() => handleOrderChange('sla_status')}
                      collapsible
                      collapsed={hiddenColumns.includes('SLA Status')}
                      collapsedLabel="SLA Status"
                      onCollapse={() => hideColumn('SLA Status')}
                      onExpand={() => showColumn('SLA Status')}
                    >
                      SLA Status
                    </Table.SortableColumnHeader>
                  </>
                )}
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('Customer')}
                  collapsedLabel="Customer"
                  onCollapse={() => hideColumn('Customer')}
                  onExpand={() => showColumn('Customer')}
                >
                  Customer
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('Stage')}
                  collapsedLabel="Stage"
                  onCollapse={() => hideColumn('Stage')}
                  onExpand={() => showColumn('Stage')}
                >
                  Stage
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('Sub-Stage')}
                  collapsedLabel="Sub-Stage"
                  onCollapse={() => hideColumn('Sub-Stage')}
                  onExpand={() => showColumn('Sub-Stage')}
                >
                  Sub-Stage
                </Table.ColumnHeader>
                {!underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Sub_On Analyst')}
                    collapsedLabel="Sub_On Analyst"
                    onCollapse={() => hideColumn('Sub_On Analyst')}
                    onExpand={() => showColumn('Sub_On Analyst')}
                  >
                    Sub_On Analyst
                  </Table.ColumnHeader>
                )}
                {!underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('PQ Analyst')}
                    collapsedLabel="PQ Analyst"
                    onCollapse={() => hideColumn('PQ Analyst')}
                    onExpand={() => showColumn('PQ Analyst')}
                  >
                    PQ Analyst
                  </Table.ColumnHeader>
                )}
                {!underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Processor')}
                    collapsedLabel="Processor"
                    onCollapse={() => hideColumn('Processor')}
                    onExpand={() => showColumn('Processor')}
                  >
                    Processor
                  </Table.ColumnHeader>
                )}
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('Underwriter')}
                  collapsedLabel="Underwriter"
                  onCollapse={() => hideColumn('Underwriter')}
                  onExpand={() => showColumn('Underwriter')}
                >
                  Underwriter
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes(
                    DECISION_OR_CREDIT_COMMITTEE
                  )}
                  collapsedLabel={DECISION_OR_CREDIT_COMMITTEE}
                  onCollapse={() => hideColumn(DECISION_OR_CREDIT_COMMITTEE)}
                  onExpand={() => showColumn(DECISION_OR_CREDIT_COMMITTEE)}
                >
                  {DECISION_OR_CREDIT_COMMITTEE}
                </Table.ColumnHeader>
                {featureFlags.move_assignment_out_of_live_pipeline && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Exception Request')}
                    collapsedLabel="Exception Request"
                    onCollapse={() => hideColumn('Exception Request')}
                    onExpand={() => showColumn('Exception Request')}
                  >
                    Exception Request
                  </Table.ColumnHeader>
                )}

                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('VCA')}
                  collapsedLabel="VCA"
                  onCollapse={() => hideColumn('VCA')}
                  onExpand={() => showColumn('VCA')}
                >
                  VCA
                </Table.ColumnHeader>

                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('UW Sign-Off')}
                  collapsedLabel="UW Sign-Off"
                  onCollapse={() => hideColumn('UW Sign-Off')}
                  onExpand={() => showColumn('UW Sign-Off')}
                >
                  UW Sign-Off
                </Table.ColumnHeader>
                <Table.SortableColumnHeader
                  label="Prime Deal"
                  order={displaySortableColumnHeaderIcon(
                    order,
                    sortingProperty,
                    'prime_deal_status'
                  )}
                  onSort={() => handleOrderChange('prime_deal_status')}
                  collapsible
                  collapsed={hiddenColumns.includes('Prime Deal')}
                  collapsedLabel="Prime Deal"
                  onCollapse={() => hideColumn('Prime Deal')}
                  onExpand={() => showColumn('Prime Deal')}
                />
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('ISO')}
                  collapsedLabel="ISO"
                  onCollapse={() => hideColumn('ISO')}
                  onExpand={() => showColumn('ISO')}
                >
                  ISO
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('ISO Competing Sub')}
                  collapsedLabel="ISO Competing Sub"
                  onCollapse={() => hideColumn('ISO Competing Sub')}
                  onExpand={() => showColumn('ISO Competing Sub')}
                >
                  ISO Competing Sub
                </Table.ColumnHeader>
                {underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Industry')}
                    collapsedLabel="Industry"
                    onCollapse={() => hideColumn('Industry')}
                    onExpand={() => showColumn('Industry')}
                  >
                    Industry
                  </Table.ColumnHeader>
                )}
                {underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Revenue')}
                    collapsedLabel="Revenue"
                    onCollapse={() => hideColumn('Revenue')}
                    onExpand={() => showColumn('Revenue')}
                  >
                    Revenue
                  </Table.ColumnHeader>
                )}
                {underwriterView && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Existing Positions')}
                    collapsedLabel="Existing Positions"
                    onCollapse={() => hideColumn('Existing Positions')}
                    onExpand={() => showColumn('Existing Positions')}
                  >
                    Existing Positions
                  </Table.ColumnHeader>
                )}
                {underwriterView &&
                  featureFlags.show_uw_deal_category_filter && (
                    <Table.SortableColumnHeader
                      label="Deal Category"
                      order={displaySortableColumnHeaderIcon(
                        order,
                        sortingProperty,
                        'category_status'
                      )}
                      onSort={() => handleOrderChange('category_status')}
                      collapsible
                      collapsed={hiddenColumns.includes('Deal Category')}
                      collapsedLabel="Deal Category"
                      onCollapse={() => hideColumn('Deal Category')}
                      onExpand={() => showColumn('Deal Category')}
                    />
                  )}
                {(!underwriterView ||
                  !featureFlags.show_uw_deal_category_filter) && (
                  <Table.ColumnHeader
                    collapsible
                    collapsed={hiddenColumns.includes('Type')}
                    collapsedLabel="Type"
                    onCollapse={() => hideColumn('Type')}
                    onExpand={() => showColumn('Type')}
                  >
                    Type
                  </Table.ColumnHeader>
                )}
                <Table.ColumnHeader
                  textAlign="right"
                  collapsible
                  collapsed={hiddenColumns.includes('Max. Approval')}
                  collapsedLabel="Max. Approval"
                  onCollapse={() => hideColumn('Max. Approval')}
                  onExpand={() => showColumn('Max. Approval')}
                >
                  Max. Approval
                </Table.ColumnHeader>
                <Table.ColumnHeader
                  collapsible
                  collapsed={hiddenColumns.includes('Last Sub-Stage Change')}
                  collapsedLabel="Last Sub-Stage Change"
                  onCollapse={() => hideColumn('Last Sub-Stage Change')}
                  onExpand={() => showColumn('Last Sub-Stage Change')}
                >
                  Last Sub-Stage Change
                </Table.ColumnHeader>
              </Table.Head>
              <Table.Body>
                {data.map((row) => {
                  const id = row.objectID.split('-')[1];
                  return (
                    <Table.Row key={row.objectID}>
                      <Table.Cell>
                        {!hiddenColumns.includes('Sub-Stage Time') &&
                          timeInStage(
                            row.lastSubStageChangeDate,
                            underwriterView
                          )}
                      </Table.Cell>
                      {featureFlags.show_sla_status_and_stage_time && (
                        <>
                          <Table.Cell>
                            {!hiddenColumns.includes('Stage Time') &&
                              timeInStage(
                                row.lastStageChangeDate,
                                underwriterView
                              )}
                          </Table.Cell>
                          <Table.Cell>
                            {!hiddenColumns.includes('SLA Status') &&
                              displaySlaStatus(row.slaStatus)}
                          </Table.Cell>
                        </>
                      )}
                      <Table.LinkCell
                        columnWidth="medium"
                        href={
                          new URL(
                            `/submissions/${row.salesforceOpportunityUuid}`,
                            window.location.origin
                          )
                        }
                        newTab
                      >
                        {!hiddenColumns.includes('Customer') &&
                          row.dba?.replace('&amp;', '&')}
                      </Table.LinkCell>
                      <Table.Cell>
                        {!hiddenColumns.includes('Stage') && row.stageName}
                      </Table.Cell>
                      <Table.Cell>
                        {!hiddenColumns.includes('Sub-Stage') &&
                          renderSubStage(row.subStage)}
                      </Table.Cell>
                      {!underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Sub_On Analyst') &&
                            row.prequalAnalystName}
                        </Table.Cell>
                      )}
                      {!underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('PQ Analyst') &&
                            findDecisionAnalystName(
                              processingUsers,
                              row.decisionAnalystId
                            )}
                        </Table.Cell>
                      )}
                      {!underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Processor') &&
                            row.processor}
                        </Table.Cell>
                      )}
                      {featureFlags.move_assignment_out_of_live_pipeline ||
                      hiddenColumns.includes('Underwriter') ? (
                        <Table.Cell>
                          {!hiddenColumns.includes('Underwriter') &&
                            row.ffUnderwriter}
                        </Table.Cell>
                      ) : (
                        <Table.EditableSelectCell
                          id={`${row.salesforceOpportunityUuid},${id}`}
                          name={'Underwriter'}
                          label="Assign Underwriter"
                          value={row.ffUnderwriter || ''}
                          onSave={saveOpportunityUnderwriter}
                          options={underwritersOptions(row.ffUnderwriter || '')}
                        />
                      )}
                      {featureFlags.move_assignment_out_of_live_pipeline ||
                      hiddenColumns.includes(DECISION_OR_CREDIT_COMMITTEE) ? (
                        <Table.Cell>
                          {!hiddenColumns.includes(
                            DECISION_OR_CREDIT_COMMITTEE
                          ) && row.creditCommittee}
                        </Table.Cell>
                      ) : (
                        <Table.EditableSelectCell
                          id={`${row.salesforceOpportunityUuid},${id}`}
                          name={DECISION_OR_CREDIT_COMMITTEE}
                          label={`Assign ${DECISION_OR_CREDIT_COMMITTEE}`}
                          value={row.creditCommittee || ''}
                          onSave={saveOpportunityCreditCommittee}
                          secondaryButtons={[
                            {
                              iconName: 'user-check',
                              onClick: () =>
                                autoAssignCreditCommittee(
                                  row.salesforceOpportunityUuid
                                ),
                              buttonLabel: 'Auto Assign',
                            },
                          ]}
                          options={
                            currentUserUnderwriter
                              ? underwritersOptions(row.creditCommittee || '')
                              : [
                                  { value: 'Unassigned', text: 'Unassigned' },
                                  {
                                    value: 'Competing Sub',
                                    text: 'Competing Sub',
                                  },
                                ]
                          }
                        />
                      )}
                      {featureFlags.move_assignment_out_of_live_pipeline && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Exception Request') &&
                            row.exceptionRequestAssignee}
                        </Table.Cell>
                      )}

                      <Table.Cell>
                        {!hiddenColumns.includes('VCA') && row.vca}
                      </Table.Cell>

                      <Table.Cell>
                        {!hiddenColumns.includes('UW Sign-Off') &&
                          row.underwriterSignOffName}
                      </Table.Cell>
                      <Table.Cell>
                        {!hiddenColumns.includes('Prime Deal') &&
                          primeDealDisplayText(row.primeDealStatus)}
                      </Table.Cell>
                      <Table.Cell>
                        {!hiddenColumns.includes('ISO') &&
                          (findPartner(isos, row.merchantPartnerId)?.label ||
                            'NA')}
                      </Table.Cell>
                      <Table.Cell>
                        {!hiddenColumns.includes('ISO Competing Sub') &&
                          (row.isoCompetingSubMessage || '')}
                      </Table.Cell>
                      {underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Industry') &&
                            row.industryName?.replace('&amp;', '&')}
                        </Table.Cell>
                      )}
                      {underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Revenue') &&
                            (row.revenueFloat === 0 || row.revenueFloat
                              ? `${formatCurrency(row.revenueFloat)}`
                              : '')}
                        </Table.Cell>
                      )}
                      {underwriterView && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Existing Positions') &&
                            row.existingFinancingPositions}
                        </Table.Cell>
                      )}
                      {underwriterView &&
                        featureFlags.show_uw_deal_category_filter && (
                          <Table.Cell>
                            {!hiddenColumns.includes('Deal Category') &&
                              categoryStatusOptions.find(
                                (option) => +option.value === row.categoryStatus
                              )?.text}
                          </Table.Cell>
                        )}
                      {(!underwriterView ||
                        !featureFlags.show_uw_deal_category_filter) && (
                        <Table.Cell>
                          {!hiddenColumns.includes('Type') && row.newOrRenewal}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="right">
                        {!hiddenColumns.includes('Max. Approval') &&
                          (row.maxApprovalIssued ? (
                            <Currency amount={row.maxApprovalIssued} />
                          ) : (
                            ''
                          ))}
                      </Table.Cell>
                      <Table.Cell>
                        {!hiddenColumns.includes('Last Sub-Stage Change') &&
                          (row.lastSubStageChangeDate
                            ? formatDateTimeString(row.lastSubStageChangeDate)
                            : '')}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </Box>

        <Flex flexDirection="row-reverse">
          <Pagination
            numPages={meta.totalPages}
            currentPage={meta.currentPage}
            onPageClick={handlePageChange}
          />
        </Flex>
      </Card>
    </Box>
  );
};
