import React from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Grid,
  Subheading,
  Text,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { NewDealScoreComposite } from '../DealScoring.types';
import { NewDealCompositeScoreTable } from './NewDealCompositeScoreTable';

export interface NewDealScoringCompositeProps {
  scoreData: NewDealScoreComposite | undefined;
  refreshScoreData: () => Promise<unknown>;
  loading: boolean;
  errorRefresh: Error | undefined;
}

export const NewDealScoringComposite = ({
  scoreData,
  refreshScoreData,
  loading,
  errorRefresh,
}: NewDealScoringCompositeProps): JSX.Element => {
  const { compositeScore, updatedAt } = scoreData || {};

  if (!scoreData && !errorRefresh) {
    return (
      <Text>
        Composite Score is not available until the submission reaches the
        Underwriting stage. If you see this and the submission has already
        reached the Underwriting stage, please open an IT Support ticket and
        report this as an issue.
      </Text>
    );
  }

  const compositeScoreToText = (
    compositeScoreValue?: typeof compositeScore
  ): string => {
    // !compositeScoreValue.score intentionally includes a value of 0 since
    // 0 is not a valid score.
    if (!compositeScoreValue || !compositeScoreValue.score) {
      return 'N/A';
    }

    return `${compositeScoreValue.score?.toFixed(1)} / Tier ${
      compositeScoreValue.tier || 'N/A'
    }`;
  };

  return (
    <Box mb={4}>
      {errorRefresh && (
        <Banner dismissable={false}>{errorRefresh.message}</Banner>
      )}

      <Grid gutter>
        <Grid.Item xs={12} s={6} l={5}>
          <Flex gap={3} alignItems={'center'}>
            <Button
              startIcon={'refresh'}
              disabled={loading}
              onClick={refreshScoreData}
            >
              Refresh Composite Score
            </Button>
            <Text size="l">
              Last Updated: {updatedAt && formatDateTimeString(updatedAt)}
            </Text>
          </Flex>
          <Subheading>
            New Deal Composite Score: {compositeScoreToText(compositeScore)}
          </Subheading>
          {scoreData && <NewDealCompositeScoreTable scoreData={scoreData} />}
        </Grid.Item>
      </Grid>
    </Box>
  );
};
