import React, { useEffect, useState } from 'react';
import { Accordion, Banner, Loading } from '@forward-financing/fast-forward';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';

import {
  useAttachments,
  useCustomer,
  useDocumentTags,
  useOwners,
  useProcessingUsers,
  useSupportedFileExtensions,
  useUnderwriterUsers,
  useUpdateUnderwriterSignOff,
} from '../attachmentManagerHooks';
import { AttachmentManagerUploader } from '../AttachmentManagerUploader';
import { AttachmentManagerProvider } from '../AttachmentManagerContext';
import { AttachmentGroup } from '../AttachmentGroup';

import { NewStipulationManager } from '../../StipulationManager/NewStipulationManager';
import {
  useStipulations,
  useValidReasons,
} from '../../StipulationManager/stipulationManagerHooks';
import { AttachmentSubmission } from '../attachmentManager.types';
import { UnderwritersProvider } from './UnderwritingAttachmentManagerContext';
import { UnderwritingSignOffBanner } from './UnderwritingSignOffBanner';

import { ViewHistoryTracker } from './ViewHistoryTracker';

export interface UnderwritingAttachmentManagerProps {
  submissionUuid: string;
  submission?: AttachmentSubmission;
  refetchSubmission: () => void;
  isSalesforce?: boolean;
}

export const UnderwritingAttachmentManager = ({
  submissionUuid,
  isSalesforce,
  submission,
  refetchSubmission,
}: UnderwritingAttachmentManagerProps): JSX.Element => {
  const {
    customer,
    loading: customerLoading,
    error: customerError,
  } = useCustomer(submission?.customerUuid);

  const {
    owners,
    loading: ownersLoading,
    error: ownersError,
  } = useOwners(submission?.ownerUuids);

  const [
    updateUnderwriterSignOff,
    { data: maybeUpdatedSubmission, error: updateUnderwriterSignOffError },
  ] = useUpdateUnderwriterSignOff(submissionUuid);

  const {
    attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchAttachments,
  } = useAttachments(submissionUuid);

  const {
    supportedFileExtensions,
    loading: extensionsLoading,
    error: extensionsError,
  } = useSupportedFileExtensions();

  const {
    underwriters,
    loading: underwritersLoading,
    error: underwritersError,
  } = useUnderwriterUsers();
  const {
    processors,
    loading: processorsLoading,
    error: processorsError,
  } = useProcessingUsers();

  const {
    data: stipulations,
    loading: stipulationsLoading,
    error: stipulationsError,
    refetch: refetchStipulations,
  } = useStipulations(submissionUuid);

  const {
    documentTags,
    loading: documentTagsLoading,
    error: documentTagsError,
  } = useDocumentTags();

  const { data: validReasons } = useValidReasons();

  // This is so we can understand if the loading state for stipulations is
  // due to initial loading or refetching.
  const [stipulationsRefetching, setStipulationsRefetching] = useState(false);

  const reloadAttachmentManager = (): void => {
    void refetchAttachments();
    setStipulationsRefetching(true);
    void refetchStipulations();
  };

  useEffect(() => {
    // We were previously refetching and now it's done loading. So we can reset
    // the refetching state.
    if (stipulationsRefetching && !stipulationsLoading) {
      setStipulationsRefetching(false);
    }
  }, [stipulationsRefetching, stipulationsLoading]);

  const stipulationsInitialLoading =
    stipulationsLoading && !stipulationsRefetching;

  const isAnythingLoading =
    attachmentsLoading ||
    // Do not show any loading state for stipulations if we're refetching. This
    // could probably be indicated better in the UI, but for now, we're just
    // fixing the UX so it's the same as before. Also to be noted, the accordion
    // that shows stipulations is closed on first render, so it's not clear why
    // we are even showing a loading state for stipulations.
    stipulationsInitialLoading ||
    extensionsLoading ||
    documentTagsLoading ||
    underwritersLoading ||
    processorsLoading ||
    customerLoading ||
    ownersLoading;

  // Because this replaces the entire component, it will close the accordian.
  // We do not want it to close after a stipulation is edited and we refetch the
  // stipulations. We should investigate a more granualar way of showing the
  // loading state. Maybe within NewStipulationManager instead of here.
  if (isAnythingLoading) {
    return <Loading />;
  }

  if (
    attachmentsError ||
    extensionsError ||
    stipulationsError ||
    documentTagsError ||
    underwritersError ||
    processorsError ||
    customerError ||
    ownersError
  ) {
    return (
      <>
        {attachmentsError && (
          <Banner dismissable={false}>{attachmentsError}</Banner>
        )}
        {extensionsError && (
          <Banner dismissable={false}>{extensionsError}</Banner>
        )}
        {stipulationsError && (
          <Banner dismissable={false} variant="error">
            {stipulationsError.message}
          </Banner>
        )}
        {documentTagsError && (
          <Banner dismissable={false} variant="error">
            {documentTagsError}
          </Banner>
        )}
        {underwritersError && (
          <Banner dismissable={false} variant="error">
            {underwritersError}
          </Banner>
        )}
        {processorsError && (
          <Banner dismissable={false} variant="error">
            {processorsError}
          </Banner>
        )}
        {customerError && (
          <Banner dismissable={false} variant="error">
            {customerError.message}
          </Banner>
        )}
        {ownersError && (
          <Banner dismissable={false} variant="error">
            {ownersError.message}
          </Banner>
        )}
      </>
    );
  }

  /**
   * Maybe TODO: We're currently passing this through one layer
   * of components that's not using it. Ultimately, that's not too
   * bad. However, if this ends up needing to be more extensive prop
   * drilling, we should refactor this to be defined in the place it's
   * used, put a `refetchSubmission` in the `reloadAttachmentManager`
   * function, and then call that after this succeeds.
   *
   * @tyrelosaur - March 2, 2023
   */
  const updateUwSignOff = async (
    underwriterSignOffId: number
  ): Promise<void> => {
    try {
      await updateUnderwriterSignOff({ underwriterSignOffId });
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
    } finally {
      refetchSubmission();
    }
  };

  if (
    stipulations &&
    attachments &&
    supportedFileExtensions &&
    documentTags &&
    underwriters &&
    processors
  ) {
    const originalDocuments = attachments.filter(
      (att) => att.source === 'original_submission'
    );
    const otherDocuments = attachments.filter((att) => att.source === 'other');
    const closingDocuments = attachments.filter(
      (att) => att.source === 'closing_documents'
    );

    const submissionToRead = maybeUpdatedSubmission ?? submission;

    return (
      <>
        {/* Why is this here? In order to complete the view history feature
    we needed a way to update the local storage with addEntry all the neccesary details
    without having to create a new iframe to just update history
    */}
        <ViewHistoryTracker submissionUuid={submissionUuid} />
        <AttachmentManagerProvider
          value={{
            primaryId: submissionUuid,
            documentTags,
            reloadAttachmentManager,
            supportedFileExtensions,
            customerDba: customer?.dba || '',
            customerLegalName: customer?.legalName || '',
            entityType: customer?.entityType,
            ownerNames: owners?.map((owner) => owner.name) || [],
            isSalesforce: isSalesforce,
            validReasons: validReasons,
          }}
        >
          <UnderwritersProvider
            value={{
              underwriters,
              processors,
            }}
          >
            <AttachmentManagerUploader />
            <Accordion type={'multiple'} defaultValue={[]}>
              <Accordion.Item value="stipulations" disabled={false}>
                <Accordion.Trigger>
                  Closing Documents ({closingDocuments.length})
                  <UnderwritingSignOffBanner
                    underwriterName={submissionToRead?.underwriterSignOffName}
                    signOffDate={submissionToRead?.underwriterSignOffTimestamp}
                  />
                </Accordion.Trigger>
                <Accordion.Content>
                  {updateUnderwriterSignOffError && (
                    <Banner dismissable={false} variant="error">
                      {updateUnderwriterSignOffError.message}
                    </Banner>
                  )}
                  <NewStipulationManager
                    submissionUuid={submissionUuid}
                    stipulations={stipulations}
                    attachments={closingDocuments}
                    submissionSubStage={submission?.subStage}
                    setUnderwriterSignOff={updateUwSignOff}
                  />
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="originalSubmission"
                disabled={originalDocuments.length === 0}
              >
                <Accordion.Trigger>
                  Original Submission ({originalDocuments?.length})
                </Accordion.Trigger>
                <Accordion.Content>
                  <AttachmentGroup
                    shouldShowDownload
                    source="original_submission"
                    attachments={originalDocuments}
                  />
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="otherDocuments"
                disabled={otherDocuments.length === 0}
              >
                <Accordion.Trigger>
                  Other Documents ({otherDocuments?.length})
                </Accordion.Trigger>
                <Accordion.Content>
                  <AttachmentGroup
                    shouldShowDownload
                    source="other"
                    attachments={otherDocuments}
                  />
                </Accordion.Content>
              </Accordion.Item>
            </Accordion>
          </UnderwritersProvider>
        </AttachmentManagerProvider>
      </>
    );
  }

  // istanbul ignore next: we should never hit this case.
  return (
    <Banner dismissable={false}>
      Something has gone very wrong, and we are in a state that should be
      impossible. Please create an AST.
    </Banner>
  );
};
