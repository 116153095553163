import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  useDeleteApiReport,
  useGetApiBusinessPaynetReport,
} from 'apiHooks/underwriting/customerFetchHooks';
import {
  BusinessPaynetResponse,
  PaynetMemberLenderResponse,
} from 'types/api/underwriting/types';
import { useLogError } from 'apiHooks/useLogError';
import {
  BusinessPaynet,
  BusinessSummary,
  PaymentDetails,
} from './businessPaynet.types';
import {
  paynetCollateralDescription,
  paynetContracts,
} from './businessPaynetHelpers';

const toBusinessSummary = (
  response: BusinessPaynetResponse
): BusinessSummary => {
  const data = response.full_payload;
  const nameAddressInfo = data.name_address;
  return {
    companyName: nameAddressInfo.primary_name ?? undefined,
    paynetMasterScorev2: data.master_score,
    taxId: nameAddressInfo.tax_id,
    percentile: data.master_score_percentile,
    address: [
      nameAddressInfo.address_1,
      nameAddressInfo.address_2,
      nameAddressInfo.city,
      nameAddressInfo.state_code,
      nameAddressInfo.postal_code,
    ]
      .filter(Boolean)
      .join(' '),
  };
};

const toPaymentDetails = (
  response: PaynetMemberLenderResponse,
  index: number
): PaymentDetails[] => {
  return [response.contracts.contract].flat().map((c) => {
    return {
      lender: `Member Lender ${index + 1}`,
      industry: response.primary_industry,
      collateral: paynetCollateralDescription[c.equipment_type],
      contract: paynetContracts[c.contract_type],
      startDate: c.start_date,
      asOfDate: response.as_of_date,
      originalAmount: c.orig_receivable_amt ?? undefined,
      balanceAmount: c.cur_bal_amt,
      paymentAmount: c.payment_amt,
      term: c.term,
      frequency: c.payment_freq,
      delinquency01: c.past_due_0130_occurrences ?? undefined,
      delinquency31: c.past_due_3160_occurrences ?? undefined,
      delinquency61: c.past_due_6190_occurrences ?? undefined,
      delinquency91: c.past_due_91_plus_occurrences ?? undefined,
      status: typeof c.loss_status === 'string' ? c.loss_status : undefined,
      loss: c.loss_amt,
    };
  });
};

const toBusinessPaynet = (response: BusinessPaynetResponse): BusinessPaynet => {
  return {
    reportId: response.full_payload.report_id,
    createdAt: response.created_at,
    businessSummary: toBusinessSummary(response),
    paymentDetails: [response.full_payload.member_lenders.member_lender]
      .flat()
      .map((ml, index) => toPaymentDetails(ml, index))
      .flat(),
  };
};

export const useBusinessPaynetReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessPaynet> => {
  return useGenericFeatureQuery(
    useGetApiBusinessPaynetReport,
    // The response is always an array of one element.
    (data) => (data && data.length > 0 ? toBusinessPaynet(data[0]) : undefined),
    submissionUuid
  );
};

type UseDeletePaynetReportResult = [
  (reportId: string) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<boolean>, 'responseReady'>
];

export const useDeletePaynetReport = (): UseDeletePaynetReportResult => {
  const [deleteFn, { error, loading }] = useDeleteApiReport();

  useLogError(error);

  return [
    deleteFn,
    {
      error,
      loading,
    },
  ];
};
