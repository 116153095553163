import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Grid,
  Link,
  List,
  Table,
  Tabs,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import {
  BANK_BASE_URL,
  FUNDING_BASE_URL,
  UNDERWRITING_BASE_URL,
} from 'constants/globals';
import {
  FraudMatchCriteria,
  PotentialFraudMatch,
} from './PotentialFraud.types';
import { RemoveFraudAlertModal } from './RemoveFraudAlertModal';

export interface PotentialFraudContentProps {
  matches: PotentialFraudMatch[];
  submissionUuid: string;
  refetchMatches: (submissionUuid: string) => Promise<void>;
}

export interface MatchCriteriaListProps {
  criteria: FraudMatchCriteria;
}

export const MatchCriteriaList = ({
  criteria,
}: MatchCriteriaListProps): JSX.Element => {
  return (
    <>
      <Text bold>Matching Criteria</Text>
      <List>
        {criteria.dba && <List.Item>DBA Name</List.Item>}
        {criteria.legalName && <List.Item>Legal Name</List.Item>}
        {criteria.fein && <List.Item>FEIN</List.Item>}
        {criteria.ownerName && <List.Item>Owner Name</List.Item>}
        {criteria.ownerSsn && <List.Item>Owner SSN</List.Item>}
        {criteria.ownerEmail && <List.Item>Owner Email</List.Item>}
        {criteria.ownerAddress && <List.Item>Owner Address</List.Item>}
        {criteria.ownerPhone && <List.Item>Owner Phone</List.Item>}
        {criteria.businessAddress && <List.Item>Business Address</List.Item>}
        {criteria.businessPhone && <List.Item>Business Phone</List.Item>}
      </List>
    </>
  );
};

export const PotentialFraudContent = ({
  matches,
  submissionUuid,
  refetchMatches,
}: PotentialFraudContentProps): JSX.Element => {
  return matches.length === 0 ? (
    <Text>No fraud matches</Text>
  ) : (
    <Tabs defaultValue={matches[0].submission.uuid}>
      <Tabs.List>
        {matches.map((match) => (
          <Tabs.Trigger
            key={match.submission.uuid}
            value={match.submission.uuid}
          >
            {match.submission.legalName || 'N/A'}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {matches.map((match) => {
        const bankingEndpoint = featureFlags.ba_links_refactor_remove_customer
          ? `/admin/prospective_merchants/sheets/${match.submission.uuid}`
          : `/admin/prospective_merchants/${match.submission.accountUuid}/sheets/${match.submission.uuid}`;

        return (
          <Tabs.Content
            key={match.submission.uuid}
            value={match.submission.uuid}
          >
            <Grid gutter>
              <Grid.Item l={8}>
                {match.alerts.length === 0 ? (
                  <Banner variant="error" dismissable={false}>
                    No alerts found for this match. Please open a support ticket
                  </Banner>
                ) : (
                  <Table caption="alert details">
                    <Table.Body>
                      <Table.Row>
                        <Table.RowHeader>Reason for Flag</Table.RowHeader>
                        <Table.Cell>
                          {/* Q: Why are we only looking at the first alert here?
                      
                      A: This feature is currently built with the assumption that
                      there will only be one alert per match, and the only recognized
                      type is payment issues. We will eventually update the UI to
                      support multiple alerts and various alert types, but do not
                      currently know what those requirements will be or have a timeframe
                      for when we will.
                      
                      If the backend starts sending multiple alerts without corresponding UI
                      changes, the UI will display type Other for non-payment alerts and will
                      only show the first alert for any given match, but should remain usable */}
                          {match.alerts[0]?.type === 'payment_issue_alert'
                            ? 'Payment Issues'
                            : 'Other'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.RowHeader>Submission Date</Table.RowHeader>
                        <Table.Cell>
                          <Flex gap={2}>
                            {match.submission.receivedAt
                              ? formatDateString(match.submission.receivedAt)
                              : 'N/A'}
                            <Link
                              newTab
                              href={
                                new URL(
                                  `/admin/applications/${match.submission.uuid}`,
                                  UNDERWRITING_BASE_URL()
                                )
                              }
                            >
                              UA
                            </Link>
                            <Link
                              newTab
                              href={new URL(bankingEndpoint, BANK_BASE_URL())}
                            >
                              BA
                            </Link>
                            {match.alerts[0].source === 'funding_app' && (
                              <Link
                                newTab
                                href={
                                  new URL(
                                    `/admin/advances/${match.submission.uuid}`,
                                    FUNDING_BASE_URL()
                                  )
                                }
                              >
                                FA
                              </Link>
                            )}
                          </Flex>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.RowHeader>Business Legal Name</Table.RowHeader>
                        <Table.Cell>
                          {match.submission.legalName || 'N/A'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.RowHeader>Business DBA</Table.RowHeader>
                        <Table.Cell>{match.submission.dba || 'N/A'}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                )}

                {match.alerts.length > 0 && (
                  <Box marginTop={3}>
                    <RemoveFraudAlertModal
                      alert={match.alerts[0]}
                      submissionUuid={submissionUuid}
                      onSuccess={refetchMatches}
                    />
                  </Box>
                )}
              </Grid.Item>
              <Grid.Item l={4}>
                <MatchCriteriaList criteria={match.criteria} />
              </Grid.Item>
            </Grid>
          </Tabs.Content>
        );
      })}
    </Tabs>
  );
};
