import { featureFlags } from 'helpers/featureFlags';

export const bankingStipulationTypes = (): string[] => [
  ...(featureFlags.new_stipulations_process
    ? ['12 Month Bank Verification']
    : []),
  'Accounts Receivable Report/Invoices',
  ...(featureFlags.new_stipulations_process ? [] : ['Balance Sheet']),
  'Bank Verification for Additional Account',
  'Confirm Business Type/Online Presence',
  'Confirm Current or Past Funding',
  'Information on Additional Owner',
  'Payback Months',
  'Proof of Ownership',
  'Tax Return',
  'UCC Information',
  'Other',
];

export const listStipulationsRequiringAdditionalInfo = [
  'Bank Verification for Additional Account',
  'Confirm Current or Past Funding',
  '12 Month Bank Verification',
  'Information on Additional Owner',
  'UCC Information',
  'Other',
];

export const listStipulationsRequiringReason = [
  'Bank Verification for Additional Account',
  'Tax Return',
  '12 Month Bank Verification',
  'Accounts Receivable Report/Invoices',
  'Payback Months',
];

export const shouldAdditionalInfoBeRequired = (stipType: string): boolean => {
  return listStipulationsRequiringAdditionalInfo.includes(stipType);
};

export const shouldReasonBeRequired = (stipType: string): boolean => {
  return listStipulationsRequiringReason.includes(stipType);
};
