import { fetchDeclineDrivers } from 'api/banking/declineDriversFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { DeclineDriversResponse } from 'types/api/banking/types';

export type UseGetApiDeclineDriversResponse =
  UseGenericQueryResponse<DeclineDriversResponse>;

export const useGetApiDeclineDrivers = (): UseGetApiDeclineDriversResponse => {
  const loadData = useCallback(() => {
    return fetchDeclineDrivers();
  }, []);

  return useGenericQuery(loadData);
};
