import * as React from 'react';
import { DeclineForm } from './DeclineForm';
import { DeclineDrivers } from '../../../api/BankingClient/codecs';
import { Decline } from '../../../api/UnderwritingClient/codecs';
import { Modal } from '@forward-financing/fast-forward';

export interface DeclineModalProps {
  declineDriverList: DeclineDrivers;
  initialDeclineNotes?: string;
  initialDeclineDrivers?: DeclineDrivers;
  closeAction: () => void;
  submitAction: (decline: Decline) => void;
  isActive: boolean;
  trigger: React.ReactNode;
}

export const DeclineModal = (props: DeclineModalProps): JSX.Element => {
  const { initialDeclineNotes = '', initialDeclineDrivers = [] } = props;

  return (
    <Modal
      isOpen={props.isActive}
      title="Decline"
      onOpenChange={props.closeAction}
      trigger={props.trigger}
      body={
        <DeclineForm
          closeAction={props.closeAction}
          submitAction={props.submitAction}
          declineDriverList={props.declineDriverList}
          initialDeclineNotes={initialDeclineNotes}
          initialDeclineDrivers={initialDeclineDrivers}
        />
      }
    />
  );
};
