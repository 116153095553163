import React from 'react';
import {
  Sheet,
  Subheading,
  Text,
  Banner,
  Loading,
} from '@forward-financing/fast-forward';
import { useFetchBuyRates } from './ledgerHooks';

// Returns the first three letters of the month that is `monthsLater` from the given `date`
const getMonth = (date: Date, monthsLater: number): string => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + monthsLater);
  return newDate.toLocaleString('en-US', { month: 'short' });
};

export type BuyRatesTableProps = {
  submissionUuid: string;
  ledgerId: number;
};

export const BuyRatesTable = ({
  submissionUuid,
  ledgerId,
}: BuyRatesTableProps): JSX.Element => {
  const {
    data: buyRateTables,
    loading: buyRateTablesLoading,
    error: buyRatesTablesError,
  } = useFetchBuyRates({
    submissionUuid,
    ledgerId,
  });

  const buyRateTable = buyRateTables?.buyRateTable;
  const totalFactorRateTable = buyRateTables?.totalFactorRateTable;

  const today = new Date();

  return (
    <>
      <Subheading>Buy Rates</Subheading>

      {buyRatesTablesError && (
        <Banner dismissable={false}>{buyRatesTablesError.message}</Banner>
      )}

      {buyRateTablesLoading ? (
        <Loading />
      ) : (
        <Sheet>
          <Sheet.Head>
            <Sheet.ColumnHeader>Term Length (Months)</Sheet.ColumnHeader>
            {buyRateTable?.map((buyRate) => (
              <Sheet.ColumnHeader key={buyRate.month}>
                {buyRate.month} - {getMonth(today, Number(buyRate.month))}
              </Sheet.ColumnHeader>
            ))}
          </Sheet.Head>

          <Sheet.Body>
            <Sheet.Row>
              <Sheet.TextCell>
                <Text bold>Buy Rate</Text>
              </Sheet.TextCell>

              {buyRateTable?.map((buyRate) => (
                <Sheet.TextCell key={`${buyRate.month}-buy-rate`}>
                  {buyRate.rate}
                </Sheet.TextCell>
              ))}
            </Sheet.Row>

            <Sheet.Row>
              <Sheet.TextCell>
                <Text bold>Max Factor Rate</Text>
              </Sheet.TextCell>
              {totalFactorRateTable?.map((maxFactorRate) => (
                <Sheet.TextCell key={`${maxFactorRate.month}-max-factor-rate`}>
                  {maxFactorRate.rate}
                </Sheet.TextCell>
              ))}
            </Sheet.Row>
          </Sheet.Body>
        </Sheet>
      )}
    </>
  );
};
