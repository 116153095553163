import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiStipulationRecommendations } from 'apiHooks/underwriting/submissionFetchHooks';
import { StipulationRecommendationsResponse } from 'types/api/underwriting/types';
import { StipulationRecommendation } from './stipulationRecommendations.types';

const toStipulationRecommendation = (
  response?: StipulationRecommendationsResponse
): StipulationRecommendation | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    id: response.id,
    submissionUuid: response.submission_uuid,
    stipulationType: response.stipulation_type,
    bucket: response.bucket,
    overallScore: response.overall_score,
    results: {
      experianCommercial: {
        score: response.results[0].experian_commercial.score,
        reasons: response.results[0].experian_commercial.reasons,
      },
      experianConsumer: {
        score: response.results[0].experian_consumer.score,
        reasons: response.results[0].experian_consumer.reasons,
      },
      lexisNexisBusiness: {
        score: response.results[0].lexis_nexis_business.score,
        reasons: response.results[0].lexis_nexis_business.reasons,
      },
      lexisNexisPersonal: {
        score: response.results[0].lexis_nexis_personal.score,
        reasons: response.results[0].lexis_nexis_personal.reasons,
      },
    },
  };
};

export const useStipulationRecommendations = (
  submissionUuid?: string
): UseGenericQueryResponse<StipulationRecommendation> =>
  useGenericFeatureQuery(
    useGetApiStipulationRecommendations,
    toStipulationRecommendation,
    submissionUuid
  );
