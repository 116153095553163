import { useCallback } from 'react';
import {
  fetchFullBusinessLexisNexisReport,
  fetchFullOwnerLexisNexisReport,
  fetchFullOwnerLexisNexisReportByReportId,
} from 'api/underwriting/documentsFetchUtils';
import {
  UseGenericQueryResponse,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  BusinessLexisNexisReportResponse,
  OwnerLexisNexisPayload,
  OwnerLexisNexisReportResponse,
} from 'types/api/underwriting/types';

export type UseGetApiOwnerLexisNexisReportResponse =
  UseGenericQueryResponse<OwnerLexisNexisReportResponse>;

type UseGetApiOwnerLexisNexisReportByIdResponse =
  UseGenericQueryResponse<OwnerLexisNexisPayload>;

export const useGetApiOwnerLexisNexisReport = (
  submissionUuid?: string
): UseGetApiOwnerLexisNexisReportResponse => {
  const getOwnerLexisNexisReport = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchFullOwnerLexisNexisReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getOwnerLexisNexisReport);
};

export const useGetApiOwnerLexisNexisReportById = (
  reportId?: string
): UseGetApiOwnerLexisNexisReportByIdResponse => {
  const getOwnerLexisNexisReportById = useCallback(() => {
    if (!reportId) {
      return undefined;
    }

    return fetchFullOwnerLexisNexisReportByReportId(reportId);
  }, [reportId]);

  return useGenericQuery(getOwnerLexisNexisReportById);
};

export const useGetApiBusinessLexisNexisReport = (
  submissionUuid?: string
): UseGenericQueryResponse<BusinessLexisNexisReportResponse> => {
  const getBusinessLexisNexisReport = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchFullBusinessLexisNexisReport(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(getBusinessLexisNexisReport);
};
