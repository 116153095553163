import React, { useEffect } from 'react';
import { defaultTo } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button, Loading } from '@forward-financing/fast-forward';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { BANK_BASE_URL } from 'constants/globals';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';

export type GoToNextDealButtonProps = {
  updateFunction: () => Promise<MutationResponse>;
  nextDealSubmissionUuid?: string;
  nextDealCustomerUuid?: string;
  nextDealLoading: boolean;
  nextDealResponseReady: boolean;
};

export const GoToNextProcessingDealButton = ({
  updateFunction,
  nextDealSubmissionUuid,
  nextDealCustomerUuid,
  nextDealLoading,
  nextDealResponseReady,
}: GoToNextDealButtonProps): JSX.Element => {
  const { sub_role: userSubRole } = useUserContext();
  const navigate = useNavigate();

  const goToNextSubmission = async (): Promise<void> => {
    await updateFunction();
  };

  useEffect(() => {
    if (
      nextDealSubmissionUuid &&
      nextDealCustomerUuid &&
      nextDealResponseReady &&
      !nextDealLoading
    ) {
      window.open(
        new URL(
          `/submissions/${nextDealSubmissionUuid}`,
          window.location.origin
        ).toString(),
        '_blank'
      );

      const endpoint = featureFlags.ba_links_refactor_remove_customer
        ? `/admin/prospective_merchants/sheets/${nextDealSubmissionUuid}`
        : `/admin/prospective_merchants/${nextDealCustomerUuid}/sheets/${nextDealSubmissionUuid}`;

      window.open(new URL(endpoint, BANK_BASE_URL()).toString(), '_blank');
    } else if (!nextDealSubmissionUuid && nextDealResponseReady) {
      navigate('/processing-queue-loading');
    }
  }, [
    nextDealCustomerUuid,
    nextDealSubmissionUuid,
    nextDealResponseReady,
    nextDealLoading,
    navigate,
  ]);

  return (
    <>
      {['processing', 'team_lead'].includes(defaultTo(userSubRole, '')) &&
        (nextDealLoading ? (
          <Loading size="small" />
        ) : (
          <Button
            startIcon={'arrow-right'}
            variant={'cta'}
            onClick={() => goToNextSubmission()}
          >
            Go to Next Submission
          </Button>
        ))}
    </>
  );
};
