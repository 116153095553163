import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  OwnerResponse,
  OwnerSimilarContactsResponse,
  SimilarOwnersResponse,
  LexisNexisOwnerReportResponse,
  LexisNexisResponse,
  UpdateOwnerRequestBody,
  BulkUpdateOwners,
  OwnerManualSearchResultsResponse,
  OwnerUccReportResponse,
} from 'types/api/underwriting/types';

// CAUTION: LOCAL TESTING ONLY. UNCOMMENT THESE LINES AS NEEDED FOR MOCK DATA.
// import { mockLexisNexisResponse } from '../../mocks/underwriting/generators/ApiV2SubmissionsUuidLexisNexisOwners';
// import { mockLexisNexisOwnerReportDocument } from '../../mocks/underwriting/generators/ApiV2SubmissionsUuidDocumentsLexisNexisOwnerReports';

export const fetchOwner = async (ownerUuid: string): Promise<OwnerResponse> => {
  const url = new URL(`/api/v2/owners/${ownerUuid}`, UNDERWRITING_BASE_URL());

  const response = await makeInternalAPIRequest<OwnerResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch owner');
  }

  return response.json();
};

export const fetchOwnerSimilarContacts = async (
  ownerUuid: string,
  submissionUuid: string
): Promise<OwnerSimilarContactsResponse[]> => {
  const url = new URL(
    `/api/v2/owners/${ownerUuid}/similar_contacts?submission_uuid=${submissionUuid}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerSimilarContactsResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch owner');
  }

  return response.json();
};

/**
 * Given  an ownerUuid, this endpoint will return a list of similar owners.
 *
 * The force param is used to force the backend to recompute the similar owners
 * rather than use the cached value that is uses by default.
 */
export const fetchSimilarOwners = async (
  ownerUuid: string,
  force: boolean
): Promise<SimilarOwnersResponse> => {
  const url = new URL(
    `/api/v2/owners/${ownerUuid}/similar_owners?force=${force}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<SimilarOwnersResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch similar owners');
  }

  return response.json();
};

export const fetchPublicDocuments = async (
  submissionUuid: string
): Promise<LexisNexisOwnerReportResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       documents: [
  //         mockLexisNexisOwnerReportDocument({
  //           owner_uuid: '64f27115-48b5-48e5-a936-cbbba6f33089', // Change this to the owner_uuid of the owner you want to test.
  //         }),
  //       ],
  //       success: true,
  //       error: null,
  //     });
  //   }, 5000);
  // });

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LexisNexisOwnerReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch public documents for submission owners'
    );
  }

  return response.json();
};

export const fetchLexisNexisForOwners = async (
  submissionUuid: string
): Promise<LexisNexisResponse[]> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve([
  //       mockLexisNexisResponse({
  //         owner_uuid: 'f9fc82fc-2b5e-4b0a-9195-e50d42a12132', // Change this to the owner_uuid of the owner you want to test.
  //       }),
  //     ]);
  //   }, 5000);
  // });

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/lexis_nexis_owner_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LexisNexisResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch LexisNexis report for submission owners'
    );
  }

  return response.json();
};

/**
 * This utils is primarily used in submission editing with the purpose of refactoring the entities we update.
 * At this moment the BE is not ready to support updates to owners model. So in the meantime we are going to
 * send applicationUuid as submissionUuid and we will fetch contacts instead of owners.
 *
 * These two entities have the same structure. For this we will just use the contacts endpoint to fetch the data.
 */
export const fetchSubmissionOwners = async (
  submissionUuid: string
): Promise<OwnerResponse[]> => {
  const url = new URL(
    `api/v2/applications/${submissionUuid}/contacts`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerResponse[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch submission owners'
    );
  }

  return response.json();
};

// submissionUuid here will be used as applicationUuid while the BE team allows updates to owner entity instead of contact
export const patchOwners = async (
  submissionUuid: string,
  body: UpdateOwnerRequestBody[]
): Promise<OwnerResponse[]> => {
  const url = new URL(
    `api/v2/applications/${submissionUuid}/contacts/bulk_update`,
    UNDERWRITING_BASE_URL()
  );

  const owners = {
    contacts_attributes: body,
  };

  const response = await makeInternalAPIRequest<
    OwnerResponse[],
    BulkUpdateOwners
  >(url, 'PATCH', owners);

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update Owner');
  }

  return response.json();
};

export const fetchOwnerManualSearchResult = async (
  submissionUuid: string,
  ownerUuid: string,
  queryParams: string
): Promise<OwnerManualSearchResultsResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_search?${queryParams}`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<OwnerManualSearchResultsResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch LexisNexis Owner Manual Search'
    );
  }

  return response.json();
};

export const fetchOwnerUccReport = async (
  submissionUuid: string,
  ownerUuid: string
): Promise<OwnerUccReportResponse> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_ucc_documents`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerUccReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Owner UCC Report');
  }

  return response.json();
};
