import { AuthClient } from '../AuthClient';
import { BANK_BASE_URL } from '../../constants/globals';
import { AuthenticationHeaders } from '../AuthClient/codecs';
import {
  DeclineDrivers,
  DeclineDriversResponse,
  AcceptedOffer,
} from './codecs';
import { FetchResponse } from 'api/codecs';

export class BankingClient extends AuthClient {
  private static BASE_URL = BANK_BASE_URL();

  static fetchDeclineDrivers = (): Promise<DeclineDrivers> => {
    const getDeclineDrivers = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<DeclineDriversResponse>> => {
      const url = `${BankingClient.BASE_URL}api/v1/decline_drivers`;

      return BankingClient.get<DeclineDriversResponse>(url, {
        headers: authenticationHeaders,
      });
    };

    return BankingClient.authenticateApiRequest<DeclineDriversResponse>(
      getDeclineDrivers
    )
      .then((response) => response.json())
      .then(({ decline_drivers }) => decline_drivers);
  };

  static fetchAcceptedOffer = (
    opportunityUuid: string
  ): Promise<AcceptedOffer> => {
    const getAcceptedOffer = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<AcceptedOffer>> => {
      const url = `${BankingClient.BASE_URL}api/v2/opportunities/${opportunityUuid}/accepted_offer/`;

      return BankingClient.get<AcceptedOffer>(url, {
        headers: authenticationHeaders,
      });
    };
    return BankingClient.authenticateApiRequest<AcceptedOffer>(
      getAcceptedOffer
    ).then((response) => response.json());
  };
}
