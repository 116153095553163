import { defaultTo } from 'lodash';
import {
  SubmissionNotesResponse,
  SubmissionNoteType,
  SubmissionResponse,
  RenewalScoresFetchResponse,
  RenewalScoreResponse,
  SubmissionNewDealCompositeScoreResponse,
  SubmissionRenewalCompositeScoreResponse,
  SubmissionCompositeScoreResponse,
} from 'types/api/underwriting/types';

import {
  useGetApiSubmission,
  useGetSubmissionNotes,
  useUpdateApiSubmissionNote,
  useCreateApiRenewalScore,
  useUpdateApiRenewalScore,
  useGetRenewalScore,
  useGetSubmissionCompositeScore,
  UseGetSubmissionCompositeScoreResponse,
} from 'apiHooks/underwriting/submissionFetchHooks';

import { useLogError } from 'apiHooks/useLogError';
import {
  MutationResponse,
  UseGenericQueryResponse,
  UseGenericMutationResult,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { FFLogger } from 'api/LogClient';
import {
  SubmissionNotes,
  DealScoringNoteType,
  ManagerDealScoring,
  NewDealScoreComposite,
  RenewalScoreComposite,
} from './DealScoring.types';
import { Submission } from './DealScoringContainer.types';
import { ErrorWithStatusCode } from './RenewalScoring/RenewalScoringFetchHooks';

const toSubmission = (
  submission?: SubmissionResponse
): Submission | undefined => {
  if (!submission) {
    return undefined;
  }

  return {
    type: submission.type,
    previousFundedSubmissionUuid:
      submission.previous_funded_opportunity_uuid ?? undefined,
    previousFundedSubmissionUuidFromCustomer:
      submission.previous_funded_submission_uuid_from_customer ?? undefined,
    customerUuid: submission.customer_uuid,
    amountRequested: defaultTo(submission.capital_needed, undefined),
    isoUuid: defaultTo(submission.partner_uuid, undefined),
    category: submission.category ?? undefined,
  };
};

export const useSubmission = (
  submissionUuid: string
): UseGenericQueryResponse<Submission> =>
  useGenericFeatureQuery(useGetApiSubmission, toSubmission, submissionUuid);

const apiNoteTypeToFeatureNoteType = (
  submissionNoteType: SubmissionNoteType
): DealScoringNoteType =>
  ({
    underwriter_scoring: 'UNDERWRITER',
    manager_scoring: 'MANAGER',
  }[submissionNoteType] as DealScoringNoteType);

const featureNoteTypeToApiNoteType = (
  featureNoteType: DealScoringNoteType
): SubmissionNoteType =>
  ({
    UNDERWRITER: 'underwriter_scoring',
    MANAGER: 'manager_scoring',
  }[featureNoteType] as SubmissionNoteType);

const toSubmissionNotes = (
  submissionNotesResponse: SubmissionNotesResponse
): SubmissionNotes => {
  return {
    content: submissionNotesResponse.content,
    noteType: apiNoteTypeToFeatureNoteType(submissionNotesResponse.note_type),
  };
};

export const useDealScoringNotes = (
  submissionUuid?: string
): UseGenericQueryResponse<SubmissionNotes[]> =>
  useGenericFeatureQuery(
    useGetSubmissionNotes,
    (data) => data?.map(toSubmissionNotes),
    submissionUuid
  );

export type UpdateSubmissionNoteBody = {
  noteContent: string;
};

type UseUpdateSubmissionNotesResult = [
  (input: UpdateSubmissionNoteBody) => Promise<MutationResponse>,
  UseGenericMutationResult<SubmissionNotes>
];

export const useUpdateSubmissionNote = (
  submissionUuid: string,
  noteType: DealScoringNoteType
): UseUpdateSubmissionNotesResult => {
  const [updateSubmissionNote, { data, error, ...rest }] =
    useUpdateApiSubmissionNote();

  const updateFunction = (
    args: UpdateSubmissionNoteBody
  ): Promise<MutationResponse> => {
    return updateSubmissionNote({
      submissionUuid,
      noteType: featureNoteTypeToApiNoteType(noteType),
      updateBody: {
        content: args.noteContent,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      data: data && toSubmissionNotes(data),
      error,
      ...rest,
    },
  ];
};

const toManagerScoring = (
  renewalScoreResponse?: RenewalScoreResponse
): ManagerDealScoring | undefined => {
  if (!renewalScoreResponse) {
    return undefined;
  }

  return {
    managerFeedbackScore:
      renewalScoreResponse.manager_feedback_score ?? undefined,
    lastUpdatedAt: renewalScoreResponse.last_updated_at ?? undefined,
  };
};

const toRenewalDealScoring = (
  renewalScoreResponse?: RenewalScoresFetchResponse
): ManagerDealScoring | undefined => {
  if (!renewalScoreResponse) {
    return undefined;
  }

  return toManagerScoring(renewalScoreResponse.renewal_score);
};

export const useManagerDealScore = (
  submissionUuid?: string
): UseGenericQueryResponse<ManagerDealScoring> =>
  useGenericFeatureQuery(
    useGetRenewalScore,
    toRenewalDealScoring,
    submissionUuid
  );

export type UpdateManagerScoreBody = {
  managerFeedbackScore: boolean;
};

type UseUpdateManagerScoreResult = [
  (input: UpdateManagerScoreBody) => Promise<MutationResponse>,
  UseGenericMutationResult<ManagerDealScoring>
];

export const useUpdateManagerScore = (
  submissionUuid: string
): UseUpdateManagerScoreResult => {
  const [updateRenewalScore, { data, error, ...rest }] =
    useUpdateApiRenewalScore();

  const updateFunction = ({
    managerFeedbackScore,
  }: UpdateManagerScoreBody): Promise<MutationResponse> => {
    return updateRenewalScore({
      submissionUuid,
      updateBody: {
        manager_feedback_score: managerFeedbackScore,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      data: toManagerScoring(data),
      error,
      ...rest,
    },
  ];
};

type UseCreateManagerScoreResult = [
  () => Promise<MutationResponse>,
  UseGenericMutationResult<ManagerDealScoring>
];

export const useCreateManagerScore = (
  submissionUuid: string
): UseCreateManagerScoreResult => {
  const [createRenewalScore, { data, error, ...rest }] =
    useCreateApiRenewalScore();

  const createFunction = (): Promise<MutationResponse> => {
    return createRenewalScore({
      submissionUuid,
    });
  };

  useLogError(error);

  return [
    createFunction,
    {
      data: toManagerScoring(data),
      error,
      ...rest,
    },
  ];
};

const toRenewalScoreComposite = (
  renewalCompositeScore: SubmissionRenewalCompositeScoreResponse
): RenewalScoreComposite | undefined => {
  return {
    type: 'Renewal',
    industryRisk: {
      score: renewalCompositeScore.industry_risk?.score,
      value: renewalCompositeScore.industry_risk?.value,
      industry: renewalCompositeScore.industry_risk?.industry,
    },
    timesFunded: {
      score: renewalCompositeScore.times_funded?.score,
      value: renewalCompositeScore.times_funded?.value,
    },
    ficoChange: {
      score: renewalCompositeScore.fico_change?.score,
      value: renewalCompositeScore.fico_change?.value,
    },
    revenueChange: {
      score: renewalCompositeScore.revenue_change?.score,
      value: renewalCompositeScore.revenue_change?.value,
    },
    repaidPercentage: {
      score: renewalCompositeScore.repaid_percentage?.score,
      value: renewalCompositeScore.repaid_percentage?.value,
    },
    paymentHistory: {
      score: renewalCompositeScore.payment_history?.score,
      value: renewalCompositeScore.payment_history?.value,
    },
    stacking: {
      score: renewalCompositeScore.stacking?.score,
      preFfGross: renewalCompositeScore.stacking?.pre_ff_gross,
    },
    ...(renewalCompositeScore.composite_score && {
      compositeScore: {
        score: renewalCompositeScore.composite_score?.score,
        tier: renewalCompositeScore.composite_score?.tier,
      },
    }),
    updatedAt: renewalCompositeScore.updated_at,
  };
};

const toNewDealScoreComposite = (
  newDealCompositeScore: SubmissionNewDealCompositeScoreResponse
): NewDealScoreComposite | undefined => {
  return {
    type: 'New Deal',
    industryRisk: {
      score: newDealCompositeScore.industry_risk?.score,
      value: newDealCompositeScore.industry_risk?.value,
      industry: newDealCompositeScore.industry_risk?.industry,
    },
    position: {
      score: newDealCompositeScore.position?.score,
      value: newDealCompositeScore.position?.value,
    },
    fico: {
      score: newDealCompositeScore.fico?.score,
      value: newDealCompositeScore.fico?.value,
    },
    timeInBusiness: {
      score: newDealCompositeScore.time_in_business?.score,
      value: newDealCompositeScore.time_in_business?.value,
    },
    riskDecile: {
      score: newDealCompositeScore.risk_decile?.score,
      value: newDealCompositeScore.risk_decile?.value,
    },
    trueRevenue: {
      score: newDealCompositeScore.true_revenue?.score,
      value: newDealCompositeScore.true_revenue?.value,
    },
    ...(newDealCompositeScore.composite_score && {
      compositeScore: {
        score: newDealCompositeScore.composite_score?.score,
        tier: newDealCompositeScore.composite_score?.tier,
      },
      updatedAt: newDealCompositeScore.updated_at,
    }),
  };
};

const toCompositeScore = (
  compositeScore?: SubmissionCompositeScoreResponse
): NewDealScoreComposite | RenewalScoreComposite | undefined => {
  if (!compositeScore) {
    return undefined;
  }

  if (compositeScore.artifact_case === 'composite_score') {
    return toNewDealScoreComposite(compositeScore);
  } else if (compositeScore.artifact_case === 'renewal_composite_score') {
    return toRenewalScoreComposite(compositeScore);
  } else {
    FFLogger.warn('Unknown composite score type', compositeScore);
    return undefined;
  }
};

interface UseScoringCompositeResult
  extends Omit<UseGetSubmissionCompositeScoreResponse, 'data'> {
  data?: NewDealScoreComposite | RenewalScoreComposite;
  error?: ErrorWithStatusCode;
}

/**
 * Note: This is currently untested because it is not implemented anywhere.
 *
 * We are using a generic feature hook, so once we implement this hook in
 * an upcoming ticket, the feature tests will cover this hook, and our
 * coverage hit should be restored. Once this is verified we can remove
 * this comment.
 */
export const useScoringComposite = (
  submissionUuid?: string
): UseScoringCompositeResult =>
  useGenericFeatureQuery(
    useGetSubmissionCompositeScore,
    toCompositeScore,
    submissionUuid
  );
