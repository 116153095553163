import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  TextInput,
  Select,
  Grid,
  IconButton,
  Divider,
  Combobox,
  Banner,
} from '@forward-financing/fast-forward';
import { v4 as uuidv4 } from 'uuid';
import {
  useHandleCreateStipulations,
  useSelectedStipulationReasons,
} from 'components/StipulationManager/stipulationManagerHooks';
import { HashMap } from 'api/codecs';
import { featureFlags } from 'helpers/featureFlags';
import { isReasonArrayEmpty } from 'components/StipulationManager/stipulationHelpers';
import { CreateStipulationInput } from 'components/StipulationManager/stipulationManager.types';
import { StipulationRecommendationsContainer } from 'components/StipulationRecommendations/StipulationRecommendationsContainer';
import {
  bankingStipulationTypes,
  shouldAdditionalInfoBeRequired,
  shouldReasonBeRequired,
} from './BankingStipulationHelpers';

export type CreateBankingStipulationFormProps = {
  onSubmit: () => void;
  onCancel: () => void;
  submissionUuid: string;
  validReasons?: HashMap<string[]>;
};

export const CreateBankingStipulationForm = ({
  onSubmit,
  onCancel,
  submissionUuid,
  validReasons,
}: CreateBankingStipulationFormProps): JSX.Element => {
  const [stips, setStips] = useState<CreateStipulationInput[]>([
    { temporaryUuid: uuidv4(), name: '', notes: '' },
  ]);
  const { error, handleSubmit } = useHandleCreateStipulations({
    stips,
    setStips,
    submissionUuid,
    onSubmitCallback: onSubmit,
  });

  const handleCancel = (): void => {
    setStips([
      {
        temporaryUuid: uuidv4(),
        name: '',
        notes: '',
      },
    ]);
    onCancel();
  };

  const handleAddStip = (): void => {
    setStips((prev) => [
      ...prev,
      {
        temporaryUuid: uuidv4(),
        name: '',
        notes: '',
      },
    ]);
  };

  const handleDeleteStip = (uuidToDelete: string): void => {
    setStips((prev) =>
      prev.filter((stip) => {
        return stip.temporaryUuid !== uuidToDelete;
      })
    );
  };

  const handleUpdateStip = (
    updatedStip: Partial<CreateStipulationInput>,
    uuidToUpdate: string
  ): void => {
    setStips((prevStips) =>
      prevStips.map((stip) =>
        stip.temporaryUuid === uuidToUpdate ? { ...stip, ...updatedStip } : stip
      )
    );
  };

  const { handleUpdateName, handleUpdateReasons } =
    useSelectedStipulationReasons({ handleUpdateStip });

  const additionalInfoRequiredValidation = (
    stip: CreateStipulationInput
  ): boolean => {
    return (
      shouldAdditionalInfoBeRequired(stip.name) &&
      (!stip.notes || stip.notes === '')
    );
  };

  const reasonRequiredValidation = (stip: CreateStipulationInput): boolean => {
    return (
      featureFlags.new_stipulations_process_phase_2 &&
      shouldReasonBeRequired(stip.name) &&
      isReasonArrayEmpty(stip.reasons)
    );
  };

  const shouldSaveBeDisabled = (): boolean => {
    let disabled;
    featureFlags.remove_describe_stipulation_type
      ? (disabled = stips.some(
          (stip) =>
            stip.name === '' ||
            additionalInfoRequiredValidation(stip) ||
            reasonRequiredValidation(stip)
        ))
      : (disabled = stips.some(
          (stip) =>
            stip.name === '' ||
            (stip.name === 'Other' && !stip.otherDescription) ||
            additionalInfoRequiredValidation(stip) ||
            reasonRequiredValidation(stip)
        ));

    return disabled;
  };

  return (
    <Flex flexDirection="column">
      {featureFlags.show_stipulation_recommendation_text && (
        <StipulationRecommendationsContainer submissionUuid={submissionUuid} />
      )}
      {error && <Banner>{error}</Banner>}
      {stips.map((stip, index) => (
        <Box p={2} key={stip.temporaryUuid}>
          <Grid gutter>
            <Grid.Item m={5}>
              <Select
                label={`Stipulation ${index + 1} Type`}
                value={stip.name}
                options={bankingStipulationTypes().map((type) => {
                  return { value: type, text: type };
                })}
                required={true}
                onValueChange={handleUpdateName(stip)}
              />
            </Grid.Item>

            {(!featureFlags.new_stipulations_process_phase_2 ||
              shouldAdditionalInfoBeRequired(stip.name)) && (
              <Grid.Item m={6}>
                <TextInput
                  label={`Stipulation ${index + 1} Additional Info`}
                  value={stip.notes}
                  maxLength={250}
                  required={
                    shouldAdditionalInfoBeRequired(stip.name) &&
                    !stip.notes?.trim()
                  }
                  onValueChange={(newValue) =>
                    handleUpdateStip(
                      {
                        notes: newValue,
                      },
                      stip.temporaryUuid
                    )
                  }
                />
              </Grid.Item>
            )}

            {featureFlags.new_stipulations_process_phase_2 &&
              shouldReasonBeRequired(stip.name) && (
                <Grid.Item m={4}>
                  <Combobox
                    closeMenuOnSelect={false}
                    isMulti
                    label={`Stipulation ${index + 1} Reason`}
                    values={
                      stip.reasons
                        ? stip.reasons.map((reason) => ({
                            value: reason,
                            text: reason,
                          }))
                        : []
                    }
                    onValueChange={handleUpdateReasons(stip)}
                    required={reasonRequiredValidation(stip)}
                    options={
                      stip.name && validReasons && validReasons[stip.name]
                        ? validReasons[stip.name]?.map((reason) => ({
                            value: reason,
                            text: reason,
                          }))
                        : []
                    }
                  />
                </Grid.Item>
              )}

            {stips.length > 1 && (
              <Flex alignItems={'end'}>
                <Grid.Item m={1}>
                  <IconButton
                    icon="trash"
                    label="Delete Stipulation"
                    hiddenLabel
                    onClick={() => handleDeleteStip(stip.temporaryUuid)}
                  />
                </Grid.Item>
              </Flex>
            )}

            {!featureFlags.remove_describe_stipulation_type &&
              stip.name === 'Other' && (
                <Grid.Item m={5}>
                  <TextInput
                    label="Describe Stipulation Type"
                    maxLength={250}
                    onValueChange={(newValue) =>
                      handleUpdateStip(
                        {
                          otherDescription: newValue,
                        },
                        stip.temporaryUuid
                      )
                    }
                    required
                  />
                </Grid.Item>
              )}
          </Grid>
        </Box>
      ))}
      <Box mt={2}>
        <Button
          onClick={handleAddStip}
          startIcon="plus"
          disabled={stips.some((stip) => stip.name === '')}
        >
          Add Another Stipulation
        </Button>
      </Box>
      <Divider />
      <Flex justifyContent="center" gap={2}>
        <Button
          onClick={handleSubmit}
          startIcon="check"
          disabled={shouldSaveBeDisabled()}
        >
          Save
        </Button>
        <Button onClick={handleCancel} startIcon="times" variant="secondary">
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};
