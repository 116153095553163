import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  TextInput,
  Select,
  Grid,
  Divider,
  Banner,
  Combobox,
} from '@forward-financing/fast-forward';
import { HashMap } from 'api/codecs';
import { featureFlags } from 'helpers/featureFlags';
import { isReasonArrayEmpty } from 'components/StipulationManager/stipulationHelpers';
import { useSelectedStipulationReasons } from 'components/StipulationManager/stipulationManagerHooks';
import {
  bankingStipulationTypes,
  shouldAdditionalInfoBeRequired,
  shouldReasonBeRequired,
} from './BankingStipulationHelpers';
import {
  BankingStipulation,
  UpdateBankingStipulationInput,
} from './BankingStipulation.types';
import { useUpdateBankingStipulation } from './BankingStipulationHooks';

export type EditBankingStipulationFormProps = {
  onSubmit: () => void;
  onCancel: () => void;
  submissionUuid: string;
  stipulations: BankingStipulation[];
  onCancelWithError: (error?: string) => void;
  validReasons?: HashMap<string[]>;
};

const getPreSelectedReasons = (
  stips: BankingStipulation[]
): Record<string, string[]> => {
  const selectedReasons: Record<string, string[]> = {};

  stips.forEach((stip) => {
    if (stip.reasons?.length) {
      selectedReasons[`${stip.uuid}${stip.name}`] = stip.reasons;
    }
  });

  return selectedReasons;
};

export const EditBankingStipulationForm = ({
  onSubmit,
  onCancel,
  submissionUuid,
  stipulations,
  onCancelWithError,
  validReasons,
}: EditBankingStipulationFormProps): JSX.Element => {
  const [stips, setStips] =
    useState<UpdateBankingStipulationInput[]>(stipulations);

  const [updateStipulationBatch, { error: errorUpdateStipulation }] =
    useUpdateBankingStipulation(submissionUuid);

  const handleSubmit = async (): Promise<void> => {
    const batchStips = stips.map((stip) => {
      let otherDescription;
      if (featureFlags.remove_describe_stipulation_type) {
        stip.name === 'Other'
          ? (otherDescription = stip.notes)
          : (otherDescription = '');
      } else {
        otherDescription = stip.otherDescription;
      }
      return {
        name: stip.name,
        notes: stip.notes,
        reasons: stip.reasons,
        otherDescription: otherDescription,
        uuid: stip.uuid,
      };
    });

    const { success } = await updateStipulationBatch(batchStips);

    success && onSubmit();
  };

  useEffect(() => {
    if (errorUpdateStipulation) {
      onCancelWithError(
        'Some of the stipulations could not be updated. Please review the current stipulations and try again.'
      );
      onCancel();
    }
  }, [errorUpdateStipulation, onCancelWithError, onCancel]);

  const handleCancel = (): void => {
    setStips(stipulations);
    onCancel();
  };

  const handleUpdateStip = (
    updatedStip: Partial<UpdateBankingStipulationInput>,
    uuidToUpdate: string
  ): void => {
    setStips((prevStips) =>
      prevStips.map((stip) =>
        stip.uuid === uuidToUpdate ? { ...stip, ...updatedStip } : stip
      )
    );
  };

  const { handleUpdateName, handleUpdateReasons } =
    useSelectedStipulationReasons({
      handleUpdateStip,
      selectedReasons: getPreSelectedReasons(stipulations),
    });

  const reasonRequiredValidation = (
    stipName: string,
    stipReason?: string[]
  ): boolean => {
    return (
      featureFlags.new_stipulations_process_phase_2 &&
      shouldReasonBeRequired(stipName) &&
      isReasonArrayEmpty(stipReason)
    );
  };

  return (
    <Flex flexDirection="column">
      {errorUpdateStipulation && (
        <Banner>{errorUpdateStipulation.message}</Banner>
      )}
      {stips.map((stip, index) => (
        <Box p={2} key={stip.uuid}>
          <Grid gutter>
            <Grid.Item m={5}>
              <Select
                label={`Stipulation ${index + 1} Type`}
                value={stip.name}
                options={bankingStipulationTypes().map((type) => {
                  return { value: type, text: type };
                })}
                required={true}
                onValueChange={handleUpdateName(stip)}
              />
            </Grid.Item>

            {(!featureFlags.new_stipulations_process_phase_2 ||
              shouldAdditionalInfoBeRequired(stip.name)) && (
              <Grid.Item m={6}>
                <TextInput
                  label={`Stipulation ${index + 1} Additional Info`}
                  value={stip.notes}
                  maxLength={250}
                  required={
                    shouldAdditionalInfoBeRequired(stip.name) &&
                    !stip.notes?.trim()
                  }
                  onValueChange={(newValue) =>
                    handleUpdateStip(
                      {
                        notes: newValue,
                      },
                      stip.uuid
                    )
                  }
                />
              </Grid.Item>
            )}

            {featureFlags.new_stipulations_process_phase_2 &&
              shouldReasonBeRequired(stip.name) && (
                <Grid.Item m={4}>
                  <Combobox
                    closeMenuOnSelect={false}
                    isMulti
                    label={`Stipulation ${index + 1} Reason`}
                    values={
                      stip.reasons
                        ? stip.reasons.map((reason) => ({
                            value: reason,
                            text: reason,
                          }))
                        : []
                    }
                    onValueChange={handleUpdateReasons(stip)}
                    required={reasonRequiredValidation(stip.name, stip.reasons)}
                    options={
                      stip.name && validReasons && validReasons[stip.name]
                        ? validReasons[stip.name]?.map((reason) => ({
                            value: reason,
                            text: reason,
                          }))
                        : []
                    }
                  />
                </Grid.Item>
              )}

            {!featureFlags.remove_describe_stipulation_type &&
              stip.name === 'Other' && (
                <Grid.Item m={5}>
                  <TextInput
                    label="Describe Stipulation Type"
                    maxLength={250}
                    onValueChange={(newValue) =>
                      handleUpdateStip(
                        {
                          otherDescription: newValue,
                        },
                        stip.uuid
                      )
                    }
                    required
                  />
                </Grid.Item>
              )}
          </Grid>
          <Divider />
        </Box>
      ))}

      <Flex justifyContent="center" gap={2}>
        <Button
          onClick={handleSubmit}
          startIcon="check"
          disabled={stips.some(
            (stip) =>
              stip.name === '' ||
              (shouldAdditionalInfoBeRequired(stip.name) &&
                stip.notes === '') ||
              reasonRequiredValidation(stip.name, stip.reasons)
          )}
        >
          Save
        </Button>
        <Button onClick={handleCancel} startIcon="times" variant="secondary">
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};
