import { AuthClient } from 'api/AuthClient';
import { User } from 'api/AuthClient/codecs';
import { FFLogger } from 'api/LogClient';
import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  useGetApiSubmission,
  UseGetApiSubmissionResponse,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { toError } from 'helpers/errorUtils';
import { useState, useEffect, useCallback } from 'react';
import {
  AttachmentResponse,
  CreateAttachmentRequestBody,
  DocumentTags,
  UpdateAttachmentRequestBody,
  ValidFileExtensions,
} from 'types/api/underwriting/types';
import {
  Attachment,
  AttachmentSubmission,
  Customer,
  Owner,
  CreateAttachmentInput,
  UpdateAttachmentInput,
  Account,
} from './attachmentManager.types';
import {
  useGetApiCustomer,
  UseGetApiCustomerResponse,
} from 'apiHooks/underwriting/customerFetchHooks';
import {
  useGetBatchApiOwners,
  UseGetBatchApiOwnersResponse,
} from 'apiHooks/underwriting/ownerFetchHooks';
import {
  fetchAttachmentsBySubmissionUuid,
  fetchDocumentTags,
  fetchFileExtensions,
} from 'api/underwriting/attachmentFetchUtils';
import {
  useApiCreateAttachmentBatch,
  useApiDeleteAttachmentBatch,
  useApiUpdateAttachmentBatch,
  useGetApiAccount,
} from 'apiHooks/underwriting/attachmentFetchHooks';
import { convertFileToBase64String } from 'helpers/fileUtils';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

const attachmentResponseToAttachment = (
  att: AttachmentResponse
): Attachment => ({
  applicationUuid: att.application_uuid,
  /*
   * This appears to be a MIME type.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  contentType: att.content_type,
  createdAt: att.created_at,
  description: att.description ?? undefined,
  documentTags: att.document_tags,
  expiresOn: att.expires_on ?? undefined,
  fileDeletedAt: att.file_deleted_at ?? undefined,
  fileName: att.file_name,
  source: att.source,
  submissionUuid: att.submission_uuid ?? undefined,
  updatedAt: att.updated_at,
  uuid: att.uuid,
  uploadedBy: att.uploaded_by ?? undefined,
});

interface UseGetAttachmentsResult {
  attachments?: Attachment[];
  loading: boolean;
  error?: string;
  refetch: () => Promise<void>;
}

export const useAttachments = (
  submissionUuid: string
): UseGetAttachmentsResult => {
  const [attachments, setAttachments] = useState<Attachment[] | undefined>(
    undefined
  );
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
  const [attachmentsError, setAttachmentsError] = useState<
    string | undefined
  >();

  const getAttachments = useCallback(async (): Promise<void> => {
    try {
      const att = await fetchAttachmentsBySubmissionUuid(submissionUuid);

      const formattedAtts = att.map(attachmentResponseToAttachment);

      setAttachments(formattedAtts);
    } catch (e: unknown) {
      const error = toError(e);

      FFLogger.error(error, {
        submissionUuid,
      });

      setAttachmentsError(error.message);
    } finally {
      setAttachmentsLoading(false);
    }
  }, [submissionUuid]);

  useEffect(() => {
    void getAttachments();
  }, [submissionUuid, getAttachments]);

  return {
    loading: attachmentsLoading,
    attachments,
    error: attachmentsError,
    refetch: getAttachments,
  };
};

interface UseExtensionsResult {
  supportedFileExtensions?: ValidFileExtensions;
  loading: boolean;
  error?: string;
}

export const useSupportedFileExtensions = (): UseExtensionsResult => {
  const [supportedFileExtensions, setSupportedFileExtensions] = useState<
    ValidFileExtensions | undefined
  >(undefined);
  const [extensionsLoading, setExtensionsLoading] = useState<boolean>(true);
  const [extensionsError, setExtensionsError] = useState<string | undefined>();

  useEffect(() => {
    const getFileExtensions = async (): Promise<void> => {
      try {
        const extensions = await fetchFileExtensions();
        setSupportedFileExtensions(extensions);
      } catch (e: unknown) {
        const error = toError(e);

        FFLogger.error(error);
        setExtensionsError(error.message);
      } finally {
        setExtensionsLoading(false);
      }
    };
    void getFileExtensions();
  }, []);

  return {
    supportedFileExtensions,
    loading: extensionsLoading,
    error: extensionsError,
  };
};

interface UseUnderwriterUserResult {
  underwriters?: User[];
  loading: boolean;
  error?: string;
}

export const useUnderwriterUsers = (): UseUnderwriterUserResult => {
  const [underwritersError, setUnderwritersError] = useState<string>();
  const [underwriters, setUnderwriters] = useState<User[] | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUnderwritters = async (): Promise<void> => {
      try {
        const response = await AuthClient.fetchUsersByRole('underwriter');
        const sortedUnderwriters = [...response].sort((a, b) =>
          a.first_name > b.last_name ? 1 : -1
        );
        setUnderwriters(sortedUnderwriters);
      } catch (e: unknown) {
        const error = toError(e);
        setUnderwritersError(`Underwriters list: ${error.message}`);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getUnderwritters();
  }, []);

  return {
    underwriters,
    loading,
    error: underwritersError,
  };
};

interface UseProcessingUserResult {
  processors?: User[];
  loading: boolean;
  error?: string;
}

export const useProcessingUsers = (): UseProcessingUserResult => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [processors, setProcessors] = useState<User[] | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUnderwritters = async (): Promise<void> => {
      try {
        const data = await AuthClient.fetchUsersByRole('processing');
        setProcessors(data);
      } catch (e: unknown) {
        const error = toError(e);
        setErrorMessage(`Processing Users list: ${error.message}`);
        FFLogger.error(error);
      } finally {
        setLoading(false);
      }
    };

    void getUnderwritters();
  }, []);

  return {
    processors: processors,
    loading,
    error: errorMessage,
  };
};

interface DocumentTagsResult {
  documentTags?: DocumentTags;
  loading: boolean;
  error?: string;
}

export const useDocumentTags = (): DocumentTagsResult => {
  const [documentTags, setDocumentTags] = useState<DocumentTags | undefined>();
  const [extensionsLoading, setExtensionsLoading] = useState<boolean>(true);
  const [extensionsError, setExtensionsError] = useState<string | undefined>();

  useEffect(() => {
    const getFileExtensions = async (): Promise<void> => {
      try {
        const extensions = await fetchDocumentTags();
        setDocumentTags(extensions);
      } catch (e: unknown) {
        const error = toError(e);

        FFLogger.error(error);
        setExtensionsError(error.message);
      } finally {
        setExtensionsLoading(false);
      }
    };
    void getFileExtensions();
  }, []);

  return {
    documentTags,
    loading: extensionsLoading,
    error: extensionsError,
  };
};

export const useAccount = (
  applicationUuid: string
): UseGenericQueryResponse<Account> => {
  return useGenericFeatureQuery(
    useGetApiAccount,
    // No data transformation needed due to the current API response (as of Nov '23).
    (response?: Account) => response,
    applicationUuid
  );
};

interface SubmissionResult {
  submission?: AttachmentSubmission;
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toSubmission = (
  data: UseGetApiSubmissionResponse['data']
): AttachmentSubmission | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    uuid: data.uuid,
    stageName: data.stage_name,
    subStage: data.sub_stage ?? undefined,
    underwriterSignOffTimestamp:
      data.underwriter_sign_off_timestamp ?? undefined,
    underwriterSignOffId: data.underwriter_sign_off_id ?? undefined,
    underwriterSignOffName: data.underwriter_sign_off_name ?? undefined,
    customerUuid: data.customer_uuid,
    ownerUuids: data.owner_uuids,
    submissionName: data.name,
  };
};

export const useSubmission = (
  submissionUuid: string | undefined
): SubmissionResult => {
  const { data, loading, error, refetch } = useGetApiSubmission(submissionUuid);

  useLogError(error);

  return {
    submission: toSubmission(data),
    loading,
    error,
    refetch,
  };
};

type UpdateBody = {
  underwriterSignOffId: number;
};

type UseUpdateUnderwriterSignOffResult = [
  (input: UpdateBody) => Promise<MutationResponse>,
  { data?: AttachmentSubmission; error?: Error }
];

export const useUpdateUnderwriterSignOff = (
  submissionUuid: string
): UseUpdateUnderwriterSignOffResult => {
  const [updateSubmission, { data, error }] = useUpdateApiSubmission();

  const updateFunction = (args: UpdateBody): Promise<MutationResponse> => {
    return updateSubmission({
      submissionUuid,
      updateBody: {
        underwriter_sign_off_id: args.underwriterSignOffId,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      data: toSubmission(data),
      error,
    },
  ];
};

interface CustomerResult {
  customer?: Customer;
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toCustomer = (
  data: UseGetApiCustomerResponse['data']
): Customer | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    uuid: data.uuid,
    legalName: data.legal_name,
    dba: data.name,
    entityType: data.entity_type ?? undefined,
  };
};

export const useCustomer = (customerUuid?: string): CustomerResult => {
  const { data, loading, error, refetch } = useGetApiCustomer(customerUuid);

  useLogError(error);

  return {
    customer: toCustomer(data),
    loading,
    error,
    refetch,
  };
};

interface OwnerResult {
  owners?: Owner[];
  loading: boolean;
  error?: Error;
  refetch: () => void;
}

const toOwners = (data: UseGetBatchApiOwnersResponse['data']): Owner[] => {
  if (!data) {
    return [];
  }

  return data.map((owner) => {
    return {
      uuid: owner.uuid,
      name: `${owner.first_name} ${owner.last_name}`,
    };
  });
};

export const useOwners = (ownerUuids?: string[]): OwnerResult => {
  const { data, loading, error, refetch } = useGetBatchApiOwners(ownerUuids);

  useLogError(error);

  return {
    owners: toOwners(data),
    loading,
    error,
    refetch,
  };
};

type UseCreateAttachmentResponse = [
  (args: CreateAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface CreateAttachmentArgs {
  submissionUuid: string;
  createBody: CreateAttachmentInput;
}

export const useCreateAttachment = (): UseCreateAttachmentResponse => {
  const [createFn, { error, ...rest }] = useApiCreateAttachmentBatch();

  const createFunction = async (
    args: CreateAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const createArgs = Promise.all(
      args.map(async ({ submissionUuid, createBody }) => {
        const fileString = await convertFileToBase64String(createBody.file);

        const body: CreateAttachmentRequestBody = {
          file: fileString,
          file_name: createBody.file.name,
          source: createBody.source,
          description: '',
          document_tags: [],
        };

        return { submissionUuid, createBody: body };
      })
    );

    const resolvedArgs = await createArgs;

    return createFn(resolvedArgs);
  };

  useLogError(error);

  return [
    createFunction,
    {
      error,
      ...rest,
    },
  ];
};

type UseDeleteAttachmentResponse = [
  (args: DeleteAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface DeleteAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
}

export const useDeleteAttachment = (): UseDeleteAttachmentResponse => {
  const [deleteFn, { error, ...rest }] = useApiDeleteAttachmentBatch();

  const deleteFunction = async (
    args: DeleteAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const deleteArgs = args.map(({ submissionUuid, attachmentUuid }) => {
      return {
        submissionUuid,
        attachmentUuid,
      };
    });

    return deleteFn(deleteArgs);
  };

  useLogError(error);

  return [
    deleteFunction,
    {
      error,
      ...rest,
    },
  ];
};

type UseUpdateAttachmentResponse = [
  (args: UpdateAttachmentArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse[]>
];

interface UpdateAttachmentArgs {
  submissionUuid: string;
  attachmentUuid: string;
  input: UpdateAttachmentInput;
}

export const useUpdateAttachment = (): UseUpdateAttachmentResponse => {
  const [updateFn, { error, ...rest }] = useApiUpdateAttachmentBatch();

  const updateFunction = async (
    args: UpdateAttachmentArgs[]
  ): Promise<MutationResponse> => {
    const updateArgs = args.map(({ submissionUuid, attachmentUuid, input }) => {
      return {
        submissionUuid,
        attachmentUuid,
        body: {
          file_name: input.fileName,
          description: input.description,
          document_tags: input.documentTags,
          source: input.source,
          expires_on: input.expiresOn,
        } as UpdateAttachmentRequestBody,
      };
    });

    return updateFn(updateArgs);
  };

  useLogError(error);

  return [
    updateFunction,
    {
      error,
      ...rest,
    },
  ];
};
