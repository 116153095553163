import React, { useState } from 'react';
import {
  Modal,
  Box,
  Subheading,
  Divider,
} from '@forward-financing/fast-forward';
import { BankingStipulation } from './BankingStipulation.types';
import { EditBankingStipulationForm } from './EditBankingStipulationForm';
import { HashMap } from 'api/codecs';

export type EditStipulationModalProps = {
  selectedStipulations: BankingStipulation[];
  onSaveComplete?: () => void;
  submissionUuid: string;
  trigger: React.ReactNode;
  refetchStipulations: () => void;
  onCancelWithError: (error?: string) => void;
  validReasons?: HashMap<string[]>;
};

export const EditBankingStipulationModal = ({
  selectedStipulations,
  onSaveComplete,
  trigger,
  submissionUuid,
  refetchStipulations,
  onCancelWithError,
  validReasons,
}: EditStipulationModalProps): JSX.Element => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const onSubmit = (): void => {
    onCancelWithError(undefined);
    refetchStipulations();
    onSaveComplete && onSaveComplete();
    setIsEditModalOpen(!isEditModalOpen);
  };

  const onCancelAction = (): void => {
    setIsEditModalOpen(!isEditModalOpen);
    onSaveComplete && onSaveComplete();
  };

  return (
    <Modal
      title="Edit Stipulation(s)"
      isOpen={isEditModalOpen}
      onOpenChange={() => setIsEditModalOpen(!isEditModalOpen)}
      trigger={trigger}
      body={
        <Box>
          <Subheading>Edit stipulation information</Subheading>
          <Divider />
          <EditBankingStipulationForm
            onSubmit={onSubmit}
            onCancel={onCancelAction}
            submissionUuid={submissionUuid}
            stipulations={selectedStipulations}
            onCancelWithError={onCancelWithError}
            validReasons={validReasons}
          />
        </Box>
      }
    />
  );
};
