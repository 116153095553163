import { useLogError } from 'apiHooks/useLogError';
import { UseGenericQueryResponse } from '../../apiHooks/genericFetchHooks';

export const useGenericFeatureQuery = <
  TQueryResponse,
  TFeature,
  TFetchParams extends unknown[]
>(
  apiFetchHook: (
    ...fetchParams: TFetchParams
  ) => UseGenericQueryResponse<TQueryResponse>,
  toDesiredType: (response?: TQueryResponse) => TFeature | undefined,
  ...fetchParams: TFetchParams
): UseGenericQueryResponse<TFeature> => {
  // Fetch the data using the supplied hook.
  const { data: apiData, error, ...rest } = apiFetchHook(...fetchParams);

  // Log any errors.
  useLogError(error);

  // Convert the data to the desired feature type.
  const data = toDesiredType(apiData);

  return {
    data,
    error,
    ...rest,
  };
};
