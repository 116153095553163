import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { LedgerOfferRaw, OfferResponse } from 'types/api/banking/types';
import { FETCH_CALCULATED_OFFER_URL } from 'api/constants';
import { FetchCalculatedLedgerParams } from '../../components/DealScoring/Ledger/ledger.types';

export const fetchCurrentOffer = async (
  submissionUuid: string
): Promise<OfferResponse | undefined> => {
  const url = new URL(
    `/api/v1/current_offer/${submissionUuid}`,
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OfferResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch current offer');
  }

  if (response.status === 204) {
    return undefined;
  }

  return response.json();
};

export const fetchCalculatedOffer = async ({
  submissionUuid,
  ledgerId,
  payload,
}: FetchCalculatedLedgerParams): Promise<LedgerOfferRaw | undefined> => {
  const url = new URL(
    FETCH_CALCULATED_OFFER_URL(submissionUuid, ledgerId),
    BANK_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LedgerOfferRaw>(
    url,
    'POST',
    payload
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to get calculated offer');
  }

  return response.json();
};
