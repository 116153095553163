import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import {
  ArtifactData,
  Relativity6ArtifactsApplicationRequestBody,
  Relativity6ArtifactsRequestBody,
  Relativity6ArtifactsSubmissionRequestBody,
} from './AdditionalInformationForm.types';
import { CreateRelativity6ArtifactsRequestBody } from 'types/api/underwriting/types';
import {
  useApiCreateRelativity6ArtifactsApplication,
  useApiCreateRelativity6ArtifactsSubmission,
} from 'apiHooks/underwriting/relativity6FetchHooks';

export type UseCreateRelativity6AnalyticsArtifactsApplicationResponse = [
  (
    args: Relativity6ArtifactsApplicationRequestBody
  ) => Promise<MutationResponse>,
  {
    error?: Error;
    loading: boolean;
  }
];

const toRelativity6ArtifactsApplication = (
  artifact: ArtifactData
): CreateRelativity6ArtifactsRequestBody => {
  return {
    artifact_data: {
      predicted_industry: artifact.predictedIndustry,
      assigned_industry: artifact.assignedIndustry,
      confidence_score: artifact.confidenceScore,
      naics_code: artifact.naicsCode,
      naics_version: artifact.naicsVersion,
      artifact_case: artifact.artifactCase,
      artifact_type: artifact.artifactType,
      naics_code_title: artifact.naicsCodeTitle,
    },
  };
};

export type UseCreateRelativity6ArtifactsApplicationArgs = {
  applicationUuid: string;
  body: Relativity6ArtifactsRequestBody;
};

export const useCreateRelativity6ArtifactsApplication =
  (): UseCreateRelativity6AnalyticsArtifactsApplicationResponse => {
    const [createFn, { error, ...rest }] =
      useApiCreateRelativity6ArtifactsApplication();

    const createFunction = async (
      args: Relativity6ArtifactsApplicationRequestBody
    ): Promise<MutationResponse> => {
      return createFn({
        applicationUuid: args.applicationUuid,
        body: toRelativity6ArtifactsApplication(args.artifactData),
      });
    };

    useLogError(error);

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

const toRelativity6ArtifactsSubmission = (
  artifact: ArtifactData
): CreateRelativity6ArtifactsRequestBody => {
  return {
    artifact_data: {
      predicted_industry: artifact.predictedIndustry,
      assigned_industry: artifact.assignedIndustry,
      confidence_score: artifact.confidenceScore,
      naics_code: artifact.naicsCode,
      naics_version: artifact.naicsVersion,
      artifact_case: artifact.artifactCase,
      artifact_type: artifact.artifactType,
      naics_code_title: artifact.naicsCodeTitle,
    },
  };
};

export type UseCreateRelativity6AnalyticsArtifactsSubmissionResponse = [
  (
    args: Relativity6ArtifactsSubmissionRequestBody
  ) => Promise<MutationResponse>,
  {
    error?: Error;
    loading: boolean;
  }
];

export type UseCreateRelativity6ArtifactsSubmissionArgs = {
  submissionUuid: string;
  body: Relativity6ArtifactsRequestBody;
};

export const useCreateRelativity6ArtifactsSubmission =
  (): UseCreateRelativity6AnalyticsArtifactsSubmissionResponse => {
    const [createFn, { error, ...rest }] =
      useApiCreateRelativity6ArtifactsSubmission();

    const createFunction = async (
      args: Relativity6ArtifactsSubmissionRequestBody
    ): Promise<MutationResponse> => {
      return createFn({
        submissionUuid: args.submissionUuid,
        body: toRelativity6ArtifactsSubmission(args.artifactData),
      });
    };

    useLogError(error);

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };
