import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  IconButton,
  Loading,
  Modal,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import isEqual from 'lodash/isEqual';
import { defaultTo } from 'lodash';

import { useNewDealScoringContext } from '../NewDealScoring/NewDealScoringContext';
import { Submission } from '../DealScoringContainer.types';
import { RevenueSummaryTable } from './RevenueSummaryTable';
import { Ledger } from './ledger.types';
import { OffersTable } from './OffersTable';
import { BuyRatesTable } from './BuyRatesTable';
import {
  useCloneLedger,
  useDeleteLedger,
  usePatchLedger,
  useSendToCreditCommittee,
} from './ledgerHooks';
import { ScoresContainerBase } from './ScoresContainerBase';

export type LedgerContainerProps = {
  ledger: Ledger;
  submissionUuid: string;
  submission?: Submission;
  refetchLedgers?: () => void;
};

const CLONE_SUCCESS_TEXT = 'Ledger cloned successfully';
const SEND_TO_COMMITTEE_SUCCESS_TEXT = 'Ledger sent to committee successfully';
const DELETE_SUCCESS_TEXT = 'Ledger deleted successfully';
const SAVE_SUCCESS_TEXT = 'Ledger saved successfully';
const SEND_SUCCESS_TEXT = 'Ledger sent successfully';

export const LedgerContainer = ({
  ledger,
  submissionUuid,
  submission,
  refetchLedgers,
}: LedgerContainerProps): JSX.Element => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sendLedgerModalIsOpen, setSendLedgerModalIsOpen] = useState(false);
  const [ledgerToUpdate, setLedgerToUpdate] = useState<Ledger>(ledger);
  const [successText, setSuccessText] = useState<string | undefined>('');

  const { response } = useNewDealScoringContext();

  const [cloneLedger, { error: hasCloningError, loading: isCloningLoading }] =
    useCloneLedger();

  const [deleteLedger, { error: hasDeleteError, loading: isDeleteLoading }] =
    useDeleteLedger();

  const [
    sendToCommittee,
    { error: hasSendToCommitteeError, loading: isSendToCommitteeLoading },
  ] = useSendToCreditCommittee();

  const [
    patchLedger,
    { loading: isPatchLedgerLoading, error: hasPatchLedgerError },
  ] = usePatchLedger();

  const handleChangeLedger = (toUpdate: Partial<Ledger>): void => {
    setLedgerToUpdate({ ...ledgerToUpdate, ...toUpdate });
  };

  const handleAction = async (
    action: () => Promise<{ success: boolean }>,
    successMessage: string,
    closeModal?: () => void
  ): Promise<void> => {
    setSuccessText(undefined);
    closeModal?.();

    const { success } = await action();

    if (success) {
      setSuccessText(successMessage);
      refetchLedgers?.();
    }
  };

  const handleClone = (): Promise<void> =>
    handleAction(
      () => cloneLedger({ submissionUuid, ledgerId: ledger.id }),
      CLONE_SUCCESS_TEXT
    );

  const handleDelete = (): Promise<void> =>
    handleAction(
      () => deleteLedger({ submissionUuid, ledgerId: ledger.id }),
      DELETE_SUCCESS_TEXT,
      () => setDeleteModalOpen(false)
    );

  const handleSendToCommittee = (): Promise<void> =>
    handleAction(
      () => sendToCommittee({ submissionUuid, ledgerId: ledger.id }),
      SEND_TO_COMMITTEE_SUCCESS_TEXT
    );

  const handleSaveLedger = (): Promise<void> =>
    handleAction(
      () =>
        patchLedger({
          ledger: ledgerToUpdate,
          ledgerId: ledger.id,
          submissionUuid,
        }),
      SAVE_SUCCESS_TEXT
    );

  const handleSendLedger = (): Promise<void> =>
    handleAction(
      () =>
        patchLedger({
          ledger: { ...ledgerToUpdate, lock: true },
          ledgerId: ledger.id,
          submissionUuid,
        }),
      SEND_SUCCESS_TEXT,
      () => setSendLedgerModalIsOpen(false)
    );

  return (
    <Flex flexDirection={'column'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text>Created On {ledger.createdAt}</Text>
        </Box>

        {hasCloningError && (
          <Banner autoDismissTime={3000}>{hasCloningError.message}</Banner>
        )}
        {hasDeleteError && (
          <Banner autoDismissTime={3000}>{hasDeleteError.message}</Banner>
        )}
        {hasSendToCommitteeError && (
          <Banner autoDismissTime={3000}>
            {hasSendToCommitteeError.message}
          </Banner>
        )}
        {hasPatchLedgerError && (
          <Banner autoDismissTime={3000}>{hasPatchLedgerError.message}</Banner>
        )}

        <Flex>
          <IconButton
            icon={['far', 'save']}
            label="Save"
            onClick={handleSaveLedger}
            disabled={isEqual(ledgerToUpdate, ledger)}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'paper-plane']}
                label="Send ledger"
                disabled={isPatchLedgerLoading}
              />
            }
            isOpen={sendLedgerModalIsOpen}
            onOpenChange={() => setSendLedgerModalIsOpen((prev) => !prev)}
            title="Confirm Ledger Lock"
            size="auto"
            body={
              <>
                <Text bold>
                  After saving, the ledger will be locked, are you sure you want
                  to proceed?
                </Text>
                {isPatchLedgerLoading && <Loading />}

                <Button
                  onClick={handleSendLedger}
                  variant={'primary'}
                  disabled={isPatchLedgerLoading}
                >
                  Save & send ledger
                </Button>
              </>
            }
          />
          <IconButton
            icon="people-line"
            label="Send to Decision Committee"
            onClick={handleSendToCommittee}
            disabled={isSendToCommitteeLoading}
          />
          <IconButton
            icon={['far', 'clone']}
            label="Clone"
            disabled={isCloningLoading}
            onClick={handleClone}
          />
          <Modal
            trigger={
              <IconButton
                icon={['far', 'trash-can']}
                label="Delete"
                variant="danger"
              />
            }
            isOpen={deleteModalOpen}
            onOpenChange={() => setDeleteModalOpen((prev) => !prev)}
            title={'Delete ledger'}
            size={'auto'}
            body={
              <>
                <Text bold>Are you sure you want to delete this ledger?</Text>
                {isDeleteLoading && <Loading />}

                <Button
                  onClick={handleDelete}
                  variant={'danger'}
                  disabled={isDeleteLoading}
                >
                  Delete
                </Button>
              </>
            }
          />
        </Flex>
      </Flex>

      {successText && (
        <Box m={3}>
          <Banner variant="success" autoDismissTime={5000} dismissable={'auto'}>
            {successText}
          </Banner>
        </Box>
      )}

      <Flex gap={2}>
        <ScoresContainerBase
          title="New Deal Score"
          currentScore={defaultTo(response?.averageScore, 0.0)}
          maxScore={5.0}
          scores={[
            {
              scoreName: 'Owner Risk',
              scoreValue: defaultTo(response?.ownerRisk, 0),
            },
            {
              scoreName: 'Business Risk',
              scoreValue: defaultTo(response?.businessRisk, 0),
            },
            {
              scoreName: 'Quality of Cash Flows',
              scoreValue: defaultTo(response?.qualityOfCashFlows, 0),
            },
          ]}
        />
      </Flex>

      <Flex flexDirection={'column'}>
        <Subheading>Revenue Summary</Subheading>

        <Box>
          <RevenueSummaryTable
            ledger={ledger}
            amountRequested={submission?.amountRequested}
            isoUuid={submission?.isoUuid}
            onChange={handleChangeLedger}
          />

          <BuyRatesTable submissionUuid={submissionUuid} ledgerId={ledger.id} />
        </Box>
      </Flex>

      <OffersTable ledger={ledger} submissionUuid={submissionUuid} />
    </Flex>
  );
};
