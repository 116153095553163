import {
  SubmissionRenewalCompositeScoreResponse,
  SubmissionResponse,
} from 'types/api/underwriting/types';
import {
  useApiRefreshCompositeScore,
  useGetApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';

import { useLogError } from 'apiHooks/useLogError';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { RenewalScoreComposite } from '../DealScoring.types';

// Does not need explict tests, covered by the tests for
// RenewalScoringContainer.test.tsx.
const toRenewalScoreComposite = (
  renewalCompositeScore?: SubmissionRenewalCompositeScoreResponse
): RenewalScoreComposite | undefined => {
  if (!renewalCompositeScore) {
    return undefined;
  }

  return {
    type: 'Renewal',
    industryRisk: {
      score: renewalCompositeScore.industry_risk?.score,
      value: renewalCompositeScore.industry_risk?.value,
      industry: renewalCompositeScore.industry_risk?.industry,
    },
    timesFunded: {
      score: renewalCompositeScore.times_funded?.score,
      value: renewalCompositeScore.times_funded?.value,
    },
    ficoChange: {
      score: renewalCompositeScore.fico_change?.score,
      value: renewalCompositeScore.fico_change?.value,
    },
    revenueChange: {
      score: renewalCompositeScore.revenue_change?.score,
      value: renewalCompositeScore.revenue_change?.value,
    },
    repaidPercentage: {
      score: renewalCompositeScore.repaid_percentage?.score,
      value: renewalCompositeScore.repaid_percentage?.value,
    },
    paymentHistory: {
      score: renewalCompositeScore.payment_history?.score,
      value: renewalCompositeScore.payment_history?.value,
    },
    stacking: {
      score: renewalCompositeScore.stacking?.score,
      preFfGross: renewalCompositeScore.stacking?.pre_ff_gross,
    },
    ...(renewalCompositeScore.composite_score && {
      compositeScore: {
        score: renewalCompositeScore.composite_score?.score,
        tier: renewalCompositeScore.composite_score?.tier,
      },
    }),
    updatedAt: renewalCompositeScore.updated_at,
  };
};

export interface ErrorWithStatusCode extends Error {
  statusCode?: number;
}

type UseRefreshCompositeScoreResult = [
  () => Promise<MutationResponse>,
  {
    renewalScoreCompositeRefreshed: RenewalScoreComposite | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

export const useRefreshRenewalCompositeScore = (
  submissionUuid: string
): UseRefreshCompositeScoreResult => {
  const [
    refreshCompositeScoreFunction,
    { data, error, loading, responseReady },
  ] = useApiRefreshCompositeScore();

  const refreshCompositeScore = async (): Promise<MutationResponse> => {
    return await refreshCompositeScoreFunction(submissionUuid);
  };

  useLogError(error);

  const score = toRenewalScoreComposite(
    data as SubmissionRenewalCompositeScoreResponse
  );
  return [
    refreshCompositeScore,
    { renewalScoreCompositeRefreshed: score, error, loading, responseReady },
  ];
};

const toSubmissionFundedDate = (
  submission?: SubmissionResponse
): string | undefined => {
  if (!submission) {
    return undefined;
  }

  return submission.funded_date__c ?? undefined;
};

// Does not need explict tests, covered by genericFeatureHooks.test.ts and
// the tests for RenewalScoringContainer.test.tsx.
export const useSubmissionFundedDate = (
  submissionUuid?: string
): UseGenericQueryResponse<string> =>
  useGenericFeatureQuery(
    useGetApiSubmission,
    toSubmissionFundedDate,
    submissionUuid
  );
