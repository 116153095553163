import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
// CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO PRODUCTION.
// Uncomment this line for mock data as needed.
// import { mockBusinessLexisNexisFullReport } from 'mocks/underwriting/generators/ApiV2BusinessLexisNexisReport';
// import { mockOwnerLexisNexisFullReport } from 'mocks/underwriting/generators/ApiV2OwnersLexisNexisReport';
import {
  BusinessLexisNexisReportResponse,
  OwnerLexisNexisPayload,
  OwnerLexisNexisReportResponse,
} from 'types/api/underwriting/types';

export const fetchFullOwnerLexisNexisReport = async (
  submissionUuid: string
): Promise<OwnerLexisNexisReportResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       documents: [mockOwnerLexisNexisFullReport({owner_uuid: "1e951792-2907-4fff-a284-0bc5c09312e5"})],
  //     });
  //   }, 5000);
  // });

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_owner_reports?full_payload=true`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerLexisNexisReportResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Owner Lexis Nexis reports'
    );
  }

  return response.json();
};

export const fetchFullOwnerLexisNexisReportByReportId = async (
  reportId: string
): Promise<OwnerLexisNexisPayload> => {
  const url = new URL(
    `/api/v2/lexis_nexis_owner_reports/${reportId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<OwnerLexisNexisPayload>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Owner Lexis Nexis reports'
    );
  }

  return response.json();
};

export const fetchFullBusinessLexisNexisReport = async (
  submissionUuid: string
): Promise<BusinessLexisNexisReportResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       documents: [mockBusinessLexisNexisFullReport()],
  //     });
  //   }, 5000);
  // });

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/documents/lexis_nexis_customer_reports?full_payload=true`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<BusinessLexisNexisReportResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Customer Lexis Nexis reports'
    );
  }

  return response.json();
};
