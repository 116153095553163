import React from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Grid,
  Loading,
  Subheading,
  Text,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { DealScoringNotesContainer } from '../DealScoringNotesContainer';
import { ManagerFeedbackNew } from './ManagerScoring/ManagerFeedbackNew';
import { RenewalCompositeScoreTable } from './RenewalCompositeScoreTable';
import { RenewalScoreComposite } from '../DealScoring.types';
export interface RenewalScoringCompositeProps {
  submissionUuid: string;
  scoreData: RenewalScoreComposite | undefined;
  refreshScoreData: () => Promise<unknown>;
  loading: boolean;
  errorRefresh: Error | undefined;
}

export const RenewalScoringComposite = ({
  submissionUuid,
  scoreData,
  refreshScoreData,
  loading,
  errorRefresh,
}: RenewalScoringCompositeProps): JSX.Element => {
  const { compositeScore, updatedAt } = scoreData || {};

  const { role: userRole } = useUserContext();

  if (loading) {
    return (
      <Box m={3}>
        <Loading size="large" />
      </Box>
    );
  }

  if (!scoreData && !errorRefresh) {
    return (
      <Text>
        Composite Score is not available until the submission reaches the
        Underwriting stage. If you see this and the submission has already
        reached the Underwriting stage, please open an IT Support ticket and
        report this as an issue.
      </Text>
    );
  }

  const compositeScoreToText = (
    compositeScoreValue?: typeof compositeScore
  ): string => {
    // !compositeScoreValue.score intentionally includes a value of 0 since
    // 0 is not a valid score.
    if (!compositeScoreValue || !compositeScoreValue.score) {
      return 'N/A';
    }

    return `${compositeScoreValue.score?.toFixed(1)} / Tier ${
      compositeScoreValue.tier || 'N/A'
    }`;
  };

  return (
    <Box mb={4}>
      {errorRefresh && (
        <Banner dismissable={false}>{errorRefresh.message}</Banner>
      )}
      <Grid gutter>
        <Grid.Item xs={12} s={6} l={6}>
          <Flex gap={3} alignItems={'center'}>
            <Button
              startIcon={'refresh'}
              disabled={loading}
              onClick={refreshScoreData}
            >
              Refresh Composite Score
            </Button>
            <Text size="l">
              Last Updated: {updatedAt && formatDateTimeString(updatedAt)}
            </Text>
          </Flex>
          <Subheading>
            Renewal Deal Composite Score: {compositeScoreToText(compositeScore)}
          </Subheading>

          <RenewalCompositeScoreTable scoreData={scoreData} />
        </Grid.Item>

        <Grid.Item xs={12} s={6} l={6}>
          <Box paddingTop={4}>
            <DealScoringNotesContainer
              submissionUuid={submissionUuid}
              noteType="UNDERWRITER"
            />
          </Box>
        </Grid.Item>

        {userRole === 'admin' && (
          <Grid.Item xs={12} s={12} l={12}>
            <ManagerFeedbackNew submissionUuid={submissionUuid} />
          </Grid.Item>
        )}
      </Grid>
    </Box>
  );
};
