import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Flex,
  Text,
  TextInput,
  TextInputProps,
  MaskedTextInput,
  Select,
  SelectProps,
  Switch,
  Button,
  Loading,
  Divider,
  Banner,
  Card,
  formatDateTimeString,
} from '@forward-financing/fast-forward';
import { GoogleZipCodeLookup } from '../../shared/form/GoogleZipCodeLookup';
import { FederalTaxIdPopover } from './FederalTaxIdPopover';
import { UnderwritingClient } from '../../../api/UnderwritingClient';
import { Account, UsState } from '../../../api/UnderwritingClient/codecs';
import { WizardSteps } from '../utils/types';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';
import { onlyContainsValidCharacters } from 'helpers/validations/validations';
import { NumberFormatValues } from 'react-number-format';

export interface AccountInformationStepProps {
  account: Account;
  startDateTime: Date | null;
  currentStep?: WizardSteps;
  onSubmit: (account?: Account) => void;
  setNextStep: () => void;
}

const isLegalNameValid = (legalName: string): boolean => {
  return legalName.trim() !== '';
};

const isNameValid = (name: string): boolean => {
  return name.trim() !== '';
};

const isStreetValid = (street: string): boolean => {
  return street.trim() !== '' && onlyContainsValidCharacters(street.trim());
};

const isCityValid = (city: string): boolean => {
  return city.trim() !== '' && onlyContainsValidCharacters(city.trim());
};

const isStateValid = (state: string): boolean => {
  return state !== '';
};

const isZipValid = (zip: string): boolean => {
  const sanitized = zip.replace('-', '');
  return sanitized.length === 5 || sanitized.length === 9;
};

const isPhoneValid = (phone: string): boolean => {
  return phone.length === 10;
};

const isFeinValid = (fein: string): boolean => {
  return fein.length === 9;
};

export function AccountInformationStep(
  props: AccountInformationStepProps
): JSX.Element {
  const [account, setAccount] = useState(props.account);
  const [states, setStates] = useState<UsState[]>([]);
  const [statesSelectOptions, setStatesSelectOptions] = useState<
    SelectProps['options']
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStates = async (): Promise<void> => {
      try {
        const response = await UnderwritingClient.fetchStates();
        const selectOptions = response.map((usState) => ({
          text: usState.name,
          value: usState.abbreviation,
        }));

        setStates(response);
        setStatesSelectOptions(selectOptions);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchStates();
  }, []);

  const isFormValid = (): boolean => {
    return (
      isLegalNameValid(account.legal_name ?? '') &&
      isNameValid(account.name ?? '') &&
      isStreetValid(account.address.street1) &&
      isCityValid(account.address.city) &&
      isStateValid(account.address.state) &&
      isZipValid(account.address.zip) &&
      (isPhoneValid(account.phone ?? '') || account.phoneNotPresent === true) &&
      (isFeinValid(account.fein ?? '') || account.feinNotPresent === true)
    );
  };

  const hasToSearchRecords = (): boolean =>
    // Checks if there is a change in the account or this step is not completed
    Object.entries(account).toString() !==
      Object.entries(props.account).toString() ||
    props.currentStep === WizardSteps.AccountInformation;

  const autofillAddressFields = (
    fields: {
      street1?: string;
      city?: string;
      state?: string;
      zip?: string;
    } = {}
  ): void => {
    setErrorMessage(null);
    const { street1, city, state, zip } = fields;

    setAccount({
      ...account,
      address: {
        ...account.address,
        street1: street1 ?? '',
        city: city ?? '',
        state: state ?? '',
        zip: zip ?? '',
      },
    });
  };

  const handleFeinNotPresent = (value: boolean): void => {
    setErrorMessage(null);
    setAccount({
      ...account,
      feinNotPresent: value,
      fein: '',
    });
  };

  const handlePhoneNotPresent = (value: boolean): void => {
    setErrorMessage(null);
    setAccount({
      ...account,
      phoneNotPresent: value,
      phone: '',
    });
  };

  const handleDbaSameAsLegalName = (value: boolean): void => {
    setErrorMessage(null);
    if (value) {
      setAccount({
        ...account,
        dbaSameAsLegalName: value,
        name: account.legal_name,
      });
    } else {
      setAccount({
        ...account,
        dbaSameAsLegalName: value,
      });
    }
  };

  const handleLegalNameChange = (value: string): void => {
    setErrorMessage(null);

    if (account.dbaSameAsLegalName) {
      setAccount({
        ...account,
        legal_name: value,
        name: value,
      });
    } else {
      setAccount({
        ...account,
        legal_name: value,
      });
    }
  };

  const handleAccountFieldsChange: TextInputProps['onValueChange'] = (
    value,
    { event }
  ) => {
    setErrorMessage(null);
    setAccount({
      ...account,
      [event.target.name]: value,
    });
  };

  const handleAccountMaskedFieldsChange = (
    valueObject: NumberFormatValues,
    targetName: string
  ): void => {
    setErrorMessage(null);
    setAccount({
      ...account,
      [targetName]: valueObject.value,
    });
  };

  const handleAddressFieldsChange: TextInputProps['onValueChange'] = (
    value,
    { event }
  ) => {
    setErrorMessage(null);
    setAccount({
      ...account,
      address: {
        ...account.address,
        [event.target.name]: value,
      },
    });
  };

  const handleZipChange = (value: string): void => {
    setErrorMessage(null);

    setAccount({
      ...account,
      address: {
        ...account.address,
        zip: value,
      },
    });
  };

  const handleSelectChange: SelectProps['onValueChange'] = (value) => {
    setErrorMessage(null);
    setAccount({
      ...account,
      address: {
        ...account.address,
        state: value,
      },
    });
  };

  const handleSubmit = (): void => {
    if (isFormValid()) {
      setIsLoading(true);
      setErrorMessage(null);

      hasToSearchRecords() ? props.onSubmit(account) : props.setNextStep();
    } else {
      setErrorMessage(
        'Before you try again, please make sure that all required information is present and correct.'
      );
    }
  };

  const getActionButton = (): JSX.Element => {
    return hasToSearchRecords() ? (
      <Button startIcon="magnifying-glass" onClick={handleSubmit}>
        Search Existing Customers
      </Button>
    ) : (
      <Button endIcon="arrow-right-long" onClick={handleSubmit}>
        Continue
      </Button>
    );
  };

  return (
    <Card title="Account Information">
      <Box>
        {errorMessage && (
          <Box marginBottom={3}>
            <Banner>{errorMessage}</Banner>
          </Box>
        )}
        <Text>
          Prequal Start DateTime:{' '}
          {props.startDateTime &&
            formatDateTimeString(props.startDateTime.toDateString())}
        </Text>
        <Box marginTop={4}>
          <Grid gutter>
            <Grid.Item l={6}>
              <TextInput
                name="legal_name"
                label="Legal Name"
                value={account.legal_name ?? ''}
                onValueChange={handleLegalNameChange}
                required
              />
            </Grid.Item>
            <Grid.Item l={6}>
              <Flex gap={3} flexDirection="column">
                <TextInput
                  name="name"
                  label="DBA"
                  value={account.name}
                  disabled={account.dbaSameAsLegalName ?? false}
                  required={!account.dbaSameAsLegalName}
                  onValueChange={handleAccountFieldsChange}
                />
                <Switch
                  id="dba-same-as-legal-name"
                  label="DBA same as Legal Name"
                  checked={account.dbaSameAsLegalName ?? false}
                  onCheckedChange={handleDbaSameAsLegalName}
                  inInputGroup
                />
              </Flex>
            </Grid.Item>
            <Grid.Item l={12}>
              <TextInput
                name="street1"
                label="Street Address"
                value={account.address.street1}
                onValueChange={handleAddressFieldsChange}
                required
              />
            </Grid.Item>
            <Grid.Item l={6}>
              <TextInput
                name="city"
                label="City"
                value={account.address.city}
                onValueChange={handleAddressFieldsChange}
                required
              />
            </Grid.Item>
            <Grid.Item l={3}>
              <Select
                name="state"
                label="State"
                options={statesSelectOptions}
                onValueChange={handleSelectChange}
                value={account.address.state}
                required
              />
            </Grid.Item>
            <Grid.Item l={3}>
              <GoogleZipCodeLookup
                handleAutofill={autofillAddressFields}
                label="Zip Code"
                onChange={handleZipChange}
                placeholder="5 or 9 digits Zip Code"
                usStates={states}
                value={account.address.zip ?? ''}
              />
            </Grid.Item>
            <Grid.Item l={3}>
              <Flex gap={3} flexDirection="column">
                <MaskedTextInput
                  name="phone"
                  label="Phone"
                  type="tel"
                  mask="_"
                  format="(###) ###-####"
                  placeholder="(999) 999-9999"
                  beforeInputContent={<TextInput.DecorativeIcon icon="phone" />}
                  value={account.phone ?? ''}
                  disabled={account.phoneNotPresent ?? false}
                  required={!account.phoneNotPresent}
                  onValueChange={(e) =>
                    handleAccountMaskedFieldsChange(e, 'phone')
                  }
                />
                <Switch
                  id="phone-not-on-application"
                  label="Phone not on application"
                  checked={account.phoneNotPresent ?? false}
                  onCheckedChange={handlePhoneNotPresent}
                  inInputGroup
                />
              </Flex>
            </Grid.Item>
            <Grid.Item l={3}>
              <Flex gap={3} flexDirection="column">
                <Flex alignItems="center" gap={1}>
                  <MaskedTextInput
                    name="fein"
                    label="Federal Tax ID"
                    type="number"
                    format="##-#######"
                    mask="_"
                    placeholder="99-9999999"
                    value={account.fein ?? ''}
                    disabled={account.feinNotPresent ?? false}
                    required={!account.feinNotPresent}
                    onValueChange={(e) =>
                      handleAccountMaskedFieldsChange(e, 'fein')
                    }
                  />
                  <FederalTaxIdPopover />
                </Flex>
                <Switch
                  id="fein-not-on-application"
                  label="FEIN not on application"
                  checked={account.feinNotPresent ?? false}
                  onCheckedChange={handleFeinNotPresent}
                  inInputGroup
                />
              </Flex>
            </Grid.Item>
          </Grid>
        </Box>
      </Box>
      <Box marginTop={4}>
        <Divider />
        <Flex justifyContent="center" marginTop={3}>
          {isLoading ? <Loading size="small" /> : getActionButton()}
        </Flex>
      </Box>
    </Card>
  );
}
