import { formatDateTimeString } from '@forward-financing/fast-forward';

import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  LedgerResponse,
  PatchLedgerRequestBody,
  BuyRatesResponse,
  LedgerOfferRaw,
} from 'types/api/banking/types';
import {
  useApiSendToCreditCommittee,
  useApiPatchLedger,
  useFetchLedgers,
  useApiCloneLedger,
  useApiFetchBuyRates,
  useApiCreateLedger,
  useApiDeleteLedger,
  useFetchOffers,
} from 'apiHooks/banking/ledgerFetchHooks';
import { IsoResponse } from 'types/api/funding/types';
import { IndependentSalesOrganization } from 'components/SubmissionUnderwriting/ApplicationSnapshot/applicationSnapshot.types';
import { useGetApiIso } from 'apiHooks/funding/isoFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { useApiFetchCalculatedOffer } from '../../../apiHooks/banking/offerFetchHooks';

import {
  FetchCalculatedLedgerParams,
  Ledger,
  BuyRates,
  GenericLedgerApiParams,
  LedgerOffer,
} from './ledger.types';

const toLedgersArray = (ledgers: LedgerResponse[]): Ledger[] => {
  return ledgers.map((rawLedger) => ({
    id: rawLedger.id,
    createdAt: formatDateTimeString(rawLedger.created_at),
    override: rawLedger.net_deposit_override_cents,
    adb: rawLedger.average_daily_balance_cents,
    program: rawLedger.program_type_id,
    forwardPosition: rawLedger.financings_count,
    totalGross: rawLedger.financings_gross,
    addOn: rawLedger.is_add_on,
    buyOut: rawLedger.has_buy_out_position,
    prime: rawLedger.prime_deal,
    previousReceived: rawLedger.previously_received,
    eligibleForWeekly: rawLedger.eligible_for_weekly,
    presentedAsWeekly: rawLedger.frequency,
    uwNotes: rawLedger.underwriter_notes,
    programExplanation: rawLedger.program_explanation,
  }));
};

export const useLedgers = (
  submissionUuid: string
): UseGenericQueryResponse<Ledger[]> =>
  useGenericFeatureQuery(
    useFetchLedgers,
    (data) => data && toLedgersArray(data),
    submissionUuid
  );

const toIso = (iso?: IsoResponse): IndependentSalesOrganization | undefined => {
  if (!iso) {
    return undefined;
  }

  return {
    uuid: iso.partner.uuid,
    name: iso.partner.name,
  };
};

export const useGetIso = (
  isoUuid?: string
): UseGenericQueryResponse<IndependentSalesOrganization> => {
  return useGenericFeatureQuery(useGetApiIso, toIso, isoUuid);
};

export const useSendToCreditCommittee = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [sendToCreditCommittee, { error, ...rest }] =
    useApiSendToCreditCommittee();

  useLogError(error);

  return [
    sendToCreditCommittee,
    {
      error,
      ...rest,
    },
  ];
};

const toPatchLedgerReqBody = (ledger: Ledger): PatchLedgerRequestBody =>
  ({
    is_add_on: ledger.addOn.toString(),
    has_buy_out_position: ledger.buyOut.toString(),
    prime_deal: ledger.prime.toString(),
    previously_received: ledger.previousReceived.toString(),
    eligible_for_weekly: ledger.eligibleForWeekly.toString(),
    frequency: ledger.presentedAsWeekly.toString(),
    program_type_id: ledger.program.toString(),
    program_explanation: ledger.programExplanation,
    underwriter_notes: ledger.uwNotes,
  } as PatchLedgerRequestBody);

export const usePatchLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams & {
    ledger: Ledger;
  }
> => {
  const [updateLedger, { error, ...rest }] = useApiPatchLedger();

  useLogError(error);

  const patchFunction = ({
    ledger,
    ...ids
  }: GenericLedgerApiParams & {
    ledger: Ledger;
  }): Promise<MutationResponse> =>
    updateLedger({ ...ids, requestBody: toPatchLedgerReqBody(ledger) });

  return [patchFunction, { error, ...rest }];
};

export const useCloneLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [post, { error, ...rest }] = useApiCloneLedger();

  useLogError(error);

  return [post, { error, ...rest }];
};

export const toBuyRates = (data?: BuyRatesResponse): BuyRates | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    buyRateTable: data.buy_rate_table.map((rate) => ({
      month: rate.months,
      rate: rate.rate,
    })),
    totalFactorRateTable: data.total_factor_rate_table.map((rate) => ({
      month: rate.months,
      rate: rate.rate,
    })),
    maxFactorRate: data.max_factor_rate,
  };
};

export const useFetchBuyRates = ({
  submissionUuid,
  ledgerId,
}: GenericLedgerApiParams): UseGenericQueryResponse<BuyRates> => {
  return useGenericFeatureQuery(useApiFetchBuyRates, toBuyRates, {
    submissionUuid,
    ledgerId,
  });
};

export const useDeleteLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  const [deleteLedger, { error, ...rest }] = useApiDeleteLedger();

  useLogError(error);

  return [deleteLedger, { error, ...rest }];
};

export const useCreateLedger = (): UseGenericMutationResponse<
  MutationResponse,
  Omit<GenericLedgerApiParams, 'ledgerId'>
> => {
  const [createLedger, { error, ...rest }] = useApiCreateLedger();

  useLogError(error);

  return [createLedger, { error, ...rest }];
};

export const toLedgerOffer = (offer: LedgerOfferRaw): LedgerOffer => ({
  approvalAmount: offer.approval_amount,
  multipleOfAdb: offer.multiple_of_adb,
  totalFactorRate: offer.total_factor_rate,
  paybackAmount: offer.payback_amount,
  paybackDays: offer.payback_days,
  dailyPayment: offer.daily_payment,
  paybackAmountGross: offer.payback_amount_gross,
  combinedGross: offer.combined_gross,
  months: Number(offer.months),
  dollarOverride: offer.dollar_override,
  gross: offer.gross,
});

export const useCalculatedLedgerOffer = ({
  submissionUuid,
  ledgerId,
  payload,
}: FetchCalculatedLedgerParams): UseGenericQueryResponse<LedgerOffer> => {
  return useGenericFeatureQuery(
    useApiFetchCalculatedOffer,
    (data) => data && toLedgerOffer(data),
    {
      submissionUuid,
      ledgerId,
      payload,
    }
  );
};

const toLedgerOffers = (
  offers: LedgerOfferRaw[] | undefined
): LedgerOffer[] => {
  if (!offers || !offers.length) {
    return [];
  }

  return offers.map((offer) => toLedgerOffer(offer));
};

export const useLedgerOffers = (
  params: Omit<GenericLedgerApiParams, 'customerUuid'>
): UseGenericQueryResponse<LedgerOffer[]> => {
  const { error, ...rest } = useGenericFeatureQuery(
    useFetchOffers,
    (data) => toLedgerOffers(data),
    {
      ...params,
    }
  );

  useLogError(error);

  return {
    error,
    ...rest,
  };
};
