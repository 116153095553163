import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.scss';
import { Application } from './Application';

// Uncomment the lines below to start the mock service worker locally.
// Note: Also see src/mocks/underwriting/generators/ApiV1ApplicationsUuidDownloadAttachmentsSourceKey.ts
// for additional instructions.
// Note: Use `npx msw init public/` to generate the mockServiceWorker.js file.
// import { worker } from 'mocks/browser';
// if (process.env.NODE_ENV === 'development') {
//   void worker.start({
//     onUnhandledRequest: 'bypass',
//   });
// }

const container = document.getElementById('root');

// we're disabling no non null assertion here because that is what
// is recommended by the React team
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<Application />);
