import React, { useEffect } from 'react';
import { useViewHistoryContext } from '../../../contexts/ViewHistoryProvider';
import { UnderwritingClient } from '../../../api/UnderwritingClient';
import { FFLogger } from 'api/LogClient';
import { toError } from 'helpers/errorUtils';

interface ViewHistoryTrackerProps {
  submissionUuid: string;
}

export const ViewHistoryTracker = (
  props: ViewHistoryTrackerProps
): JSX.Element => {
  const { addEntry } = useViewHistoryContext();
  useEffect(() => {
    if (props.submissionUuid) {
      UnderwritingClient.fetchOpportunity(props.submissionUuid)
        .then((opportunity) => {
          addEntry('submissions', {
            title: opportunity.name,
            timestamp: Date.now(),
            objectUUID: props.submissionUuid,
          });
        })
        .catch((e: unknown) => {
          const error = toError(e);
          FFLogger.error(error);
        });
    }
  }, [props.submissionUuid, addEntry]);

  return <></>;
};
