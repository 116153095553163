import { useCallback } from 'react';
import { useGetApiLexisNexisOwnerManualSearch } from 'apiHooks/underwriting/ownerFetchHooks';
import { OwnerLexisNexisSearchResultResponse } from 'types/api/underwriting/types';
import { useLogError } from 'apiHooks/useLogError';
import {
  MutationResponse,
  UseGenericMutationResult,
} from 'apiHooks/genericFetchHooks';
import { toAddressString } from '../helpers';
import {
  OwnerLexisNexisManualSearch,
  OwnerManualSearchFetcher,
  OwnerManualSearchQueryParams,
} from './ownerManualSearch.types';

const toOwnerLexisNexisManualSearch = (
  result: OwnerLexisNexisSearchResultResponse
): OwnerLexisNexisManualSearch => {
  return {
    name: result.full_name,
    ssn: result.ssn,
    dob: result.date_of_birth?.map((date) => `${date.month}/${date.year}`),
    addresses: result.addresses?.map(toAddressString),
    reportId: result.report_identifier,
    createdAt: result.created_at ?? undefined,
  };
};

const toManualSearchQueryParams = (
  params: OwnerManualSearchQueryParams
): string => {
  const queryParams = [
    params.ssn && `ssn=${params.ssn}`,
    params.firstName && `first_name=${params.firstName}`,
    params.lastName && `last_name=${params.lastName}`,
    params.state && `state=${params.state}`,
    params.street1 && `street1=${params.street1}`,
    params.phone && `phone=${params.phone}`,
    params.city && `city=${params.city}`,
    params.zip && `zip=${params.zip}`,
  ];

  return queryParams.filter(Boolean).join('&');
};

type UseLexisNexisManualSearchResult = [
  OwnerManualSearchFetcher,
  UseGenericMutationResult<OwnerLexisNexisManualSearch[]>
];

export const useGetLexisNexisManualSearchResult =
  (): UseLexisNexisManualSearchResult => {
    const [fetcher, { data, loading, error, responseReady }] =
      useGetApiLexisNexisOwnerManualSearch();

    const fetchSearchResults = useCallback(
      (
        ownerUuid: string,
        submissionUuid: string,
        queryParams: OwnerManualSearchQueryParams
      ): Promise<MutationResponse> => {
        const params = toManualSearchQueryParams(queryParams);
        return fetcher({ ownerUuid, submissionUuid, queryParams: params });
      },
      [fetcher]
    );

    useLogError(error);

    return [
      fetchSearchResults,
      {
        data: data?.results?.map(toOwnerLexisNexisManualSearch),
        loading,
        error,
        responseReady,
      },
    ];
  };
