import { useCallback } from 'react';
import {
  ExperianConsumerResponse,
  ExperianConsumerArchivesResponse,
  ExperianConsumerCreditReportResponse,
  ExperianConsumerResponseV2,
  ConsumerCreditEntities,
} from 'types/api/3pi/types';
import {
  fetchExperianConsumer,
  fetchExperianConsumerArchives,
  fetchExperianConsumerCreditReport,
  fetchExperianConsumerV2,
} from 'api/3pi/experianConsumerFetchUtils';
import {
  UseGenericMutationResponse,
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';

export type UsePullExperianConsumerArgs = {
  submissionUuid: string;
  ownerId: number;
  firstName?: string;
  surname?: string;
  ssn: string;
  age?: number;
  yob?: string; // Year of birth YYYY
  currentAddress?: {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  force: boolean;
};

export type UsePullExperianConsumerResponse = UseGenericMutationResponse<
  ExperianConsumerResponse,
  UsePullExperianConsumerArgs
>;

export const useFetchExperianConsumer = (
  submissionUuid?: string,
  ownerUuid?: string
): UseGenericQueryResponse<ExperianConsumerResponseV2> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid || !ownerUuid) {
      return undefined;
    }

    return fetchExperianConsumer(submissionUuid, ownerUuid);
  }, [submissionUuid, ownerUuid]);

  return useGenericQuery(fetchFunction);
};

export const useFetchExperianConsumerV2 = (
  submissionUuid: string,
  ownerUuid: string,
  entities?: ConsumerCreditEntities[]
): UseGenericQueryResponse<ExperianConsumerResponseV2> => {
  const fetchFunction = useCallback(() => {
    if (!submissionUuid || !ownerUuid) {
      return undefined;
    }

    return fetchExperianConsumerV2(submissionUuid, ownerUuid, entities);
  }, [submissionUuid, ownerUuid, entities]);

  return useGenericQuery(fetchFunction);
};

export const useFetchExperianConsumerArchives = (
  ownerUuid?: string
): UseGenericQueryResponse<ExperianConsumerArchivesResponse> => {
  const fetchFunction = useCallback(() => {
    if (!ownerUuid) {
      return undefined;
    }

    return fetchExperianConsumerArchives(ownerUuid);
  }, [ownerUuid]);

  return useGenericQuery(fetchFunction);
};

export const useFetchExperianConsumerCreditReport = (
  referenceUuid?: string
): UseGenericQueryResponse<ExperianConsumerCreditReportResponse> => {
  const fetchFunction = useCallback(() => {
    if (!referenceUuid) {
      return undefined;
    }

    return fetchExperianConsumerCreditReport(referenceUuid);
  }, [referenceUuid]);

  return useGenericQuery(fetchFunction);
};
