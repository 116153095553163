import React, { ErrorInfo } from 'react';
import { Box, Icon, Subheading, Text } from '@forward-financing/fast-forward';
import { FFLogger } from '../../../../api/LogClient';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: string;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    FFLogger.error(error, errorInfo);
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Box bg="primary" textAlign="center" p={5}>
          <Icon name="bug" color="danger" size="2x" />
          <Subheading>Oh, no! Something went wrong.</Subheading>
          <Subheading variant="section">
            Please refresh the page and try again.
          </Subheading>
          <Subheading variant="section">
            Our developers have been notified of the issue and will look into
            it.
          </Subheading>
          <Text>Error: {this.state.error}</Text>
        </Box>
      );
    }

    return this.props.children;
  }
}
