import { useApiUnderwriterNextDeal } from 'apiHooks/banking/underwriterNextDealHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import {
  useApiLazyGetSubmissions,
  useUpdateApiSubmission,
} from 'apiHooks/underwriting/submissionFetchHooks';

import { useLogError } from 'apiHooks/useLogError';
import { NextDealResponse } from 'types/api/banking/types';
import {
  AlgoliaSearchParams,
  SubmissionSearchResultResponse,
} from 'types/api/underwriting/types';
import { SubmissionSearchResultData } from './LivePipeline.types';
import { useCallback } from 'react';

export type UseAssignUnderwriterNextDealResponse = [
  () => Promise<MutationResponse>,
  {
    data?: NextDealData | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

interface NextDealData {
  nextDeal?: {
    opportunityUuid: string;
    recordId: number;
    applicationUrl: string;
    accountUuid: string;
    legalName: string;
  };
  dataUrls: string[];
}

const toNextDealData = (responseData: NextDealResponse): NextDealData => {
  return {
    nextDeal: responseData.next_deal
      ? {
          opportunityUuid: responseData.next_deal.opportunity_uuid,
          recordId: responseData.next_deal.record_id,
          applicationUrl: responseData.next_deal.application_url,
          accountUuid: responseData.next_deal.account_uuid,
          legalName: responseData.next_deal.legal_name,
        }
      : undefined,
    dataUrls: responseData.data_urls,
  };
};

export const useAssignUnderwriterNextDeal =
  (): UseAssignUnderwriterNextDealResponse => {
    const [createFn, { error, data, ...rest }] = useApiUnderwriterNextDeal();

    const createFunction = async (): Promise<MutationResponse> => {
      return createFn();
    };

    useLogError(error);

    return [
      createFunction,
      {
        data: data ? toNextDealData(data) : undefined,
        error,
        ...rest,
      },
    ];
  };

export type UseSearchSubmissionsResponse = [
  (params: AlgoliaSearchParams) => Promise<void>,
  {
    data?: SubmissionSearchResultData | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

const toSearchResult = (
  responseData: SubmissionSearchResultResponse
): SubmissionSearchResultData => {
  return {
    meta: {
      totalCount: responseData.meta.total_count,
      totalPages: responseData.meta.total_pages,
      currentPage: responseData.meta.current_page,
    },
    submissionSearchResults: responseData.applications.map((result) => {
      return {
        objectID: result.objectID,
        merchantPartnerId: result.merchant_partner_id,
        dba: result.dba,
        legalName: result.legal_name,
        stageName: result.stage_name,
        subStage: result.sub_stage,
        approvalFlag: result.approval_flag ?? undefined,
        ffUnderwriter: result.ff_underwriter ?? undefined,
        creditCommittee: result.credit_committee ?? undefined,
        exceptionRequestAssignee:
          result.exception_request_assignee ?? undefined,
        underwriterSignOffName: result.underwriter_sign_off_name ?? undefined,
        processor: result.processor ?? undefined,
        newOrRenewal: result.new_or_renewal,
        maxApprovalIssued: result.max_approval_issued ?? undefined,
        lastSubStageChangeDate: result.last_sub_stage_change_date ?? undefined,
        salesforceOpportunityUuid: result.salesforce_opportunity_uuid,
        vca: result.vca ?? undefined,
        prequalAnalystName: result.prequal_analyst_name ?? undefined,
        decisionAnalystId: result.decision_analyst_id ?? undefined,
        industryName: result.industry_name ?? undefined,
        revenueFloat: result.revenue_float ?? undefined,
        existingFinancingPositions:
          result.existing_financing_positions ?? undefined,
        isoCompetingSubMessage: result.iso_competing_sub_message ?? undefined,
        primeDealStatus: result.prime_deal_status ?? undefined,
        lastStageChangeDate: result.last_stage_change_date ?? undefined,
        slaStatus: result.sla_status ?? undefined,
        categoryStatus: result.category_status ?? undefined,
      };
    }),
  };
};

export const useSearchSubmissions = (): UseSearchSubmissionsResponse => {
  const [searchFn, { error, data, ...rest }] = useApiLazyGetSubmissions();

  const searchFunction = useCallback(
    async (searchParams: AlgoliaSearchParams): Promise<void> => {
      return searchFn(searchParams);
    },
    [searchFn]
  );

  useLogError(error);

  return [
    searchFunction,
    {
      data: data ? toSearchResult(data) : undefined,
      error,
      ...rest,
    },
  ];
};

interface UpdateUnderwriterBody {
  underwriterName: string;
  creditCommitteeName?: never;
}

interface UpdateCreditComitteeBody {
  underwriterName?: never;
  creditCommitteeName: string;
}

interface UpdateSubmissionAssigmentArgs {
  submissionUuid: string;
  body: UpdateUnderwriterBody | UpdateCreditComitteeBody;
}

type UseUpdateSubmissionAssignmentResult = [
  (input: UpdateSubmissionAssigmentArgs) => Promise<MutationResponse>,
  { error?: Error }
];

// This is stricter than the API requires because we want to ensure we have
// a string for either underwriter or credit committee name. In other words the
// API is happy with an empty body because all the properties are optional.
// We want to make sure our body is not empty and has either underwriter or
// credit committee name.

interface UpdateSubmissionAPIUnderwriterBody {
  x2dc_underwriter__c: string;
  credit_committee__c?: never;
}

interface UpdateSubmissionAPICreditCommitteeBody {
  x2dc_underwriter__c?: never;
  credit_committee__c: string;
}

interface UpdateSubmissionArgsRequireBody {
  submissionUuid: string;
  updateBody:
    | UpdateSubmissionAPIUnderwriterBody
    | UpdateSubmissionAPICreditCommitteeBody;
}

// We do not need tests for this feature hook because the LivePipeline tests
// already result in 100% coverage of this hook.
export const useUpdateSubmissionAssignment =
  (): UseUpdateSubmissionAssignmentResult => {
    const [updateSubmission, { error }] = useUpdateApiSubmission();

    // Wrapper function to make sure either the underwriter or credit committee
    // body property is required. Which is stricter than the API requires.
    const updateSubmissionRequireBody = (
      input: UpdateSubmissionArgsRequireBody
    ): ReturnType<typeof updateSubmission> => updateSubmission(input);

    const updateFunction = (
      args: UpdateSubmissionAssigmentArgs
    ): Promise<MutationResponse> => {
      const isUnderwriterBody = (
        body: UpdateUnderwriterBody | UpdateCreditComitteeBody
      ): body is UpdateUnderwriterBody => {
        return typeof body.underwriterName === 'string';
      };

      return updateSubmissionRequireBody({
        submissionUuid: args.submissionUuid,
        updateBody: isUnderwriterBody(args.body)
          ? {
              x2dc_underwriter__c: args.body.underwriterName,
            }
          : {
              credit_committee__c: args.body.creditCommitteeName,
            },
      });
    };

    useLogError(error);

    return [
      updateFunction,
      {
        error,
      },
    ];
  };
