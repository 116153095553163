export const ROLLBAR_ACCESS_TOKEN = (): string | undefined =>
  process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;

export const ROLLBAR_ENV = (): string | undefined =>
  process.env.REACT_APP_ROLLBAR_ENV;

export const FETCH_LEDGERS_URL = (submissionUuid = ':submissionUuid'): string =>
  `/api/v3/submissions/${submissionUuid}/ledgers`;

export const SAVE_LEDGER_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string => `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}`;

export const SEND_LEDGER_TO_CREDIT_COMMITTEE_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string =>
  `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}/send_to_credit_committee`;

export const CLONE_LEDGER_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string => `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}/clone`;

export const FETCH_BUY_RATES_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string =>
  `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}/buy_rates`;

export const DELETE_LEDGER_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string => `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}`;

export const CREATE_LEDGER_URL = (submissionUuid = ':submissionUuid'): string =>
  `/api/v2/submissions/${submissionUuid}/ledgers`;

export const FETCH_OFFERS_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string => `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}/offers`;

export const FETCH_CALCULATED_OFFER_URL = (
  submissionUuid = ':submissionUuid',
  ledgerId: string | number = ':ledgerId'
): string =>
  `/api/v3/submissions/${submissionUuid}/ledgers/${ledgerId}/offer_calculations`;
