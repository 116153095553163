import React, { useEffect } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  formatDateTimeString,
  Grid,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { V6ScoreDisplayTable } from './V6ScoreDisplayTable';
import {
  useInference,
  usePullInference,
  useUpdateSubmission,
} from './v6ScoreDisplayHooks';

type V6ScoreDisplayProps = {
  submissionUuid: string;
};

export const V6ScoreDisplay = ({
  submissionUuid,
}: V6ScoreDisplayProps): JSX.Element => {
  const currentUser = useUserContext();

  const {
    data: inferenceData,
    loading: inferenceLoading,
    error: inferenceError,
    refetch: refetchInference,
  } = useInference(submissionUuid);

  const [
    pullInference,
    {
      data: pullInferenceData,
      error: pullInferenceError,
      loading: pullInferenceLoading,
    },
  ] = usePullInference();

  const [updateRiskScore, { error: updateRiskScoreError }] =
    useUpdateSubmission(submissionUuid);

  useEffect(() => {
    // Using typeof in the conditional to handle a rare edge case where the score is 0,
    // which would be falsy and not trigger the update
    if (typeof inferenceData?.score === 'number') {
      void updateRiskScore({
        riskScore: inferenceData?.score,
      });
    }
  }, [inferenceData?.score, updateRiskScore]);

  if (inferenceLoading) {
    return <Loading />;
  }

  if (inferenceError) {
    return <Banner>{inferenceError?.message}</Banner>;
  }

  const handleRefresh = async (): Promise<void> => {
    const result = await pullInference({
      submissionUuid,
      apiKey: currentUser.api_key,
    });

    if (result.success) {
      void refetchInference();
    }
  };

  return (
    <Box mb={4}>
      {pullInferenceError && <Banner>{pullInferenceError?.message}</Banner>}
      {updateRiskScoreError && <Banner>{updateRiskScoreError?.message}</Banner>}
      {pullInferenceData && pullInferenceData.autoDecline && (
        <Banner variant="error">
          Based on the risk score, the recommendation is to decline the
          submission.
        </Banner>
      )}

      <Flex gap={3} alignItems={'center'}>
        <Button
          startIcon="refresh"
          onClick={() => handleRefresh()}
          disabled={pullInferenceLoading}
        >
          Refresh V6 Scores
        </Button>

        {inferenceData?.createdAt && (
          <Text>
            Last updated on {formatDateTimeString(inferenceData?.createdAt)}
          </Text>
        )}
      </Flex>

      <Subheading>V6 Score: {inferenceData?.score}/20</Subheading>

      <Grid gutter>
        <Grid.Item l={12} xl={6}>
          <Subheading variant="section">Top Supporters</Subheading>

          <V6ScoreDisplayTable features={inferenceData?.topFive} />
        </Grid.Item>

        <Grid.Item l={12} xl={6}>
          <Subheading variant="section">Top Detractors</Subheading>

          <V6ScoreDisplayTable features={inferenceData?.bottomFive} />
        </Grid.Item>
      </Grid>
    </Box>
  );
};
