import { useCallback } from 'react';
import {
  fetchCalculatedOffer,
  fetchCurrentOffer,
} from 'api/banking/offerFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { LedgerOfferRaw, OfferResponse } from 'types/api/banking/types';
import { FetchCalculatedLedgerParams } from '../../components/DealScoring/Ledger/ledger.types';

export type UseApiCurrentOfferResponse = UseGenericQueryResponse<
  OfferResponse | undefined
>;

export const useApiCurrentOffer = (
  submissionUuid?: string
): UseApiCurrentOfferResponse => {
  const loadData = useCallback(() => {
    if (!submissionUuid) {
      return undefined;
    }

    return fetchCurrentOffer(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(loadData);
};

export const useApiFetchCalculatedOffer = ({
  ledgerId,
  submissionUuid,
  payload,
}: FetchCalculatedLedgerParams): UseGenericQueryResponse<
  LedgerOfferRaw | undefined
> => {
  const getCalculatedOfferFn = useCallback(
    () =>
      fetchCalculatedOffer({
        submissionUuid,
        ledgerId,
        payload,
      }),
    [submissionUuid, ledgerId, payload]
  );

  return useGenericQuery(getCalculatedOfferFn);
};
