import React, { useCallback, useEffect, useState } from 'react';
import { Box, Icon, Subheading } from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import { featureFlags } from 'helpers/featureFlags';
import {
  Submission,
  UsState,
  Contact,
  Account,
  Owner,
  Decline,
  IndustryType,
  Customer,
} from '../../api/UnderwritingClient/codecs';
import { DeclineDrivers } from '../../api/BankingClient/codecs';
import { Partner } from '../../api/FundingClient/codecs';
import {
  generateEmptyOpportunity,
  SubmissionObject,
} from '../../api/UnderwritingClient/utils/index';
import { HTMLFormChangeOrMouseEvent } from '../../types/form';
import { User } from '../../api/AuthClient/codecs';
import { userFullName } from '../../helpers/utils';
import { REACT_APP_SUBMISSION_QUEUE_URL } from '../../constants/globals';
import { LogData } from '../../api/AnalyticsGatewayClient/codecs';
import { StepStatus, WizardSteps, StepProgress } from './utils/types';

// Wizard Components
import { WizardProgress } from './WizardProgress/index';
import { AccountInformationForm } from './AccountInformationForm/AccountInformationForm';
import { AccountInformationStep } from './AccountInformationForm/AccountInformationStep';
import { OwnerInformationForm } from './OwnerInformationForm/';
import { OwnerInformationStep } from './OwnerInformationForm/OwnerInformationStep';
import { AdditionalInformationForm } from './AdditionalInformationForm/AdditionalInformationForm';
import { OverviewPage } from './OverviewPage/OverviewPage';
import { PrequalComplete } from './PrequalComplete/PrequalComplete';
import { ErrorPage } from './ErrorPage';
import { CustomerSearch } from './CustomerSearch/CustomerSearch';
import {
  useGetIndustryNaicsIndustryId,
  usePullRelativity6IndustryPrediction,
} from './WizardFetchHooks';

export interface WizardProps {
  submission: Submission;
  usStates: UsState[];
  partners: Partner[];
  declineDriversList: DeclineDrivers;
  industryTypes: IndustryType[];
  loggedInUser: User;
  handlePrequalComplete: () => Promise<void>;
  handleCustomerChange: (customer: Customer) => void;
  handleCustomerSearchResultsReviewed: (
    customerUuid: string,
    customerOwners: Owner[],
    industry_id: number
  ) => void;
  handleOwnersSubmit: (owners: Contact[]) => void;
  handleApplicationOrAccountChange: (
    object: SubmissionObject
  ) => (e: HTMLFormChangeOrMouseEvent) => void;
  handleAccountInformationSubmit: (account?: Account) => void;
  handleAdditionalInformationSubmit: () => void;
  storeOwners: (owners: Contact[]) => void;
  handleOwnerStepCompleted: (contacts: Contact[]) => void;
  setDecline: (decline: Decline) => void;
  handleDeclineAndSubmit: () => Promise<void>;
  isDeclined: boolean;
  sendUnmaskedFieldLogs: (logData: LogData) => Promise<void>;
}

export const Wizard = (props: WizardProps): JSX.Element => {
  const getFirstIncompletedStep = useCallback((): WizardSteps => {
    if (!props.submission.prequal_state_attributes.account_completed) {
      return WizardSteps.AccountInformation;
    } else if (
      !props.submission.prequal_state_attributes
        .customer_search_results_reviewed
    ) {
      return WizardSteps.CustomerSearchResults;
    } else if (!props.submission.prequal_state_attributes.owners_completed) {
      return WizardSteps.OwnerInformation;
    } else if (
      !props.submission.prequal_state_attributes.other_info_completed
    ) {
      return WizardSteps.AdditionalInformation;
    } else if (
      props.submission.prequal_state_attributes.prequal_completed_at === null
    ) {
      return WizardSteps.Overview;
    } else {
      return WizardSteps.PrequalComplete;
    }
  }, [props.submission.prequal_state_attributes]);

  const [currentStep, setCurrentStep] = useState<WizardSteps>(
    getFirstIncompletedStep()
  );
  const [shouldDisplayDeclineModal, setShouldDisplayDeclineModal] =
    useState<boolean>(false);

  // Used for the Relativity 6 checkbox in AdditionalInformationForm
  const [hasVerifiedIndustry, setHasVerifiedIndustry] =
    useState<boolean>(false);
  const [hasUpdatedBusinessType, setHasUpdatedBusinessType] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.submission.prequal_state_attributes) {
      setCurrentStep(getFirstIncompletedStep());
      setShouldDisplayDeclineModal(false);
    }
  }, [getFirstIncompletedStep, props.submission.prequal_state_attributes]);

  const [
    pullRelativity6IndustryPrediction,
    { data: relativity6IndustryPredictionData },
  ] = usePullRelativity6IndustryPrediction();

  const account = props.submission.account;

  const pullIndustryPrediction = useCallback(async (): Promise<void> => {
    if (featureFlags.show_relativity_6_industry_prediction) {
      await pullRelativity6IndustryPrediction({
        body: {
          businessName: defaultTo(account.name, ''), // account.name should never be undefined
          street: [account.address.street1, account.address.street2]
            .filter(Boolean)
            .join(' '),
          state: account.address.state,
          country: 'USA', // Default to USA as we only serve US businesses
          ...(props.submission.uuid && {
            referenceIds: [props.submission.uuid],
          }),
        },
      });
    }
  }, [
    account.address,
    account.name,
    props.submission.uuid,
    pullRelativity6IndustryPrediction,
  ]);

  const hasAccountData =
    account.name && account.address && account.address.state;

  // We only want to pull the industry prediction if the feature flag is enabled,
  // we don't have the prediction yet, and we have the account data
  // and the submission's uuid.
  const shouldPullIndustryPrediction =
    featureFlags.show_relativity_6_industry_prediction &&
    !relativity6IndustryPredictionData &&
    hasAccountData &&
    props.submission.uuid;

  useEffect(() => {
    if (shouldPullIndustryPrediction) {
      void pullIndustryPrediction();
    }
  }, [
    hasAccountData,
    pullIndustryPrediction,
    props.submission.uuid,
    relativity6IndustryPredictionData,
    shouldPullIndustryPrediction,
  ]);

  // Filter by rank 0 in industry_prediction,
  // which will give us the most confident prediction
  const mostConfidentIndustry =
    relativity6IndustryPredictionData?.industryPredictions.find(
      (industry) => industry.rank === 0
    );

  const { data: industryNaicsIndustryIdData } = useGetIndustryNaicsIndustryId(
    mostConfidentIndustry?.naicsCode || '',
    '2017'
  );

  const suggestedIndustry = props.industryTypes.find(
    (industry) =>
      industry.id === Number(industryNaicsIndustryIdData?.industryId)
  );

  const toggleDeclineModal = (): void => {
    setShouldDisplayDeclineModal(!shouldDisplayDeclineModal);
  };

  const selectStep = (currStep: WizardSteps): void => setCurrentStep(currStep);

  const calculateWizardProgress = (currStep: WizardSteps): StepProgress[] => {
    const wizardButtons = [
      WizardSteps.AccountInformation,
      WizardSteps.OwnerInformation,
      WizardSteps.AdditionalInformation,
    ];

    const comingFromOverview =
      getFirstIncompletedStep() === WizardSteps.Overview;

    const calculateStatus = (step: WizardSteps): StepStatus => {
      const { isDeclined } = props;
      if (step === currStep) {
        return StepStatus.Active;
      } else if (isDeclined || step > currStep || comingFromOverview) {
        return StepStatus.Inactive;
      } else if (step < currStep && !comingFromOverview) {
        return StepStatus.Completed;
      }

      return StepStatus.Inactive;
    };

    return wizardButtons.map((step) => ({
      step,
      status: calculateStatus(step),
    }));
  };

  const declinedIcon = (): JSX.Element => {
    if (props.isDeclined) {
      return (
        <div className="decline-icon">
          <Icon name="thumbs-down" color="danger" size="lg" />
        </div>
      );
    }
    return <></>;
  };

  const customerSearchCriteria = (): Customer => ({
    uuid: props.submission.account.uuid || '',
    name: props.submission.account.name || '',
    legal_name: props.submission.account.legal_name || '',
    phone: props.submission.account.phone || '',
    created_at: props.submission.account.created_at || new Date(),
    updated_at: props.submission.account.updated_at || new Date(),
    fein: props.submission.account.fein || '',
    address: props.submission.account.address,
  });

  const additionalInfoPreviousStep = (): WizardSteps => {
    return WizardSteps.OwnerInformation;
  };

  const submitActionLabel = (): string =>
    getFirstIncompletedStep() === WizardSteps.Overview
      ? 'Proceed to Overview'
      : 'Next';

  const renderCurrentStep = (): JSX.Element => {
    switch (currentStep) {
      case WizardSteps.CustomerSearchResults:
        return (
          <CustomerSearch
            handleCustomerSearchResultsReviewed={
              props.handleCustomerSearchResultsReviewed
            }
            partners={props.partners}
            handleEditAccountInformation={() =>
              selectStep(WizardSteps.AccountInformation)
            }
            handleCustomerChange={props.handleCustomerChange}
            customer={customerSearchCriteria()}
          />
        );
      case WizardSteps.OwnerInformation:
        return featureFlags.show_updated_prequal_form ? (
          <OwnerInformationStep
            proceedToOverview={
              getFirstIncompletedStep() === WizardSteps.Overview
            }
            accountAddress={props.submission.account.address}
            contacts={props.submission.contacts}
            applicationUuid={props.submission.uuid || ''}
            onBack={() => selectStep(WizardSteps.AccountInformation)}
            handleOwnerStepCompleted={props.handleOwnerStepCompleted}
            sendUnmaskedFieldLogs={props.sendUnmaskedFieldLogs}
          />
        ) : (
          <OwnerInformationForm
            submitActionLabel={submitActionLabel()}
            storeOwners={props.storeOwners}
            accountAddress={props.submission.account.address}
            usStates={props.usStates}
            owners={props.submission.contacts}
            onBack={() => selectStep(WizardSteps.AccountInformation)}
            onSubmit={props.handleOwnersSubmit}
            declinedIcon={declinedIcon}
            forPrequal={true}
            sendUnmaskedFieldLogs={props.sendUnmaskedFieldLogs}
          />
        );
      case WizardSteps.AdditionalInformation:
        return (
          <AdditionalInformationForm
            partner_id={props.submission.partner_id}
            submission={props.submission}
            industryTypes={props.industryTypes}
            partners={props.partners}
            handleApplicationChange={props.handleApplicationOrAccountChange(
              SubmissionObject.Application
            )}
            handleAccountChange={props.handleApplicationOrAccountChange(
              SubmissionObject.Account
            )}
            handleWebPresenceChange={props.handleApplicationOrAccountChange(
              SubmissionObject.WebPresence
            )}
            onBack={() => selectStep(additionalInfoPreviousStep())}
            onSubmit={props.handleAdditionalInformationSubmit}
            declinedIcon={declinedIcon}
            forPrequal={true}
            // we don't need the opportunity for the prequal form, but passing
            // an empty one avoids some extra conditionals inside the form.
            opportunity={generateEmptyOpportunity()}
            loggedInUser={props.loggedInUser}
            mostConfidentIndustry={mostConfidentIndustry}
            suggestedIndustry={suggestedIndustry}
            hasVerifiedIndustry={hasVerifiedIndustry}
            setHasVerifiedIndustry={setHasVerifiedIndustry}
            hasUpdatedBusinessType={hasUpdatedBusinessType}
            setHasUpdatedBusinessType={setHasUpdatedBusinessType}
          />
        );
      case WizardSteps.Overview:
        return (
          <OverviewPage
            submission={props.submission}
            industryTypes={props.industryTypes}
            partners={props.partners}
            handleEditAccountInformation={() =>
              selectStep(WizardSteps.AccountInformation)
            }
            handleEditAdditionalInformation={() =>
              selectStep(WizardSteps.AdditionalInformation)
            }
            handleEditOwnerInformation={() =>
              selectStep(WizardSteps.OwnerInformation)
            }
            handlePrequalComplete={props.handlePrequalComplete}
            declineDriversList={props.declineDriversList}
            toggleDeclineModal={toggleDeclineModal}
            setDecline={props.setDecline}
            shouldDisplayDeclineModal={shouldDisplayDeclineModal}
            handleDeclineAndSubmit={props.handleDeclineAndSubmit}
            isDeclined={props.isDeclined}
            declinedIcon={declinedIcon}
            sendUnmaskedFieldLogs={props.sendUnmaskedFieldLogs}
            mostConfidentIndustry={mostConfidentIndustry}
            suggestedIndustry={suggestedIndustry}
          />
        );
      case WizardSteps.PrequalComplete:
        return <PrequalComplete DBA={props.submission.account.name || ''} />;
      case WizardSteps.AccountInformation:
      // fallthrough
      default:
        return featureFlags.show_updated_prequal_form ? (
          <AccountInformationStep
            account={props.submission.account}
            startDateTime={
              props.submission.prequal_state_attributes.prequal_started_at
            }
            currentStep={getFirstIncompletedStep()}
            onSubmit={() => {
              props.handleAccountInformationSubmit();
            }}
            setNextStep={() => selectStep(getFirstIncompletedStep())}
          />
        ) : (
          <AccountInformationForm
            partner_id={props.submission.partner_id}
            account={props.submission.account}
            usStates={props.usStates}
            handleAccountChange={props.handleApplicationOrAccountChange(
              SubmissionObject.Account
            )}
            handleAccountAddressChange={props.handleApplicationOrAccountChange(
              SubmissionObject.AccountAddress
            )}
            onSubmit={() => {
              props.handleAccountInformationSubmit();
            }}
            forPrequal={true}
            setNextStep={() => selectStep(getFirstIncompletedStep())}
            currentStep={getFirstIncompletedStep()}
          />
        );
    }
  };

  const showWizard = (): JSX.Element => {
    return (
      <>
        {shouldShowWizard() && (
          <WizardProgress
            stepProgresses={calculateWizardProgress(currentStep)}
            selectStep={selectStep}
          />
        )}
        {renderCurrentStep()}
      </>
    );
  };

  const shouldShowWizard = (): boolean =>
    currentStep !== WizardSteps.Overview &&
    currentStep !== WizardSteps.PrequalComplete &&
    currentStep !== WizardSteps.CustomerSearchResults;

  const showErrorPage = (): JSX.Element => {
    return (
      <ErrorPage
        mainMessage="Oops!"
        secondaryMessages={
          <Box>
            <Subheading>
              Someone else is already working on this application.
            </Subheading>
            <Subheading variant="section">
              Please select a different one.
            </Subheading>
          </Box>
        }
        buttonText="Back to the Queue"
        buttonIcon="list-ul"
        buttonIconIsLeading={true}
        onClick={() => {
          window.location.href = REACT_APP_SUBMISSION_QUEUE_URL();
        }}
      />
    );
  };

  const currentUserOwnsApplication = (): boolean => {
    const { loggedInUser, submission } = props;
    const noPrequalAnalyst = !submission.prequal_analyst_name;
    const currentUserMatchesOwner =
      submission.prequal_analyst_name ===
      userFullName(loggedInUser.first_name, loggedInUser.last_name);
    return noPrequalAnalyst || currentUserMatchesOwner;
  };

  return (
    <div>{currentUserOwnsApplication() ? showWizard() : showErrorPage()}</div>
  );
};
