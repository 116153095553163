import React, { useEffect, useState } from 'react';
import { FundingInfoSection } from './Sections/FundingInfoSection';
import { ApprovalInfoSection } from './Sections/ApprovalInfoSection';
import { CashFlowSection } from './Sections/CashFlowSection';
import { OwnerRiskSection } from './Sections/OwnerRiskSection';
import { UnderwritingClient } from '../../api/UnderwritingClient';
import {
  Banner,
  Box,
  Flex,
  Icon,
  Loading,
  PageContainer,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { FFLogger } from 'api/LogClient';
import { toError } from 'helpers/errorUtils';
import { useUserContext } from 'contexts/UserContext';
import {
  RenewalComparisonData,
  RenewalComparisonResponse,
} from 'types/api/underwriting/types';

export interface RenewalComparisonProps {
  opportunityUuid: string;
}

const pageTitle = 'Renewal Comparison';

const isFundingDataPresent = (
  data: RenewalComparisonResponse
): data is RenewalComparisonData<'funding_data' | 'summary_data'> =>
  'funding_data' in data.current;

const isCashFlowDataPresent = (
  data: RenewalComparisonResponse
): data is RenewalComparisonData<'cash_flow_data' | 'summary_data'> =>
  'cash_flow_data' in data.current;

const isApprovalDataPresent = (
  data: RenewalComparisonResponse
): data is RenewalComparisonData<'approval_data' | 'summary_data'> =>
  'approval_data' in data.current;

const isOwnerRiskDataPresent = (
  data: RenewalComparisonResponse
): data is RenewalComparisonData<'owner_risk_data' | 'summary_data'> =>
  'owner_risk_data' in data.current;

export const RenewalComparison = (
  props: RenewalComparisonProps
): JSX.Element => {
  const { opportunityUuid } = props;

  const [data, setData] = useState<RenewalComparisonResponse | undefined>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const { role: userRole } = useUserContext();

  useEffect(() => {
    document.title = pageTitle;
  }, []);

  useEffect(() => {
    const sections =
      userRole === 'processing'
        ? ['funding_data']
        : [
            'funding_data',
            'approval_data',
            'cash_flow_data',
            'owner_risk_data',
          ];

    const fetchData = async (): Promise<void> => {
      try {
        const response = await UnderwritingClient.fetchRenewalComparison(
          opportunityUuid,
          sections
        );

        setData(response);
      } catch (e: unknown) {
        const err = toError(e);
        FFLogger.error(err, {
          opportunityUuid,
        });
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [opportunityUuid, userRole]);

  const advanceOrAddOn = (column: 'previous_1' | 'previous_2'): string =>
    data && data[column]?.funding_data?.is_add_on ? 'Add On' : 'Advance';

  const getCompleteHeader = (column: 'previous_1' | 'previous_2'): string => {
    const opportunity = data?.[column];
    if (opportunity?.summary_data?.stage === 'Funded') {
      return `${advanceOrAddOn(column)} ${
        opportunity.funding_data?.advance_number
      }`;
    }
    return 'Unable to Fetch';
  };
  const sectionHeaders = {
    previous_1: getCompleteHeader('previous_1'),
    previous_2: data?.previous_2 ? getCompleteHeader('previous_2') : '',
  };

  if (loading) {
    return (
      <Box my={3}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Banner dismissable={false}>
          <Flex alignItems="center" gap={3}>
            <Icon name="exclamation-triangle" color="white" />
            <Text>{error}</Text>
          </Flex>
        </Banner>
      </Box>
    );
  }

  if (data) {
    return (
      <Box textAlign="center">
        <Subheading>{data.current.summary_data?.business_name}</Subheading>
        <Subheading variant="section">{pageTitle}</Subheading>
        {data.previous_1 === null ? (
          <Box>
            <Banner dismissable={false}>
              <Text>
                This opportunity does not appear to be a renewal. Renewal
                Comparison can only be used for Renewal deals.
              </Text>
            </Banner>
          </Box>
        ) : (
          <PageContainer>
            <Flex flexDirection="column" gap={4}>
              {isFundingDataPresent(data) && (
                <FundingInfoSection data={data} headers={sectionHeaders} />
              )}
              {isApprovalDataPresent(data) && userRole !== 'processing' && (
                <ApprovalInfoSection data={data} headers={sectionHeaders} />
              )}
              {isCashFlowDataPresent(data) && userRole !== 'processing' && (
                <CashFlowSection data={data} headers={sectionHeaders} />
              )}
              {isOwnerRiskDataPresent(data) && userRole !== 'processing' && (
                <OwnerRiskSection data={data} headers={sectionHeaders} />
              )}
            </Flex>
          </PageContainer>
        )}
      </Box>
    );
  }

  // we shouldn't ever hit this, but it's included to make TS happy
  return <></>;
};
