import {
  Banner,
  Box,
  Button,
  Card,
  Loading,
  Subheading,
  Tabs,
} from '@forward-financing/fast-forward';
import React, { useEffect } from 'react';
import { SubmissionOnboardingQueueTable } from './SubmissionOnboardingQueueTable';
import {
  useAssignUserNextApplication,
  useGetUsersByRole,
  useUpdateUnderwritingApplication,
} from './SubmissionOnboardingQueueHooks';
import { defaultTo } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';

export const SubmissionOnboardingQueueContainer = (): JSX.Element => {
  const urlSearch = new URLSearchParams(window.location.search);

  const {
    data: processingUsers,
    loading: usersLoading,
    error: usersError,
  } = useGetUsersByRole('processing');

  const user = useUserContext();
  const navigate = useNavigate();

  const [
    assignUser,
    {
      data: nextApplication,
      loading: nextApplicationLoading,
      error: nextApplicationError,
    },
  ] = useAssignUserNextApplication();

  const [updateUnderwritingApplication] = useUpdateUnderwritingApplication();

  useEffect(() => {
    if (nextApplication?.uuid) {
      /**
       * We need to clear the assigned user in Underwriting here to ensure that the Prequal
       * Form link can be used by the newly assigned user in the rare case that the Application in
       * Underwriting already has a different user assigned to it.
       */
      void updateUnderwritingApplication({
        applicationUuid: nextApplication.underwritingApplicationUuid,
        prequalAnalystName: '',
      });
      navigate(
        `/dashboard/submission-onboarding/applications/${nextApplication.uuid}`
      );
    }
  }, [
    nextApplication?.uuid,
    nextApplication?.underwritingApplicationUuid,
    navigate,
    updateUnderwritingApplication,
  ]);

  const handleClickGoToNextApplication = (): void => {
    void assignUser(user.uuid);
  };

  if (usersLoading) {
    return <Loading />;
  }

  return (
    <Box margin={4}>
      {usersError && (
        <Banner variant="error" dismissable={false}>
          {usersError.message}
        </Banner>
      )}
      {nextApplicationError && (
        <Banner variant="error" dismissable={false}>
          {nextApplicationError.message}
        </Banner>
      )}

      <Card
        title="Submission Onboarding Queue"
        actions={
          <>
            {nextApplicationLoading && <Loading size="small" />}
            {(user.sub_role === 'prequal' || user.sub_role === 'team_lead') && (
              <Button
                disabled={nextApplicationLoading}
                onClick={handleClickGoToNextApplication}
                variant="cta"
              >
                Go to Next Application
              </Button>
            )}
          </>
        }
      >
        <Tabs defaultValue={urlSearch.get('status') || 'available'}>
          <Tabs.List>
            <Tabs.Trigger value="available">Unprocessed</Tabs.Trigger>
            <Tabs.Trigger value="in_progress">In Progress</Tabs.Trigger>
            <Tabs.Trigger value="waiting_on_iso">Waiting on ISO</Tabs.Trigger>
            <Tabs.Trigger value="completed">Completed</Tabs.Trigger>
            <Tabs.Trigger value="junk">Junk</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="available">
            <Subheading>Unprocessed Submissions</Subheading>
            <SubmissionOnboardingQueueTable
              applicationStatus="available"
              users={defaultTo(processingUsers, [])}
            />
          </Tabs.Content>

          <Tabs.Content value="in_progress">
            <Subheading>In Progress Submissions</Subheading>
            <SubmissionOnboardingQueueTable
              applicationStatus="in_progress"
              users={defaultTo(processingUsers, [])}
            />
          </Tabs.Content>

          <Tabs.Content value="waiting_on_iso">
            <Subheading>Waiting on ISO Submissions</Subheading>
            <SubmissionOnboardingQueueTable
              applicationStatus="waiting_on_iso"
              users={defaultTo(processingUsers, [])}
            />
          </Tabs.Content>

          <Tabs.Content value="completed">
            <Subheading>Completed Submissions</Subheading>
            <SubmissionOnboardingQueueTable
              applicationStatus="completed"
              users={defaultTo(processingUsers, [])}
            />
          </Tabs.Content>

          <Tabs.Content value="junk">
            <Subheading>Junk Submissions</Subheading>
            <SubmissionOnboardingQueueTable
              applicationStatus="junk"
              users={defaultTo(processingUsers, [])}
            />
          </Tabs.Content>
        </Tabs>
      </Card>
    </Box>
  );
};
