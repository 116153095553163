import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Form,
  FormProps,
  Grid,
  IconButton,
  Modal,
  OptionShape,
  Select,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { defaultTo } from 'lodash';
import {
  IndustryPredictionResponse,
  RiskProfile,
  UpdateCustomerBody,
} from './businessOverview.types';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useCreateRelativity6Artifacts } from './businessOverviewHooks';

export type EditIndustryModalProps = {
  industry: string;
  options: OptionShape[];
  mostConfidentIndustry?: IndustryPredictionResponse;

  naicsConfidenceScore?: string;
  setIndustry: (industry: string) => void;
  submissionUuid: string;
  suggestedIndustry?: RiskProfile;
  updateIndustry: (industry: UpdateCustomerBody) => Promise<MutationResponse>;
  updatedIndustryError?: Error;
  updatedIndustryLoading?: boolean;
};

export const IndustryEditModal = ({
  industry,
  mostConfidentIndustry,
  naicsConfidenceScore,
  options,
  setIndustry,
  submissionUuid,
  suggestedIndustry,
  updateIndustry,
  updatedIndustryError,
  updatedIndustryLoading,
}: EditIndustryModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasVerifiedIndustry, setHasVerifiedIndustry] = useState<boolean>(true);
  const [newIndustry, setNewIndustry] = useState<string>(industry);

  const [industryEditing, setIndustryEditing] = useState(false);
  const [industryError, setIndustryError] = useState('');
  const [industryCanceled, setIndustryCanceled] = useState(false);

  const [updatingIndustry, setUpdatingIndustry] = useState(false);

  const [createArtifacts] = useCreateRelativity6Artifacts();

  const hasUpdatedIndustry = industry !== newIndustry;

  // Show errors.
  useEffect(() => {
    // 1. Don't show the error if we aren't editing (modal isn't open).
    // 2. Don't show the error if we previously canceled the changes. This one
    // is an edge case where we submit and get an error, and then cancel the
    // changes. Canceling closes the modal, and we don't want the same error to
    // appear again after re-opening.
    if (industryEditing && updatedIndustryError && !industryCanceled) {
      setIndustryError(updatedIndustryError.message);
    }
  }, [industryEditing, updatedIndustryError, industryCanceled]);

  const handleOpenChange = (): void => {
    // Reset any errors when opening the modal.
    if (!isModalOpen) {
      setIndustryError('');
      setIndustryEditing(true);
    }

    // Changes canceled.
    if (isModalOpen) {
      setNewIndustry(industry);
      setHasVerifiedIndustry(true);
      setIndustryError('');
      setIndustryEditing(false);
      setIndustryCanceled(true);
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit: FormProps['onSubmit'] = async (_sourceInfo) => {
    if (!newIndustry) {
      setIndustryError('Please select an industry.');
      return;
    }

    if (!hasVerifiedIndustry) {
      setIndustryError('Please verify the industry classification.');
      return;
    }

    // No need to fire request if the industry hasn't changed.
    if (!hasUpdatedIndustry) {
      setIsModalOpen(false);
      return;
    }

    setUpdatingIndustry(true);
    setIndustryCanceled(false);

    const { success } = await updateIndustry({
      industryId: Number(newIndustry),
    });

    setUpdatingIndustry(false);

    if (success) {
      // This updates the value in the parent component.
      setIndustry(newIndustry);

      // Only create an artifact if the selected industry
      // does not match the suggested industry.
      if (suggestedIndustry?.id?.toString() !== newIndustry) {
        await createArtifacts({
          submissionUuid,
          artifactData: {
            predictedIndustry: defaultTo(suggestedIndustry?.id?.toString(), ''),
            assignedIndustry: defaultTo(newIndustry, ''),
            confidenceScore: defaultTo(
              mostConfidentIndustry?.confidenceScore?.toString(),
              ''
            ),
            naicsCode: defaultTo(mostConfidentIndustry?.naicsCode, ''),
            naicsVersion: '2017',
            artifactCase: 'Predicted Industry',
            artifactType: 'relativity',
            naicsCodeTitle: defaultTo(mostConfidentIndustry?.industryName, ''),
          },
        });
      }

      setIsModalOpen(false);
      setIndustryEditing(false);
    }
  };

  return (
    <Modal
      trigger={
        <IconButton icon="pencil-alt" hiddenLabel label="Edit Industry" />
      }
      isOpen={isModalOpen}
      title="Edit Industry"
      onOpenChange={handleOpenChange}
      body={
        <Box>
          {industryError && (
            <Banner dismissable={false}>{industryError}</Banner>
          )}

          <Box
            my={3}
            px={3}
            pb={2}
            borderColor="blue-900"
            rounded
            data-testid="industry-classification-section"
          >
            <Flex mt={2} textAlign={'left'} flexDirection={'column'}>
              <Subheading variant="section">Industry Classification</Subheading>
              <Box mb={3}>
                <Divider />
              </Box>
            </Flex>
            <Grid gutter>
              <Grid.Item s={12} m={6} l={6}>
                <Flex flexDirection={'column'} alignItems={'flex-start'} mb={2}>
                  <Text bold>NAICS Industry</Text>
                  <Text>
                    {defaultTo(mostConfidentIndustry?.industryName, 'N/A')}
                  </Text>
                </Flex>

                <Flex flexDirection={'column'} alignItems={'flex-start'} mb={2}>
                  <Text bold>Suggested Industry</Text>
                  <Text>{defaultTo(suggestedIndustry?.name, 'N/A')}</Text>
                </Flex>
              </Grid.Item>
              <Grid.Item s={12} m={6} l={6}>
                <Flex flexDirection={'column'} alignItems={'flex-start'} mb={2}>
                  <Text bold>NAICS Confidence Score</Text>
                  <Text>{defaultTo(naicsConfidenceScore, 'N/A')}</Text>
                </Flex>

                <Flex flexDirection={'column'} alignItems={'flex-start'} mb={2}>
                  <Text bold>NAICS Code</Text>
                  <Text>
                    {defaultTo(mostConfidentIndustry?.naicsCode, 'N/A')}
                  </Text>
                </Flex>
              </Grid.Item>
            </Grid>
          </Box>

          <Form
            accessibleName="Edit Industry"
            onSubmit={handleSubmit}
            allowImplicitSubmission={true}
          >
            {({ fireSubmit }) => (
              <Flex flexDirection="column" gap={3}>
                <Select
                  label="Industry"
                  options={options}
                  value={newIndustry}
                  onValueChange={(value) => {
                    setNewIndustry(value);
                    setHasVerifiedIndustry(false);
                  }}
                />

                <Checkbox
                  label="Please check to verify that you have reviewed the industry classification before proceeding."
                  checked={hasVerifiedIndustry}
                  onCheckboxChange={() =>
                    setHasVerifiedIndustry(!hasVerifiedIndustry)
                  }
                  required={!hasVerifiedIndustry}
                />

                <Box marginY={2}>
                  <Button
                    onClick={fireSubmit}
                    disabled={updatingIndustry || updatedIndustryLoading}
                  >
                    Submit
                  </Button>
                </Box>
              </Flex>
            )}
          </Form>
        </Box>
      }
    />
  );
};
