import React from 'react';
import {
  Banner,
  Loading,
  Sheet,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';

import { noop } from 'lodash';
import { Ledger, LedgerOffer } from './ledger.types';
import { useLedgerOffers } from './ledgerHooks';

type OffersTableProps = {
  ledger: Ledger;
  submissionUuid: string;
};

/**
 * Amount of month starting from ledger.created_at date.
 * For each item ColumnHeader will be generated and rendered in the table.
 */
const MONTHS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/**
 * Name for each month idx. 0-based
 */
const IDX_MONTH_NAME_MAP = {
  '0': 'Jan',
  '1': 'Feb',
  '2': 'Mar',
  '3': 'Apr',
  '4': 'May',
  '5': 'Jun',
  '6': 'Jul',
  '7': 'Aug',
  '8': 'Sep',
  '9': 'Oct',
  '10': 'Nov',
  '11': 'Dec',
};

const generateColumnHeaders = (creationDateString: string): string[] => {
  const creationDate = new Date(creationDateString);

  return MONTHS.reduce((result, current) => {
    const monthIdx = new Date(
      creationDate.setMonth(creationDate.getMonth() + current)
    )
      .getMonth()
      .toString() as keyof typeof IDX_MONTH_NAME_MAP;

    result.push(`${current} - ${IDX_MONTH_NAME_MAP[monthIdx]}`);

    return result;
  }, [] as string[]);
};

type OfferCellProps = {
  data: LedgerOffer[];
  fieldName: keyof LedgerOffer;
  months: number;
  isEditable?: boolean;
};

const OfferCell = ({
  data,
  fieldName,
  months,
  isEditable,
}: OfferCellProps): JSX.Element | null => {
  const offer = data.find((offersItem) => offersItem.months === months);

  if (isEditable) {
    let value = offer && offer[fieldName] ? String(offer[fieldName]) : '';

    if (fieldName === 'dollarOverride' && !!value) {
      value = `$${value}`;
    }

    if (fieldName === 'gross' && !!value) {
      value = `${value}%`;
    }

    return (
      <Sheet.TextCell
        value={value}
        onValueChange={(_val) => {
          noop();
        }}
      />
    );
  }

  return <Table.Cell>{offer ? offer[fieldName] : ''}</Table.Cell>;
};

export const OffersTable = ({
  ledger: { createdAt, id },
  submissionUuid,
}: OffersTableProps): JSX.Element => {
  const { data, error, loading } = useLedgerOffers({
    submissionUuid,
    ledgerId: id,
  });
  const generatedColumnNames = generateColumnHeaders(createdAt);

  return (
    <>
      <Subheading>Offer</Subheading>
      {loading && <Loading />}
      {error && <Banner>{error.message}</Banner>}
      {data && (
        <Table>
          <Table.Head>
            <Table.ColumnHeader>Term length (Months)</Table.ColumnHeader>
            {generatedColumnNames.map((header) => (
              <Table.ColumnHeader key={header}>{header}</Table.ColumnHeader>
            ))}
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.RowHeader>Gross $</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="dollarOverride"
                  months={months}
                  key={months}
                  isEditable
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Gross %</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="gross"
                  months={months}
                  key={months}
                  isEditable
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Approval Amount</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="approvalAmount"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Multiple of ADB</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="multipleOfAdb"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Payback Amount</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="paybackAmount"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Daily Payment</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="dailyPayment"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>FF% of Gross</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="paybackAmountGross"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
            <Table.Row>
              <Table.RowHeader>Total Gross</Table.RowHeader>
              {MONTHS.map((months) => (
                <OfferCell
                  data={data}
                  fieldName="combinedGross"
                  months={months}
                  key={months}
                />
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  );
};
