import { Box, Heading } from '@forward-financing/fast-forward';

import React from 'react';
import { useParams } from 'react-router-dom';
import { BankingStipulationManager } from './BankingStipulationManager';

type Params = {
  submissionUuid: string;
};
export const BankingStipulationManagerRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();

  return (
    <Box p={4} m={4} borderColor="blue-900" rounded>
      <Heading>Banking Stipulation Manager</Heading>

      {submissionUuid && (
        <BankingStipulationManager submissionUuid={submissionUuid} />
      )}
    </Box>
  );
};
