import React from 'react';
import {
  Accordion,
  Box,
  formatDateString,
  Table,
} from '@forward-financing/fast-forward';
import { FFLogger } from '../../api/LogClient';
import { toError } from '../../helpers/errorUtils';
import {
  InquiriesInterface,
  InquiriesTableInterface,
} from './codecs/Inquiries.codec';

export const countInquiries = (data: InquiriesInterface): number => {
  return (
    Object.values(data.totals_by_month || {}).reduce(
      (sum, val) => sum + val,
      0
    ) || 0
  );
};

function getInquiriesData(data: InquiriesInterface): InquiriesTableInterface[] {
  try {
    let calendar;
    let monthDay;
    let dateStrings;
    let number;

    const categories = Object.keys(data.totals_by_month_and_category);

    const tableData = categories.map((category) => {
      // datestring yyyy-MM-dd - ex. 2000-01-01
      // number                - ex. 5
      // calendar ex. [{ business_category: 'ABC', 'Feb 01': 0 }, { 'Mar 01': 1 }, ... ]
      dateStrings = Object.keys(data.totals_by_month_and_category[category]);
      calendar = dateStrings.reduce((acc, dateString) => {
        monthDay = formatDateString(dateString);
        number = data.totals_by_month_and_category[category][dateString];

        return {
          ...acc,
          [monthDay]: number,
        };
      }, {});

      return {
        business_category: category,
        ...calendar,
      };
    });

    return tableData;
  } catch (caughtValue: unknown) {
    const error = toError(caughtValue);
    FFLogger.warn(error.message);
    return [];
  }
}

export interface InquiriesProps {
  data: InquiriesInterface;
}

export const Inquiries = ({ data }: InquiriesProps): JSX.Element => {
  // This table is unique because the data displayed in the table determines the headers keys.
  // Notice the headers keys are dates, these dates will come from the data displayed in the table.
  const tableData = getInquiriesData(data);
  const dateStrings: string[] =
    tableData.length > 0 ? Object.keys(tableData[0]).slice(1) : [];

  return (
    <Box>
      <Accordion type="single" defaultValue="summary-of-inquiries" collapsible>
        <Accordion.Item value="summary-of-inquiries">
          <Accordion.Trigger>Summary of Inquiries</Accordion.Trigger>
          <Accordion.Content>
            <Table>
              <Table.Head>
                <Table.ColumnHeader>Business Category</Table.ColumnHeader>
                {dateStrings.map((date) => {
                  return (
                    <Table.ColumnHeader key={date}>{date}</Table.ColumnHeader>
                  );
                })}
              </Table.Head>
              <Table.Body>
                {tableData.map((row) => {
                  return (
                    <Table.Row key={row.business_category}>
                      <Table.Cell>{row.business_category}</Table.Cell>
                      {dateStrings.map((date) => {
                        return (
                          <Table.Cell
                            key={`${row.business_category}-${row[date]}`}
                          >
                            {row[date]}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
                <Table.SummaryRow>
                  <Table.Cell>Totals</Table.Cell>
                  {dateStrings.map((date) => {
                    return (
                      <Table.Cell key={`${date}-total`}>
                        {tableData.reduce(
                          (acc, elem) => acc + (elem[date] as number),
                          0
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.SummaryRow>
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
