import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useApiGoToNextDeal } from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { EnqueuedDealResponse } from 'types/api/underwriting/types';

export interface EnqueuedDeal {
  submissionUuid: string;
  nextDealUrl: string;
}

type UseNextDealResult = [
  () => Promise<MutationResponse>,
  {
    nextDealData?: EnqueuedDeal;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

const toNextDeal = (
  response?: EnqueuedDealResponse
): EnqueuedDeal | undefined => {
  if (!response) {
    return undefined;
  }

  return {
    submissionUuid: response?.submission_uuid,
    nextDealUrl: response?.underwriting_app_url,
  };
};

export const useGoToNextDeal = (): UseNextDealResult => {
  const [goToNextDeal, { data, error, loading, responseReady }] =
    useApiGoToNextDeal();

  const updateFunction = async (): Promise<MutationResponse> => {
    return await goToNextDeal();
  };

  useLogError(error);

  return [
    updateFunction,
    { nextDealData: toNextDeal(data), error, loading, responseReady },
  ];
};
