import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { THIRD_PARTY_INTEGRATIONS_BASE_URL } from 'constants/globals';
import { featureFlags } from 'helpers/featureFlags';
import {
  ExperianConsumerArchivesResponse,
  ExperianConsumerCreditReportResponse,
  ExperianConsumerResponseV2,
  ConsumerCreditEntities,
} from 'types/api/3pi/types';

// CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
// PRODUCTION.
// Uncomment these lines as needed for mock data.
// import {
//   mockExperianConsumer,
//   mockConsumerCredits,
//   mockExperianConsumerArchives,
//   mockExperianConsumerCreditReport,
// } from 'mocks/3pi/generators/ApiV2ExperianConsumer';

const threePi = (path: string): URL =>
  new URL(path, THIRD_PARTY_INTEGRATIONS_BASE_URL());

export const fetchExperianConsumerV2 = async (
  submissionUuid: string,
  ownerUuid: string,
  entities?: ConsumerCreditEntities[]
): Promise<ExperianConsumerResponseV2> => {
  const url = threePi(
    `/api/v2/experian/consumer/${submissionUuid}/owners/${ownerUuid}${
      entities?.length ? `?entities=${entities.join(',')}` : ''
    }`
  );

  const response = await makeInternalAPIRequest<ExperianConsumerResponseV2>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Report'
    );
  }

  return response.json();
};

export const fetchExperianConsumer = async (
  submissionUuid: string,
  ownerUuid: string
): Promise<ExperianConsumerResponseV2> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(
  //       mockExperianConsumer({
  //         consumer_credits: [mockConsumerCredits({ risk_models: [] })],
  //       })
  //     );
  //   }, 500);
  // });

  const version = featureFlags.experian_consumer_3pi_v2_endpoints ? 'v2' : 'v1';

  const url = threePi(
    `api/${version}/experian/consumer/${submissionUuid}/owners/${ownerUuid}`
  );

  const response = await makeInternalAPIRequest<ExperianConsumerResponseV2>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Report'
    );
  }

  return response.json();
};

export const fetchExperianConsumerArchives = async (
  ownerUuid: string
): Promise<ExperianConsumerArchivesResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(mockExperianConsumerArchives());
  //   }, 10);
  // });

  // Don't fetch from 3pi if the feature flag is disabled.
  if (!featureFlags.experian_consumer_3pi_archive) {
    return { consumer_credits: [] };
  }

  const version = featureFlags.experian_consumer_3pi_v2_endpoints ? 'v2' : 'v1';

  const url = threePi(
    `api/${version}/experian/consumer_credit?ownerUUIDs=${ownerUuid}`
  );

  const response =
    await makeInternalAPIRequest<ExperianConsumerArchivesResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Report Archives for Owner'
    );
  }

  return response.json();
};

export const fetchExperianConsumerCreditReport = async (
  referenceUuid: string
): Promise<ExperianConsumerCreditReportResponse> => {
  // CAUTION: LOCAL TESTING ONLY. DO NOT MERGE UNCOMMENTED TEST CODE TO
  // PRODUCTION.
  // Uncomment these lines for mock data, and comment out the code below it.
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(mockExperianConsumerCreditReport());
  //   }, 100);
  // });

  if (!featureFlags.experian_consumer_3pi_detailed_page) {
    return { consumer_credit: null, success: true };
  }

  const version = featureFlags.experian_consumer_3pi_v2_endpoints ? 'v2' : 'v1';

  const url = threePi(
    `api/${version}/experian/consumer_credit/${referenceUuid}`
  );

  const response =
    await makeInternalAPIRequest<ExperianConsumerCreditReportResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch Experian Consumer Credit Report'
    );
  }

  return response.json();
};
