import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreateRelativity6ArtifactsRequestBody,
  IndustryNaicsResponse,
} from 'types/api/underwriting/types';

export const createRelativity6ArtifactsSubmission = async (
  submissionUuid: string,
  body: CreateRelativity6ArtifactsRequestBody
): Promise<MutationResponse> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/predicted_industry`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    CreateRelativity6ArtifactsRequestBody
  >(url, 'POST', body);

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(
    response.status,
    'Failed to create Relativity6 artifacts for Submission'
  );
};

export const createRelativity6ArtifactsApplication = async (
  applicationUuid: string,
  body: CreateRelativity6ArtifactsRequestBody
): Promise<MutationResponse> => {
  const url = new URL(
    `api/v2/applications/${applicationUuid}/predicted_industry`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<
    void,
    CreateRelativity6ArtifactsRequestBody
  >(url, 'POST', body);

  if (response.ok) {
    return { success: true };
  }

  throw new NetworkError(
    response.status,
    'Failed to create Relativity6 artifacts for Application'
  );
};

// For context on the naming convention, "IndustryNaics" is the model's name in UA.
// 2022 NAICS version is still in beta for Relativity6, so we default to 2017
export const fetchIndustryNaicsIndustryId = async (
  naicsCode: string,
  naicsVersion: '2017' | '2022' = '2017'
): Promise<IndustryNaicsResponse> => {
  const url = new URL(
    `api/v2/industry_naics/${naicsCode}?version=${naicsVersion}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<IndustryNaicsResponse>(
    url,
    'GET'
  );

  if (response.ok) {
    return response.json();
  }

  throw new NetworkError(
    response.status,
    'Failed to fetch IndustryNaics industry ID'
  );
};
