import { useLogError } from 'apiHooks/useLogError';
import {
  UseDeleteStipulationArgs,
  useDeleteApiStipulation,
  useGetApiSubmission,
  useUpdateApiStipulationBatch,
} from 'apiHooks/underwriting/submissionFetchHooks';

import {
  UseGenericMutationResult,
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  BankingStipulation,
  Submission,
  UpdateBankingStipulationInput,
} from './BankingStipulation.types';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { SubmissionResponse } from 'types/api/underwriting/types';

// Update Stipulation Batch
type UseUpdateBankingStipulationBatchResult = [
  (args: UpdateBankingStipulationInput[]) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<BankingStipulation[]>, 'responseReady'>
];

export const useUpdateBankingStipulation = (
  submissionUuid: string
): UseUpdateBankingStipulationBatchResult => {
  const [updateFn, { error, loading }] = useUpdateApiStipulationBatch();

  const updateFunction = (
    input: UpdateBankingStipulationInput[]
  ): Promise<MutationResponse> => {
    return updateFn(
      input.map((stip) => {
        return {
          stipulationUuid: stip.uuid,
          submissionUuid: submissionUuid,
          body: {
            name: `${stip.name}${
              stip.otherDescription ? ` - ${stip.otherDescription}` : ''
            }`,
            notes: stip.notes,
            reasons: stip.reasons,
          },
        };
      })
    );
  };

  useLogError(error);

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};

type UseDeleteBankingStipulationResult = [
  (input: UseDeleteStipulationArgs) => Promise<MutationResponse>,
  Omit<UseGenericMutationResult<BankingStipulation[]>, 'responseReady'>
];

export const useDeleteBankingStipulation =
  (): UseDeleteBankingStipulationResult => {
    const [deleteFn, { error, loading }] = useDeleteApiStipulation();

    useLogError(error);

    return [
      deleteFn,
      {
        error,
        loading,
      },
    ];
  };
const toSubmission = (sub?: SubmissionResponse): Submission | undefined => {
  if (!sub) {
    return undefined;
  }
  return {
    stageName: sub.stage_name,
    subStage: sub.sub_stage ?? undefined,
  };
};

type UseSubmissionResult = UseGenericQueryResponse<Submission>;

export const useGetSubmission = (
  submissionUuid?: string
): UseSubmissionResult =>
  useGenericFeatureQuery(useGetApiSubmission, toSubmission, submissionUuid);
