import {
  Box,
  Currency,
  Flex,
  Link,
  Subheading,
  Table,
  Text,
} from '@forward-financing/fast-forward';
import React from 'react';
import { CustomerLexisNexisOverview } from './businessOverview.types';
import { UNDERWRITING_BASE_URL } from 'constants/globals';

export interface PublicRecordsCountTableProps {
  lexisNexisOverview?: CustomerLexisNexisOverview;
}

interface InnerTableProps {
  overview: CustomerLexisNexisOverview;
}

/**
 * We're creating an inner table component purely for organizational
 * and readability purposes.
 *
 * It's not as nice to look at a big ternary, and extracting this component
 * allows us to treat it as always defined, which also allows us to make
 * things a bit easier to parse. That said, creating a whole extra file
 * seems like overkill.
 */
const InnerTable = ({ overview }: InnerTableProps): JSX.Element => {
  return (
    <Table caption="Public Records Overview">
      <Table.Head>
        <Table.ColumnHeader />
        <Table.ColumnHeader textAlign="right">Open</Table.ColumnHeader>
        <Table.ColumnHeader textAlign="right">Closed</Table.ColumnHeader>
        <Table.ColumnHeader textAlign="right">Total</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>BK #</Table.RowHeader>
          <Table.Cell textAlign="right">
            {overview.openBankruptcyCount}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {overview.closedBankruptcyCount}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {overview.totalBankruptcyCount}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Liens #</Table.RowHeader>
          <Table.Cell textAlign="right">{overview.openLienCount}</Table.Cell>
          <Table.Cell textAlign="right">{overview.closedLienCount}</Table.Cell>
          <Table.Cell textAlign="right">{overview.totalLienCount}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Liens $</Table.RowHeader>
          <Table.Cell textAlign="right">
            <Currency amount={overview.openLienAmount} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Currency amount={overview.closedLienAmount} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Currency amount={overview.totalLienAmount} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Judgment #</Table.RowHeader>
          <Table.Cell textAlign="right">
            {overview.openJudgmentCount}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {overview.closedJudgmentCount}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {overview.totalJudgmentCount}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.RowHeader>Judgment $</Table.RowHeader>
          <Table.Cell textAlign="right">
            <Currency amount={overview.openJudgmentAmount} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Currency amount={overview.closedJudgmentAmount} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Currency amount={overview.totalJudgmentAmount} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export const PublicRecordsCountTable = (
  props: PublicRecordsCountTableProps
): JSX.Element => {
  return (
    <Box>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Public Records</Subheading>
        {props.lexisNexisOverview ? (
          <Link
            newTab
            href={
              new URL(
                `admin/documents/${props.lexisNexisOverview.documentId}`,
                UNDERWRITING_BASE_URL()
              )
            }
          >
            View Report
          </Link>
        ) : (
          <Text>Report Not Available</Text>
        )}
      </Flex>
      {props.lexisNexisOverview ? (
        <InnerTable overview={props.lexisNexisOverview} />
      ) : (
        <Text>No Lexis Nexis Overview found</Text>
      )}
    </Box>
  );
};
