import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import {
  CreditDataResponse,
  InquiryOverview,
  LegacyReportLinksResponse,
  ReportSearchLinkResponse,
  CustomerOwnerResponse,
  LexisNexisOwnerSearchResultsResponse,
} from 'types/api/underwriting/types';
import { BasicOwnerInfoTableFragmentFragment } from '__generated__/graphql';
import {
  CreditData,
  CreditReport,
  TradelinesOverview,
  ReportSearchLink,
  LegacyReportLinks,
  LexisNexisSearchResult,
  OwnerCreditInquiry,
} from './types';

// CAUTION - LOCAL DEV ENV ONLY: Uncomment these lines as needed for mock data.
// import { mockLexisNexisOwnerReportDocument } from 'mocks/underwriting/generators/ApiV2SubmissionsUuidDocumentsLexisNexisOwnerReports';
// import { mockLexisNexisResponse } from 'mocks/underwriting/generators/ApiV2SubmissionsUuidLexisNexisOwners';
// import { mockCreditDataResponse } from 'mocks/underwriting/generators/ApiV2SubmissionUuidCreditOverviews';

/**
 * This function is...bad. Very bad. I don't like doing it.
 *
 * But it's also pretty short lived, in theory.
 *
 * We're making multiple network requests here to mimic what the graphql
 * schema is doing, which is passing work phone down to the owner, despite
 * the fact that it lives on the Customer model.
 *
 * This way, we can blindly pass the result of this into the component that
 * will soon be using GraphQL, and can feature flag between them without risk.
 *
 * @param customerUuid
 * @returns
 */
export const fetchSubmissionOwners = async (
  submissionUuid: string
): Promise<BasicOwnerInfoTableFragmentFragment[]> => {
  const ownersUrl = new URL(
    `/api/v2/submissions/${submissionUuid}/owners`,
    UNDERWRITING_BASE_URL()
  );

  const ownersResponse = await makeInternalAPIRequest<CustomerOwnerResponse[]>(
    ownersUrl,
    'GET'
  );

  if (!ownersResponse.ok) {
    throw new NetworkError(ownersResponse.status, 'Failed to fetch Owners');
  }

  const owners = await ownersResponse.json();

  return owners.map((o) => ({
    id: o.uuid,
    fullName: `${o.first_name} ${o.last_name}`,
    ssn: o.ssn,
    ssnLastFour: o.ssn?.slice(-4),
    birthdate: o.born_on,
    homePhone: o.home_phone,
    workPhone: '', // Will be removed once we can properly refactor this type
    cellPhone: o.cell_phone,
    email: o.email,
    ownershipDate: o.ownership_date,
    ownershipPercentage: o.ownership_percentage
      ? Number(o.ownership_percentage)
      : undefined,
    address: o.address ?? {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
    },
  }));
};

export const toCreditReport = (response: CreditDataResponse): CreditReport => {
  return {
    id: response.id,
    ownerUuid: response.owner_uuid,
    fico: response.fico_score ?? undefined,
    bkPlus: response.bankruptcy_score,
    vantageScore: response.vantage_score,
    totalUnsecuredBalance: response.total_unsecured_balance,
    totalDebtToHighCredit: response.revolving_utilized_percent,
    totalTradeLines: response.trade_lines_count,
    totalSatisfactoryTradeLines: response.satisfactory_accounts_count,
    totalDelinquentTradeLines: response.now_delinquent_derog_count,
    newDelinquentTradeLines: response.derog_count,
    numberAccountsCurrentlyPayingOnTime: response.active_paid_accounts_count,
    publicRecordsCount: response.public_records_count,
    oldestTradeLine: response.oldest_open_trade_line,
    createdAt: response.created_at,
    creditReportExperianPath: response.credit_report_experian_path,
    experianArchivePath: response.experian_archive_path,
    tradeLinesPath: response.trade_lines_path,
    experianReportDate: response.experian_report_date,
    mismatch: response.mismatch
      ? response.mismatch.map((mismatch) => {
          return {
            field: mismatch.field,
            oldValue: mismatch.old_value,
            newValue: mismatch.new_value,
          };
        })
      : [],
    note: response.note ?? undefined,
  };
};

const toTradelineOverview = (
  response: CreditDataResponse
): TradelinesOverview => {
  return {
    ownerUuid: response.owner_uuid,
    current30Days: response.current_delinquencies_over_thirty_days_count,
    current60Days: response.current_delinquencies_over_sixty_days_count,
    current90Days: response.current_delinquencies_over_ninety_days_count,
    currentDerog: response.current_derog_count,
    allTime30Days: response.delinquencies_over_thirty_days_count,
    allTime60Days: response.delinquencies_over_sixty_days_count,
    allTime90Days: response.delinquencies_over_ninety_days_count,
    allTimeDerog: response.now_delinquent_derog_count,
  };
};

// this returns data for tradelines count tables as well as credit overview
export const fetchCreditDataForOwners = async (
  submissionUuid: string
): Promise<CreditData> => {
  // CAUTION - LOCAL DEV ENV ONLY: Uncomment these lines as needed for mock data.
  // const response = [
  //   mockCreditDataResponse({
  //     owner_uuid: '4c2b5e26-01b6-402b-9713-d527fb7b818b',
  //     fico_score: undefined,
  //   }),
  // ];
  // const credit: CreditReport[] = response.map(toCreditReport);
  // const tradelines: TradelinesOverview[] = response.map(toTradelineOverview);
  // return Promise.resolve({
  //   creditReports: credit,
  //   tradelinesOverviews: tradelines,
  // });

  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/credit_overviews`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<CreditDataResponse[]>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch CreditOverviews');
  }

  const responseJson = await response.json();

  const credit: CreditReport[] = responseJson.map(toCreditReport);

  const tradelines: TradelinesOverview[] =
    responseJson.map(toTradelineOverview);

  return {
    creditReports: credit,
    tradelinesOverviews: tradelines,
  };
};

// this returns data for inquiries overview
export const fetchInquiriesOverviewForOwners = async (
  submissionUuid: string
): Promise<InquiryOverview[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/inquiries`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<InquiryOverview[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to fetch InquiriesOverview'
    );
  }

  const responseJson = await response.json();

  return responseJson;
};

const toLexisNexisSearchResults = (
  results: LexisNexisOwnerSearchResultsResponse
): LexisNexisSearchResult[] => {
  if (!results.documents) {
    return [];
  }
  return results.documents
    .filter((document) => document.results)
    .map((document) => {
      return {
        ownerUuid: document.owner_uuid,
        results: document.results.map((result) => {
          return {
            fullName: result.full_name,
            ssn: result.ssn,
            dateOfBirth: result.date_of_birth?.map((dob) => {
              return {
                month: dob.month,
                year: dob.year,
              };
            }),
            addresses: result.addresses.map((address) => {
              return {
                city: address.city,
                zip: address.zip5,
                state: address.state,
                streetAddress: `${address.street_number || ''} ${
                  address.street_pre_direction || ''
                } ${address.street_name || ''} ${address.street_suffix || ''}`,
                unitNumber: address.unit_number,
                unitDesignation: address.unit_designation,
              };
            }),
            reportIdentifier: result.report_identifier,
          };
        }),
        mismatch: document.mismatch
          ? document.mismatch.map((mismatch) => {
              return {
                field: mismatch.field,
                oldValue: mismatch.old_value,
                newValue: mismatch.new_value,
              };
            })
          : [],
      };
    });
};

export const updateDocument = async (
  documentId: number,
  primary: boolean
): Promise<boolean> => {
  const url = new URL(
    `/api/v2/documents/${documentId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'PATCH',
    {
      primary: primary,
    }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to update document');
  }

  return true;
};

export const deleteDocument = async (documentId: number): Promise<boolean> => {
  const url = new URL(
    `/api/v2/documents/${documentId}`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'DELETE'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to delete document');
  }

  return true;
};

export const pullLexisNexis = async (
  submissionUuid: string,
  ownerUuid: string,
  force: boolean,
  reportId?: string
): Promise<boolean> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/lexis_nexis_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<{ success: boolean }>(
    url,
    'POST',
    {
      force: force,
      report_id: reportId,
    }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull Lexis Nexis');
  }

  return true;
};

export const fetchLexisNexisSearchResults = async (
  submissionUuid: string
): Promise<LexisNexisSearchResult[]> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/documents/lexis_nexis_owner_search_results`,
    UNDERWRITING_BASE_URL()
  );

  const response =
    await makeInternalAPIRequest<LexisNexisOwnerSearchResultsResponse>(
      url,
      'GET'
    );

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to pull Lexis Nexis search results'
    );
  }

  const result: LexisNexisOwnerSearchResultsResponse = await response.json();
  return toLexisNexisSearchResults(result);
};

type PullCredit = {
  experian_owner_report: {
    force: boolean;
  };
};
export const pullCreditForOwners = async (
  submissionUuid: string,
  ownerUuid: string,
  force: boolean
): Promise<void> => {
  const url = new URL(
    `api/v2/submissions/${submissionUuid}/owners/${ownerUuid}/experian_owner_reports`,
    UNDERWRITING_BASE_URL()
  );

  const body = {
    experian_owner_report: {
      force: force,
    },
  };

  const response = await makeInternalAPIRequest<void, PullCredit>(
    url,
    'POST',
    body
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull Experian');
  }
};

const reportSearchLinkResponseToReportSearchLink = (
  data: ReportSearchLinkResponse
): ReportSearchLink => ({
  entityUuid: data.entity_uuid,
  manualReportSearchLink: data.manual_report_search_link,
});

const legacyReportLinksResponseToLegacyReportLinks = (
  response: LegacyReportLinksResponse
): LegacyReportLinks => ({
  ownerManualReportSearchLinks: response.owner_manual_report_search_links.map(
    reportSearchLinkResponseToReportSearchLink
  ),
  customerManualReportSearchLink: reportSearchLinkResponseToReportSearchLink(
    response.customer_manual_report_search_link
  ),
  paynetReportLinks: {
    reportPulledAt: response.paynet_report_links?.primary_report_pulled_at,
    reportLink: response.paynet_report_links?.primary_report_link,
    archiveLink: response.paynet_report_links?.archive_link,
    reportCount: response.paynet_report_links?.paynet_reports_count ?? 0,
  },
  dataMerchLink: response.data_merch_link,
});
export const fetchLegacyReportLinks = async (
  submissionUuid: string
): Promise<LegacyReportLinks> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/legacy_report_links`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<LegacyReportLinksResponse>(
    url,
    'GET'
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Report Links');
  }

  const responseJson = await response.json();

  return legacyReportLinksResponseToLegacyReportLinks(responseJson);
};

export const fetchOwnerCreditInquiries = async (
  submissionUuid: string
): Promise<OwnerCreditInquiry[]> => {
  const url = new URL(
    `/api/v2/submissions/${submissionUuid}/inquiries`,
    UNDERWRITING_BASE_URL()
  );

  const response = await makeInternalAPIRequest<InquiryOverview[]>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch Owner Inquiries');
  }

  const responseJson = await response.json();

  return responseJson.map((inquiry) => ({
    id: inquiry.id,
    ownerUuid: inquiry.owner_uuid,
    inquiredOn: inquiry.inquired_on,
    subscriber: inquiry.subscriber,
    inquiryType: inquiry.translated_inquiry_type_code,
    mcaLenderName: inquiry.mca_lender_name ?? undefined,
  }));
};
