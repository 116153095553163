import React, { useEffect } from 'react';
import { Banner, Flex, Icon } from '@forward-financing/fast-forward';
import { useUpdateSubmission } from 'components/Navbar/SubmissionNavbar/navbarFetchHooks';
import { useGoToNextDeal } from 'components/SubmissionUnderwriting/ApplicationSnapshot/applicationSnapshotFetchHooks';

import { featureFlags } from 'helpers/featureFlags';
import { ToolboxEditingSubmissionLink } from './ToolboxEditingSubmissionLink';
import { ToolboxGoToNextSubmissionLink } from './ToolboxGoToNextSubmissionLink';

import { SendToProcessingButton } from './SendToProcessingButton';
import { ToolboxWaitingOnIsoModal } from './ToolboxWaitingOnIsoModal';
import { ToolboxIsoCompetingSubmissionModal } from './ToolboxIsoCompetingSubmissionModal';
import { ToolboxDeclineModal } from './ToolboxDeclineModal';
import { ToolboxSendDealToModal } from './ToolboxSendDealToModal';
import { useNavigate } from 'react-router-dom';
import { CreateInternalRenewalConfirmationModal } from '../../shared/CreateInternalRenewalConfirmationModal';
import { ToolboxPrequalAnalystModal } from './ToolboxPrequalAnalystModal';
import { ToolboxSubmission } from './Toolbox.types';
import { GoToNextProcessingDealButton } from 'components/shared/GoToNextProcessingDeal/GoToNextProcessingDealButton';
import { useAssignNextProcessingDeal } from 'components/ProcessingQueue/ProcessingQueueHooks';

export interface ToolboxProps {
  submissionUuid: string;
  submission?: ToolboxSubmission;
  refetchSubmission: () => void;
}
export const Toolbox = ({
  submissionUuid,
  submission,
  refetchSubmission,
}: ToolboxProps): JSX.Element => {
  const navigate = useNavigate();

  const [
    updateSubmission,
    { error: errorUpdateSubmission, loading: loadingUpdateSubmission },
  ] = useUpdateSubmission(submissionUuid);

  const [
    gotToNextDealFunction,
    {
      nextDealData,
      error: nextDealError,
      loading: nextDealLoading,
      responseReady: nextDealResponseReady,
    },
  ] = useGoToNextDeal();

  const [
    goToNextProcessingDealFunction,
    {
      data: nextProcessingDealData,
      error: nextProcessingDealError,
      loading: nextProcessingDealLoading,
      responseReady: nextProcessingDealResponseReady,
    },
  ] = useAssignNextProcessingDeal();

  const isSubmissionNotFunded = submission?.stageName !== 'Funded';
  const isRenewalSubmission = submission?.type === 'Renewal';

  const isNotInternalRenewal =
    isRenewalSubmission && !submission?.internalRenewal;

  const shouldRenderCreateInternalRenewalButton =
    isNotInternalRenewal &&
    isSubmissionNotFunded &&
    submission?.previousFundedSubmissionUuid;

  const isNextDealUnavailable =
    nextDealResponseReady && !nextDealError && !nextDealData?.nextDealUrl;

  useEffect(() => {
    if (isNextDealUnavailable) {
      navigate('/prequal-queue-loading');
    }
  }, [isNextDealUnavailable, navigate]);

  return (
    <>
      <Flex
        pb={2}
        pr={3}
        flexDirection={'row'}
        gap={2}
        backgroundColor="primary"
        justifyContent={'flex-end'}
      >
        {submission && (
          <ToolboxSendDealToModal
            key="sendDealToModal"
            submission={submission}
            refetchSubmission={refetchSubmission}
          />
        )}
        {submission && (
          <ToolboxDeclineModal
            submission={submission}
            key="decline"
            refetchSubmission={refetchSubmission}
          />
        )}
        <SendToProcessingButton
          key="sendToProcessing"
          submission={submission}
          updateSubmission={updateSubmission}
          refetchSubmission={refetchSubmission}
          requestLoading={loadingUpdateSubmission}
        />
        {submission && (
          <ToolboxWaitingOnIsoModal
            key="waiitngOnIsoModal"
            submission={submission}
            refetchSubmission={refetchSubmission}
          />
        )}

        <ToolboxIsoCompetingSubmissionModal
          key="waitingOnIsoModal"
          submissionUuid={submissionUuid}
          submissionCompetingMessage={submission?.isoCompetingSubMessage}
          refetchSubmission={refetchSubmission}
        />

        <ToolboxEditingSubmissionLink
          key={'editingSubmission'}
          applicationUuid={submission?.applicationUuid}
          submissionUuid={submissionUuid}
        />
        <ToolboxGoToNextSubmissionLink
          key={'gotToNextDeal'}
          updateFunction={gotToNextDealFunction}
          nextDealUrl={nextDealData?.nextDealUrl}
          nextDealLoading={nextDealLoading}
        />
        {featureFlags.processing_queue && (
          <GoToNextProcessingDealButton
            key={'goToNextProcessingDeal'}
            updateFunction={goToNextProcessingDealFunction}
            nextDealSubmissionUuid={nextProcessingDealData?.submissionUuid}
            nextDealCustomerUuid={nextProcessingDealData?.customerUuid}
            nextDealLoading={nextProcessingDealLoading}
            nextDealResponseReady={nextProcessingDealResponseReady}
          />
        )}
        {shouldRenderCreateInternalRenewalButton &&
          submission?.previousFundedSubmissionUuid && (
            <CreateInternalRenewalConfirmationModal
              fundedSubmissionUuid={submission.previousFundedSubmissionUuid}
            />
          )}
        {featureFlags.show_assign_me_as_pq_analyst_button &&
          submission &&
          !submission.decisionAnalystId && (
            <ToolboxPrequalAnalystModal
              submissionUuid={submissionUuid}
              refetchSubmission={refetchSubmission}
            />
          )}
      </Flex>
      {errorUpdateSubmission && (
        <Banner dismissable={false} variant="error">
          <Flex gap={3}>
            <Icon size="lg" name="triangle-exclamation" />
            {errorUpdateSubmission.message}
          </Flex>
        </Banner>
      )}

      {nextDealError && (
        <Banner dismissable={false}>{nextDealError.message}</Banner>
      )}

      {nextProcessingDealError && (
        <Banner>{nextProcessingDealError.message}</Banner>
      )}

      {nextDealResponseReady && nextDealData?.nextDealUrl === undefined && (
        <Banner dismissable={false} variant="success">
          Queue is empty! There are no more submissions available.
        </Banner>
      )}
    </>
  );
};
