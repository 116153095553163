import React, { useEffect, useState } from 'react';
import {
  Banner,
  PageContainer,
  StickyContainer,
} from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { useGetApiFeatureFlagWithIdentifier } from 'apiHooks/underwriting/featureFlagFetchHooks';
import { NewDealScoringContainer } from './NewDealScoring/NewDealScoringContainer';
import { NewDealScoring } from './NewDealScoring/NewDealScoring';
import { RenewalScoringContainer } from './RenewalScoring/RenewalScoringContainer';
import { useScoringComposite, useSubmission } from './DealScoringFetchHooks';
import {
  NewDealScoreComposite,
  RenewalScoreComposite,
} from './DealScoring.types';
import { useLedgers } from './Ledger/ledgerHooks';

export interface DealScoringContainerProps {
  submissionUuid: string;
}

export const DealScoringContainer = ({
  submissionUuid,
}: DealScoringContainerProps): JSX.Element => {
  const [previousFundedSubmissionUuid, setPreviousFundedSubmissionUuid] =
    useState<string>();

  const {
    data: submission,
    error: submissionError,
    loading: submissionLoading,
  } = useSubmission(submissionUuid);

  const {
    data: compositeScore,
    error: compositeScoreError,
    loading: compositeScoreLoading,
  } = useScoringComposite(submissionUuid);

  const {
    data: ledgersData,
    error: ledgersError,
    refetch: refetchLedgers,
  } = useLedgers(submissionUuid);

  /**
   * This is to fetch a single feature flag with identifier.
   * This v6 score feature and the new pricing feature need to be released together.
   * The new Pricing feature is using a feature flag but instead of turning it on/off
   * at the user level, it will be toggled at the customer level, that's why we need
   * to send customer uuid, to check if the flag is on for the current customer
   */
  const ua_ba_pricing_suggestion_a_b_test = useGetApiFeatureFlagWithIdentifier(
    'ua_ba_pricing_suggestion_a_b_test',
    submission?.customerUuid
  );

  // should only show v6 score
  // if new suggested pricing is on and v6 score feature is on
  const shouldShowV6Score =
    ua_ba_pricing_suggestion_a_b_test &&
    featureFlags.show_v6_score_display &&
    // if submission category is new sales or business dev
    submission?.category !== undefined &&
    ['new_sales', 'business_development_program'].includes(
      submission.category
    ) &&
    // if there are any ledgers already created
    ledgersData &&
    ledgersData.length > 0;

  useEffect(() => {
    if (submission) {
      setPreviousFundedSubmissionUuid(
        submission.previousFundedSubmissionUuidFromCustomer ||
          submission.previousFundedSubmissionUuid
      );
    }
  }, [submission]);

  if (
    submissionError ||
    (compositeScoreError && compositeScoreError.statusCode !== 404) ||
    ledgersError
  ) {
    return (
      <>
        {submissionError && (
          <Banner dismissable={false}>{submissionError.message}</Banner>
        )}
        {compositeScoreError && compositeScoreError.statusCode !== 404 && (
          <Banner dismissable={false}>{compositeScoreError.message}</Banner>
        )}
        {featureFlags.show_new_ledgers_in_UA && ledgersError && (
          <Banner dismissable={false}>{ledgersError.message}</Banner>
        )}
      </>
    );
  }

  if (submissionLoading || compositeScoreLoading) {
    // return nothing while loading
    return <></>;
  }

  const showNewDealScoring: boolean =
    featureFlags.render_scoring_section_based_on_composite_score
      ? (compositeScore && compositeScore.type === 'New Deal') ||
        (!compositeScore && submission?.type === 'New Deal')
      : submission?.type === 'New Deal';

  // Note that the type coersion below is necessary to support the above feature flag.
  // When the feature flag is no longer needed, the types will be inferred correctly
  // via the type (or absence) of the composite score.
  return (
    <StickyContainer stickTo="bottom">
      <PageContainer>
        {showNewDealScoring ? (
          <NewDealScoringContainer
            submissionUuid={submissionUuid}
            submission={submission}
            shouldShowV6Score={shouldShowV6Score}
            compositeScore={compositeScore as NewDealScoreComposite | undefined}
            ledgersData={ledgersData}
            refetchLedgers={refetchLedgers}
          >
            <NewDealScoring />
          </NewDealScoringContainer>
        ) : (
          <RenewalScoringContainer
            submissionUuid={submissionUuid}
            previousFundedSubmissionUuid={previousFundedSubmissionUuid}
            compositeScore={compositeScore as RenewalScoreComposite | undefined}
            shouldShowV6Score={shouldShowV6Score}
          />
        )}
      </PageContainer>
    </StickyContainer>
  );
};
