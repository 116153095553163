import { useApiUnderwriterNextDeal } from 'apiHooks/banking/underwriterNextDealHooks';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { NextDealResponse } from 'types/api/banking/types';

export type UseAssignUnderwriterNextDealResponse = [
  () => Promise<MutationResponse>,
  {
    data?: NextDealData | undefined;
    error?: Error;
    loading: boolean;
    responseReady: boolean;
  }
];

interface NextDealData {
  nextDeal?: {
    opportunityUuid: string;
    recordId: number;
    applicationUrl: string;
    accountUuid: string;
    legalName: string;
  };
  dataUrls: string[];
}

const toNextDealData = (responseData: NextDealResponse): NextDealData => {
  return {
    nextDeal: responseData.next_deal
      ? {
          opportunityUuid: responseData.next_deal.opportunity_uuid,
          recordId: responseData.next_deal.record_id,
          applicationUrl: responseData.next_deal.application_url,
          accountUuid: responseData.next_deal.account_uuid,
          legalName: responseData.next_deal.legal_name,
        }
      : undefined,
    dataUrls: responseData.data_urls,
  };
};

export const useAssignUnderwriterNextDeal =
  (): UseAssignUnderwriterNextDealResponse => {
    const [createFn, { error, data, ...rest }] = useApiUnderwriterNextDeal();

    const createFunction = async (): Promise<MutationResponse> => {
      return createFn();
    };

    useLogError(error);

    return [
      createFunction,
      {
        data: data ? toNextDealData(data) : undefined,
        error,
        ...rest,
      },
    ];
  };
