import { FilterDisplayNames } from './LivePipeline.types';

export type ValidStages =
  | 'Processing'
  | 'Underwriting'
  | 'Contracting'
  | 'Closing';

export const validStageMappings: Record<ValidStages, string[]> = {
  Processing: [
    'Pre-qualification',
    'Waiting on ISO',
    'In Processing',
    'Processing Complete',
    'Predictive Processing',
  ],
  Underwriting: [
    'Waiting on ISO',
    'In Underwriting',
    'Credit Committee',
    'In Exception Review',
    'Underwriting Exception Request',
  ],
  Contracting: [
    'Contract Out',
    'Contract In',
    'Bank Login',
    'Bank Login - Attempted',
    'File Complete',
  ],
  Closing: [
    'Bank Login',
    'In Verification',
    'Merchant Interview',
    'Final UW Signoff',
    'Reprice',
    'Funding Call',
    'Funding Call - No Answer',
    'Funding Call - New Owner',
    'Funding Call - New Contract',
    'Funding Call - Refresh Login',
    'Funding Call - Other Issue',
    'File Missing Info',
    'Payment Update',
    'In Funding Queue',
  ],
};

export const validUWSubStages = [
  'Waiting on ISO',
  'In Underwriting',
  'Credit Committee',
  'In Exception Review',
  'Underwriting Exception Request',
  'Final UW Signoff',
];

export const filterDisplayNames: FilterDisplayNames = {
  stage_name: 'Stage',
  sub_stage: 'Sub-Stage',
  ff_underwriter: 'Underwriter',
  credit_committee: 'Credit Committee',
  decision_analyst_id: 'Prequal Analyst',
  deal_type: 'Deal Type',
  merchant_partner_id: 'ISOs',
  industry_name: 'Industry',
  revenue_bucket: 'Revenue',
  position_bucket: 'Positions',
  iso_competing_sub_message: 'ISO Competing Sub',
  prime_deal_filter: 'Prime Deal',
  sla_status_filter: 'SLA Status',
  category_filter: 'Deal Category',
};

export const propertyAlgoliaMappings = {
  total_stage_time: 'live_submission_last_stage_change_date',
  total_sub_stage_time: 'live_submission_last_sub_stage_change_date',
  deal_type: 'new_or_renewal',
  prime_deal_status: 'live_submission_prime_deal_status',
  prime_deal_filter: 'prime_deal_status',
  sla_status: 'live_submission_sla_status',
  sla_status_filter: 'sla_status',
  category_status: 'live_submission_category_status',
  category_filter: 'category_status',
};

export const revenueBucketOptions = [
  { value: '1', text: '<$25K' },
  { value: '2', text: '$25K - $50K' },
  { value: '3', text: '$50K - $100K' },
  { value: '4', text: '$100K+' },
];

export const positionBucketOptions = [
  { value: '1', text: '0' },
  { value: '2', text: '1' },
  { value: '3', text: '2' },
  { value: '4', text: '3+' },
];

export const isoCompetingSubOptions = [
  'N/A',
  'Dupe Decline - Forward Score',
  'Dupe Decline - Contracts In',
  'In Competition Email Sent',
  'Competing Sub - Approval Sent',
  'Competing Sub - Sent to UW',
  'Competing Sub - Received',
  'Competing Sub - Pending',
  'Competing Sub - Complete',
];

export const slaStatusOptions = [
  { value: '0', text: 'Not Yet Approaching' },
  { value: '1', text: 'Approaching' },
  { value: '2', text: 'Exceeded' },
];

export const categoryStatusOptions = [
  { value: '0', text: 'Business Development Program' },
  { value: '1', text: 'New Sales' },
  { value: '2', text: 'Other ISO Renewal' },
  { value: '3', text: 'PIF Existing ISO' },
  { value: '4', text: 'PIF ISO Exclusivity' },
  { value: '5', text: 'PIF New ISO' },
  { value: '6', text: 'Renewal' },
];
