import {
  createRelativity6ArtifactsApplication,
  createRelativity6ArtifactsSubmission,
  fetchIndustryNaicsIndustryId,
} from 'api/underwriting/relativity6FetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import {
  CreateRelativity6ArtifactsRequestBody,
  IndustryNaicsResponse,
} from 'types/api/underwriting/types';

export type UseCreateRelativity6ArtifactsSubmissionArgs = {
  submissionUuid: string;
  body: CreateRelativity6ArtifactsRequestBody;
};

export type UseRelativity6ArtifactsSubmissionResponse =
  UseGenericMutationResponse<
    MutationResponse,
    UseCreateRelativity6ArtifactsSubmissionArgs
  >;

const createRelativity6ArtifactsSubmissionWithArgs = async (
  args: UseCreateRelativity6ArtifactsSubmissionArgs
): Promise<MutationResponse> =>
  createRelativity6ArtifactsSubmission(args.submissionUuid, args.body);

export const useApiCreateRelativity6ArtifactsSubmission =
  (): UseRelativity6ArtifactsSubmissionResponse => {
    return useGenericMutation(createRelativity6ArtifactsSubmissionWithArgs);
  };

export type UseCreateRelativity6ArtifactsApplicationArgs = {
  applicationUuid: string;
  body: CreateRelativity6ArtifactsRequestBody;
};

export type UseRelativity6ArtifactsApplicationResponse =
  UseGenericMutationResponse<
    MutationResponse,
    UseCreateRelativity6ArtifactsApplicationArgs
  >;

const createRelativity6ArtifactsApplicationWithArgs = async (
  args: UseCreateRelativity6ArtifactsApplicationArgs
): Promise<MutationResponse> =>
  createRelativity6ArtifactsApplication(args.applicationUuid, args.body);

export const useApiCreateRelativity6ArtifactsApplication =
  (): UseRelativity6ArtifactsApplicationResponse => {
    return useGenericMutation(createRelativity6ArtifactsApplicationWithArgs);
  };

export type UseIndustryNaicsIndustryIdResponse =
  UseGenericQueryResponse<IndustryNaicsResponse>;

// 2022 NAICS version is still in beta for Relativity6, so we default to 2017
export const useApiIndustryNaicsIndustryId = (
  naicsCode?: string,
  naicsVersion: '2017' | '2022' = '2017'
): UseIndustryNaicsIndustryIdResponse => {
  const loadData = useCallback(() => {
    if (!naicsCode) {
      return undefined;
    }

    return fetchIndustryNaicsIndustryId(naicsCode, naicsVersion);
  }, [naicsCode, naicsVersion]);

  return useGenericQuery(loadData);
};
