import { defaultTo } from 'lodash';
import { useCallback } from 'react';
import {
  useApiPullInference,
  useGetApiInference,
} from 'apiHooks/mldp/inferenceFetchHooks';
import {
  InferenceResponse,
  Input,
  PullInferenceResponse,
} from 'types/api/mldp/types';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useLogError } from 'apiHooks/useLogError';
import { useUpdateApiSubmission } from 'apiHooks/underwriting/submissionFetchHooks';
import { PullInference, V6Scores } from './v6ScoreDisplay.types';
import { v6ScoreFeatures } from './v6ScoreDisplayHelpers';

const toHumanReadableFeature = (variable: string): string =>
  defaultTo(v6ScoreFeatures[variable]?.description, variable);

const getScoreFromFeature = (
  feature: string,
  inputs: Input[]
): number | undefined => {
  const input = inputs.find((i) => i.key === feature);
  return input?.value;
};

const getInputValueFromFeature = (
  feature: string,
  inputs: Input[]
): number | undefined => {
  const input = inputs.find((i) => i.key === feature);
  return input?.input_value ?? undefined;
};

const getFormattedInputValueFromFeature = (
  feature: string,
  inputs: Input[]
): string | undefined => {
  const inputValue = getInputValueFromFeature(feature, inputs);

  if (typeof inputValue !== 'number') {
    return undefined;
  }

  const formattedInputValue = defaultTo(
    v6ScoreFeatures[feature]?.displayFormatter(inputValue),
    inputValue.toString()
  );

  return formattedInputValue;
};

const toV6Scores = (
  inferenceData?: InferenceResponse
): V6Scores | undefined => {
  if (!inferenceData) {
    return undefined;
  }

  return {
    score: inferenceData.details.score,
    topFive: inferenceData.details.top_five.map((feature) => ({
      name: toHumanReadableFeature(feature),
      score: getScoreFromFeature(feature, inferenceData.details.inputs),
      inputValue: getInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
      formattedInputValue: getFormattedInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
    })),
    bottomFive: inferenceData.details.bottom_five.map((feature) => ({
      name: toHumanReadableFeature(feature),
      score: getScoreFromFeature(feature, inferenceData.details.inputs),
      inputValue: getInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
      formattedInputValue: getFormattedInputValueFromFeature(
        feature,
        inferenceData.details.inputs
      ),
    })),
    createdAt: inferenceData.created_at,
  };
};

export const useInference = (
  submissionUuid: string
): UseGenericQueryResponse<V6Scores> =>
  useGenericFeatureQuery(useGetApiInference, toV6Scores, submissionUuid);

export const toInference = (
  inferenceData?: PullInferenceResponse
): PullInference | undefined => {
  if (!inferenceData) {
    return undefined;
  }

  return {
    riskScore: inferenceData.risk_score,
    autoDecline: inferenceData.auto_decline,
  };
};

export type PullInferenceBodyArgs = {
  submissionUuid: string;
  apiKey?: string;
};

type UsePullInferenceResult = [
  (input: PullInferenceBodyArgs) => Promise<MutationResponse>,
  { data?: PullInference; error?: Error; loading: boolean }
];

export const usePullInference = (): UsePullInferenceResult => {
  const [pullInference, { data, error, loading }] = useApiPullInference();

  const pullFunction = (
    args: PullInferenceBodyArgs
  ): Promise<MutationResponse> => {
    return pullInference({
      submissionUuid: args.submissionUuid,
      apiKey: args.apiKey,
    });
  };

  useLogError(error);

  return [
    pullFunction,
    {
      data: data && toInference(data),
      error,
      loading,
    },
  ];
};

type UpdateSubmissionBody = {
  riskScore?: number;
};

type UseUpdateSubmissionStageResult = [
  (input: UpdateSubmissionBody) => Promise<MutationResponse>,
  { error?: Error; loading: boolean }
];

export const useUpdateSubmission = (
  submissionUuid: string
): UseUpdateSubmissionStageResult => {
  const [updateSubmission, { error, loading }] = useUpdateApiSubmission();

  const updateFunction = useCallback(
    (args: UpdateSubmissionBody): Promise<MutationResponse> => {
      return updateSubmission({
        submissionUuid,
        updateBody: {
          risk_score: args.riskScore,
        },
      });
    },
    [submissionUuid, updateSubmission]
  );

  useLogError(error);

  return [
    updateFunction,
    {
      error,
      loading,
    },
  ];
};
