import React from 'react';
import {
  Address,
  Card,
  Currency,
  Table,
  Text,
  formatDateString,
} from '@forward-financing/fast-forward';
import { LienJudgmentRecord } from '../types';

const LienJudgmentRow = ({
  originFilingDate,
  type: types,
  creditor,
  debtor,
  amount,
  status,
}: LienJudgmentRecord): JSX.Element => (
  <Table.Row>
    <Table.Cell columnWidth="small">
      {types?.map((type) => (
        <Text key={type}>{type}</Text>
      ))}
    </Table.Cell>

    <Table.Cell columnWidth="small">
      <Text>{creditor}</Text>
    </Table.Cell>

    <Table.Cell columnWidth="small">
      {debtor.map(({ name, address }) => (
        <>
          <Text key={name}>{name}</Text>
          {Array.isArray(address) ? (
            address.length > 0 &&
            address.map((singleAddress) => (
              <Text
                key={`${singleAddress.streetAddress}-${singleAddress.city}-${singleAddress.state}`}
              >
                <Address {...singleAddress} isSingleLine />
              </Text>
            ))
          ) : (
            <Text>
              <Address {...address} isSingleLine />
            </Text>
          )}
        </>
      ))}
    </Table.Cell>

    <Table.Cell columnWidth="small">
      {amount ? <Currency amount={parseInt(amount, 10)} /> : <Text>None</Text>}
    </Table.Cell>

    <Table.Cell columnWidth="small">
      {originFilingDate ? formatDateString(originFilingDate) : 'N/A'}
    </Table.Cell>

    <Table.Cell columnWidth="small">{status}</Table.Cell>
  </Table.Row>
);

export interface LiensJudgmentsTableProps {
  title: string;
  liens: LienJudgmentRecord[];
}

export const LiensJudgmentsTable = ({
  title,
  liens,
}: LiensJudgmentsTableProps): JSX.Element => (
  <Card title={title}>
    {liens.length === 0 ? (
      <Text>No Records Found</Text>
    ) : (
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Type</Table.ColumnHeader>
          <Table.ColumnHeader>Creditor</Table.ColumnHeader>
          <Table.ColumnHeader>Debtor</Table.ColumnHeader>
          <Table.ColumnHeader>Amount</Table.ColumnHeader>
          <Table.ColumnHeader>Date</Table.ColumnHeader>
          <Table.ColumnHeader>Status</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          {liens.map((lien) => (
            <LienJudgmentRow key={lien.originFilingDate} {...lien} />
          ))}
        </Table.Body>
      </Table>
    )}
  </Card>
);
