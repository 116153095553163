import { fetchCoreSalesforceMap } from 'api/crmGateway/coreSalesforceMapsFetchUtils';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { useCallback } from 'react';
import { CoreSalesforceMapResponse } from 'types/api/crmGateway/types';

interface CoreSalesforceMap {
  coreId: string;
  objectType: string;
  salesforceId: string;
}

interface UseCoreSalesforceMapResult
  extends Omit<UseGenericQueryResponse<CoreSalesforceMapResponse>, 'data'> {
  map?: CoreSalesforceMap;
}

/**
 * Given a salesforce ID, this hook fetches the related
 * ID for the same entity in the core apps. Because the
 * use case is so incredibly clear cut, we have deviated
 * from convention with this hook. The API Hook handles
 * mapping to the feature shape, as well as error logging.
 *
 * It is therefore safe to use in other features without
 * following the standard convention of wrapping in an
 * additional hook.
 *
 * @param salesforceId
 * @returns the mapped response
 */
export const useCoreSalesforceMap = (
  salesforceId?: string
): UseCoreSalesforceMapResult => {
  const loadData = useCallback(() => {
    if (!salesforceId) {
      return undefined;
    }

    return fetchCoreSalesforceMap(salesforceId);
  }, [salesforceId]);

  const { data, error, ...rest } = useGenericQuery(loadData);

  useLogError(error);

  return {
    map: data
      ? {
          coreId: data.core_id,
          objectType: data.object_type,
          salesforceId: data.salesforce_id,
        }
      : undefined,
    error,
    ...rest,
  };
};
