import React, { useMemo } from 'react';
import {
  Banner,
  Box,
  Card,
  Flex,
  Subheading,
} from '@forward-financing/fast-forward';
import { SimilarContactsTable } from './SimilarContactsTable';
import {
  useCustomer,
  useCustomerSubmissionIsos,
  useCustomerSubmissions,
  useRefreshOwners,
} from './matchedRecordsHooks';
import { CustomerSubmissionTable } from './CustomerSubmissionsTable';
import { AdditionalDataModal } from './AdditionalDataModal';
import { hasOpenApproval } from './helpers';
import { MatchRecordsSubmission } from './matchedRecords.types';
import { SimilarOwnersTable } from './SimilarOwnersTable';
import { featureFlags } from 'helpers/featureFlags';

export interface MatchedRecordsContainerProps {
  submission: MatchRecordsSubmission | undefined;
}

export const MatchedRecordsContainer = ({
  submission,
}: MatchedRecordsContainerProps): JSX.Element => {
  const {
    customer: customerFromRest,
    error: customerError,
    responseReady: customerReady,
  } = useCustomer(submission?.customerUuid);

  const {
    customerSubmissions,
    error: customerSubmissionsError,
    responseReady: customerSubmissionsReady,
  } = useCustomerSubmissions(customerFromRest?.id);

  const isoUuids = useMemo(
    () => customerSubmissions?.map((s) => s.isoUuid),
    [customerSubmissions]
  );

  const {
    isos,
    error: isosError,
    responseReady: isosReady,
  } = useCustomerSubmissionIsos(isoUuids);

  const [refreshOwners, { data: refreshOwnerData, error: refreshOwnerError }] =
    useRefreshOwners();

  if (!customerReady || !customerSubmissionsReady || !isosReady) {
    // just return nothing for now
    return <></>;
  }

  const shouldRenderApprovalBanner = (): boolean | undefined => {
    return customerSubmissions?.some((sub) => {
      return hasOpenApproval(sub.stage, sub.approvedDate) === true;
    });
  };

  return (
    <Card title="Matched Records" collapsible>
      {customerError && (
        <Banner dismissable={false} variant="error">
          {customerError.message}
        </Banner>
      )}
      {customerSubmissionsError && (
        <Banner dismissable={false} variant="error">
          {customerSubmissionsError.message}
        </Banner>
      )}
      {isosError && (
        <Banner dismissable={false} variant="error">
          {isosError.message}
        </Banner>
      )}
      {refreshOwnerError && (
        <Banner dismissable={false} variant="error">
          {refreshOwnerError.message}
        </Banner>
      )}
      {refreshOwnerData?.success && (
        <Banner dismissable={false} variant="success">
          A refresh to similar owners was enqueued successfully! It takes
          anywhere from 5 seconds to 4 minutes to process the data, so please
          allow a little bit of time before refreshing the page. If you are
          still not able to view the refreshed results after 4 minutes, please
          contact the tech support.
        </Banner>
      )}
      {shouldRenderApprovalBanner() && (
        <Banner dismissable={false} variant="warning">
          This business seems to have open approvals. Please check status column
          for more info.
        </Banner>
      )}
      <Box>
        <Flex justifyContent="space-between">
          <Subheading variant="section">
            Customer Submissions for: {customerFromRest?.name}
          </Subheading>

          <AdditionalDataModal
            submissionData={customerSubmissions}
            currentSubmissionUuid={submission?.uuid}
            isos={isos}
          />
        </Flex>
        <Box bg="gray-200">
          <CustomerSubmissionTable
            submissions={customerSubmissions}
            currentSubmissionUuid={submission?.uuid}
            isos={isos}
          />
        </Box>
      </Box>
      {submission?.ownerUuids.map((uuid) => (
        <Box key={uuid}>
          {featureFlags.show_similar_owners_table ? (
            <SimilarOwnersTable
              key={uuid}
              ownerUuid={uuid}
              isos={isos}
              submissions={customerSubmissions}
              currentSubmissionUuid={submission.uuid}
            />
          ) : (
            <SimilarContactsTable
              key={uuid}
              ownerUuid={uuid}
              onRefresh={() => refreshOwners(uuid, submission.uuid)}
              isos={isos}
              submissions={customerSubmissions}
              currentSubmissionUuid={submission.uuid}
            />
          )}
        </Box>
      ))}
    </Card>
  );
};
