import { Banner, Box, Button, Flex } from '@forward-financing/fast-forward';
import React, { useState } from 'react';
import {
  useStipulations,
  useValidReasons,
} from 'components/StipulationManager/stipulationManagerHooks';
import { featureFlags } from 'helpers/featureFlags';
import { StipulationRecommendationsContainer } from 'components/StipulationRecommendations/StipulationRecommendationsContainer';
import { BankingStipulationTable } from './BankingStipulationTable';
import { CreateBankingStipulationModal } from './CreateBankingStipulationModal';
import { EditBankingStipulationModal } from './EditBankingStipulationModal';

export type BankingStipulationManagerProps = {
  submissionUuid?: string;
};

export const BankingStipulationManager = ({
  submissionUuid,
}: BankingStipulationManagerProps): JSX.Element => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [errorBanner, setErrorBanner] = useState<string>();

  const {
    data: stipulations,
    error: stipulationsError,
    refetch: refetchStipulations,
  } = useStipulations(submissionUuid);

  const { data: validReasons, error: validReasonsError } = useValidReasons();

  const onStipRowChecked = (stipulationtId: string, checked: boolean): void => {
    if (checked) {
      setSelectedIds([...selectedIds, stipulationtId]);
    } else {
      setSelectedIds(selectedIds.filter((a) => a !== stipulationtId));
    }
  };

  const onCancelWithError = (error?: string): void => {
    refetchStipulations();
    setErrorBanner(error);
  };

  const selectedStipulations =
    stipulations?.filter((stip) => selectedIds.includes(stip.uuid)) || [];

  const hasSelectedStipulations = selectedIds.length < 1;

  const additionalStipulations =
    stipulations?.filter((stip) => stip.isAdditional) || [];

  const toggleAllChecked = (allChecked: boolean): void => {
    if (allChecked) {
      setSelectedIds(additionalStipulations.map((s) => s.uuid));
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <Box>
      {stipulationsError && (
        <Banner variant="error" dismissable={false}>
          {stipulationsError.message}
        </Banner>
      )}
      {validReasonsError && (
        <Banner variant="error" dismissable={false}>
          {validReasonsError.message}
        </Banner>
      )}
      {errorBanner && (
        <Banner variant="error" dismissable={false}>
          {errorBanner}
        </Banner>
      )}
      <Flex justifyContent={'space-between'} mb={3}>
        <Flex>
          {featureFlags.show_stipulation_recommendation_text &&
            submissionUuid && (
              <StipulationRecommendationsContainer
                submissionUuid={submissionUuid}
              />
            )}
        </Flex>

        <Flex gap={2}>
          {submissionUuid && (
            <CreateBankingStipulationModal
              submissionUuid={submissionUuid}
              refetchStipulations={refetchStipulations}
              onCancelWithError={onCancelWithError}
              validReasons={validReasons}
            />
          )}

          {stipulations && submissionUuid && (
            <EditBankingStipulationModal
              selectedStipulations={selectedStipulations}
              onSaveComplete={() => setSelectedIds([])}
              trigger={
                <Button disabled={hasSelectedStipulations} startIcon="pencil">
                  Edit Stipulation(s)
                </Button>
              }
              submissionUuid={submissionUuid}
              refetchStipulations={refetchStipulations}
              onCancelWithError={onCancelWithError}
              validReasons={validReasons}
            />
          )}
        </Flex>
      </Flex>
      <BankingStipulationTable
        stipulations={additionalStipulations}
        selectedIds={selectedIds}
        onRowSelect={onStipRowChecked}
        refetchStipulations={refetchStipulations}
        submissionUuid={submissionUuid}
        onCancelWithError={onCancelWithError}
        validReasons={validReasons}
        toggleAllChecked={toggleAllChecked}
      />
    </Box>
  );
};
