import {
  Banner,
  Box,
  Flex,
  FlexProps,
  IconButton,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import React from 'react';
import styled from 'styled-components';
import { HashMap } from 'api/codecs';
import { featureFlags } from 'helpers/featureFlags';
import { BankingStipulation } from './BankingStipulation.types';
import { EditBankingStipulationModal } from './EditBankingStipulationModal';
import {
  useDeleteBankingStipulation,
  useGetSubmission,
} from './BankingStipulationHooks';

export type StipulationTableProps = {
  stipulations: BankingStipulation[];
  selectedIds: string[];
  onRowSelect: (stipulationId: string, checked: boolean) => void;
  refetchStipulations: () => void;
  submissionUuid?: string;
  onCancelWithError: (error?: string) => void;
  validReasons?: HashMap<string[]>;
  toggleAllChecked: (allSelected: boolean) => void;
};

const isStipTypeOther = (stipulationToCheck: BankingStipulation): boolean =>
  stipulationToCheck.name.slice(0, 5) === 'Other';

const CheckboxHeaderWrapper = styled(Flex)<FlexProps>`
  border-right: 1px solid var(--color-gray-500);
`;

export const BankingStipulationTable = ({
  stipulations,
  selectedIds,
  onRowSelect,
  submissionUuid,
  refetchStipulations,
  onCancelWithError,
  validReasons,
  toggleAllChecked,
}: StipulationTableProps): JSX.Element => {
  const sortedStips = [...stipulations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (!isStipTypeOther(a) && isStipTypeOther(b) ? -1 : 1));

  const [deleteStip, { error: errorDeleteStip }] =
    useDeleteBankingStipulation();
  const { data: submission, error: submissionError } =
    useGetSubmission(submissionUuid);

  const handleDeleteStip = async (stipUuid: string): Promise<void> => {
    if (!submissionUuid) {
      return;
    }
    const { success } = await deleteStip({
      stipulationUuid: stipUuid,
      submissionUuid: submissionUuid,
    });

    success && refetchStipulations();
  };

  const areAllStipsSelected = (): boolean => {
    return !!stipulations.length && stipulations.length === selectedIds.length;
  };

  return (
    <Box>
      {errorDeleteStip && (
        <Banner variant="error" dismissable={false}>
          {errorDeleteStip.message}
        </Banner>
      )}
      {submissionError && (
        <Banner variant="error" dismissable={false}>
          {submissionError.message}
        </Banner>
      )}
      <Table caption="Banking Stipulations">
        <Table.Head>
          <CheckboxHeaderWrapper justifyContent="center" alignItems="center">
            <Table.CheckboxColumnHeader
              label="Select all stipulations"
              checked={areAllStipsSelected()}
              onCheckboxChange={toggleAllChecked}
            />
          </CheckboxHeaderWrapper>
          <Table.ColumnHeader>Actions</Table.ColumnHeader>
          <Table.ColumnHeader>Stipulation Name</Table.ColumnHeader>
          <Table.ColumnHeader>Additional Info</Table.ColumnHeader>
          {featureFlags.new_stipulations_process_phase_2 && (
            <Table.ColumnHeader>Reason</Table.ColumnHeader>
          )}
        </Table.Head>
        <Table.Body>
          {stipulations.length < 1 && (
            <Box paddingLeft={3}>
              <Subheading variant="subsection">
                No Stipulations to show...
              </Subheading>
            </Box>
          )}
          {sortedStips?.map((stip) => (
            <Table.Row key={stip.uuid}>
              <Table.CheckboxCell
                label={`Select ${stip.name}`}
                checked={selectedIds.includes(stip.uuid)}
                onCheckboxChange={(checked) => {
                  onRowSelect(stip.uuid, checked);
                }}
              />
              <Table.Cell columnWidth="small">
                <Flex justifyContent="center">
                  {submissionUuid && (
                    <EditBankingStipulationModal
                      selectedStipulations={[stip]}
                      trigger={
                        <IconButton
                          icon="pencil"
                          label={`Edit ${stip.name}`}
                          hiddenLabel
                        />
                      }
                      submissionUuid={submissionUuid}
                      refetchStipulations={refetchStipulations}
                      onCancelWithError={onCancelWithError}
                      validReasons={validReasons}
                    />
                  )}
                  {submission?.stageName === 'Underwriting' && (
                    <IconButton
                      icon="trash"
                      label={`Delete ${stip.name}`}
                      hiddenLabel
                      onClick={() => handleDeleteStip(stip.uuid)}
                    />
                  )}
                </Flex>
              </Table.Cell>
              <Table.Cell overflowStrategy="wrap">{stip.name}</Table.Cell>
              <Table.Cell overflowStrategy="wrap">{stip.notes}</Table.Cell>
              {featureFlags.new_stipulations_process_phase_2 && (
                <Table.Cell overflowStrategy="wrap">
                  {stip.reasons?.join(', ')}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
};
