import { useCallback } from 'react';
import {
  fetchLedgers,
  sentToCreditCommittee,
  cloneLedger,
  updateLedger,
  fetchBuyRates,
  deleteLedger,
  createLedger,
  fetchLedgerOffers,
  patchLedger,
} from 'api/banking/ledgerFetchUtils';
import {
  MutationResponse,
  UseGenericMutationResponse,
  UseGenericQueryResponse,
  useGenericMutation,
  useGenericQuery,
} from 'apiHooks/genericFetchHooks';
import {
  BuyRatesResponse,
  LedgerOfferRaw,
  LedgerResponse,
  PatchLedgerRequestBody,
  UpdateLedgerBody,
} from 'types/api/banking/types';
import { GenericLedgerApiParams } from 'components/DealScoring/Ledger/ledger.types';

export interface UseUpdateLedgerArgs {
  submissionUuid: string;
  updateBody: UpdateLedgerBody;
}

export type UseUpdateLedgerResponse = UseGenericMutationResponse<
  { success: boolean },
  UseUpdateLedgerArgs
>;

const updateLedgerWithArgs = async (
  args: UseUpdateLedgerArgs
): Promise<{ success: boolean }> =>
  updateLedger(args.submissionUuid, args.updateBody);

export const useApiUpdateLedger = (): UseUpdateLedgerResponse => {
  return useGenericMutation(updateLedgerWithArgs);
};

export const useFetchLedgers = (
  submissionUuid: string
): UseGenericQueryResponse<LedgerResponse[]> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgers(submissionUuid);
  }, [submissionUuid]);

  return useGenericQuery(fetchFunction);
};

export const useApiSendToCreditCommittee = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => {
  return useGenericMutation(sentToCreditCommittee);
};

export const useApiPatchLedger = (): UseGenericMutationResponse<
  { success: boolean },
  GenericLedgerApiParams & {
    requestBody: PatchLedgerRequestBody;
  }
> => useGenericMutation(patchLedger);

export const useApiCloneLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => useGenericMutation(cloneLedger);

export const useApiFetchBuyRates = ({
  submissionUuid,
  ledgerId,
}: GenericLedgerApiParams): UseGenericQueryResponse<BuyRatesResponse> => {
  const fetchFunction = useCallback(() => {
    return fetchBuyRates({ submissionUuid, ledgerId });
  }, [submissionUuid, ledgerId]);

  return useGenericQuery(fetchFunction);
};

export const useApiDeleteLedger = (): UseGenericMutationResponse<
  MutationResponse,
  GenericLedgerApiParams
> => useGenericMutation(deleteLedger);

export const useApiCreateLedger = (): UseGenericMutationResponse<
  MutationResponse,
  Omit<GenericLedgerApiParams, 'ledgerId'>
> => {
  return useGenericMutation(createLedger);
};

export const useFetchOffers = ({
  ledgerId,
  submissionUuid,
}: Omit<GenericLedgerApiParams, 'customerUuid'>): UseGenericQueryResponse<
  LedgerOfferRaw[]
> => {
  const fetchFunction = useCallback(() => {
    return fetchLedgerOffers({ submissionUuid, ledgerId });
  }, [submissionUuid, ledgerId]);

  return useGenericQuery(fetchFunction);
};
