import { SelectableOption } from '@forward-financing/fast-forward';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLogError } from 'apiHooks/useLogError';
import { StipulationResponse } from 'types/api/underwriting/types';
import {
  useGetApiStipulations,
  useCreateApiStipulationBatch,
  useUpdateApiStipulationBatch,
  useGetApiValidReasons,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  UseGenericQueryResponse,
  UseGenericMutationResult,
  MutationResponse,
} from 'apiHooks/genericFetchHooks';
import { HashMap } from 'api/codecs';
import { UpdateBankingStipulationInput } from 'components/BankingStipulationManager/BankingStipulation.types';
import { FFLogger } from 'api/LogClient';
import { featureFlags } from 'helpers/featureFlags';
import {
  CreateStipulationInput,
  Stipulation,
  UpdateStipulationInput,
} from './stipulationManager.types';

const toStipulation = (response: StipulationResponse): Stipulation => {
  return {
    name: response.name,
    status: response.status,
    bankVerificationType: response.bank_verification_type ?? undefined,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    underwriter:
      response.underwriter_id && response.underwriter_name
        ? {
            id: response.underwriter_id,
            name: response.underwriter_name,
          }
        : undefined,
    closingAnalyst:
      response.closing_analyst_id && response.closing_analyst_name
        ? {
            id: response.closing_analyst_id,
            name: response.closing_analyst_name,
          }
        : undefined,
    notes: response.notes ?? undefined,
    isAdditional: response.is_additional,
    uuid: response.uuid,
    submissionUuid: response.opportunity_uuid,
    internalComments: response.internal_comments ?? undefined,
    emailDisplay: response.is_internal,
    received: response.received,
    receivedAt: response.received_at ?? undefined,
    eligibleForClosingAssignment: response.eligible_for_closing_assignment,
    reasons: response.reasons,
  };
};

export const useStipulations = (
  submissionUuid?: string
): UseGenericQueryResponse<Stipulation[]> =>
  useGenericFeatureQuery(
    useGetApiStipulations,
    (data) => data?.map(toStipulation),
    submissionUuid
  );

// Create Stipulation Batch
interface CreateStipulationArgs {
  submissionUuid: string;
  createBody: CreateStipulationInput;
}

type UseCreateStipulationBatchResponse = [
  (args: CreateStipulationArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<PromiseSettledResult<StipulationResponse>[]> & {
    errors?: Error[];
  }
];

export const useCreateStipulationBatch =
  (): UseCreateStipulationBatchResponse => {
    const [createFn, { data, ...rest }] = useCreateApiStipulationBatch();
    const [errors, setErrors] = useState<Error[] | undefined>(undefined);

    const processErrors = useCallback(() => {
      setErrors(undefined);
      const rejectedResults = data?.filter((res) => res.status === 'rejected');

      if (!rejectedResults) {
        return;
      }
      /**
       * When using useLogError hook next error occurs
       * "React Hook "useLogError" cannot be called inside a callback.
       * React Hooks must be called in a React function component
       * or a custom React Hook function"
       *
       * Have to use FFlogger directly since we have an array of Errors
       * and we need to log each error while iterating.
       */
      rejectedResults
        .map(
          (promiseResult) =>
            (promiseResult as PromiseRejectedResult).reason as Error
        )
        .forEach((error) => {
          setErrors((prev) => [...(prev ?? []), error]);

          FFLogger.error(error);
        });
    }, [data]);

    useEffect(() => {
      if (!data) {
        return;
      }

      processErrors();
    }, [data, processErrors]);

    const createFunction = async (
      args: CreateStipulationArgs[]
    ): Promise<MutationResponse> => {
      // Create a copy of args and transform it into the format expected by the
      // API.
      const createArgs = args.map(
        ({
          submissionUuid,
          createBody: { name, notes, otherDescription, reasons },
        }) => {
          return {
            submissionUuid,
            createBody: {
              name: `${name}${
                otherDescription ? ` - ${otherDescription}` : ''
              }`,
              notes,
              reasons,
              is_additional: true as const,
              status: 'not_received' as const,
            },
          };
        }
      );

      return createFn(createArgs);
    };

    return [
      createFunction,
      {
        data,
        errors,
        ...rest,
      },
    ];
  };

// Update Stipulation Batch
interface UpdateStipulationArgs {
  submissionUuid: string;
  stipulationUuid: string;
  input: UpdateStipulationInput;
}

type UseUpdateStipulationBatchResponse = [
  (args: UpdateStipulationArgs[]) => Promise<MutationResponse>,
  UseGenericMutationResult<Stipulation[]>
];

export const useUpdateStipulationBatch =
  (): UseUpdateStipulationBatchResponse => {
    const [updateFn, { data, error, ...rest }] = useUpdateApiStipulationBatch();

    const updateFunction = async (
      args: UpdateStipulationArgs[]
    ): Promise<MutationResponse> => {
      // Create a copy of args and transform it into the format expected by the
      // API.
      const updateArgs = args.map(
        ({
          submissionUuid,
          stipulationUuid,
          input: {
            status,
            bankVerificationType: bank_verification_type,
            closingAnalystId: closing_analyst_id,
            underwriterId: underwriter_id,
            notes,
            internalComments: internal_comments,
            receivedAt: received_at,
            reasons,
          },
        }) => {
          return {
            submissionUuid,
            stipulationUuid,
            body: {
              status,
              bank_verification_type,
              closing_analyst_id,
              underwriter_id,
              notes,
              internal_comments,
              received_at,
              reasons,
            },
          };
        }
      );

      return updateFn(updateArgs);
    };

    useLogError(error);

    return [
      updateFunction,
      {
        data: data && data.map(toStipulation),
        error,
        ...rest,
      },
    ];
  };

/**
 * Sorts reasons for each stipulation
 * @param data HashMap of [stipulation]: reasons[]
 * @returns HashMap with sorted reasons
 */
const toSortedReasons = (
  data: HashMap<string[]> | undefined
): HashMap<string[]> => {
  if (!data) {
    return {};
  }

  const sortedHashMap = cloneDeep(data);

  Object.keys(sortedHashMap).forEach((key) => {
    sortedHashMap[key].sort((first, second) => {
      if (first.toLowerCase() === second.toLowerCase()) {
        return 0;
      }

      return first.toLowerCase() < second.toLowerCase() ? -1 : 0;
    });
  });

  return sortedHashMap;
};

export const useValidReasons = (): UseGenericQueryResponse<HashMap<string[]>> =>
  useGenericFeatureQuery(useGetApiValidReasons, (data) =>
    toSortedReasons(data)
  );

type StipulationInputType =
  | CreateStipulationInput
  | UpdateBankingStipulationInput;

type UseSelectedStipulationReasonsProps = {
  handleUpdateStip: (
    newStip: Partial<StipulationInputType>,
    uuidToUpdate: string
  ) => void;
  selectedReasons?: Record<string, string[]>;
};

type UseSelectedStipulationReasonsResult = {
  handleUpdateName: (stip: StipulationInputType) => (value: string) => void;
  handleUpdateReasons: (
    stip: StipulationInputType
  ) => (value: SelectableOption[]) => void;
};

const isCreateStipulationInput = (
  stip: StipulationInputType
): stip is CreateStipulationInput => 'temporaryUuid' in stip;

export const useSelectedStipulationReasons = ({
  handleUpdateStip,
  selectedReasons = {},
}: UseSelectedStipulationReasonsProps): UseSelectedStipulationReasonsResult => {
  /**
   * Place to store selected reasons for each stip.
   * User might have changed the reason name and we want
   * to keep selected reasons if name was changed accidentaly.
   */
  const [selectedStipReasons, setSelectedStipReasons] =
    useState<Record<string, string[]>>(selectedReasons);

  const handleUpdateName =
    (stip: StipulationInputType) =>
    (newValue: string): void => {
      const uuid = isCreateStipulationInput(stip)
        ? stip.temporaryUuid
        : stip.uuid;

      const existingReasons = selectedStipReasons[`${uuid}${newValue}`];

      handleUpdateStip(
        {
          name: newValue,
          reasons: existingReasons,
        },
        uuid
      );
    };

  const handleUpdateReasons =
    (stip: StipulationInputType) =>
    (newValues: SelectableOption[]): void => {
      const uuid = isCreateStipulationInput(stip)
        ? stip.temporaryUuid
        : stip.uuid;

      setSelectedStipReasons((prev) => ({
        ...prev,
        [`${uuid}${stip.name}`]: newValues.map((v) => v.value),
      }));

      handleUpdateStip(
        {
          reasons: newValues.map((v) => v.value),
        },
        uuid
      );
    };

  return { handleUpdateName, handleUpdateReasons };
};

type UseHandleCreateStipulationsProps = {
  stips: CreateStipulationInput[];
  submissionUuid: string;
  setStips: React.Dispatch<React.SetStateAction<CreateStipulationInput[]>>;
  onSubmitCallback: () => void;
};

type UseHandleCreateStipulationsResult = {
  handleSubmit: () => Promise<void>;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ERROR_TEXT =
  'These stipulations could not be created. Please review the current stipulations and try again';

export const useHandleCreateStipulations = ({
  stips,
  submissionUuid,
  setStips,
  onSubmitCallback,
}: UseHandleCreateStipulationsProps): UseHandleCreateStipulationsResult => {
  const [error, setError] = useState<string | undefined>();
  const [createStipulationBatch, { errors: createStipulationsErrors }] =
    useCreateStipulationBatch();

  useEffect(() => {
    if (createStipulationsErrors) {
      setError(
        `${ERROR_TEXT}: ${createStipulationsErrors
          .map((e) => e.message)
          .join(', ')}`
      );
    }
  }, [createStipulationsErrors]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setError(undefined);
    const batchStips = stips.map((stip) => {
      return {
        submissionUuid: submissionUuid,
        createBody: featureFlags.remove_describe_stipulation_type
          ? { ...stip, otherDescription: stip.notes }
          : stip,
      };
    });

    const { response } = await createStipulationBatch(batchStips);

    const isPromiseSettledArray = (
      resp: unknown
    ): resp is PromiseSettledResult<unknown>[] =>
      Array.isArray(response) && response?.every((res) => 'status' in res);

    if (
      isPromiseSettledArray(response) &&
      response.some((res) => res.status === 'rejected')
    ) {
      /**
       * When we have errors (rejected promises) after trying to create an array of stips
       * we need to hide successfully created stips and show only failed.
       */
      setStips((prev) =>
        prev.filter((_stip, i) => response[i].status === 'rejected')
      );
    } else {
      setError(undefined);
      onSubmitCallback();
    }
  }, [
    createStipulationBatch,
    onSubmitCallback,
    setStips,
    stips,
    submissionUuid,
  ]);

  return {
    error,
    setError,
    handleSubmit,
  };
};
