import React, { useCallback } from 'react';

import {
  Button,
  IconButtonProps,
  Text,
  Combobox,
  Box,
  Flex,
  Accordion,
  Grid,
} from '@forward-financing/fast-forward';
import { SelectableOption } from '@forward-financing/fast-forward/dist/Combobox/Combobox';
import { Partner } from 'api/FundingClient/codecs';
import { User } from 'api/AuthClient/codecs';
import { IndustryType } from 'api/UnderwritingClient/codecs';
import { findPartner, userFullName } from 'helpers/utils';
import { featureFlags } from 'helpers/featureFlags';
import { Filters } from './codecs';
import {
  ValidStages,
  validStageMappings,
  validUWSubStages,
  revenueBucketOptions,
  positionBucketOptions,
  isoCompetingSubOptions,
  slaStatusOptions,
  categoryStatusOptions,
} from './livePipelineConstants';

export interface LivePipelineFilterBoxProps {
  underwriters: string[];
  underwriterView: boolean;
  processingUsers: User[];
  isos?: Partner[];
  currentFilters: Filters;
  industries: IndustryType[];
  updateFilters: (object: Filters) => void;
}

interface NonUnderwriterFiltersProps {
  processingUsers: User[];
  selectedFilters: Filters;
  toSelectableOptions: (options: string[]) => SelectableOption[];
  handleSelectedFiltersChange: (
    filter: string,
    value: SelectableOption[]
  ) => void;
}

interface UnderwriterFiltersProps {
  underwriters: string[];
  isos: Partner[];
  industries: IndustryType[];
  selectedFilters: Filters;
  toSelectableOptions: (options: string[]) => SelectableOption[];
  handleSelectedFiltersChange: (
    filter: string,
    value: SelectableOption[]
  ) => void;
}

const toPrimeDealOption = (options: string[]): SelectableOption[] => {
  return options.map((o) => {
    switch (o) {
      case '2':
        return { text: 'No', value: '2' };
      case '0':
        return { text: 'Yes', value: '0' };
      default:
        return { text: '', value: '' };
    }
  });
};

const determineValidSubStages = (stages: string[]): string[] => {
  const stageNames =
    stages.length === 0 ? Object.keys(validStageMappings) : stages;
  const validSubStages: string[] = stageNames.reduce(
    (acc, stage): string[] => {
      const result = Object.keys(validStageMappings).includes(stage)
        ? validStageMappings[stage as ValidStages]
        : [];

      return [...acc, ...result];
    },

    [] as string[]
  );
  return [...new Set(validSubStages)];
};

const emptyFilters: Filters = {
  stage_name: [],
  sub_stage: [],
  ff_underwriter: [],
  decision_analyst_id: [],
  credit_committee: [],
  deal_type: [],
  merchant_partner_id: [],
  industry_name: [],
  revenue_bucket: [],
  position_bucket: [],
  iso_competing_sub_message: [],
  prime_deal_filter: [],
  sla_status_filter: [],
  category_filter: [],
};

const NonUnderwriterFilters = ({
  processingUsers,
  selectedFilters,
  toSelectableOptions,
  handleSelectedFiltersChange,
}: NonUnderwriterFiltersProps): JSX.Element => {
  const validSubStages = React.useMemo(
    () => determineValidSubStages(selectedFilters.stage_name),
    [selectedFilters]
  );

  const toDecisionAnalystSelectableOptions = (
    users: User[]
  ): SelectableOption[] => {
    const options = users
      .map((user) => {
        return {
          text: userFullName(user.first_name, user.last_name),
          value: user.id.toString(),
        };
      })
      .sort((a, b) => (a.text >= b.text ? 1 : -1));
    options.unshift({ text: 'Unassigned', value: 'Unassigned' });
    return options;
  };

  const toDecisionAnalystSelectableOptionValues = (
    values: string[]
  ): SelectableOption[] => {
    return values.map((value: string) => {
      const id = parseInt(value, 10);
      const user = processingUsers.find(
        (userResponse) => userResponse.id === id
      );
      return {
        text: user ? userFullName(user.first_name, user.last_name) : value,
        value: value,
      };
    });
  };

  return (
    <Grid gutter>
      <Grid.Item xs={12} s={6} m={4}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Stage"
          label={'Stage'}
          options={toSelectableOptions(Object.keys(validStageMappings))}
          values={toSelectableOptions(selectedFilters.stage_name)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('stage_name', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Sub-Stage"
          label={'Sub-Stage'}
          options={toSelectableOptions(validSubStages)}
          values={toSelectableOptions(selectedFilters.sub_stage)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('sub_stage', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Deal Type"
          label={'Deal Type'}
          options={toSelectableOptions(['New', 'Renewal'])}
          values={toSelectableOptions(selectedFilters.deal_type)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('deal_type', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Prequal Analyst"
          label={'Prequal Analyst'}
          options={toDecisionAnalystSelectableOptions(processingUsers)}
          values={toDecisionAnalystSelectableOptionValues(
            selectedFilters.decision_analyst_id
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('decision_analyst_id', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="ISO Competing Sub"
          label={'ISO Competing Sub'}
          options={toSelectableOptions(isoCompetingSubOptions)}
          values={toSelectableOptions(
            selectedFilters.iso_competing_sub_message
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('iso_competing_sub_message', newValues)
          }
        />
      </Grid.Item>
      {featureFlags.show_sla_status_and_stage_time && (
        <Grid.Item xs={12} s={6} m={4}>
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            placeholder="SLA Status"
            label="SLA Status"
            options={slaStatusOptions}
            values={slaStatusOptions.filter((option) =>
              selectedFilters.sla_status_filter.includes(option.value)
            )}
            onValueChange={(newValues: SelectableOption[]) =>
              handleSelectedFiltersChange('sla_status_filter', newValues)
            }
          />
        </Grid.Item>
      )}
    </Grid>
  );
};

const UnderwriterFilters = ({
  underwriters,
  isos,
  industries,
  selectedFilters,
  toSelectableOptions,
  handleSelectedFiltersChange,
}: UnderwriterFiltersProps): JSX.Element => {
  const DECISION_OR_CREDIT_COMMITTEE =
    featureFlags.use_decision_committee_naming
      ? 'Decision Committee'
      : 'Credit Committee';

  const toISOSelectableOptions = (isosList: Partner[]): SelectableOption[] => {
    return isosList.map((ISO) => {
      return { text: ISO.label, value: ISO.value.toString() };
    });
  };

  const toISOSelectableOptionValues = (
    values: string[]
  ): SelectableOption[] => {
    return values.map((value) => {
      return {
        text: findPartner(isos ?? [], parseInt(value))?.label ?? value,
        value: value,
      };
    });
  };

  const toIndustrySelectableOptions = (
    industryTypes: IndustryType[]
  ): SelectableOption[] => {
    return industryTypes.map((industry) => {
      return { text: industry.name, value: industry.name };
    });
  };

  const toIndustrySelectableOptionValues = (
    values: string[]
  ): SelectableOption[] => {
    return values.map((value) => {
      return {
        text: value,
        value: value,
      };
    });
  };

  return (
    <Grid gutter>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Sub-Stage"
          label="Sub-Stage"
          options={toSelectableOptions(validUWSubStages)}
          values={toSelectableOptions(selectedFilters.sub_stage)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('sub_stage', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Underwriter"
          label={'Underwriter'}
          options={toSelectableOptions(underwriters)}
          values={toSelectableOptions(selectedFilters.ff_underwriter)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('ff_underwriter', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder={DECISION_OR_CREDIT_COMMITTEE}
          label={DECISION_OR_CREDIT_COMMITTEE}
          options={toSelectableOptions(underwriters)}
          values={toSelectableOptions(selectedFilters.credit_committee)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('credit_committee', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        {featureFlags.show_uw_deal_category_filter ? (
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            searchable
            placeholder="Deal Category"
            label={'Deal Category'}
            options={categoryStatusOptions}
            values={categoryStatusOptions.filter((option) =>
              selectedFilters.category_filter.includes(option.value)
            )}
            onValueChange={(newValues: SelectableOption[]) =>
              handleSelectedFiltersChange('category_filter', newValues)
            }
          />
        ) : (
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            searchable
            placeholder="Deal Type"
            label={'Deal Type'}
            options={toSelectableOptions(['New', 'Renewal'])}
            values={toSelectableOptions(selectedFilters.deal_type)}
            onValueChange={(newValues: SelectableOption[]) =>
              handleSelectedFiltersChange('deal_type', newValues)
            }
          />
        )}
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="ISO"
          label={'ISO'}
          options={toISOSelectableOptions(isos ?? [])}
          values={toISOSelectableOptionValues(
            selectedFilters.merchant_partner_id
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('merchant_partner_id', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Industry"
          label={'Industry'}
          options={toIndustrySelectableOptions(industries)}
          values={toIndustrySelectableOptionValues(
            selectedFilters.industry_name
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('industry_name', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Revenue"
          label={'Revenue'}
          options={revenueBucketOptions}
          values={revenueBucketOptions.filter((option) =>
            selectedFilters.revenue_bucket.includes(option.value)
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('revenue_bucket', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="Existing Positions"
          label={'Existing Positions'}
          options={positionBucketOptions}
          values={positionBucketOptions.filter((option) =>
            selectedFilters.position_bucket.includes(option.value)
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('position_bucket', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          searchable
          placeholder="ISO Competing Sub"
          label={'ISO Competing Sub'}
          options={toSelectableOptions(isoCompetingSubOptions)}
          values={toSelectableOptions(
            selectedFilters.iso_competing_sub_message
          )}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('iso_competing_sub_message', newValues)
          }
        />
      </Grid.Item>
      <Grid.Item xs={12} s={6} m={4} l={3}>
        <Combobox
          closeMenuOnSelect={false}
          isMulti
          placeholder="Prime Deal"
          label={'Prime Deal'}
          options={[
            { text: 'Yes', value: '0' },
            { text: 'No', value: '2' },
          ]}
          values={toPrimeDealOption(selectedFilters.prime_deal_filter)}
          onValueChange={(newValues: SelectableOption[]) =>
            handleSelectedFiltersChange('prime_deal_filter', newValues)
          }
        />
      </Grid.Item>
      {featureFlags.show_sla_status_and_stage_time && (
        <Grid.Item xs={12} s={6} m={4} l={3}>
          <Combobox
            closeMenuOnSelect={false}
            isMulti
            placeholder="SLA Status"
            label="SLA Status"
            options={slaStatusOptions}
            values={slaStatusOptions.filter((option) =>
              selectedFilters.sla_status_filter.includes(option.value)
            )}
            onValueChange={(newValues: SelectableOption[]) =>
              handleSelectedFiltersChange('sla_status_filter', newValues)
            }
          />
        </Grid.Item>
      )}
    </Grid>
  );
};

export const LivePipelineFilterBox = ({
  underwriters,
  underwriterView,
  processingUsers,
  isos,
  currentFilters,
  industries,
  updateFilters,
}: LivePipelineFilterBoxProps): JSX.Element => {
  const [selectedFilters, setSelectedFilters] = React.useState(currentFilters);

  const [applyClearedFilters, setApplyClearedFilters] = React.useState(false);

  const handleSelectedFiltersChange = (
    filter: string,
    value: SelectableOption[]
  ): void => {
    setSelectedFilters({
      ...selectedFilters,
      [filter]: value.map((valueItem: SelectableOption) => {
        return valueItem.value;
      }),
    });
  };

  const applyFilters = useCallback(() => {
    updateFilters(selectedFilters);
  }, [selectedFilters, updateFilters]);

  React.useEffect(() => {
    const filtersEmpty = (Object.values(selectedFilters) as string[][]).every(
      (value) => value.length === 0
    );
    if (applyClearedFilters && filtersEmpty) {
      applyFilters();
      setApplyClearedFilters(false);
    }
  }, [
    selectedFilters,
    applyFilters,
    applyClearedFilters,
    setApplyClearedFilters,
  ]);

  const resetFilters: IconButtonProps['onClick'] = (e) => {
    setApplyClearedFilters(true);
    setSelectedFilters(emptyFilters);
    e.currentTarget.blur();
  };

  const toSelectableOptions = (options: string[]): SelectableOption[] => {
    return options.map((option) => {
      if (
        featureFlags.use_decision_committee_naming &&
        option === 'Credit Committee'
      ) {
        return { text: 'Decision Committee', value: option };
      }

      return { text: option, value: option };
    });
  };

  return (
    <Box margin={2}>
      <Accordion type="single" collapsible>
        <Accordion.Item value="filters">
          <Accordion.Trigger>
            <Box>Filters</Box>
          </Accordion.Trigger>
          <Accordion.Content>
            <Text>Select Filter(s)</Text>
            {underwriterView ? (
              <UnderwriterFilters
                underwriters={underwriters}
                isos={isos || []}
                industries={industries}
                selectedFilters={selectedFilters}
                toSelectableOptions={toSelectableOptions}
                handleSelectedFiltersChange={handleSelectedFiltersChange}
              />
            ) : (
              <NonUnderwriterFilters
                processingUsers={processingUsers}
                selectedFilters={selectedFilters}
                toSelectableOptions={toSelectableOptions}
                handleSelectedFiltersChange={handleSelectedFiltersChange}
              />
            )}
            <hr />
            <Flex flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
              <Button variant="secondary" onClick={resetFilters}>
                Reset
              </Button>
              <Button onClick={applyFilters}>Apply Filters</Button>
            </Flex>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
