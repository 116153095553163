import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { BANK_BASE_URL } from 'constants/globals';
import { DeclineDriversResponse } from 'types/api/banking/types';

export const fetchDeclineDrivers =
  async (): Promise<DeclineDriversResponse> => {
    const url = new URL('/api/v1/decline_drivers', BANK_BASE_URL());

    const response = await makeInternalAPIRequest<DeclineDriversResponse>(
      url,
      'GET'
    );

    if (!response.ok) {
      throw new NetworkError(
        response.status,
        'Failed to fetch decline drivers'
      );
    }

    return response.json();
  };
