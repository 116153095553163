import React from 'react';
import {
  Subheading,
  Grid,
  Select,
  TextArea,
  Flex,
  DatePicker,
  PlainDateTime,
  PlainDate,
  SelectableOption,
  Combobox,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import {
  shouldAdditionalInfoBeRequired,
  shouldReasonBeRequired,
} from 'components/BankingStipulationManager/BankingStipulationHelpers';
import { featureFlags } from 'helpers/featureFlags';
import { HashMap } from 'api/codecs';
import { useUnderwritersProvider } from '../AttachmentManager/UnderwritingAttachmentManager/UnderwritingAttachmentManagerContext';
import {
  SIGNED_CONTRACTS_STIPULATION_STATUS_LIST,
  STIPULATION_STATUS_LIST,
  BANK_VERIFICATION_TYPE_LIST,
} from '../AttachmentManager/constants';
import {
  StipulationStatus,
  BankVerificationType,
} from './stipulationManager.types';
import { isReasonArrayEmpty } from './stipulationHelpers';

export interface SingleStipulationFormState {
  status?: StipulationStatus;
  bankVerificationType?: BankVerificationType;
  externalNotes: string;
  internalComments: string;
  receivedAt?: PlainDateTime;
  closingAnalystId?: number;
  underwriterId?: number;
  reasons: string[];
}

export interface EditStipulationFormProps {
  name: string;
  formState: SingleStipulationFormState;
  onChange: (newState: SingleStipulationFormState) => void;
  eligibleForClosingAssignment: boolean;
  validReasons?: HashMap<string[]>;
}

export const EditStipulationForm = (
  props: EditStipulationFormProps
): JSX.Element => {
  const { role } = useUserContext();
  const { underwriters, processors } = useUnderwritersProvider();

  const nowDate = new Date();
  const now = new PlainDate(
    nowDate.getFullYear(),
    nowDate.getMonth() + 1,
    nowDate.getDate()
  );

  const statusOptions =
    props.name === 'Signed Contracts'
      ? SIGNED_CONTRACTS_STIPULATION_STATUS_LIST
      : STIPULATION_STATUS_LIST;

  // We need to include 'Streaming' as an option for Bank Verification Type
  // to support historic deals (otherwise it will display a blank value for 'Streaming').
  // If the user selects something other than 'Streaming',
  // we will then hide that option and only show the other options.
  const bankVerificationTypeOptions =
    props.formState.bankVerificationType === 'Streaming'
      ? [
          { value: 'Streaming', text: 'Streaming' },
          ...BANK_VERIFICATION_TYPE_LIST,
        ]
      : BANK_VERIFICATION_TYPE_LIST;

  const displayElegibilityMessage = (): string => {
    if (!props.eligibleForClosingAssignment) {
      return 'Closing Analyst cannot be adjusted at this time. Please contact your manager to make any changes.';
    }
    return '';
  };

  const notesValidation = (): boolean => {
    return (
      featureFlags.new_stipulations_process &&
      shouldAdditionalInfoBeRequired(props.name) &&
      !props.formState.externalNotes.trim()
    );
  };

  const reasonValidation = (): boolean => {
    return (
      featureFlags.new_stipulations_process_phase_2 &&
      shouldReasonBeRequired(props.name) &&
      isReasonArrayEmpty(props.formState.reasons)
    );
  };

  const validReasonsOptions = (stipName: string): SelectableOption[] => {
    if (!stipName || !props.validReasons || !props.validReasons[stipName]) {
      return [];
    }

    return props.validReasons[stipName].map((reason) => ({
      value: reason,
      text: reason,
    }));
  };

  return (
    <>
      <Flex justifyContent="center">
        <Subheading variant="section">Stipulation: {props.name}</Subheading>
      </Flex>
      <Grid gutter>
        <Grid.Item m={4}>
          <Select
            label="Status"
            value={props.formState.status ?? ''}
            onValueChange={(newVal) => {
              props.onChange({
                ...props.formState,
                status:
                  newVal === '' ? undefined : (newVal as StipulationStatus),
              });
            }}
            options={statusOptions}
          />
        </Grid.Item>

        <Grid.Item m={4}>
          <TextArea
            label="External Notes"
            value={props.formState.externalNotes}
            required={notesValidation()}
            onChange={(event) => {
              props.onChange({
                ...props.formState,
                externalNotes: event.target.value,
              });
            }}
          />
        </Grid.Item>

        <Grid.Item m={4}>
          <TextArea
            label="Internal Comments"
            value={props.formState.internalComments}
            onChange={(event) => {
              props.onChange({
                ...props.formState,
                internalComments: event.target.value,
              });
            }}
          />
        </Grid.Item>

        <Grid.Item m={4}>
          {props.name === 'Bank Verification' && (
            <Select
              label="Bank Verification Type"
              value={props.formState.bankVerificationType ?? ''}
              onValueChange={(newVal) => {
                props.onChange({
                  ...props.formState,
                  bankVerificationType:
                    newVal === ''
                      ? undefined
                      : (newVal as BankVerificationType),
                });
              }}
              options={bankVerificationTypeOptions}
              required={props.formState.status === 'uw_sign_off'}
            />
          )}
        </Grid.Item>

        <Grid.Item m={4}>
          <DatePicker
            label="Received At"
            selected={props.formState.receivedAt}
            onChange={(newVal) => {
              props.onChange({
                ...props.formState,
                receivedAt: newVal,
              });
            }}
            canSelectTime
            showDropdownCalendarPicker
            maxDate={now}
            showNowButton
          />
        </Grid.Item>

        {featureFlags.new_stipulations_process_phase_2 &&
          shouldReasonBeRequired(props.name) && (
            <Grid.Item m={4}>
              <Combobox
                closeMenuOnSelect={false}
                isMulti
                label="Reason"
                values={
                  props.formState.reasons
                    ? props.formState.reasons.map((reason) => ({
                        value: reason,
                        text: reason,
                      }))
                    : []
                }
                required={reasonValidation()}
                onValueChange={(newVals) =>
                  props.onChange({
                    ...props.formState,
                    reasons: newVals.map((v) => v.value),
                  })
                }
                options={validReasonsOptions(props.name)}
              />
            </Grid.Item>
          )}

        {role === 'admin' && (
          <Grid.Item m={4}>
            <Select
              label="Underwriter"
              value={props.formState.underwriterId?.toString() ?? ''}
              onValueChange={(newVal) => {
                props.onChange({
                  ...props.formState,
                  underwriterId:
                    newVal === '' ? undefined : parseInt(newVal, 10),
                });
              }}
              options={underwriters.map((uw) => ({
                text: `${uw.first_name} ${uw.last_name}`,
                value: uw.id.toString(),
              }))}
            />
          </Grid.Item>
        )}

        {role === 'processing' && (
          <Grid.Item m={4}>
            <Select
              errorMessage={displayElegibilityMessage()}
              disabled={!props.eligibleForClosingAssignment}
              label="Closing Analyst"
              value={props.formState.closingAnalystId?.toString() ?? ''}
              onValueChange={(newVal) => {
                props.onChange({
                  ...props.formState,
                  closingAnalystId:
                    newVal === '' ? undefined : parseInt(newVal, 10),
                });
              }}
              options={processors.map((uw) => ({
                text: `${uw.first_name} ${uw.last_name}`,
                value: uw.id.toString(),
              }))}
            />
          </Grid.Item>
        )}
      </Grid>
    </>
  );
};
