import React from 'react';
import {
  Banner,
  Box,
  Flex,
  Loading,
  PageContainer,
  Tabs,
} from '@forward-financing/fast-forward';
import { useParams } from 'react-router-dom';

import { BusinessPaynetSummaryTable } from './BusinessPaynetSummaryTable';
import { useBusinessPaynetReport } from './businessPaynetHooks';
import { BusinessPaynetDetailsTable } from './BusinessPaynetDetailsTable';
import { BusinessPaynetDeleteReportModal } from './BusinessPaynetDeleteReportModal';

type Params = { submissionUuid: string };

const TAB_VALUES: Record<string, string> = {
  BUSINESS_SUMMARY: 'Business Summary',
  PAYMENT_DETAILS: 'Payment Details',
};

export const BusinessPaynetPageContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<Params>();
  const {
    loading,
    data: businessPaynetData,
    error,
  } = useBusinessPaynetReport(submissionUuid);

  const BusinessSummaryContent = (): JSX.Element | null => {
    if (loading) {
      return <Loading />;
    }

    return businessPaynetData?.businessSummary && !error ? (
      <BusinessPaynetSummaryTable data={businessPaynetData.businessSummary} />
    ) : null;
  };

  return (
    <PageContainer>
      {error && (
        <Box mt={3}>
          <Banner>{error.message}</Banner>
        </Box>
      )}
      <Tabs defaultValue={TAB_VALUES.BUSINESS_SUMMARY}>
        <Flex justifyContent="space-between" alignItems="center">
          <Tabs.List wrap={'nowrap'}>
            <Tabs.Trigger value={TAB_VALUES.BUSINESS_SUMMARY}>
              {TAB_VALUES.BUSINESS_SUMMARY}
            </Tabs.Trigger>
            <Tabs.Trigger value={TAB_VALUES.PAYMENT_DETAILS}>
              {TAB_VALUES.PAYMENT_DETAILS}
            </Tabs.Trigger>
          </Tabs.List>
          {submissionUuid && businessPaynetData && (
            <BusinessPaynetDeleteReportModal
              reportId={businessPaynetData.reportId}
              submissionUuid={submissionUuid}
            />
          )}
        </Flex>
        <Tabs.Content value={TAB_VALUES.BUSINESS_SUMMARY}>
          <BusinessSummaryContent />
        </Tabs.Content>
        <Tabs.Content value={TAB_VALUES.PAYMENT_DETAILS}>
          <BusinessPaynetDetailsTable
            paymentDetails={businessPaynetData?.paymentDetails}
          />
        </Tabs.Content>
      </Tabs>
    </PageContainer>
  );
};
