/**
 * Made the decision to ignore test coverage for this file because it contains a lot of function
 * definitions that do not actually get run in this component due to fairly aggressive prop
 * drilling in this feature.
 *
 * In order to get this file up to our coverage standards, we would need to either mock
 * out the entire prequal form component tree to integration test the actual implementation
 * of these functions several components deep, or refactor the entire feature to elminate
 * this prop drilling and implement more effective unit tests.
 *
 * The latter option is very much on our roadmap, but would be a much larger undertaking than
 * we have the bandwidth to accomplish right now.
 *
 * Because this feature is almost exclusively using legacy patterns, spending the time now
 * to increase its code coverage with mocking only to have it be fully replaced with new fetch and
 * testing patterns delivers very little business value.
 *
 * In the short term, this allows us to make important bug fixes to the business logic here without
 * getting PRs blocked for coverage.
 *
 * @bradleyden - Jun 9, 2023
 */
//istanbul ignore file

/**
 * This rule was enabled after changes to this file passed UAT but before the PR could be merged in.
 * Because this was already tested, reviewed, and had a delivery date communicated to stakeholders,
 * it is too risky and out of scope to refactor now, but it is blocking the deploy.
 *
 * TODO: Refactor this file to implement await/async and re-enable this rule.
 */
/* eslint-disable promise/prefer-await-to-then */

import * as React from 'react';
import { Box, Loading, Subheading } from '@forward-financing/fast-forward';
import { featureFlags } from 'helpers/featureFlags';
import { toError } from 'helpers/errorUtils';
import { declinePatchSubmission } from 'api/underwriting/submissionFetchUtils';
import { SubmissionResponse } from 'types/api/underwriting/types';
import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { Toast } from '../shared/Toast';
import { Partner } from '../../api/FundingClient/codecs';
import { User } from '../../api/AuthClient/codecs';
import {
  generateEmptySubmission,
  generateEmptyCustomer,
  SubmissionObject,
} from '../../api/UnderwritingClient/utils/index';
import {
  UsState,
  Application,
  Account,
  Contact,
  Owner,
  Submission,
  Customer,
  Decline,
  Ownership,
  PrequalState,
  WebPresences,
  WebPresenceResponse,
  Opportunity,
  IndustryType,
} from '../../api/UnderwritingClient/codecs';
import { HTMLFormChangeOrMouseEvent } from '../../types/form';
import { DeclineDrivers } from '../../api/BankingClient/codecs';
import {
  buildOwnerModels,
  buildContactsFromOwners,
  buildAccount,
  setOwnershipPercentageForContacts,
  buildSubmission,
} from '../../api/UnderwritingClient/utils/DataBuilder';
import { sanitizeTargetValue, userFullName } from '../../helpers/utils';
import { FFLogger } from '../../api/LogClient';
import { LogData } from '../../api/AnalyticsGatewayClient/codecs';
import { UpdateApplicationBody } from './WizardFetchHooks';
import { ErrorPage } from './ErrorPage';
import { AnalystHeader } from './AnalystHeader/AnalystHeader';
import { Wizard } from './Wizard';

export interface PrequalFormProps {
  getPartners: () => Promise<Partner[]>;
  getIndustryTypes: () => Promise<IndustryType[]>;
  getUsStates: () => Promise<UsState[]>;
  getDeclineDrivers: () => Promise<DeclineDrivers>;
  getAuthenticatedUser: () => Promise<User>;

  getAccount: (applicationUuid: string) => Promise<Account>;
  getApplication: (applicationUuid: string) => Promise<Application>;
  getContacts: (applicationUuid: string) => Promise<Contact[]>;
  getOwners: (customerUuid: string) => Promise<Owner[]>;
  getWebPresence: (accountUuid: string) => Promise<WebPresenceResponse>;

  upsertApplicationContacts: (
    applicationUuid: string,
    owners: Contact[]
  ) => Promise<Contact[]>;
  upsertApplication: (
    accountUuid: string,
    application: Application
  ) => Promise<Application>;
  upsertAccount: (account: Account) => Promise<Account>;
  updateOwnership: (
    applicationUuid: string,
    ownerships: Ownership[]
  ) => Promise<void>;
  bulkUpdateWebPresence: (
    accountUuid: string,
    webPresences?: WebPresences
  ) => Promise<WebPresenceResponse>;
  createOpportunity: (
    applicationUuid: string,
    customerUuid: string
  ) => Promise<Opportunity>;
  declineApplication: (
    applicationUuid: string,
    decline?: Decline
  ) => Promise<Opportunity | void>;
  applicationUuid?: string;
  loadGooglePlacesLibrary: () => void;
  sendUnmaskedFieldLogs: (
    user: User,
    source: string
  ) => (data: LogData) => Promise<void>;
  updateApplication: (
    input: UpdateApplicationBody
  ) => Promise<MutationResponse>;
}

interface State {
  partners: Partner[];
  industryTypes: IndustryType[];
  usStates: UsState[];
  declineDriversList: DeclineDrivers;
  submission: Submission;
  customer: Customer;
  matchedCustomerUuid: string;
  error: boolean;
  loading: boolean;
  loggedInUser: User;
  toastError: string;
}

export class PrequalForm extends React.Component<PrequalFormProps, State> {
  constructor(props: PrequalFormProps) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      partners: [],
      industryTypes: [],
      usStates: [],
      declineDriversList: [],
      submission: generateEmptySubmission(),
      customer: generateEmptyCustomer(),
      matchedCustomerUuid: '',
      loggedInUser: {
        id: 0,
        first_name: '',
        last_name: '',
        role: '',
        sub_role: null,
        email: '',
        uuid: '',
        anonymized_id: '',
        api_key: '',
        cell_phone: null,
        created_by: '',
        current_ip_address: null,
        home_phone: null,
        img_src: null,
        inserted_at: null,
        is_activated: null,
        last_signed_in_at: null,
        record_id: null,
        role_id: 0,
        sign_in_count: 0,
      },
      toastError: '',
    };
  }

  get application(): Application {
    const {
      submission: {
        uuid,
        notes,
        record_id,
        loan_use,
        capital_needed,
        partner_id,
        partner_uuid,
        prequal_state_attributes,
        submission_source,
        ownership_percentages,
        sales_rep_email,
        available_renewal_url,
      },
    } = this.state;

    return {
      uuid,
      notes,
      record_id,
      loan_use,
      capital_needed,
      partner_id,
      partner_uuid,
      prequal_state_attributes,
      submission_source,
      ownership_percentages,
      sales_rep_email,
      available_renewal_url,
    };
  }

  // args: e: React.MouseEvent<HTMLButtonElement>
  setToastError = (toastError: string) => (): void => {
    void this.setState({ toastError });
  };

  componentDidMount(): void {
    document.title = 'Prequal Form';

    const applicationPromise: Promise<Submission | null> = this.props
      .applicationUuid
      ? buildSubmission(
          this.props.applicationUuid,
          this.props.getApplication,
          this.props.getAccount,
          this.props.getContacts,
          this.props.getWebPresence,
          this.props.getOwners
        )
      : Promise.resolve(null);

    Promise.all([
      this.props.getPartners(),
      this.props.getIndustryTypes(),
      this.props.getUsStates(),
      featureFlags.fetch_decline_drivers_from_underwriting
        ? undefined
        : this.props.getDeclineDrivers(),
      this.props.getAuthenticatedUser(),
      applicationPromise,
    ])
      .then(
        ([
          partners,
          industryTypes,
          usStates,
          declineDriversList,
          loggedInUser,
          submission,
        ]) => {
          this.setState((state) => ({
            partners,
            industryTypes,
            usStates,
            declineDriversList: declineDriversList || [],
            loggedInUser,
            submission: submission ? submission : state.submission,
            matchedCustomerUuid:
              submission?.prequal_state_attributes.selected_customer || '',
            error: false,
            loading: false,
          }));
        }
      )
      .catch((e: unknown) => {
        const error = toError(e);
        FFLogger.error(error);
        this.setState({ error: true, loading: false });
      });

    void this.props.loadGooglePlacesLibrary();
  }

  storeOwners = (owners: Contact[]): void => {
    this.setState((state) => ({
      submission: {
        ...state.submission,
        contacts: owners,
      },
    }));
  };

  handleCustomerChange = (customer: Customer): void => {
    this.setState((state) => ({
      customer: {
        ...state.customer,
        ...customer,
      },
    }));
  };

  handleApplicationChange = (
    object: SubmissionObject
  ): ((e: HTMLFormChangeOrMouseEvent) => void) => {
    // If the first step of the form was completed with no UUID, we will redirect to the url using the UUID of the recently
    // created submission, so that the wizard steps will be correctly handled going forward in the event of a refresh or
    // loading the wizard in a new browser at a later time.
    if (
      this.state.submission.uuid &&
      !window.location.href.includes(this.state.submission.uuid)
    ) {
      window.history.pushState(
        {},
        'Prequal Form',
        `/prequalification/${this.state.submission.uuid}`
      );
    }

    return (e) => {
      e.persist();

      const target = (
        e.currentTarget ? e.currentTarget : e.target
      ) as HTMLInputElement;

      const value =
        target.type && target.type === 'checkbox'
          ? target.checked
          : sanitizeTargetValue(target);

      switch (object) {
        case SubmissionObject.Application:
          this.setState((state) => ({
            submission: {
              ...state.submission,
              [target.name]: value,
            },
          }));
          break;

        case SubmissionObject.Account:
          this.setState((state) => ({
            submission: {
              ...state.submission,
              account: {
                ...state.submission.account,
                [target.name]: value,
              },
            },
          }));
          break;

        case SubmissionObject.AccountAddress:
          this.setState((state) => ({
            submission: {
              ...state.submission,
              account: {
                ...state.submission.account,
                address: {
                  ...state.submission.account.address,
                  [target.name]: value,
                },
              },
            },
          }));
          break;

        case SubmissionObject.WebPresence: {
          const previous_url =
            this.state.submission.account.web_presences?.[
              target.name as keyof WebPresences
            ]?.url;

          this.setState((state) => ({
            submission: {
              ...state.submission,
              account: {
                ...state.submission.account,
                web_presences: {
                  ...state.submission.account.web_presences,
                  [target.name]: {
                    ...state.submission.account.web_presences?.[
                      target.name as keyof WebPresences
                    ],
                    url: value,
                    urlChanged: previous_url !== value,
                  },
                },
              },
            },
          }));
          break;
        }
      }
    };
  };

  isDeclined = (): boolean => {
    const {
      submission: { decline },
    } = this.state;
    return decline && decline.decline_drivers.length > 0;
  };

  // Api Handlers

  sendDeclineRequest = (
    submissionUuid: string
  ): Promise<void | SubmissionResponse> => {
    return declinePatchSubmission(submissionUuid, {
      decline_driver_notes: this.state.submission.decline.decline_driver_notes,
      decline_drivers: this.state.submission.decline.decline_drivers.join(';'),
      is_previous_decline:
        this.state.submission.decline.previous_decline || false,
      declined_source: 'prequal',
    }).catch((e) => {
      const err = toError(e);
      this.setState({ toastError: err.message });
      FFLogger.error(err);
    });
  };

  handleCreateOpportunityAndUpdatePrequalState = async (
    prequalStateAttr: PrequalState,
    shouldDecline?: boolean
  ): Promise<void> => {
    let opportunity: Opportunity | undefined;
    try {
      opportunity = await this.props.createOpportunity(
        this.state.submission.uuid || '',
        this.state.matchedCustomerUuid
      );
    } catch (e: unknown) {
      const err = toError(e);
      this.setState({ toastError: err.message });
      FFLogger.error(err);
    }

    if (opportunity && shouldDecline) {
      await this.sendDeclineRequest(opportunity.uuid);
      this.handleUpdatePrequalAndUpsertApplication(
        prequalStateAttr,
        opportunity.uuid
      );
    } else if (opportunity) {
      this.handleUpdatePrequalAndUpsertApplication(
        prequalStateAttr,
        opportunity.uuid
      );
    }

    return undefined;
  };

  handleCustomerSearchResultsReviewed = (
    customerUuid: string,
    customerOwners: Owner[],
    industry_id: number
  ): void => {
    const prequalStateAttr: PrequalState = {
      ...this.state.submission.prequal_state_attributes,
      customer_search_results_reviewed: new Date(),
      selected_customer: customerUuid,
    };

    // Update the state with the contacts only if the uuid is not empty (meaning a customer was selected)
    // AND the new uuid is different from the previous uuid
    // AND the list has owners
    if (
      customerUuid !== '' &&
      this.state.matchedCustomerUuid !== customerUuid &&
      customerOwners.length > 0
    ) {
      // Convert the Owner objects to Contact objects
      const incomingContacts: Contact[] =
        buildContactsFromOwners(customerOwners);

      // Add to the list only the current Contacts
      // that doesn't have the first_name empty
      // and remove their owner_ids, this is to avoid using Owners from a different Customer
      const currentContacts: Contact[] = this.state.submission.contacts
        .filter((contact) => contact.first_name !== '')
        .map((contact) => ({ ...contact, owner_id: undefined }));

      // Save in state all the Contacts
      this.storeOwners([...currentContacts, ...incomingContacts]);
    }

    this.setState({ matchedCustomerUuid: customerUuid });

    this.handleUpdatePrequalAndUpsertApplication(prequalStateAttr);

    // If the industry id is not set or the selected customer has a different industry id, update the account
    // If the user is creating a new customer and industry id has already been set, do not override the industry id
    if (
      !this.state.submission.account.industry_id ||
      (!!industry_id &&
        industry_id !== this.state.submission.account.industry_id)
    ) {
      this.setIndustryIdFromMatchedCustomer(industry_id);
    }
  };

  setIndustryIdFromMatchedCustomer = (industry_id: number): void => {
    void this.props
      .upsertAccount({
        ...this.state.submission.account,
        industry_id: industry_id,
      })
      .then((account) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            account: {
              ...state.submission.account,
              industry_id: account.industry_id,
            },
          },
        }));
      });
  };

  handleOwnerStepCompleted = (contacts: Contact[]): void => {
    const stateApplication: Application = {
      ...this.application,
      prequal_state_attributes: this.isDeclined()
        ? {
            ...this.state.submission.prequal_state_attributes,
            ...{ owners_completed: true, bank_accounts_completed: true }, // if it's a decline skip bank accounts step
          }
        : {
            ...this.state.submission.prequal_state_attributes,
            ...{ owners_completed: true },
          },
    };

    Promise.all([
      this.props.upsertApplication(
        this.state.submission.account.uuid || '',
        stateApplication
      ),
    ])
      .then(([application]) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            ...application,
            contacts: buildOwnerModels(
              contacts,
              application.prequal_state_attributes === null
                ? false
                : application.prequal_state_attributes.owners_completed
            ),
          },
          showToast: false,
        }));
      })
      .catch((e) => {
        const error = toError(e);
        this.setState({ toastError: error.message });
        FFLogger.error(error);
      });
  };

  handlePrequalComplete = async (shouldDecline?: boolean): Promise<void> => {
    const prequalStateAttr = {
      ...this.state.submission.prequal_state_attributes,
      prequal_completed_at: new Date(),
    };

    await this.handleCreateOpportunityAndUpdatePrequalState(
      prequalStateAttr,
      shouldDecline
    );

    await this.props.updateApplication({
      applicationUuid: this.state.submission.uuid || '',
      onboardingAnalystUuid: this.state.loggedInUser.uuid,
      submissionUuid: this.state.submission.uuid || '',
      onBoardingStatus: 'completed',
    });

    return undefined;
  };

  handleUpdatePrequalAndUpsertApplication = (
    prequal_state_attributes: PrequalState,
    underwritingRedirectUuid?: string
  ): void => {
    const { loggedInUser } = this.state;
    const stateApplication: Application = {
      ...this.application,
      prequal_analyst_name: userFullName(
        loggedInUser.first_name,
        loggedInUser.last_name
      ),
      prequal_state_attributes: {
        ...this.state.submission.prequal_state_attributes,
        ...prequal_state_attributes,
      },
    };

    this.props
      .upsertApplication(
        this.state.submission.account.uuid || '',
        stateApplication
      )
      .then((application) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            ...application,
            showToast: false,
          },
        }));
        if (underwritingRedirectUuid) {
          window.location.replace(
            `${window.location.origin}/submissions/${underwritingRedirectUuid}`
          );
        }
      })
      .catch((e) => {
        const err = toError(e);
        this.setState({ toastError: err.message });
        FFLogger.error(err);
      });
  };

  handleContactsSubmit = (owners: Contact[]): void => {
    const stateApplication: Application = {
      ...this.application,
      prequal_state_attributes: this.isDeclined()
        ? {
            ...this.state.submission.prequal_state_attributes,
            ...{ owners_completed: true, bank_accounts_completed: true }, // if it's a decline skip bank accounts step
          }
        : {
            ...this.state.submission.prequal_state_attributes,
            ...{ owners_completed: true },
          },
    };

    this.props
      .upsertApplicationContacts(stateApplication.uuid || '', owners)
      .then((contacts) => {
        const modifiedContacts: Contact[] = setOwnershipPercentageForContacts(
          contacts,
          owners
        );
        const ownerships: Ownership[] = modifiedContacts.map(
          (contact): Ownership => ({
            contact_uuid: contact.uuid || '',
            ownership_percentage: (contact.ownership_percentage || 0) * 1,
          })
        );
        return this.props
          .updateOwnership(this.application.uuid || '', ownerships)
          .then(() => {
            return Promise.all([
              this.props.upsertApplication(
                this.state.submission.account.uuid || '',
                stateApplication
              ),
              modifiedContacts,
              ownerships,
            ]);
          });
      })
      .then(([application, modifiedContacts, ownerships]) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            ...application,
            ownership_percentages: ownerships,
            contacts: buildOwnerModels(
              modifiedContacts,
              application.prequal_state_attributes === null
                ? false
                : application.prequal_state_attributes.owners_completed,
              ownerships
            ),
          },
          showToast: false,
        }));
      })
      .catch((e) => {
        const err = toError(e);
        this.setState({ toastError: err.message });
        FFLogger.error(err);
      });
  };

  handleAccountInformationSubmit = async (account?: Account): Promise<void> => {
    const { loggedInUser } = this.state;

    const stateApplication: Application = {
      ...this.application,
      prequal_analyst_name: userFullName(
        loggedInUser.first_name,
        loggedInUser.last_name
      ),
      prequal_state_attributes: {
        ...this.state.submission.prequal_state_attributes,
        account_completed: true,
        customer_search_results_reviewed: null,
      },
    };

    const accountToSubmit =
      featureFlags.show_updated_prequal_form && account
        ? account
        : this.state.submission.account;

    await this.props
      .upsertAccount(accountToSubmit)
      .then((upsertedAccount) =>
        Promise.all([
          upsertedAccount,
          this.props.upsertApplication(
            upsertedAccount.uuid || '',
            stateApplication
          ),
          this.props.getWebPresence(upsertedAccount.uuid || ''),
        ])
      )
      .then(([upsertedAccount, application, web_presences]) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            ...application,
            // buildAccount used in this case since we have to incorporate web presences to the account.
            // prequal_state can be null here as we are using directly from the API response
            account: buildAccount(
              upsertedAccount,
              web_presences,
              application.prequal_state_attributes === null
                ? false
                : application.prequal_state_attributes.account_completed
            ),
          },
          showToast: false,
        }));
      })
      .catch((e) => {
        const err = toError(e);
        this.setState({ toastError: err.message });
        FFLogger.error(err);
      });
  };

  handleAdditionalInformationSubmit = (): void => {
    const stateApplication: Application = {
      ...this.application,
      prequal_state_attributes: {
        ...this.state.submission.prequal_state_attributes,
        other_info_completed: true,
      },
    };

    const account = this.state.submission.account;

    Promise.all([
      this.props.upsertAccount(account),
      this.props.upsertApplication(account.uuid || '', stateApplication),
      this.props.bulkUpdateWebPresence(
        account.uuid || '',
        account.web_presences
      ),
    ])
      .then(([upsertedAccount, application, web_presences]) => {
        this.setState((state) => ({
          ...state,
          submission: {
            ...state.submission,
            ...application,
            // buildAccount used in this case since we have to incorporate web presences to the account.
            account: buildAccount(
              upsertedAccount,
              web_presences,
              application.prequal_state_attributes === null
                ? false
                : application.prequal_state_attributes.account_completed
            ),
          },
          showToast: false,
        }));
      })
      .catch((e) => {
        const err = toError(e);
        this.setState({ toastError: err.message });
        FFLogger.error(err);
      });
  };

  setDecline = (decline: Decline): void => {
    this.setState((state) => ({
      ...state,
      submission: {
        ...state.submission,
        decline: decline,
      },
    }));
  };

  handleDeclineAndSubmit = async (): Promise<void> => {
    try {
      await this.props.declineApplication(this.application.uuid || '');
    } catch (e: unknown) {
      const err = toError(e);
      this.setState({ toastError: err.message });
      FFLogger.error(err);
    }

    return this.handlePrequalComplete(true);
  };

  render(): JSX.Element {
    return (
      <div className="prequal-form">
        <div className="navbar header-color">
          <div className="container-header">
            {this.state.toastError ? (
              <Toast
                mainMessage={this.state.toastError}
                onClose={this.setToastError('')}
                leadingIcon="fas fa-exclamation-triangle fa-2x"
              />
            ) : (
              !this.state.error &&
              !this.state.loading && (
                <AnalystHeader
                  loggedInUser={this.state.loggedInUser}
                  startTime={
                    this.state.submission.prequal_state_attributes
                      .prequal_started_at
                  }
                />
              )
            )}
          </div>
        </div>
        <div className="form-background">
          <div className="form-container">
            {this.state.loading && <Loading text="Loading Submission Data" />}
            {this.state.error ? (
              <ErrorPage
                mainMessage="Oops!"
                secondaryMessages={
                  <Box>
                    <Subheading>Something went wrong...</Subheading>
                    <Subheading variant="section">Please try again</Subheading>
                  </Box>
                }
                buttonText="Reload the page"
                buttonIcon="sync"
                onClick={() => {
                  window.location.reload();
                }}
              />
            ) : (
              !this.state.loading && (
                <Wizard
                  submission={this.state.submission}
                  usStates={this.state.usStates}
                  partners={this.state.partners}
                  industryTypes={this.state.industryTypes}
                  declineDriversList={this.state.declineDriversList}
                  loggedInUser={this.state.loggedInUser}
                  handleOwnersSubmit={this.handleContactsSubmit}
                  handleApplicationOrAccountChange={
                    this.handleApplicationChange
                  }
                  handleAccountInformationSubmit={
                    this.handleAccountInformationSubmit
                  }
                  handleAdditionalInformationSubmit={
                    this.handleAdditionalInformationSubmit
                  }
                  handlePrequalComplete={this.handlePrequalComplete}
                  handleCustomerChange={this.handleCustomerChange}
                  handleCustomerSearchResultsReviewed={
                    this.handleCustomerSearchResultsReviewed
                  }
                  handleOwnerStepCompleted={this.handleOwnerStepCompleted}
                  storeOwners={this.storeOwners}
                  setDecline={this.setDecline}
                  handleDeclineAndSubmit={this.handleDeclineAndSubmit}
                  isDeclined={this.isDeclined()}
                  sendUnmaskedFieldLogs={this.props.sendUnmaskedFieldLogs(
                    this.state.loggedInUser,
                    'Prequal Form'
                  )}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
