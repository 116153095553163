import { MutationResponse } from 'apiHooks/genericFetchHooks';
import { useUpdateApiSubmission } from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';

type UpdateBody = {
  emailContent: string;
};

type UseUpdateSubmissionEmailContentResult = [
  (input: UpdateBody) => Promise<MutationResponse>,
  { emailContent?: string; loading: boolean; error?: Error }
];

export const useUpdateSubmissionEmailContent = (
  submissionUuid: string
): UseUpdateSubmissionEmailContentResult => {
  const [updateSubmission, { data, loading, error }] = useUpdateApiSubmission();

  const updateFunction = async (
    args: UpdateBody
  ): Promise<MutationResponse> => {
    return await updateSubmission({
      submissionUuid,
      updateBody: {
        email_content: args.emailContent,
      },
    });
  };

  useLogError(error);

  return [
    updateFunction,
    {
      emailContent: data?.email_content ?? undefined,
      loading,
      error,
    },
  ];
};
