import { Banner, Grid } from '@forward-financing/fast-forward';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BusinessOverviewSection } from './BusinessOverview/BusinessOverviewSection';
import { OwnerOverviewSection } from './OwnerOverview/OwnerOverviewSection';
import { OwnerOverviewSection as OwnerOverviewSectionOld } from './OwnerOverview/OwnerOverviewSectionOld';
import { useGetSubmission } from './SubmissionUnderwritingContainerHooks';
import { featureFlags } from 'helpers/featureFlags';

type Params = {
  submissionUuid: string;
};

export const OverviewSectionsRouteContainer = (): JSX.Element => {
  const { submissionUuid } = useParams<keyof Params>() as Params;

  const { data: submission, error: errorSubmission } =
    useGetSubmission(submissionUuid);

  return (
    <>
      {errorSubmission && (
        <Banner dismissable={false} variant="error">
          {errorSubmission.message}
        </Banner>
      )}
      <Grid gutter>
        <Grid.Item>
          {featureFlags.experian_consumer_3pi_owner ? (
            <OwnerOverviewSection
              submissionUuid={submissionUuid}
              submission={submission}
            />
          ) : (
            <OwnerOverviewSectionOld
              submissionUuid={submissionUuid}
              submission={submission}
            />
          )}
        </Grid.Item>
        <Grid.Item>
          <BusinessOverviewSection
            submissionUuid={submissionUuid}
            submission={submission}
          />
        </Grid.Item>
      </Grid>
    </>
  );
};
