import * as React from 'react';
import { FundingClient } from '../../api/FundingClient';
import { UnderwritingClient } from '../../api/UnderwritingClient';
import { Submissions } from './Submissions';
import { AuthClient } from '../../api/AuthClient';
import { AnalyticsGatewayClient } from '../../api/AnalyticsGatewayClient';
import { GOOGLE_MAPS_SOURCE } from '../../constants/globals';
import { useParams } from 'react-router-dom';

type Params = {
  uuid: string;
};

/* istanbul ignore next */
const loadGooglePlacesLibrary = (): void => {
  const script = document.createElement('script');
  script.src = GOOGLE_MAPS_SOURCE;
  script.charset = 'utf-8';
  script.async = true;
  document.head.appendChild(script);
};

export const SubmissionsContainer = (): JSX.Element => {
  const { uuid } = useParams<keyof Params>() as Params;

  return (
    <Submissions
      getPartners={FundingClient.fetchPartners}
      getIndustryTypes={UnderwritingClient.fetchIndustryTypes}
      getUsStates={UnderwritingClient.fetchStates}
      getAuthenticatedUser={AuthClient.fetchAuthenticatedUser}
      getApplication={UnderwritingClient.fetchApplication}
      getCustomer={UnderwritingClient.fetchCustomer}
      getContacts={UnderwritingClient.fetchContacts}
      getWebPresence={UnderwritingClient.fetchWebPresence}
      getOpportunity={UnderwritingClient.fetchOpportunity}
      fetchCustomerOwners={UnderwritingClient.fetchCustomerOwners}
      upsertApplicationContacts={UnderwritingClient.upsertApplicationContacts}
      updateApplication={UnderwritingClient.updateApplication}
      updateCustomer={UnderwritingClient.updateCustomer}
      updateCustomerOwner={UnderwritingClient.updateCustomerOwner}
      createCustomerOwner={UnderwritingClient.createCustomerOwner}
      updateRenewalSubmitterEmail={
        UnderwritingClient.updateRenewalSubmitterEmail
      }
      updateOwnership={UnderwritingClient.updateOwnership}
      bulkUpdateWebPresence={UnderwritingClient.bulkUpdateWebPresence}
      applicationUuid={uuid}
      sendUnmaskedFieldLogs={AnalyticsGatewayClient.createLogSender}
      loadGooglePlacesLibrary={loadGooglePlacesLibrary}
    />
  );
};
