import queryString from 'query-string';
import { AuthClient } from '../AuthClient';

import { UNDERWRITING_BASE_URL } from '../../constants/globals';
import {
  SearchResult,
  Application,
  UsState,
  Account,
  Contact,
  Owner,
  Ownership,
  WebPresenceResponse,
  WebPresence,
  WebPresences,
  Opportunity,
  IndustryType,
  Customer,
  CustomerSearchParams,
  SearchParams,
  PrequalState,
  Address,
  BankInfo,
  Meta,
  SubmissionSearchResult,
} from './codecs';
import { AuthenticationHeaders } from '../AuthClient/codecs';
import isEmpty from 'lodash/isEmpty';
import { FetchResponse, HashMap } from '../codecs';
import { buildApplication } from './utils/DataBuilder';
import {
  formatDateString,
  mdyFormat,
  ymdFormat,
} from '../../helpers/string/dateUtils';
import { FeatureFlagPayload } from '../../helpers/featureFlags/types';
import { makeInternalAPIRequest } from '../makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import {
  RenewalComparisonResponse,
  SubmissionResponse,
} from 'types/api/underwriting/types';

export class UnderwritingClient extends AuthClient {
  private static BASE_URL = UNDERWRITING_BASE_URL();

  // common usage

  static fetchStates = (): Promise<UsState[]> => {
    return UnderwritingClient.get<UsState[]>(
      `${UnderwritingClient.BASE_URL}api/v1/states`
    ).then((response) => {
      if (!response.ok) {
        throw new NetworkError(
          response.status,
          'Something went wrong fetching states'
        );
      }
      return response.json();
    });
  };

  static fetchIndustryTypes = (): Promise<IndustryType[]> => {
    const getIndustryTypes = (
      authenticatedHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<IndustryType[]>> =>
      UnderwritingClient.get<IndustryType[]>(
        `${UnderwritingClient.BASE_URL}api/v2/industries`,
        {
          headers: authenticatedHeaders,
        }
      );

    return UnderwritingClient.authenticateApiRequest<IndustryType[]>(
      getIndustryTypes
    ).then((response) => {
      if (!response.ok) {
        throw new NetworkError(
          response.status,
          'Something went wrong fetching industries'
        );
      }
      return response.json();
    });
  };

  // applications/submissions
  static fetchApplication = (applicationUuid: string): Promise<Application> => {
    const getApplication = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Application>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}`;

      return UnderwritingClient.get<Application>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getApplication)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong fetching application'
          );
        }
        return response.json();
      })
      .then((application) => application);
  };

  static createApplication = (
    accountUuid: string,
    application: Application
  ): Promise<Application> => {
    const postApplication = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Application>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${accountUuid}/applications`;

      const body = {
        loan_use: application.loan_use,
        capital_needed: application.capital_needed,
        prequal_analyst_name: application.prequal_analyst_name,
        sales_rep_email: application.sales_rep_email,
        prequal_state_attributes: application.prequal_state_attributes,
      };

      return UnderwritingClient.post<
        HashMap<string | number | PrequalState | undefined>,
        Application
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return (
      UnderwritingClient.authenticateApiRequest(postApplication)
        .then((response) => {
          if (!response.ok) {
            throw new NetworkError(
              response.status,
              'Something went wrong updating application'
            );
          }
          return response.json();
        })
        // buildApplication used in here since otherwise we would have to call build on every area that submits an application
        // to prepare the incoming prequal state dates as moments instead of strings (which is what we recieve).
        .then((applicationResponse) => buildApplication(applicationResponse))
    );
  };

  static updateApplication = (
    application: Application,
    syncToCrmGW = false
  ): Promise<Application> => {
    const patchApplication = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Application>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${application.uuid}`;

      const body = {
        loan_use: application.loan_use,
        capital_needed: application.capital_needed,
        partner_id: application.partner_id,
        partner_uuid: application.partner_uuid,
        prequal_state_attributes: application.prequal_state_attributes,
        prequal_analyst_name: application.prequal_analyst_name,
        notes: application.notes,
        sales_rep_email: application.sales_rep_email,
        sync_to_crmgw: syncToCrmGW,
      };

      if (!syncToCrmGW) {
        body.prequal_state_attributes = application.prequal_state_attributes;
      }

      return UnderwritingClient.patch<
        HashMap<string | number | PrequalState | boolean | undefined>,
        Application
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return (
      UnderwritingClient.authenticateApiRequest(patchApplication)
        .then((response) => {
          if (!response.ok) {
            throw new NetworkError(
              response.status,
              'Something went wrong updating application'
            );
          }
          return response.json();
        })
        // buildApplication used in here since otherwise we would have to call build on every area that submits an application
        // to prepare the incoming prequal state dates as moments instead of strings (which is what we recieve).
        .then((applicationResponse) => buildApplication(applicationResponse))
    );
  };

  static declineApplication = (
    applicationUuid: string
  ): Promise<Opportunity | void> => {
    const sendApplicationDecline = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/decline`;

      const body = {
        prequal_state_attributes: {
          application_should_be_declined: true,
        },
      };
      return UnderwritingClient.patch<
        HashMap<string | boolean | undefined | Record<string, unknown>>,
        Opportunity
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest<Opportunity>(
      sendApplicationDecline
    ).then((response) => {
      if (response.status !== 200) {
        throw new NetworkError(
          response.status,
          'Problem declining Application'
        );
      }
    });
  };

  static searchApplications = (
    searchParams: SearchParams
  ): Promise<SearchResult> => {
    interface SearchResultResponse {
      meta: Meta;
      applications: SubmissionSearchResult[];
    }

    const findApplications = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<SearchResultResponse>> => {
      const url = `${
        UnderwritingClient.BASE_URL
      }partner/api/v1/applications?${queryString.stringify(searchParams)}`;

      return UnderwritingClient.get<SearchResultResponse>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest<SearchResultResponse>(
      findApplications
    )
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong searching for application'
          );
        }
        return response.json();
      })
      .then((response) => ({
        meta: response.meta,
        submissionSearchResults: response.applications,
      }));
  };

  static searchCustomers = (
    searchParams: CustomerSearchParams
  ): Promise<Customer[]> => {
    const url = new URL(
      `api/v2/customers?${queryString.stringify(searchParams)}`,
      UNDERWRITING_BASE_URL()
    );

    return makeInternalAPIRequest<Customer[]>(url, 'GET')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          throw new NetworkError(
            response.status,
            'You do not have permission to access this content, please contact your manager.'
          );
        } else if (response.status === 500) {
          throw new NetworkError(
            response.status,
            'Something went wrong. Please wait a moment and try again. If the issue persists, please contact the engineering team.'
          );
        } else {
          throw new NetworkError(
            response.status,
            'Something went wrong searching for customers. Please try again.'
          );
        }
      })
      .then((customers) => customers);
  };

  static assignApplicationToExistingAccount = (
    applicationUuid: string,
    accountUuid: string
  ): Promise<void> => {
    const reassignApplicationToAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<void>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/reassign`;

      const body = {
        account_uuid: accountUuid,
      };

      return UnderwritingClient.patch<HashMap<string | number[]>, void>(
        url,
        body,
        {
          headers: authenticationHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(
      reassignApplicationToAccount
    ).then((response) => {
      if (response.status !== 200) {
        throw new NetworkError(response.status, 'Problem reassigning Account');
      }
    });
  };

  // accounts

  static fetchAccount = (applicationUuid: string): Promise<Account> => {
    const getAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/accounts`;

      return UnderwritingClient.get<Account>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getAccount)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Problem fetching Account');
        }
        return response.json();
      })
      .then((account) => account);
  };

  static fetchCustomer = (customerUuid: string): Promise<Account> => {
    const getCustomer = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/customers/${customerUuid}`;

      return UnderwritingClient.get<Account>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getCustomer)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Problem fetching Customer');
        }
        return response.json();
      })
      .then((account) => account);
  };

  static createAccount = (account: Account): Promise<Account> => {
    const postAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts`;

      const body = {
        legal_name: account.legal_name,
        name: account.name,
        phone: account.phone,
        industry_id: account.industry_id,
        entity_type: account.entity_type,
        fein: account.fein,
        started_on: account.started_on,
        current_address_attributes: account.address,
      };

      return UnderwritingClient.post<
        HashMap<string | number | Address | undefined>,
        Account
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(postAccount)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Problem creating Account');
        }
        return response.json();
      })
      .then((accountResponse) => accountResponse);
  };

  static updateCustomer = (
    account: Account,
    syncToCrmGW = false
  ): Promise<Account> => {
    const patchCustomer = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/customers/${account.uuid}`;
      const { bank_account } = account;

      const body = {
        legal_name: account.legal_name || '',
        name: account.name || '',
        phone: account.phone || '',
        industry_id: account.industry_id || '',
        entity_type: account.entity_type || '',
        fein: account.fein || '',
        started_on: isEmpty(account.started_on || '')
          ? account.started_on || ''
          : formatDateString(account.started_on || '', mdyFormat, ymdFormat),
        current_address_attributes: account.address,
        sync_to_crmgw: syncToCrmGW,
        ...(bank_account && { bank_account_attributes: bank_account }),
      };

      return UnderwritingClient.patch<
        HashMap<string | number | Address | BankInfo | boolean>,
        Account
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchCustomer)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Problem updating Customer');
        }
        return response.json();
      })
      .then((accountResponse) => accountResponse);
  };

  static updateAccount = (
    account: Account,
    syncToCrmGW = false
  ): Promise<Account> => {
    const patchAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${account.uuid}`;

      const body = {
        legal_name: account.legal_name,
        name: account.name,
        phone: account.phone,
        industry_id: account.industry_id,
        entity_type: account.entity_type,
        fein: account.fein,
        started_on: isEmpty(account.started_on || '')
          ? account.started_on
          : formatDateString(account.started_on || '', mdyFormat, ymdFormat),
        current_address_attributes: account.address,
        sync_to_crmgw: syncToCrmGW,
      };

      return UnderwritingClient.patch<
        HashMap<string | number | Address | boolean | undefined>,
        Account
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchAccount)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Problem updating Account');
        }
        return response.json();
      })
      .then((accountResponse) => accountResponse);
  };

  static updateMerchantAggregateIds = (
    accountUuid: string,
    merchantAggregateIds: (number | undefined)[]
  ): Promise<void> => {
    const patchAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Account>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${accountUuid}`;

      const body = { merchant_aggregate_ids: merchantAggregateIds };

      return UnderwritingClient.patch<
        HashMap<string | number | (number | undefined)[]>,
        Account
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchAccount).then(() => {
      // Intentionally empty; Final output should be Promise object that resolves
      // with void
    });
  };

  // contacts

  static fetchContacts = (applicationUuid: string): Promise<Contact[]> => {
    const getContacts = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Contact[]>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/contacts`;

      return UnderwritingClient.get<Contact[]>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getContacts)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong fetching contacts'
          );
        }
        return response.json();
      })
      .then((contacts) => contacts);
  };

  static upsertApplicationContacts = (
    applicationUuid: string,
    contacts: Contact[]
  ): Promise<Contact[]> => {
    const upsertContacts = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Contact[]>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/contacts/bulk_update`;
      interface CleanContact
        extends Omit<
          Contact,
          'key' | 'ssnNotPresent' | 'address' | 'ownership_percentages'
        > {
        current_address_attributes: Address;
      }
      const contactsAttributes: CleanContact[] = contacts.map((contact) => {
        return {
          id: contact.id,
          uuid: contact.uuid,
          first_name: contact.first_name,
          last_name: contact.last_name,
          born_on: contact.born_on
            ? formatDateString(contact.born_on, mdyFormat, ymdFormat)
            : '',
          email: contact.email,
          home_phone: contact.home_phone,
          cell_phone: contact.cell_phone,
          ssn: contact.ssn || '',
          title: contact.title,
          _destroy: contact._destroy,
          bornOnNotPresent: contact.bornOnNotPresent,
          owner_id: contact.owner_id,
          deleted_at: contact.deleted_at,
          current_address_attributes: contact.address,
        };
      });

      const body = {
        contacts_attributes: contactsAttributes,
      };

      return UnderwritingClient.patch<HashMap<CleanContact[]>, Contact[]>(
        url,
        body,
        {
          headers: authenticationHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(upsertContacts)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong upserting contacts'
          );
        }
        return response.json();
      })
      .then((contactsResponse) => contactsResponse);
  };

  static updateOwnership = (
    applicationUuid: string,
    ownerships: Ownership[]
  ): Promise<void> => {
    const patchApplication = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Application>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}`;

      const body = { ownership_percentages: ownerships };

      return UnderwritingClient.patch<HashMap<Ownership[]>, Application>(
        url,
        body,
        {
          headers: authenticationHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(patchApplication).then(
      () => {
        // Intentionally empty; Final output should be Promise object that resolves
        // with void
      }
    );
  };

  // web presence
  static fetchWebPresence = (
    accountUuid: string
  ): Promise<WebPresenceResponse> => {
    const getWebPresence = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<WebPresenceResponse>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${accountUuid}/web_presences`;

      return UnderwritingClient.get<WebPresenceResponse>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getWebPresence)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong fetching web presence'
          );
        }
        return response.json();
      })
      .then((webPresences) => webPresences);
  };

  static updateWebPresence = (
    accountUuid: string,
    webPresence: WebPresence
  ): Promise<WebPresence> => {
    const patchWebPresence = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<WebPresence>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${accountUuid}/web_presences/${webPresence.web_presence_type}`;

      const body = { url: webPresence.url };
      return UnderwritingClient.patch<HashMap<string>, WebPresence>(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchWebPresence)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong updating web presence'
          );
        }
        return response.json();
      })
      .then((webPresences) => webPresences);
  };

  static bulkUpdateWebPresence = (
    accountUuid: string,
    webPresences?: WebPresences
  ): Promise<WebPresenceResponse> => {
    const patchWebPresences = (
      authenticatedHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<WebPresenceResponse>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/accounts/${accountUuid}/web_presences/bulk_update`;

      const body = webPresences
        ? Object.entries(webPresences).reduce(
            (accumulator, [webPresenceType, webPresence]) => {
              return {
                ...accumulator,
                // According to our types, url should always be a string, so this is impossible to test without breaking existing code
                // istanbul ignore next
                [webPresenceType]: (webPresence as WebPresence)?.url ?? null,
              };
            },
            {}
          )
        : {};

      return UnderwritingClient.patch<HashMap<string>, WebPresenceResponse>(
        url,
        body,
        {
          headers: authenticatedHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(patchWebPresences)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong updating web presence'
          );
        }
        return response.json();
      })
      .then((webPresencesResponse) => webPresencesResponse);
  };

  // opportunities

  static createOpportunity = (
    applicationUuid: string,
    customerUuid: string,
    syncToSalesforce = true
  ): Promise<Opportunity> => {
    const postOpportunity = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/applications/${applicationUuid}/submissions`;
      const body = {
        sync_to_salesforce: syncToSalesforce,
        customer_uuid: customerUuid,
      };
      return UnderwritingClient.post<typeof body, Opportunity>(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(postOpportunity)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong creating opportunity'
          );
        }
        return response.json();
      })
      .then((opportunity) => opportunity);
  };

  static fetchOpportunity = (
    submissionUuid: string | null
  ): Promise<Opportunity> => {
    const getOpportunity = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}`;
      return UnderwritingClient.get<Opportunity>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getOpportunity).then(
      (response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong fetching opportunity'
          );
        }
        return response.json();
      }
    );
  };

  static updateRenewalSubmitterEmail = (
    submissionUuid: string,
    email: string
  ): Promise<Opportunity> => {
    const patchOpportunity = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}`;
      const body = {
        // Because email is defined as a string, this line is impossible to test without breaking the build
        // istanbul ignore next
        renewal_submitter_email: email || '',
      };
      return UnderwritingClient.patch<HashMap<string>, Opportunity>(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchOpportunity)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(response.status, 'Something went wrong');
        }
        return response.json();
      })
      .then((opportunity) => opportunity);
  };

  static updateDecisionAnalyst = (
    submissionUuid: string | null,
    analystId: number | null,
    allowDecisionAnalystOverwrite = false
  ): Promise<Opportunity> => {
    const url = new URL(
      `api/v2/submissions/${submissionUuid}`,
      UnderwritingClient.BASE_URL
    );
    const body = {
      decision_analyst_id: analystId,
      allow_decision_analyst_overwrite: allowDecisionAnalystOverwrite,
    };
    return makeInternalAPIRequest<Opportunity>(url, 'PATCH', body)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            `Something went wrong when updating Prequal Analyst Id: ${analystId} Opp uuid: ${submissionUuid}`
          );
        }
        return response.json();
      })
      .then((opportunity) => opportunity);
  };

  static updateFinalSignOffUnderwriter = (
    submissionUuid: string | null,
    underwriterId: number | null
  ): Promise<Opportunity> => {
    const patchOpportunity = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}`;
      const body = {
        underwriter_sign_off_id: underwriterId,
      };
      return UnderwritingClient.patch<
        HashMap<number | null | undefined>,
        Opportunity
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(patchOpportunity)
      .then((response) => {
        if (response.status !== 200) {
          throw new NetworkError(response.status, 'Something went wrong');
        }
        return response.json();
      })
      .then((opportunity) => opportunity);
  };

  static updateUnderwriter = (
    submissionUuid: string,
    underwriter: string | null,
    field: 'underwriter' | 'credit_committee'
  ): Promise<Opportunity> => {
    const patchOpportunity = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Opportunity>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}`;
      type UnderwriterBody = {
        credit_committee__c?: string | null;
        x2dc_underwriter__c?: string | null;
      };

      let body: UnderwriterBody;

      switch (field) {
        case 'underwriter':
          body = {
            x2dc_underwriter__c: underwriter,
          };
          break;
        case 'credit_committee':
          body = {
            credit_committee__c: underwriter,
          };
          break;
      }

      return UnderwritingClient.patch<HashMap<string | null>, Opportunity>(
        url,
        body,
        {
          headers: authenticationHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(patchOpportunity).then(
      (response) => {
        if (response.status !== 200) {
          throw new NetworkError(response.status, 'Something went wrong');
        }
        return response.json();
      }
    );
  };

  static fetchRenewalComparison = (
    submissionUuid: string,
    sections: string[]
  ): Promise<RenewalComparisonResponse> => {
    const getRenewalComparison = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<RenewalComparisonResponse>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}/renewal_comparison?sections=${sections}`;

      return UnderwritingClient.get<RenewalComparisonResponse>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getRenewalComparison).then(
      (response) => {
        if (response.status === 404) {
          throw new NetworkError(
            response.status,
            'Renewal opportunity not found'
          );
        } else if (response.status !== 200) {
          throw new Error(
            'Something went wrong fetching Renewal Comparison data'
          );
        }
        return response.json();
      }
    );
  };

  // owners

  static fetchCustomerOwners = (customerUuid: string): Promise<Owner[]> => {
    // CAUTION: LOCAL TESTING ONLY. UNCOMMENT THIS LINES AS NEEDED FOR MOCK
    // DATA.
    // return Promise.resolve([
    //   {
    //     id: 2,
    //     first_name: 'Yor',
    //     last_name: 'Forger',
    //     ssn: '323232323',
    //     born_on: '1998-07-08',
    //     email: 'yforger@email.com',
    //     home_phone: '3420876164',
    //     cell_phone: '1489679134',
    //     title: 'fugiat vitae mollitia',
    //     uuid: 'some-uuid-2898',
    //     ownership_percentage: 50,
    //     address: {
    //       street1: '4206 Marie Rest Apt. 778',
    //       city: 'South Orphafort',
    //       state: 'AR',
    //       zip: '61728-7787',
    //     },
    //   },
    //   {
    //     id: 2,
    //     first_name: 'Yor',
    //     last_name: 'Forger',
    //     ssn: '323232323',
    //     born_on: '1998-07-08',
    //     email: 'yforger@email.com',
    //     home_phone: '3420876164',
    //     cell_phone: '1489679134',
    //     title: 'fugiat vitae mollitia',
    //     uuid: 'some-uuid-2898',
    //     ownership_percentage: 50,
    //     address: {
    //       street1: '4206 Marie Rest Apt. 778',
    //       city: 'South Orphafort',
    //       state: 'AR',
    //       zip: '61728-7787',
    //     },
    //   },
    // ]);

    const getOwners = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Owner[]>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/customers/${customerUuid}/owners`;

      return UnderwritingClient.get<Owner[]>(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(getOwners)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong fetching owners'
          );
        }
        return response.json();
      })
      .then((owners) => owners);
  };

  static updateCustomerOwner = (owner: Owner): Promise<Owner> => {
    const updateOwner = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Owner>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/owners/${owner.uuid}`;

      return UnderwritingClient.patch<
        HashMap<boolean | Date | Address | string | undefined | number | null>,
        Owner
      >(
        url,
        { current_address_attributes: owner.address, ...owner },
        {
          headers: authenticationHeaders,
        }
      );
    };

    return UnderwritingClient.authenticateApiRequest(updateOwner)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong upserting owner'
          );
        }
        return response.json();
      })
      .then((ownerResponse) => ownerResponse);
  };

  static createCustomerOwner = (
    owner: Owner,
    customer_uuid: string
  ): Promise<Owner> => {
    const postOwner = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<Owner>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/customers/${customer_uuid}/owners`;

      const body = {
        first_name: owner.first_name,
        last_name: owner.last_name,
        ssn: owner.ssn,
        email: owner.email,
        home_phone: owner.home_phone,
        cell_phone: owner.cell_phone,
        born_on: owner.born_on,
        ownership_percentage: owner.ownership_percentage,
        title: owner.title,
        current_address_attributes: owner.address,
      };

      return UnderwritingClient.post<
        HashMap<string | null | number | Address | undefined>,
        Owner
      >(url, body, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(postOwner)
      .then((response) => {
        if (!response.ok) {
          throw new NetworkError(
            response.status,
            'Something went wrong creating owner'
          );
        }
        return response.json();
      })
      .then((ownerResponse) => ownerResponse);
  };

  //
  // Delete orphaned Objects and update name
  //

  // Delete Orphaned Application on renewal redirect
  static deleteOrphanedApplication = async (
    applicationUuid: string | undefined
  ): Promise<void> => {
    const deleteApplication = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<void>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/destroy_orphan_application/${applicationUuid}`;

      // const body = { uuid: applicationUuid };

      return UnderwritingClient.delete(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(deleteApplication).then(
      () => {
        // Intentionally empty; Final output should be Promise object that resolves
        // with void
      }
    );
  };

  //  Delete Orphaned Account on renewal redirect
  static deleteOrphanedAccount = async (
    accountUuid: string | undefined
  ): Promise<void> => {
    const deleteAccount = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<void>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/destroy_orphan_submission_customer/${accountUuid}`;

      // const body = { uuid: accountUuid };

      return UnderwritingClient.delete(url, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest(deleteAccount).then(() => {
      // Intentionally empty; Final output should be Promise object that resolves
      // with void
    });
  };

  //  Delete Orphaned Contact on renewal redirect
  static deleteOrphanedContact = async (
    contactUuid: string | undefined
  ): Promise<void> => {
    const deleteContact = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<void>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/destroy_orphan_contact/${contactUuid}`;

      return UnderwritingClient.delete(url, {
        headers: authenticationHeaders,
      });
    };
    return UnderwritingClient.authenticateApiRequest(deleteContact).then(() => {
      // Intentionally empty; Final output should be Promise object that resolves
      // with void
    });
  };

  //  Update the analyst name on renewal redirect
  static updateAnalystName = async (
    submissionUuid: string | undefined,
    analystName: string | undefined
  ): Promise<void> => {
    const updateAnalystName = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<SubmissionResponse>> => {
      const url = `${UnderwritingClient.BASE_URL}api/v2/submissions/${submissionUuid}`;

      const body = { prequal_analyst_name: analystName };

      return UnderwritingClient.patch<
        HashMap<string | undefined>,
        SubmissionResponse
      >(url, body, {
        headers: authenticationHeaders,
      });
    };
    return UnderwritingClient.authenticateApiRequest(updateAnalystName).then(
      () => {
        // Intentionally empty; Final output should be Promise object that resolves
        // with void
      }
    );
  };

  // feature flags

  static fetchFeatureFlags = (
    flagNames: string[] | readonly string[]
  ): Promise<FeatureFlagPayload> => {
    const fetchFeatureFlags = (
      authenticationHeaders: AuthenticationHeaders
    ): Promise<FetchResponse<FeatureFlagPayload>> => {
      const url = new URL('api/v2/feature_flags', UnderwritingClient.BASE_URL);
      url.searchParams.set('flags', flagNames.join(','));

      return UnderwritingClient.get<FeatureFlagPayload>(`${url}`, {
        headers: authenticationHeaders,
      });
    };

    return UnderwritingClient.authenticateApiRequest<FeatureFlagPayload>(
      fetchFeatureFlags
    ).then((response) => {
      if (!response.ok) {
        throw new NetworkError(
          response.status,
          'Something went wrong fetching Feature Flag data'
        );
      }

      return response.json();
    });
  };
}
