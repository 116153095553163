import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import EmailValidator from 'email-validator';
import { toDate, ymdFormat } from '../../helpers/string/dateUtils';

const numbers = /^[0-9]+\.{0,1}[0-9]*$/; // this matches any whole number, and also decimals. Accepts only one period followed by another set of numbers.
const dateRegEx = /^\d{2}\/\d{2}\/\d{4}$/; // this matches dd/mm/yyyy format used for Prequal form
const emailValidChar = /([^a-zA-Z|\d|\-_.@]+|\|)+/g; //this matches all non alphanumeric chars excluding ".", "-", "_", "@"
const addressNoSpecialChar = /([^a-zA-Z|\d|\s|&\-.,'#]+|\|)+/g; //this matches non all alphanumeric chars excluding ".", "-", "&", "'", "#" commas and spaces
const textNoSpecialChar = /([^a-zA-Z|\d|\s|&\-.,']+|\|)+/g; //this matches all non alphanumeric chars excluding ".", "-", "&", "'", "," and spaces
const URLInvalidChar = /[<|>|}|{|"]+/g; //this matches any of these invalida chars

// this verifies the presence of only 1 @ and the presence of . in the content that comes after the 2

// helpers
export const isNumber = (input: string): boolean => numbers.test(input);

export const isNumericString = (newVal: string): boolean => {
  return /^\d*$/.test(newVal);
};

export const URLhasInvalidChars = (input: string): boolean =>
  input.match(URLInvalidChar) !== null;

// in theory, we're always getting valid data here, but in practice
// that's not actually happening. check for null and undefined
// explicitly because empty string should be run through .match
export const addressHasNoSpecialChar = (input?: string | null): boolean =>
  !input?.match(addressNoSpecialChar);

export const textHasNoSpecialChar = (input?: string | null): boolean =>
  !input?.match(textNoSpecialChar);

export const hasMinLength = (
  minLength: number
): ((input: string) => boolean) => {
  return (input) => input.length >= minLength;
};

export const hasMaxLength = (max: number): ((input: string) => boolean) => {
  return (input) => input.length <= max;
};

export const hasExactLength = (val: number): ((input: string) => boolean) => {
  return (input) => input.length === val;
};

export const isRequired = (input?: string | null): boolean => {
  return (
    input !== undefined &&
    input !== null &&
    input !== '' &&
    input !== '0' &&
    input.trim().length > 0
  );
};

export const isSmallerThan = (val: number): ((input: string) => boolean) => {
  return (input) => Number.parseFloat(input) < val;
};

export const isBiggerThan = (val: number): ((input: string) => boolean) => {
  return (input) => Number.parseFloat(input) > val;
};

export const isInBetween = (
  val1: number,
  val2: number
): ((input: string) => boolean) => {
  return (input) =>
    Number.parseFloat(input) >= val1 && Number.parseFloat(input) <= val2;
};

export const isValidDate = (format: string): ((input: string) => boolean) => {
  return (input) => dateRegEx.test(input) && isValid(toDate(input, format));
};

export const isDateInThePast = (
  format: string
): ((input: string) => boolean) => {
  return (input) =>
    isValidDate(format)(input) && isBefore(toDate(input, format), Date.now());
};

export const isDateAfter1800 = (
  format: string
): ((input: string) => boolean) => {
  return (input) =>
    isValidDate(format)(input) &&
    isAfter(toDate(input, format), toDate('1779-12-31', ymdFormat));
};

export const isTooOld = (format: string): ((input: string) => boolean) => {
  return (input) => {
    return (
      isValidDate(format)(input) &&
      isAfter(toDate(input, format), sub(Date.now(), { years: 100 }))
    );
  };
};

export const isTooYoung = (format: string): ((input: string) => boolean) => {
  return (input) =>
    isValidDate(format)(input) &&
    isBefore(toDate(input, format), sub(Date.now(), { years: 18 }));
};

export const isValidEmail = (input: string): boolean => {
  return EmailValidator.validate(input);
};

export const emailHasValidChars = (input: string): boolean => {
  return input.match(emailValidChar) === null;
};

const routingNumberPattern = /^\d{9}$/;

export const isRoutingNumber = (input: string): boolean => {
  return routingNumberPattern.test(input);
};
