import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Banner,
  Card,
  Flex,
  IconButton,
  PageContainer,
  Subheading,
} from '@forward-financing/fast-forward';
import { useCustomer, useOwner, useOwnerUccReport } from './uccReportPageHooks';
import { UccReportTable } from './UccReportTable';

type Params = {
  submissionUuid: string;
  customerUuid: string;
  ownerUuid: string;
};

export const UccReportPage = (): JSX.Element => {
  // ownerUuid and SubmissionUuid are used to fetch the report, customerUuid for fetch customer dba
  const { ownerUuid, submissionUuid, customerUuid } = useParams<Params>();

  const { data: ownerData, error: ownerError } = useOwner(ownerUuid);

  const { data: customerData, error: customerError } =
    useCustomer(customerUuid);

  const { data: uccReportData, error: uccReportError } = useOwnerUccReport(
    submissionUuid,
    ownerUuid
  );

  const ownerName =
    ownerData?.firstName && ownerData?.lastName
      ? `${ownerData.firstName} ${ownerData.lastName}`
      : '';

  const customerName = customerData?.legalName;

  useEffect(() => {
    if (ownerName && customerName) {
      document.title = `${ownerName} | ${customerName}`;
    }

    return () => {
      document.title = 'Forward Financing';
    };
  }, [ownerName, customerName]);

  const activeUccReports = uccReportData?.filter(
    (uccReport) => !uccReport.closed
  );
  const closedUccReports = uccReportData?.filter(
    (uccReport) => uccReport.closed
  );

  const handleBackToSubmission = (): void => {
    const submissionUrl = new URL(
      `/submissions/${submissionUuid}`,
      window.location.origin
    );

    window.location.assign(submissionUrl.toString());
  };

  return (
    <PageContainer>
      <Flex gap={3} mt={4} flexDirection="column">
        <IconButton
          label="Back to submission"
          onClick={handleBackToSubmission}
          icon={'circle-left'}
        />

        {ownerError && (
          <Banner variant="danger" dismissable={false}>
            {ownerError.message}
          </Banner>
        )}

        {customerError && (
          <Banner variant="danger" dismissable={false}>
            {customerError.message}
          </Banner>
        )}

        {uccReportError && (
          <Banner variant="danger" dismissable={false}>
            {uccReportError.message}
          </Banner>
        )}

        <Subheading>
          {ownerName && customerName && `${ownerName} | ${customerName} - `}UCC
          Detail
        </Subheading>

        <Card title="UCC - Active">
          <UccReportTable uccReports={activeUccReports} />
        </Card>

        <Card title="UCC - Closed">
          <UccReportTable uccReports={closedUccReports} />
        </Card>
      </Flex>
    </PageContainer>
  );
};
