import { useCallback } from 'react';
import {
  useGenericQuery,
  UseGenericQueryResponse,
} from '../../../apiHooks/genericFetchHooks';
import { UccBusinessReportResponse } from '../../../types/api/underwriting/types';
import { fetchBusinessUccReport } from './businessOverviewFetchUtils';

export type UseGetApiBusinessUccReport =
  UseGenericQueryResponse<UccBusinessReportResponse>;

type UseUccReportArgs = {
  submissionUuid?: string;
};

export const useGetApiBusinessUccReport = (
  input: UseUccReportArgs
): UseGetApiBusinessUccReport => {
  const fetcher = useCallback(() => {
    if (!input.submissionUuid) {
      return undefined;
    }
    return fetchBusinessUccReport(input.submissionUuid);
  }, [input.submissionUuid]);

  return useGenericQuery(fetcher);
};
