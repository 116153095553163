import { formatDateString } from '@forward-financing/fast-forward';
import {
  useGetApiOwnerLexisNexisReport,
  useGetApiOwnerLexisNexisReportById,
} from 'apiHooks/underwriting/documentsFetchHooks';
import { useGetApiOwner } from 'apiHooks/underwriting/ownerFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';

import { isDateInPast } from 'helpers/date/dateUtils';
import {
  BestInfoResponse,
  NameVariationsReponse,
  NoticesOfDefaultResponse,
  OwnerLexisNexisAddressSummaryResponse,
  OwnerLexisNexisProfessionalLicenseResponse,
  LexisNexisDateResponse,
  OwnerLexisNexisPayload,
  OwnerLexisNexisSsnInfoResponse,
  OwnerResponse,
  PhoneNumbersResponse,
  LiensJudgmentResponse,
  DebtorResponse,
  CreditorResponse,
  FilingResponse,
  OwnerLexisNexisCriminalResponse,
  UccFilingsResponse,
  UccFilingEntity,
  FilingsResponse,
  CorporateAffiliationResponse,
  ForeclosuresResponse,
  BankruptcyResponse,
  BankruptcyStatusHistory,
  LexisNexisCustomerSearchResponse,
} from 'types/api/underwriting/types';
import {
  MutationResponse,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  useGetApiLexisNexisCustomerManualSearch,
  usePullCustomerLexisNexis,
} from 'apiHooks/underwriting/submissionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { toAddressString, toNameString } from '../helpers';
import {
  NameVariation,
  AddressSummary,
  Owner,
  OwnerLexisNexisReport,
  PersonalInformation,
  NoticeOfDefault,
  ProfessionalLicense,
  Debtor,
  Creditor,
  Filing,
  LienJudgment,
  CriminalRecord,
  UccFiling,
  UccDebtorOrSecured,
  Foreclosure,
  CorporateAffiliation,
  Bankruptcy,
  LexisNexisCustomerManualSearchData,
} from './ownerLexisNexisPage.types';

const toDateString = (dobObject?: LexisNexisDateResponse): string => {
  const dateArray = [dobObject?.month, dobObject?.day, dobObject?.year];
  return dateArray.filter(Boolean).join('/');
};

const toPersonalInformation = (
  personalInfoResponse: BestInfoResponse,
  phoneNumbers?: PhoneNumbersResponse
): PersonalInformation => {
  return {
    name: toNameString(personalInfoResponse.name),
    address: toAddressString(personalInfoResponse.address),
    ssn: personalInfoResponse.ssn,
    dob: toDateString(personalInfoResponse.dob),
    phoneNumbers: phoneNumbers
      ? [phoneNumbers.phones_plus].flat().map((p) => p.phone_number)
      : [],
  };
};

// Name variations utilities
const toSsn = (
  ssnObject?: OwnerLexisNexisSsnInfoResponse
): string | undefined => {
  if (!ssnObject || !Object.values(ssnObject).every(Boolean)) {
    return undefined;
  }
  return `${ssnObject.ssn} (Issued on ${toDateString(
    ssnObject.issued_start_date
  )} - ${toDateString(ssnObject.issued_end_date)} in ${
    ssnObject.issued_location
  })`;
};

const toNameVariations = (
  nameVariationResponse: NameVariationsReponse
): NameVariation => {
  return {
    name: toNameString(nameVariationResponse.name),
    ssnInformation: toSsn(nameVariationResponse.ssn_info),
  };
};

// This function parses the full report data
const toAddressSummary = (
  addressSummary: OwnerLexisNexisAddressSummaryResponse
): AddressSummary => {
  return {
    address: toAddressString(addressSummary.address),
    phones: addressSummary.phones
      ? [
          ...new Set(
            [addressSummary.phones.phone]
              .flat()
              .map((phoneObject) => phoneObject?.phone10)
              .filter(Boolean)
          ),
        ]
      : [],
    subject: toNameString(addressSummary.subject_name),
    dateLastSeen: toDateString(addressSummary.date_last_seen),
  };
};

const toNoticesOfDefults = (
  noticeOfDefault: NoticesOfDefaultResponse
): NoticeOfDefault => {
  return {
    defendantNames: [noticeOfDefault.defendants.defendant]
      .flat()
      .map((defendant) => toNameString(defendant.name)),
    siteAddress: toAddressString(noticeOfDefault.site_address),
    siteAddress2: toAddressString(noticeOfDefault.site2_address),
    recordingDate: toDateString(noticeOfDefault.recording_date),
    deedType: noticeOfDefault.deed_type,
  };
};

const toProfessionalLicense = ({
  profession_or_board,
  license_type,
  issued_date,
  expiration_date,
  status,
}: OwnerLexisNexisProfessionalLicenseResponse): ProfessionalLicense => {
  return {
    professionOrBoard: profession_or_board || '',
    licenseType: license_type,
    issuedDate: issued_date ? toDateString(issued_date) : '',
    expirationDate: expiration_date ? toDateString(expiration_date) : '',
    status: status,
  };
};

const toDebtor = (debtorResponse: DebtorResponse): Debtor => {
  return {
    name: toNameString(debtorResponse.parsed_parties.party.name),
    companyName: debtorResponse.parsed_parties.party.company_name,
    address:
      debtorResponse.addresses &&
      [debtorResponse.addresses.address].flat().map(toAddressString),
  };
};

const toCreditor = (creditorResponse: CreditorResponse): Creditor => {
  return {
    name: creditorResponse.name,
    address:
      creditorResponse.addresses &&
      [creditorResponse.addresses.address].flat().map(toAddressString),
  };
};

const toFiling = (filingResponse: FilingResponse): Filing => {
  return {
    type: filingResponse.type,
    originFilingDate: toDateString(filingResponse.origin_filing_date),
    agency: filingResponse.agency,
    agencyState: filingResponse.agency_state,
    agencyCounty: filingResponse.agency_county,
    number: filingResponse.number,
  };
};

const toLiensJudgments = (
  liensJudgmentsResponse: LiensJudgmentResponse
): LienJudgment => {
  return {
    debtor:
      liensJudgmentsResponse.debtors === null ||
      liensJudgmentsResponse.debtors === undefined
        ? []
        : [liensJudgmentsResponse.debtors.debtor].flat().map(toDebtor),
    creditor:
      liensJudgmentsResponse.creditors === undefined ||
      liensJudgmentsResponse.creditors === null
        ? []
        : [liensJudgmentsResponse.creditors.creditor].flat().map(toCreditor),
    filings:
      liensJudgmentsResponse.filings === undefined ||
      liensJudgmentsResponse.filings === null
        ? []
        : [liensJudgmentsResponse.filings.filing].flat().map(toFiling),
    amount: liensJudgmentsResponse.amount,
  };
};

const toCriminalRecord = (
  criminalRecord: OwnerLexisNexisCriminalResponse
): CriminalRecord => {
  return {
    dataSource: criminalRecord.data_source,
    name: toNameString(criminalRecord.name),
    // offenses can be an array or an object, so in case it is an object we put it into an array.
    // If it is already an array, we use flat to remove the extra outer array.
    // We also use filter(Boolean) to remove any undefined values prior to mapping.
    // e.g. [[undfined, {some_key: 'some value' }]] would become [{some_key: 'some value' }]
    offenses: [criminalRecord.offenses?.offense]
      .flat()
      .filter(Boolean)
      .map((offense) => ({
        offense: offense.court?.offense,
        disposition: offense.court?.disposition,
        offenseDate: toDateString(offense.offense_date),
        arrestDate: offense.arrest?.date
          ? toDateString(offense.arrest.date)
          : '',
      })),
    caseFilingDate: toDateString(criminalRecord.case_filing_date),
    state: criminalRecord.state_of_origin,
    uniqueId: criminalRecord.unique_id,
  };
};

const toUccDebtorOrSecured = (data: UccFilingEntity): UccDebtorOrSecured => {
  return {
    originName: data.origin_name,
    addresses:
      data.addresses === undefined || data.addresses === null
        ? []
        : [data.addresses.address].flat().map(toAddressString),
  };
};

const isUccStatusClose = (
  filingsResponse: FilingsResponse[],
  judgeSatisfiedDate?: string,
  judgeVacatedDate?: string,
  releaseDate?: string
): boolean => {
  const filingsTypes = filingsResponse.map((f) => f.type);

  const releasedKeyWords = [
    'release',
    'satisfied',
    'discharge',
    'dismiss',
    'terminat', // This is to match words like "terminated", "terminate", "terminating", etc.
  ];

  const hasReleasedType =
    filingsTypes.filter((type) => {
      return releasedKeyWords.some((keyWord) =>
        type?.toLowerCase().includes(keyWord.toLowerCase())
      );
    }).length > 0;

  const isReleased =
    [judgeSatisfiedDate, releaseDate, judgeVacatedDate].filter(Boolean).length >
      0 || hasReleasedType;

  const hasExpireStatus =
    [filingsResponse].flat().filter((f) => f.filing_status?.includes('expir')) // This is to match words like "expired", "expiration", etc.
      .length > 0;

  const expirationDateInPast =
    [filingsResponse].flat().filter((f) => {
      if (f.expiration_date) {
        const date = new Date(
          formatDateString(toDateString(f.expiration_date))
        );
        return isDateInPast(date);
      }
      return false;
    }).length > 0;

  const isExpired = hasExpireStatus || expirationDateInPast;

  return isExpired || isReleased;
};

const toUccFilings = (uccFilingsResponse: UccFilingsResponse): UccFiling => {
  const filings = uccFilingsResponse.filings
    ? [uccFilingsResponse.filings.filing].flat()
    : [];
  const status = isUccStatusClose(
    filings,
    uccFilingsResponse.judge_satisfied_date,
    uccFilingsResponse.judge_vacated_date,
    uccFilingsResponse.release_date
  );
  return {
    debtor: uccFilingsResponse.debtors
      ? [uccFilingsResponse.debtors.debtor].flat().map(toUccDebtorOrSecured)
      : [],
    secured: uccFilingsResponse.secureds
      ? [uccFilingsResponse.secureds.secured].flat().map(toUccDebtorOrSecured)
      : [],
    originFilingDate:
      uccFilingsResponse.origin_filing_date &&
      toDateString(uccFilingsResponse.origin_filing_date),
    status: status ? 'Closed' : 'Active',
    collateral: uccFilingsResponse.collaterals
      ? [uccFilingsResponse.collaterals.collateral]
          .flat()
          .map((c) => c.description)
      : [],
  };
};

const toForeclosure = (foreclosure: ForeclosuresResponse): Foreclosure => {
  return {
    deedType: foreclosure.deed_type,
    defendant: [foreclosure.defendants.defendant]
      .flat()
      .map((f) => (toNameString(f.name) || f.company_name) as string),
    landUsage: foreclosure.land_usage,
    siteAddress: toAddressString(foreclosure.site_address),
    recordingDate: toDateString(foreclosure.recording_date),
  };
};
const toCorporateAffiliation = (
  affiliation: CorporateAffiliationResponse
): CorporateAffiliation => ({
  companyName: affiliation.company_name,
  address: toAddressString(affiliation.address),
  filingDate: toDateString(affiliation.filing_date),
  state: affiliation.state,
  status: affiliation.status,
  recordType: affiliation.record_type,
});

const toStatusHistory = (staus: BankruptcyStatusHistory): string => {
  return `${staus.type} ${toDateString(staus.date)}`;
};

const toBankruptcies = (bankruptcy: BankruptcyResponse): Bankruptcy => {
  return {
    type: bankruptcy.original_chapter,
    filingDate: toDateString(bankruptcy.original_filing_date),
    statusHistory: [bankruptcy.status_history.status]
      .flat()
      .map(toStatusHistory),
  };
};

const toOwnerLexisNexisReport = (
  response: OwnerLexisNexisPayload
): OwnerLexisNexisReport => {
  const report = response.full_payload.individual;

  return {
    ownerUuid: response.owner_uuid,
    personalInformation:
      report.best_info &&
      toPersonalInformation(report.best_info, report.phones_pluses),
    nameVariations:
      report.ak_as && [report.ak_as.identity].flat().map(toNameVariations),
    addressSummary:
      report.bps_report_addresses === undefined
        ? []
        : // bps_report_address can be an array or an object, so in case it is
          // an object we put it into an array. If it's already an array, then
          // the flat() method will remove the extra outer array.
          [report.bps_report_addresses.bps_report_address]
            .flat()
            .map(toAddressSummary),
    noticesOfDefaults:
      report.notices_of_defaults &&
      [report.notices_of_defaults.notice_of_defaults]
        .flat()
        .map(toNoticesOfDefults),
    professionalLicenses:
      report.professional_licenses === undefined
        ? []
        : // professional_license can be an array or an object, so in case it is
          // an object we put it into an array. If it's already an array, then
          // the flat() method will remove the extra outer array.
          [report.professional_licenses.professional_license]
            .flat()
            .map(toProfessionalLicense),
    liensJudgments:
      report.liens_judgments === undefined
        ? []
        : // liens_judgments can be an array or an object, so in case it is
          // an object we put it into an array. If it's already an array, then
          // the flat() method will remove the extra outer array.
          [report.liens_judgments.lien_judgment].flat().map(toLiensJudgments),
    criminalRecords:
      report.criminal_records === undefined
        ? []
        : // criminal_records.criminal can be an array or an object, so in case it is
          // an object we put it into an array. If it's already an array, then
          // the flat() method will remove the extra outer array.
          [report.criminal_records.criminal].flat().map(toCriminalRecord),

    uccFilings:
      report.ucc_filings === undefined
        ? []
        : [report.ucc_filings.ucc_filing].flat().map(toUccFilings),

    foreclosures:
      report.foreclosures === undefined
        ? []
        : [report.foreclosures.foreclosure].flat().map(toForeclosure),
    corporateAffiliations:
      report.corporate_affiliations === undefined
        ? []
        : [report.corporate_affiliations.affiliation]
            .flat()
            .map(toCorporateAffiliation),
    bankruptcies:
      report.bankruptcies === undefined
        ? []
        : [report.bankruptcies.bankruptcy].flat().map(toBankruptcies),
  };
};

export const useOwnerLexisNexisReport = (
  submissionUuid?: string
): UseGenericQueryResponse<OwnerLexisNexisReport[]> =>
  useGenericFeatureQuery(
    useGetApiOwnerLexisNexisReport,
    (data) => data?.documents?.map(toOwnerLexisNexisReport),
    submissionUuid
  );

export const useOwnerLexisNexisReportById = (
  reportId?: string
): UseGenericQueryResponse<OwnerLexisNexisReport> =>
  useGenericFeatureQuery(
    useGetApiOwnerLexisNexisReportById,
    (data) => data && toOwnerLexisNexisReport(data),
    reportId
  );

const toOwner = (reponse?: OwnerResponse): Owner | undefined => {
  if (!reponse) {
    return undefined;
  }
  return {
    uuid: reponse.uuid,
    fullName: `${reponse.first_name} ${reponse.last_name}`,
  };
};

export const useOwner = (ownerUuid?: string): UseGenericQueryResponse<Owner> =>
  useGenericFeatureQuery(useGetApiOwner, toOwner, ownerUuid);

const toLexisNexisCustomerSearchData = (
  response?: LexisNexisCustomerSearchResponse
): LexisNexisCustomerManualSearchData[] => {
  if (!response) {
    return [];
  }

  return response.results.map((data) => ({
    reportId: data.report_identifier,
    name: data.name,
    address: toAddressString(data.address),
    hasUcc: data.has_ucc,
  }));
};

export const useLexisNexisCustomerManualSearch = (
  submissionUuid?: string,
  companyName?: string
): UseGenericQueryResponse<LexisNexisCustomerManualSearchData[]> =>
  useGenericFeatureQuery(
    useGetApiLexisNexisCustomerManualSearch,
    toLexisNexisCustomerSearchData,
    submissionUuid,
    companyName && `company_name=${companyName}`
  );

type PullLexisNexisBody = {
  force?: boolean;
  reportId?: string;
};

type UsePullCustomerLexisNexisReportResult = [
  (input: PullLexisNexisBody) => Promise<MutationResponse>,
  { data?: { success: true }; loading: boolean; error?: Error }
];

export const usePullCustomerLexisNexisReport = (
  submissionUuid: string
): UsePullCustomerLexisNexisReportResult => {
  const [baseFetchFunction, { data, loading, error }] =
    usePullCustomerLexisNexis();

  const pullReport = async (
    input: PullLexisNexisBody
  ): Promise<MutationResponse> => {
    return await baseFetchFunction({
      submissionUuid,
      force: input.force,
      reportId: input.reportId,
    });
  };

  useLogError(error);

  return [
    pullReport,
    {
      data,
      loading,
      error,
    },
  ];
};
