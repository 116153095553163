import React from 'react';
import {
  Box,
  Flex,
  Link,
  Subheading,
  Table,
} from '@forward-financing/fast-forward';
import isAfter from 'date-fns/isAfter';
import sub from 'date-fns/sub';
import { ExperianInquiry } from './types';

export type InquiriesContainerProps = {
  inquiries: ExperianInquiry[];
  submissionUuid: string;
  ownerUuid: string;
};

const inquiriesDateComparison = (
  inquiredOn: string,
  comparisonDate: number
): boolean => {
  return isAfter(
    new Date(inquiredOn),
    sub(Date.now(), { days: comparisonDate + 1 })
  );
};

export const InquiriesContainerV2 = ({
  inquiries,
  submissionUuid,
  ownerUuid,
}: InquiriesContainerProps): JSX.Element => {
  const inquiriesCount = {
    thirtyDays: inquiries.filter((i) =>
      inquiriesDateComparison(i.inquiredOn, 30)
    ).length,
    sixtyDays: inquiries.filter((i) =>
      inquiriesDateComparison(i.inquiredOn, 60)
    ).length,
    ninetyDays: inquiries.filter((i) =>
      inquiriesDateComparison(i.inquiredOn, 90)
    ).length,
    afterNinetyDays: inquiries.filter(
      (i) => !inquiriesDateComparison(i.inquiredOn, 90)
    ).length,
    mcaThirtyDays: inquiries.filter(
      (i) => !!i.isMcaSubscriber && inquiriesDateComparison(i.inquiredOn, 30)
    ).length,
    mcaSixtyDays: inquiries.filter(
      (i) => !!i.isMcaSubscriber && inquiriesDateComparison(i.inquiredOn, 60)
    ).length,
    mcaNinetyDays: inquiries.filter(
      (i) => !!i.isMcaSubscriber && inquiriesDateComparison(i.inquiredOn, 90)
    ).length,
    mcaAfterNinetyDays: inquiries.filter(
      (i) => !!i.isMcaSubscriber && !inquiriesDateComparison(i.inquiredOn, 90)
    ).length,
  };

  return (
    <Box>
      <Flex gap={3} alignItems="center">
        <Subheading variant="section">Inquiries</Subheading>
        <Link
          newTab
          href={
            new URL(
              `/dashboard/submissions/${submissionUuid}/owners/${ownerUuid}/inquiries`,
              window.location.origin
            )
          }
        >
          View Report
        </Link>
      </Flex>
      <Table>
        <Table.Head>
          <Table.ColumnHeader>Inquiries</Table.ColumnHeader>
          <Table.ColumnHeader>30 Days</Table.ColumnHeader>
          <Table.ColumnHeader>60 Days</Table.ColumnHeader>
          <Table.ColumnHeader>90 Days</Table.ColumnHeader>
          <Table.ColumnHeader>90+ Days</Table.ColumnHeader>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.RowHeader>All Inquiries</Table.RowHeader>
            <Table.Cell>{inquiriesCount.thirtyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.sixtyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.ninetyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.afterNinetyDays}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.RowHeader>MCA Inquiries</Table.RowHeader>
            <Table.Cell>{inquiriesCount.mcaThirtyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.mcaSixtyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.mcaNinetyDays}</Table.Cell>
            <Table.Cell>{inquiriesCount.mcaAfterNinetyDays}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  );
};
