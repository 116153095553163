import { defaultTo } from 'lodash';
import { useGetApiRenewalComparison } from 'apiHooks/underwriting/submissionFetchHooks';
import { UseGenericQueryResponse } from 'apiHooks/genericFetchHooks';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import {
  CashFlowInfo,
  FundingInfo,
  OwnerRiskInfo,
  RenewalComparisonResponse,
  SummaryInfo,
} from 'types/api/underwriting/types';
import {
  CashFlowData,
  FundingData,
  OwnerRiskData,
  RenewalComparisons,
  SummaryData,
} from './renewalComparison.types';

const toFundingData = (data?: FundingInfo): FundingData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    amount: defaultTo(data.amount, undefined),
    targetTerm: defaultTo(data.target_term, undefined),
    balance: defaultTo(data.balance, undefined),
    minimumToApprove: defaultTo(data.minimum_to_approve, undefined),
    isAddOn: defaultTo(data.is_add_on, undefined),
    netFundedAmount: defaultTo(data.net_funded_amount, undefined),
    missedPaymentCount: defaultTo(data.missed_payment_count, undefined),
    reducedPaymentCount: defaultTo(data.reduced_payment_count, undefined),
    processingFeeAmount: defaultTo(data.processing_fee_amount, undefined),
    contractualPaybackFrequency: defaultTo(
      data.contractual_payback_frequency,
      undefined
    ),
    recordId: defaultTo(data.record_id, undefined),
    commission: defaultTo(data.commission, undefined),
    lastPaymentDate: defaultTo(data.last_payment_date, undefined),
    thirtyDayDollarPace: defaultTo(data.thirty_day_dollar_pace, undefined),
    sixtyDayDollarPace: defaultTo(data.sixty_day_dollar_pace, undefined),
    overallDollarPace: defaultTo(data.overall_dollar_pace, undefined),
    overallPaymentCountPace: defaultTo(
      data.overall_payment_count_pace,
      undefined
    ),
  };
};

const toSummaryData = (data?: SummaryInfo): SummaryData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    businessName: data.business_name,
    underwriterName: defaultTo(data.underwriter_name, undefined),
    stage: defaultTo(data?.stage, ''),
    subStage: defaultTo(data.sub_stage, undefined),
  };
};

const toCashFlowData = (data?: CashFlowInfo): CashFlowData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    revenueOverride: defaultTo(data.revenue_override, undefined),
    averageNegativeDays: defaultTo(data.average_negative_days, undefined),
    averageDailyBalance: defaultTo(data.average_daily_balance, undefined),
    numberOfPositions: defaultTo(data.number_of_positions, undefined),
    grossOfPositions: defaultTo(data.gross_of_positions, undefined),
    ffPercentOfGross: defaultTo(data.ff_percent_of_gross, undefined),
    totalGrossPercent: defaultTo(data.total_gross_percent, undefined),
  };
};

const toOwnerRiskData = (data?: OwnerRiskInfo): OwnerRiskData | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    ficoScore: defaultTo(data.fico_score, undefined),
    bankruptcyPlus: defaultTo(data.bankruptcy_plus, undefined),
    vantageScore: defaultTo(data.vantage_score, undefined),
    totalDelinquentTradeLines: defaultTo(
      data.total_delinquent_trade_lines,
      undefined
    ),
    debtToHighCredit: defaultTo(data.debt_to_high_credit, undefined),
  };
};

const toRenewalComparisons = (
  data: RenewalComparisonResponse
): RenewalComparisons => {
  return {
    current: {
      fundingData: toFundingData(data.current.funding_data),
      summaryData: toSummaryData(data.current.summary_data),
      cashFlowData: toCashFlowData(data.current.cash_flow_data),
      ownerRiskData: toOwnerRiskData(data.current.owner_risk_data),
    },
    previousOne: data.previous_1
      ? {
          fundingData: toFundingData(data.previous_1.funding_data),
          summaryData: toSummaryData(data.previous_1.summary_data),
          cashFlowData: toCashFlowData(data.previous_1.cash_flow_data),
          ownerRiskData: toOwnerRiskData(data.previous_1.owner_risk_data),
        }
      : undefined,
    previousTwo: data.previous_2
      ? {
          fundingData: toFundingData(data.previous_2.funding_data),
          summaryData: toSummaryData(data.previous_2.summary_data),
        }
      : undefined,
  };
};

export const useRenewalComparisons = (
  submissionUuid?: string,
  sections?: string[]
): UseGenericQueryResponse<RenewalComparisons> => {
  return useGenericFeatureQuery(
    useGetApiRenewalComparison,
    (data) => data && toRenewalComparisons(data),
    submissionUuid,
    sections
  );
};
