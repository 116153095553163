import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  Tabs,
  Text,
  Link,
  Subheading,
  formatDateString,
  Tooltip,
  Divider,
  Loading,
} from '@forward-financing/fast-forward';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import { useLazyQuery } from '@apollo/client';
import { UNDERWRITING_BASE_URL } from 'constants/globals';
import { toError } from 'helpers/errorUtils';
import { FFLogger } from 'api/LogClient';
import { InquiryOverview } from 'types/api/underwriting/types';
import { gql } from '__generated__/gql';
import { BasicOwnerInfoTableFragmentFragment } from '__generated__/graphql';
import { useUserContext } from 'contexts/UserContext';
import { featureFlags } from 'helpers/featureFlags';
import { client } from 'contexts/ApolloContext';
import { toDate } from 'helpers/string/dateUtils';
import { InternalLink } from 'components/shared/InternalLink';
import { TradelinesContainer } from './TradelinesContainer';
import { InquiriesContainer } from './InquiriesContainer';
import {
  fetchLegacyReportLinks,
  fetchCreditDataForOwners,
  fetchInquiriesOverviewForOwners,
  updateDocument,
  fetchSubmissionOwners,
  pullCreditForOwners,
} from './ownerOverviewFetchUtils';
import { CreditReportTable } from './CreditReport';
import { BasicOwnerInfoTable } from './BasicOwnerInfoTable';
import {
  CreditReport,
  TradelinesOverview,
  ReportSearchLink,
  OwnerOverviewSubmission,
} from './types';
import { PublicRecordsCount } from './PublicRecordsCount';
import { CriminalFilingsCount } from './CriminalFilingsCount';
import { UccTable } from './UccTable';
import { ReportDeleteConfirmationModal } from './ReportDeleteConfirmationModal';
import {
  useLexisNexisSearchResults,
  usePublicDocuments,
  usePullLexisNexis,
  useLexisNexisForOwners,
} from './ownerOverviewHooks';
import { LexisNexisSearchResults } from './LexisNexisSearchResults';

export interface OwnerOverviewProps {
  submissionUuid: string;
  submission?: OwnerOverviewSubmission;
}

const OwnerBasicInfo = gql(`
  query getOwnerOverviewSubmission($id: String!) {
    me {
      canSeeBirthdate: isAllowed(action: "READ", resource: "Submission.Customer.Owner.birthdate")
      canSeeFullSsn: isAllowed(action: "READ", resource: "Submission.Customer.Owner.ssn")
      canSeePartialSsn: isAllowed(action: "READ", resource: "Submission.Customer.Owner.ssnLastFour")
    }
    submission(id: $id) {
      id
      customer {
        id
        owners {
          ...BasicOwnerInfoTableFragment
        }
      }
    }
  }
`);

interface NoResultsMessageProps {
  title: string;
}

const NoResultsMessage = ({ title }: NoResultsMessageProps): JSX.Element => (
  <Box>
    <Subheading variant="section">{title}</Subheading>
    <Text>There are no {title}</Text>
  </Box>
);

export const shouldShowPullCreditWarning = (
  cr: CreditReport | undefined,
  submissionType: string | undefined,
  today = Date.now()
): boolean => {
  const date = cr?.createdAt.split('T')[0];
  const isDate30dayAgoOrNotPresent =
    !date || isBefore(toDate(date), sub(today, { days: 30 }));

  return submissionType !== 'Renewal' && isDate30dayAgoOrNotPresent;
};

export const OwnerOverviewSection = ({
  submissionUuid,
  submission,
}: OwnerOverviewProps): JSX.Element => {
  const [submissionOwners, setSubmissionOwners] = useState<
    BasicOwnerInfoTableFragmentFragment[]
  >([]);

  const [submissionType, setSubmissionType] = useState<string>();
  const [submissionOwnerUuids, setSubmissionOwnerUuids] = useState<string[]>(
    []
  );
  const [creditReports, setCreditReports] = useState<CreditReport[]>();
  const [documentsUpdateError, setDocumentsUpdateError] = useState<string>();
  const [inquiriesError, setInquiriesError] = useState<string>();
  const [ownerError, setOwnerError] = useState<string>();
  const [pullCreditError, setPullCreditError] = useState<string | undefined>();
  const [inquiries, setInquiries] = useState<InquiryOverview[]>([]);
  const [tradelines, setTradelines] = useState<TradelinesOverview[]>([]);
  const { role } = useUserContext();
  const [manualReportSearchLinks, setManualReportSearchLinks] =
    useState<ReportSearchLink[]>();
  const [manualReportSearchLinkError, setManualReportSearchLinkError] =
    useState<string>();
  const [experianSuccess, setExperianSuccess] = useState<boolean>(false);
  const {
    data: publicDocuments,
    error: publicDocumentsError,
    refetch: refetchPublicDocuments,
    loading: publicDocumentsLoading,
  } = usePublicDocuments(submissionUuid);

  const { searchResults, error: searchResultError } =
    useLexisNexisSearchResults(submissionUuid);

  const [
    pullLexisNexis,
    { error: lexisNexisPullError, success: lexisNexisSuccess },
  ] = usePullLexisNexis();

  const [loadOwnerBasicInfoViaGraphql, { data, error: graphqlError }] =
    useLazyQuery(OwnerBasicInfo, {
      variables: {
        id: submissionUuid,
      },
      client,
    });

  const {
    data: publicRecordsOverviews,
    error: lexisNexisError,
    loading: lexisNexisForOwnersLoading,
  } = useLexisNexisForOwners(submissionUuid);

  const { publicRecords, criminalFilings } = publicRecordsOverviews || {};

  useEffect(() => {
    if (submission) {
      setSubmissionType(submission.type);
      setSubmissionOwnerUuids(submission.ownerUuids);
    } else {
      setSubmissionType(undefined);
      setSubmissionOwnerUuids([]);
    }
  }, [submission]);

  useEffect(() => {
    const getLegacyLinks = async (): Promise<void> => {
      try {
        const legacyReportLinksData = await fetchLegacyReportLinks(
          submissionUuid
        );
        setManualReportSearchLinks(
          legacyReportLinksData.ownerManualReportSearchLinks
        );
      } catch (e: unknown) {
        const error = toError(e);
        setManualReportSearchLinkError(
          `Manual Report Search Link Error: ${error.message}`
        );
        FFLogger.error(error);
      }
    };

    void getLegacyLinks();
  }, [submissionUuid]);

  useEffect(() => {
    const getCreditData = async (): Promise<void> => {
      try {
        const creditData = await fetchCreditDataForOwners(submissionUuid);
        setCreditReports(creditData.creditReports);
        setTradelines(creditData.tradelinesOverviews);
      } catch (e: unknown) {
        const error = toError(e);
        setOwnerError(`Credit Report: ${error.message}`);
        FFLogger.error(error);
      }
    };

    void getCreditData();
  }, [submissionUuid]);

  useEffect(() => {
    const getInquiries = async (): Promise<void> => {
      try {
        const inquiriesOverview = await fetchInquiriesOverviewForOwners(
          submissionUuid
        );
        setInquiries(inquiriesOverview);
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error);
        setInquiriesError(error.message);
      }
    };

    void getInquiries();
  }, [submissionUuid]);

  const handleUpdateDocument = async (
    documentId: number,
    primary: boolean
  ): Promise<void> => {
    try {
      await updateDocument(documentId, primary);
      void refetchPublicDocuments();
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setDocumentsUpdateError(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (!featureFlags.graphql_enabled) {
          const submissionOwnersResponse = await fetchSubmissionOwners(
            submissionUuid
          );
          setSubmissionOwners(submissionOwnersResponse);
        }
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error, {
          contextMessage: 'Failed to fetch Owner Basic Info via REST',
        });
      }
    };

    void fetchData();
  }, [submissionUuid]);

  useEffect(() => {
    // this use effect conditionally loads owner basic info from graphql
    // once we no longer need the feature flag, we should flip the current
    // useLazyQuery usage to a simple useQuery
    const getOwnerBasicInfo = (): void => {
      try {
        if (featureFlags.graphql_enabled) {
          void loadOwnerBasicInfoViaGraphql();
        }
      } catch (e: unknown) {
        const error = toError(e);
        FFLogger.error(error, {
          contextMessage: 'Failed to fetch owner basic info via graphql',
        });
      }
    };

    void getOwnerBasicInfo();
  }, [loadOwnerBasicInfoViaGraphql]);

  const owners = featureFlags.graphql_enabled
    ? data?.submission?.customer?.owners
    : submissionOwners;

  const graphqlOwnerUuids =
    data?.submission?.customer?.owners?.map(
      (owner) => (owner as BasicOwnerInfoTableFragmentFragment)?.id
    ) ?? [];

  const restOwnerUuids = submissionOwners
    .filter((owner) => submissionOwnerUuids.includes(owner.id))
    .map((o) => o.id);

  const ownersUuids = featureFlags.graphql_enabled
    ? graphqlOwnerUuids
    : restOwnerUuids;

  const pullCredit = async (
    ownerUuid: string,
    force: boolean
  ): Promise<void> => {
    try {
      await pullCreditForOwners(submissionUuid, ownerUuid, force);
      setExperianSuccess(true);
    } catch (e: unknown) {
      const error = toError(e);
      FFLogger.error(error);
      setPullCreditError(error.message);
    }
  };

  const lexisNexisPullDate =
    publicDocuments &&
    publicDocuments[0] &&
    publicDocuments[0].createdAt &&
    formatDateString(publicDocuments[0].createdAt);

  const isParseableDate = (date: string): boolean => {
    return !Number.isNaN(Date.parse(date));
  };

  // experianReportDate can return error string e.g. "Error at 09/20/2023 09:20: Invalid request format"
  const experianPullDate =
    creditReports &&
    creditReports[0] &&
    creditReports[0].experianReportDate &&
    isParseableDate(creditReports[0].experianReportDate)
      ? formatDateString(creditReports[0].experianReportDate)
      : creditReports &&
        creditReports[0] &&
        creditReports[0].createdAt &&
        formatDateString(creditReports[0].createdAt);

  return (
    <>
      {graphqlError && (
        <Banner dismissable={false} variant="error">
          {graphqlError.message}
        </Banner>
      )}
      {ownerError && (
        <Banner dismissable={false} variant="error">
          {ownerError}
        </Banner>
      )}
      {lexisNexisError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisError.message}
        </Banner>
      )}
      {lexisNexisPullError && (
        <Banner dismissable={false} variant="error">
          {lexisNexisPullError}
        </Banner>
      )}
      {inquiriesError && (
        <Banner dismissable={false} variant="error">
          {inquiriesError}
        </Banner>
      )}
      {publicDocumentsError && (
        <Banner dismissable={false} variant="error">
          {publicDocumentsError.message}
        </Banner>
      )}
      {manualReportSearchLinkError && (
        <Banner dismissable={false} variant="error">
          {manualReportSearchLinkError}
        </Banner>
      )}
      {pullCreditError && (
        <Banner dismissable={false} variant="error">
          {pullCreditError}
        </Banner>
      )}
      {searchResultError && (
        <Banner dismissable={false} variant="error">
          {searchResultError}
        </Banner>
      )}
      {ownersUuids.length === 0 && (
        <Banner dismissable={false} variant="error">
          There are no Owners for this Submission.
        </Banner>
      )}
      {ownersUuids.map((uuid, index) => {
        const publicRecordCount =
          publicRecords?.find((pr) => pr.ownerUuid === uuid) || null;
        const criminalFilingCount =
          criminalFilings?.find((pr) => pr.ownerUuid === uuid) || null;
        const tradelinesCount =
          tradelines.find((t) => t.ownerUuid === uuid) || null;

        const ownerPublicDocuments = publicDocuments?.filter(
          (doc) => doc.ownerUuid === uuid
        );

        const lexixNexisOwnerDocument =
          ownerPublicDocuments && ownerPublicDocuments.length > 0
            ? ownerPublicDocuments.find((doc) => doc.primary === true) ||
              ownerPublicDocuments[0]
            : undefined;
        const lexisNexisOwnerUrl =
          lexixNexisOwnerDocument &&
          new URL(
            `/admin/documents/${lexixNexisOwnerDocument.documentId}/lexis_nexis`,
            UNDERWRITING_BASE_URL()
          );

        const manualReportSearchLink = manualReportSearchLinks?.find(
          (searchLink) => searchLink.entityUuid === uuid
        )?.manualReportSearchLink;

        const manualReportNewSearchLink =
          featureFlags.show_new_owner_ln_search ? (
            <InternalLink
              to={`/dashboard/${submissionUuid}/owner_report_search/${uuid}`}
              target="_blank"
            >
              Manual Search Report
            </InternalLink>
          ) : (
            manualReportSearchLink && (
              <Link
                newTab
                href={new URL(manualReportSearchLink, UNDERWRITING_BASE_URL())}
              >
                Manual Search Report
              </Link>
            )
          );

        const owner = (owners as BasicOwnerInfoTableFragmentFragment[])?.find(
          (o) => o.id === uuid
        );

        const hasOwnerSsn = !!(owner?.ssn || owner?.ssnLastFour);

        const creditReport = creditReports?.find((cr) => cr.ownerUuid === uuid);
        const hasFicoScore = creditReport?.fico !== undefined;

        const ownerLexisNexisSearchResult = searchResults.find(
          (results) => results.ownerUuid === uuid
        );
        const lexisNexisOwnerPageUrl = `/dashboard/submission/${submissionUuid}/owner-lexis-nexis/${uuid}`;
        return (
          <Card
            title={`Owner ${index + 1} Overview: ${owner?.fullName}${
              owner?.ownershipPercentage
                ? ` - ${owner.ownershipPercentage?.toFixed(1)}%`
                : ''
            }`}
            collapsible={true}
            key={uuid}
          >
            <Grid gutter>
              <Grid.Item m={12}>
                {ownerLexisNexisSearchResult &&
                  owner &&
                  (featureFlags.graphql_enabled ? (
                    <LexisNexisSearchResults
                      submissionUuid={submissionUuid}
                      results={ownerLexisNexisSearchResult.results}
                      ownerPublicDocuments={ownerPublicDocuments}
                      owner={owner}
                      currentUserCanSeePartialSsn={!!data?.me?.canSeePartialSsn}
                      currentUserCanSeeDateOfBirth={!!data?.me?.canSeeBirthdate}
                    />
                  ) : (
                    // Permission props are hardcoded here because this component
                    // is only rendered when fetching without GraphQL enabled.
                    <LexisNexisSearchResults
                      submissionUuid={submissionUuid}
                      ownerPublicDocuments={ownerPublicDocuments}
                      results={ownerLexisNexisSearchResult.results}
                      owner={owner}
                      currentUserCanSeeDateOfBirth={true}
                      currentUserCanSeePartialSsn={true}
                    />
                  ))}
              </Grid.Item>
              <Grid.Item xs={12} m={12} xl={12}>
                {shouldShowPullCreditWarning(creditReport, submissionType) && (
                  <Banner dismissable={false} variant="warning">
                    <Flex flexDirection={'row'} gap={2}>
                      <Icon name="warning" />
                      <Text>
                        Before proceeding to pull the Credit Report please make
                        sure the the Original Application has the owner&apos;s
                        signature and that it was signed in the last 30 days.
                      </Text>
                    </Flex>
                  </Banner>
                )}
                {creditReport?.note && !hasFicoScore && (
                  <Banner dismissable={false} variant="warning">
                    <Text>{`Credit Report Warnings: ${creditReport.note}`}</Text>
                  </Banner>
                )}
                {experianSuccess && (
                  <Banner dismissable={false} variant="success">
                    <Flex flexDirection={'row'} gap={2}>
                      <Icon name="circle-check" size="2x" />
                      <Text>
                        Credit report was pulled successfully! It takes anywhere
                        from 5 seconds to 4 minutes to process the data, so
                        please allow a little bit of time before refreshing the
                        page. If you are still not able to view the report after
                        4 minutes, please contact the tech support.
                      </Text>
                    </Flex>
                  </Banner>
                )}
                {lexisNexisSuccess && (
                  <Banner dismissable={false} variant="success">
                    <Flex flexDirection={'row'} gap={2}>
                      <Icon name="circle-check" size="2x" />
                      <Text>
                        LexisNexis was pulled successfully! It takes anywhere
                        from 5 seconds to 4 minutes to process the data, so
                        please allow a little bit of time before refreshing the
                        page. If you are still not able to view the report after
                        4 minutes, please contact the tech support.
                      </Text>
                    </Flex>
                  </Banner>
                )}
                <Flex flexDirection={'row'} gap={2} alignItems="center">
                  <Button
                    startIcon={'square-poll-vertical'}
                    onClick={() => pullLexisNexis(submissionUuid, uuid, false)}
                  >
                    Pull LexisNexis
                  </Button>
                  <Button
                    startIcon={'refresh'}
                    onClick={() => pullLexisNexis(submissionUuid, uuid, true)}
                  >
                    Refresh LexisNexis
                  </Button>

                  {hasOwnerSsn ? (
                    <Button
                      startIcon={'square-poll-vertical'}
                      onClick={() => pullCredit(uuid, false)}
                      endIcon={
                        shouldShowPullCreditWarning(
                          creditReport,
                          submissionType
                        )
                          ? 'warning'
                          : undefined
                      }
                    >
                      Pull Credit
                    </Button>
                  ) : (
                    <Tooltip
                      position="top"
                      content={<>Owner does not have SSN</>}
                      trigger={
                        <span>
                          <Button startIcon={'square-poll-vertical'} disabled>
                            Pull Credit
                          </Button>
                        </span>
                      }
                    />
                  )}

                  {hasOwnerSsn ? (
                    <Button
                      startIcon={'refresh'}
                      onClick={() => pullCredit(uuid, true)}
                    >
                      Refresh Credit
                    </Button>
                  ) : (
                    <Tooltip
                      position="top"
                      content={<>Owner does not have SSN</>}
                      trigger={
                        <span>
                          <Button startIcon={'refresh'} disabled>
                            Refresh Credit
                          </Button>
                        </span>
                      }
                    />
                  )}
                </Flex>
                <Box mt={3}>
                  <Flex gap={2}>
                    <Icon name="magnifying-glass" />
                    {manualReportNewSearchLink}
                  </Flex>
                </Box>
              </Grid.Item>
              <Grid.Item m={12} l={4}>
                <Flex
                  flexDirection={'row'}
                  gap={1}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <Flex alignItems={'center'} gap={1}>
                    <Text>Lexis Nexis</Text>
                    {publicDocumentsLoading && <Loading size="small" />}
                    {lexisNexisPullDate ? (
                      <Tooltip
                        content={<>Last Pulled Date {lexisNexisPullDate}</>}
                        trigger={
                          <span data-testid={'ln-check'}>
                            <Icon name="check-circle" color="success" />
                          </span>
                        }
                      />
                    ) : (
                      !publicDocumentsLoading && (
                        <span data-testid={'not-present'}>
                          <Icon name="circle-minus" />
                        </span>
                      )
                    )}
                  </Flex>

                  <Divider orientation="vertical" />

                  <Flex alignItems={'center'} gap={1}>
                    <Text>Credit</Text>
                    {experianPullDate ? (
                      <Tooltip
                        content={<>Last Pulled Date {experianPullDate}</>}
                        trigger={
                          <span data-testid={'ex-check'}>
                            <Icon name="check-circle" color="success" />
                          </span>
                        }
                      />
                    ) : (
                      <span data-testid={'not-present'}>
                        <Icon name="circle-minus" />
                      </span>
                    )}
                  </Flex>
                </Flex>
                <Box backgroundColor="gray-200" padding={3}>
                  {featureFlags.graphql_enabled ? (
                    <BasicOwnerInfoTable
                      owner={owner}
                      currentUserCanSeePartialSsn={!!data?.me?.canSeePartialSsn}
                      currentUserCanSeeDateOfBirth={!!data?.me?.canSeeBirthdate}
                      lexisNexisReportUrl={lexisNexisOwnerUrl}
                      lexisNexisOwnerPageUrl={lexisNexisOwnerPageUrl}
                    />
                  ) : (
                    // Permission props are hardcoded here because this component
                    // is only rendered when fetching without GraphQL enabled.
                    <BasicOwnerInfoTable
                      owner={owner}
                      currentUserCanSeeDateOfBirth={true}
                      currentUserCanSeePartialSsn={true}
                      lexisNexisReportUrl={lexisNexisOwnerUrl}
                      lexisNexisOwnerPageUrl={lexisNexisOwnerPageUrl}
                    />
                  )}
                </Box>
              </Grid.Item>
              <Grid.Item m={12} l={4}>
                <Box backgroundColor="gray-200" padding={3}>
                  <CreditReportTable
                    submissionUuid={submissionUuid}
                    ownerUuid={uuid}
                    creditReport={creditReport}
                    canArchive={
                      (creditReports && creditReports.length > 1) || false
                    }
                  />
                </Box>
              </Grid.Item>
              <Grid.Item m={12} l={4}>
                <Box backgroundColor="gray-200" padding={3}>
                  <Flex flexDirection="column" gap={2}>
                    <TradelinesContainer
                      tradelines={tradelinesCount}
                      tradelinesUrl={creditReport?.tradeLinesPath}
                      ownerUuid={uuid}
                      submissionUuid={submissionUuid}
                    />
                    <InquiriesContainer
                      inquiries={inquiries.filter((i) => i.owner_uuid === uuid)}
                      submissionUuid={submissionUuid}
                      ownerUuid={uuid}
                    />
                    {lexisNexisForOwnersLoading && <Loading />}
                    {publicRecordCount ? (
                      <PublicRecordsCount
                        publicRecord={publicRecordCount}
                        submissionUuid={submissionUuid}
                      />
                    ) : (
                      !lexisNexisForOwnersLoading && (
                        <NoResultsMessage title="Public records" />
                      )
                    )}

                    {criminalFilingCount ? (
                      <CriminalFilingsCount
                        {...criminalFilingCount}
                        submissionUuid={submissionUuid}
                      />
                    ) : (
                      <NoResultsMessage title="Criminal filings" />
                    )}
                  </Flex>
                </Box>
              </Grid.Item>
              <Grid.Item>
                <Subheading variant="section">Public Documents</Subheading>
                {!ownerPublicDocuments || ownerPublicDocuments.length === 0 ? (
                  <Text>There are no public documents</Text>
                ) : (
                  <Tabs
                    defaultValue={ownerPublicDocuments[0]?.documentId?.toString()}
                    onValueChange={() => setDocumentsUpdateError('')}
                  >
                    <Tabs.List>
                      {ownerPublicDocuments.map((doc) => (
                        <Tabs.Trigger
                          key={doc.documentId}
                          value={doc.documentId.toString()}
                        >
                          <Flex gap={2}>
                            {doc.primary && <Icon name="flag" />}
                            {doc.individualBestName
                              ? `${doc.individualBestName} - ${formatDateString(
                                  doc.createdAt
                                )}`
                              : `No Name available - ${formatDateString(
                                  doc.createdAt
                                )}`}
                          </Flex>
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                    {ownerPublicDocuments.map((doc) => (
                      <Tabs.Content
                        key={doc.documentId}
                        value={doc.documentId.toString()}
                      >
                        {documentsUpdateError && (
                          <Banner dismissable={false} variant="error">
                            {documentsUpdateError}
                          </Banner>
                        )}
                        <Flex flexDirection={'row'} gap={2}>
                          <Button
                            startIcon={'flag'}
                            onClick={() =>
                              handleUpdateDocument(doc.documentId, true)
                            }
                            disabled={doc.primary}
                          >
                            {doc.primary
                              ? 'Flagged as Primary Report'
                              : 'Flag Report as Primary'}
                          </Button>

                          {role === 'admin' && (
                            <ReportDeleteConfirmationModal
                              document={doc}
                              afterDelete={refetchPublicDocuments}
                            />
                          )}
                        </Flex>
                        <Box mt={3}>
                          <Flex gap={2}>
                            <Icon name="eye" />
                            {featureFlags.use_owner_ln_page ? (
                              <InternalLink
                                to={`/dashboard/submission/${submissionUuid}/owner-lexis-nexis/report-id/${doc.reportIdentifier}`}
                                target="_blank"
                              >
                                View LexisNexis Report
                              </InternalLink>
                            ) : (
                              <Link
                                newTab
                                href={
                                  new URL(
                                    `/admin/documents/${doc.documentId}/lexis_nexis`,
                                    UNDERWRITING_BASE_URL()
                                  )
                                }
                              >
                                View LexisNexis Report
                              </Link>
                            )}
                          </Flex>
                        </Box>
                        <Flex flexDirection="column" gap={3} mt={3}>
                          <Box backgroundColor="gray-200" padding={3}>
                            <UccTable
                              uccs={doc.uccs}
                              reportId={doc.documentId}
                              submissionUuid={submissionUuid}
                              ownerUuid={uuid}
                              customerUuid={submission?.customerUuid}
                            />
                          </Box>
                        </Flex>
                      </Tabs.Content>
                    ))}
                  </Tabs>
                )}
              </Grid.Item>
            </Grid>
          </Card>
        );
      })}
    </>
  );
};
