import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Banner,
  Heading,
} from '@forward-financing/fast-forward';
import { PaymentHistory } from './PaymentHistory';
import { fetchCustomer, MerchantResponse } from './paymentHistoryFetchUtils';
import { toError } from 'helpers/errorUtils';
import { Navbar } from 'components/Navbar/Navbar';
import { FUNDING_URL } from 'constants/globals';

type Params = {
  customerUuid: string;
};

export const PaymentHistoryContainer = (): JSX.Element => {
  const { customerUuid } = useParams<keyof Params>() as Params;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [merchant, setMerchant] = useState<MerchantResponse | null>(null);

  useEffect(() => {
    fetchCustomer(customerUuid)
      .then((customer) => {
        setMerchant(customer);
      })
      .catch((e: unknown) => {
        const error = toError(e);
        setErrorMessage(error.message);
      });
  }, [customerUuid]);

  return (
    <>
      <Navbar />
      <Box m={4}>
        {errorMessage && <Banner dismissable={false}>{errorMessage}</Banner>}
        {merchant && (
          <>
            <Breadcrumbs>
              <Breadcrumbs.Crumb href={new URL('/admin', FUNDING_URL())}>
                Funding App
              </Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb
                href={new URL(`/admin/merchants/${merchant.id}`, FUNDING_URL())}
              >
                {merchant.dba}
              </Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb href={new URL(window.location.href)}>
                Payment History
              </Breadcrumbs.Crumb>
            </Breadcrumbs>
            <Heading>{merchant.dba}</Heading>
          </>
        )}
        <PaymentHistory customerUuid={customerUuid} />
      </Box>
    </>
  );
};
