import React from 'react';
import { Box, Grid } from '@forward-financing/fast-forward';
import {
  BankruptcyRecord,
  LienJudgmentRecord,
  PublicRecordsOverview,
} from '../types';
import { Bankruptcies } from './Bankruptcies';
import { PublicRecordsSummary } from '../PublicRecordsSummary';
import { LiensJudgmentsTable } from './LiensJudgmentsTable';

type CategorizedLiens = {
  liensOpen: LienJudgmentRecord[];
  liensReleased: LienJudgmentRecord[];
  liensStateOpen: LienJudgmentRecord[];
  liensStateReleased: LienJudgmentRecord[];
  liensFederalOpen: LienJudgmentRecord[];
  liensFederalReleased: LienJudgmentRecord[];
  judgmentsOpen: LienJudgmentRecord[];
  judgmentsSettled: LienJudgmentRecord[];
};

// A lien must have a non-null type, because the type array is how we determine
// that it is a lien. This is helpful so we don't have to add extra condtions
// to check for null for the functions below that categorize only liens.
// E.g. `isLienState`.
interface Lien extends LienJudgmentRecord {
  type: string[];
}

export const categorizeLiensJudgments = (
  liensJudgments: LienJudgmentRecord[]
): CategorizedLiens => {
  const isOpen = (lien: LienJudgmentRecord): boolean =>
    lien.status !== 'RELEASED';
  const isReleased = (lien: LienJudgmentRecord): boolean => !isOpen(lien);

  const isTypeLien = (type: string): boolean => /lien/i.exec(type) !== null;

  const isTypeState = (type: string): boolean =>
    isTypeLien(type) && /state/i.exec(type) !== null;
  const isTypeFederal = (type: string): boolean =>
    isTypeLien(type) && /federal/i.exec(type) !== null;

  // lien.type is an array of strings, which is why we need to use .some()
  const isLien = (lien: LienJudgmentRecord): boolean =>
    lien.type?.some((type) => isTypeLien(type)) ?? false;
  const isJudgement = (lien: LienJudgmentRecord): boolean => !isLien(lien);

  const isLienState = (lien: Lien): boolean => lien.type.some(isTypeState);
  const isLienFederal = (lien: Lien): boolean => lien.type.some(isTypeFederal);

  const liens = liensJudgments.filter(isLien) as Lien[];
  const judgments = liensJudgments.filter(isJudgement);

  const liensOpen = liens.filter(isOpen);
  const liensReleased = liens.filter(isReleased);

  const liensStateOpen = liensOpen.filter(isLienState);
  const liensStateReleased = liensReleased.filter(isLienState);

  const liensFederalOpen = liensOpen.filter(isLienFederal);
  const liensFederalReleased = liensReleased.filter(isLienFederal);

  const judgmentsOpen = judgments.filter(isOpen);
  const judgmentsSettled = judgments.filter(isReleased);

  return {
    liensOpen,
    liensReleased,
    liensStateOpen,
    liensStateReleased,
    liensFederalOpen,
    liensFederalReleased,
    judgmentsOpen,
    judgmentsSettled,
  };
};

export interface PublicRecordsProps {
  publicRecord: PublicRecordsOverview | null;
  bankruptcies: BankruptcyRecord[];
  liens: LienJudgmentRecord[];
}

export const PublicRecords = (props: PublicRecordsProps): JSX.Element => {
  const { publicRecord } = props;

  const {
    liensOpen,
    liensReleased,
    liensStateOpen,
    liensStateReleased,
    liensFederalOpen,
    liensFederalReleased,
    judgmentsOpen,
    judgmentsSettled,
  } = categorizeLiensJudgments(props.liens);

  return (
    <Box margin={4} paddingBottom={4}>
      <Grid gutter>
        <Grid.Item xs={6}>
          <Bankruptcies bankruptcies={props.bankruptcies} />
        </Grid.Item>

        <Grid.Item xs={4}>
          <PublicRecordsSummary publicRecord={publicRecord} />
        </Grid.Item>

        <Grid.Item xs={2}> </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable liens={liensOpen} title="Total Liens - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={liensReleased}
            title="Total Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={liensStateOpen}
            title="State Liens - Open"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={liensStateReleased}
            title="State Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={liensFederalOpen}
            title="Federal Liens - Open"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={liensFederalReleased}
            title="Federal Liens - Released"
          />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable liens={judgmentsOpen} title="Judgments - Open" />
        </Grid.Item>

        <Grid.Item xs={6}>
          <LiensJudgmentsTable
            liens={judgmentsSettled}
            title="Judgments - Settled"
          />
        </Grid.Item>
      </Grid>
    </Box>
  );
};
