import { useState, useCallback, useEffect } from 'react';
import { toError } from 'helpers/errorUtils';

export interface UseGenericQueryResponse<TData> {
  /**
   * Whether or not there is an in-flight network request.
   */
  loading: boolean;
  /**
   * Whether or not a network request has been made and resolved
   * into either response data, or an error.
   *
   *  - `true` means we made a request at some point in the past and
   * the request completed, so either {@link data} or {@link error}
   * definitely has a value that is not `undefined`.
   * - `false` means either no request has ever been made, or the request
   * is still in flight. You can check the value of {@link loading} to see
   * if a request is currently in flight.
   */
  responseReady: boolean;
  /**
   * The response from the network request, if successful
   */
  data?: TData;
  /**
   * The error object from the network request, if failed
   */
  error?: Error;
  /**
   * A function which can be called to re-trigger the
   * network request (on a button click, or via polling)
   */
  refetch: () => void;
}

/**
 * This is a generic query hook, intended to make it easy
 * to write a custom hook for fetching data when a component mounts.
 *
 * It should not be used directly within a particular feature.
 * Instead, you should write a custom hook which wraps this one
 * which relates to a particular API endpoint. Then you can use
 * THAT hook within your feature.
 *
 * Example Usage:
 * ```
 * type UseGetApiSubmissionResponse =
 *   UseGenericQueryResponse<SubmissionResponse>;
 *
 * const useGetApiSubmission = (
 *   // generally, this function should accept and handle undefined values
 *   submissionUuid?: string
 * ): UseGetApiSubmissionResponse => {
 *   // define a function for fetching data, wrapped in `useCallback`
 *   const fetchFunction = useCallback(() => {
 *     if (!submissionUuid) { return undefined; }
 *
 *     // This should return `SubmissionResponse`, since that's what's
 *     // being passed as the generic parameter
 *     return fetchSubmission(submissionUuid);
 *   }, [submissionUuid]);
 *
 *   return useGenericQuery<SubmissionResponse>(fetchFunction);
 * };
 * ```
 *
 * @param fetchFunction
 * @returns
 */
export const useGenericQuery = <TData>(
  fetchFunction: () => Promise<TData> | undefined
): UseGenericQueryResponse<TData> => {
  const [data, setData] = useState<TData | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const loadData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      setError(undefined);
      const response = await fetchFunction();
      setData(response);
    } catch (e: unknown) {
      const errorObject = toError(e);
      setError(errorObject);
    } finally {
      setLoading(false);
    }
  }, [fetchFunction]);

  useEffect(() => {
    void loadData();
  }, [fetchFunction, loadData]);

  return {
    data,
    loading,
    error,
    refetch: loadData,
    responseReady: !!(data || error),
  };
};

export type UseLazyGenericQueryFetcher<TInput> = (
  input: TInput
) => Promise<void>;

export type UseLazyGenericQueryResult<TData> = {
  /**
   * Whether or not there is an in-flight network request.
   */
  loading: boolean;
  /**
   * Whether or not a network request has been made and resolved
   * into either response data, or an error.
   *
   *  - `true` means we made a request at some point in the past and
   * the request completed, so either {@link data} or {@link error}
   * definitely has a value that is not `undefined`.
   * - `false` means either no request has ever been made, or the request
   * is still in flight. You can check the value of {@link loading} to see
   * if a request is currently in flight.
   */
  responseReady: boolean;
  /**
   * The response from the network request, if successful
   */
  data?: TData;
  /**
   * The error object from the network request, if failed
   */
  error?: Error;
};

export type UseLazyGenericQueryResponse<TData, TInput> = [
  UseLazyGenericQueryFetcher<TInput>,
  UseLazyGenericQueryResult<TData>
];

/**
 * This is a generic lazy query hook, intended to make it easy
 * to write a custom hook for managing loading states when fetching
 * data at some time _other_ than component mount (usually on some
 * user action).
 *
 * It should not be used directly within a particular feature.
 * Instead, you should write a custom hook which wraps this one
 * which relates to a particular API endpoint. Then you can use
 * THAT hook within your feature.
 *
 * Example Usage:
 * ```
 * // this can be any type you need it to be,
 * // but should generally match what the caller
 * // should pass to the returned fetch function
 * interface UseLazyGetApiSubmissionArgs {
 *   submissionUuid: string;
 * }
 *
 * export type UseLazyGetApiSubmissionRefresher =
 *   UseLazyGenericQueryFetcher<UseLazyGetApiSubmissionArgs>
 *
 * export type UseLazyGetApiSubmissionResponse =
 *   UseLazyGenericQueryResponse<SubmissionResponse>
 *
 * // define a function to be returned to the consuming component, so whatever
 * // inputs you expect the caller to provide should be set as `UseLazyGetApiSubmissionArgs`
 * // note that we are defining this outside the body of the hook.
 * // if your fetch function relies on arguments to the hook, you can define it
 * // inside the hook, but be sure to wrap it in useCallback in that case
 * const fetchFunction = (args: UseLazyGetApiSubmissionArgs) =>
 *   fetchSubmission(args.submissionUuid);
 *
 * const useLazyGetApiSubmission = (): UseLazyGetApiSubmissionResponse => {
 *   return useLazyGenericQuery<SubmissionResponse, UseLazyGetApiSubmissionArgs>(
 *     fetchFunction
 *   );
 * };
 * ```
 *
 * @param fetchFunction
 * @returns
 */
export const useLazyGenericQuery = <TData, TInput>(
  fetchFunction: (input: TInput) => Promise<TData> | undefined
): UseLazyGenericQueryResponse<TData, TInput> => {
  const [data, setData] = useState<TData | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const loadData = useCallback(
    async (args: TInput) => {
      try {
        setLoading(true);
        setError(undefined);
        const response = await fetchFunction(args);
        setData(response);
      } catch (e: unknown) {
        const errorObject = toError(e);
        setError(errorObject);
      } finally {
        setLoading(false);
      }
    },
    [fetchFunction]
  );

  return [
    loadData,
    {
      loading,
      data,
      error,
      responseReady: !!(data || error),
    },
  ];
};

export type MutationResponse = { success: boolean; response?: unknown };

export type UseGenericMutationFetcher<TInput> = (
  input: TInput
) => Promise<MutationResponse>;

export type UseGenericMutationResult<TData> = {
  /**
   * Whether or not there is an in-flight network request.
   */
  loading: boolean;
  /**
   * Whether or not a network request has been made and resolved
   * into either response data, or an error.
   *
   *  - `true` means we made a request at some point in the past and
   * the request completed, so either {@link data} or {@link error}
   * definitely has a value that is not `undefined`.
   * - `false` means either no request has ever been made, or the request
   * is still in flight. You can check the value of {@link loading} to see
   * if a request is currently in flight.
   */
  responseReady: boolean;
  /**
   * The response from the network request, if successful
   */
  data?: TData;
  /**
   * The error object from the network request, if failed
   */
  error?: Error;
};

export type UseGenericMutationResponse<TData, TInput> = [
  UseGenericMutationFetcher<TInput>,
  UseGenericMutationResult<TData>
];

/**
 * This is a generic mutation hook, intended to make it easy
 * to write a custom hook for managing loading states when mutating
 * data (e.g. PATCH, POST, DELETE requests).
 *
 * It should not be used directly within a particular feature.
 * Instead, you should write a custom hook which wraps this one
 * which relates to a particular API endpoint. Then you can use
 * THAT hook within your feature.
 *
 * Example Usage:
 * ```
 * // this can be any type you need it to be,
 * // but should generally match what the caller
 * // should pass to the returned fetch function
 * interface UseUpdateSubmissionArgs {
 *   submissionUuid: string;
 *   updateBody: UpdateSubmissionRequestBody;
 * }
 *
 * type UseUpdateSubmissionResponse = UseGenericMutationResponse<
 *   SubmissionResponse,
 *   UseUpdateSubmissionArgs
 * >;
 *
 * const useUpdateApiSubmission = (): UseUpdateSubmissionResponse => {
 *   // this function is returned to the consuming component, so whatever
 *   // inputs you expect the caller to provide should be set as `UseLazyGetApiSubmissionArgs`
 *   const fetchFunction = useCallback(async (input: UseUpdateSubmissionArgs) => {
 *     return await patchSubmission(input.submissionUuid, input.updateBody);
 *   }, []);
 *
 *   return useGenericMutation(fetchFunction);
 * };
 * ```
 *
 * @param fetchFunction
 * @returns
 */
export const useGenericMutation = <TData, TInput>(
  fetchFunction: (input: TInput) => Promise<TData> | undefined
): UseGenericMutationResponse<TData, TInput> => {
  const [data, setData] = useState<TData | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const loadData = useCallback(
    async (args: TInput) => {
      let success = true;
      let response = undefined;

      try {
        setLoading(true);
        setError(undefined);
        response = await fetchFunction(args);
        setData(response);
      } catch (e: unknown) {
        const errorObject = toError(e);
        setError(errorObject);
        success = false;
      } finally {
        setLoading(false);
      }
      return { success, ...(response && { response }) };
    },
    [fetchFunction]
  );

  return [
    loadData,
    {
      loading,
      data,
      error,
      responseReady: !!(data || error),
    },
  ];
};
