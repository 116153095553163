import {
  MutationResponse,
  UseGenericMutationResult,
  UseGenericQueryResponse,
} from 'apiHooks/genericFetchHooks';
import {
  useApiLazyGetFraudProtectionMatches,
  useCreateApiFraudProtectionDecision,
} from 'apiHooks/underwriting/fraudProtectionFetchHooks';
import { useLogError } from 'apiHooks/useLogError';
import { useCallback } from 'react';
import {
  Ineligibilities,
  PotentialFraudAlert,
  PotentialFraudMatch,
} from './HighRiskReview.types';
import {
  FraudMatchResponse,
  FraudProtectionAlertResponse,
  FraudStatus,
  IneligibilitiesData,
} from 'types/api/underwriting/types';
import { useGenericFeatureQuery } from 'components/featureHooks/genericFeatureHooks';
import { useGetApiIneligibilities } from 'apiHooks/underwriting/customerFetchHooks';

const toPotentialFraudAlert = (
  alert: FraudProtectionAlertResponse
): PotentialFraudAlert => {
  return {
    id: alert.id,
    type: alert.type,
    status: alert.status,
    source: alert.source,
  };
};

const toPotentialFraudMatch = (
  match: FraudMatchResponse
): PotentialFraudMatch => {
  return {
    submission: {
      uuid: match.submission.uuid,
      accountUuid: match.submission.account_uuid ?? undefined,
      receivedAt: match.submission.received_at ?? undefined,
      dba: match.submission.dba ?? undefined,
      legalName: match.submission.legal_name ?? undefined,
    },
    alerts: match.alerts.map(toPotentialFraudAlert),
    criteria: {
      dba: match.criteria.dba,
      legalName: match.criteria.legal_name,
      fein: match.criteria.fein,
      businessAddress: match.criteria.business_address,
      businessPhone: match.criteria.business_phone,
      ownerName: match.criteria.owner_name,
      ownerSsn: match.criteria.owner_ssn,
      ownerEmail: match.criteria.owner_email,
      ownerAddress: match.criteria.owner_address,
      ownerPhone: match.criteria.owner_phone,
    },
  };
};

const toIneligiblities = (
  ineligiblities: IneligibilitiesData
): Ineligibilities => {
  return {
    currentCustomerIneligibilities:
      ineligiblities.current_customer.ineligibilities.map((ineligibility) => ({
        ineligibilityType: ineligibility.ineligibility_type,
        reason: ineligibility.reason,
        createdAt: ineligibility.created_at,
      })),
    matchedCustomers: ineligiblities.matched_customers.map(
      (matchedCustomer) => ({
        customer: {
          uuid: matchedCustomer.customer.uuid,
          dba: matchedCustomer.customer.dba,
          legalName: matchedCustomer.customer.legal_name,
        },
        ineligibilities: matchedCustomer.ineligibilities.map(
          (ineligibility) => ({
            ineligibilityType: ineligibility.ineligibility_type,
            reason: ineligibility.reason,
            createdAt: ineligibility.created_at,
          })
        ),
        criteria: {
          dba: matchedCustomer.criteria.dba,
          legalName: matchedCustomer.criteria.legal_name,
          fein: matchedCustomer.criteria.fein,
          businessAddress: matchedCustomer.criteria.business_address,
          businessPhone: matchedCustomer.criteria.business_phone,
          ownerName: matchedCustomer.criteria.owner_name,
          ownerSsn: matchedCustomer.criteria.owner_ssn,
          ownerEmail: matchedCustomer.criteria.owner_email,
          ownerAddress: matchedCustomer.criteria.owner_address,
          ownerPhone: matchedCustomer.criteria.owner_phone,
        },
      })
    ),
  };
};

type UseLazyPotentialFraudMatchesResponse = [
  (submissionUuid: string) => Promise<void>,
  {
    data: PotentialFraudMatch[];
    loading: boolean;
    error?: Error;
    responseReady: boolean;
  }
];

export const useLazyPotentialFraudMatches =
  (): UseLazyPotentialFraudMatchesResponse => {
    const [fetcher, { data, loading, error, responseReady }] =
      useApiLazyGetFraudProtectionMatches();

    const wrappedFetcher = useCallback(
      (submissionUuid: string): Promise<void> => fetcher({ submissionUuid }),
      []
    );

    useLogError(error);

    return [
      wrappedFetcher,
      {
        data: data?.map(toPotentialFraudMatch) ?? [],
        loading,
        error,
        responseReady,
      },
    ];
  };

// Create Decision
type UseCreatePotentialFraudDecisionResponse = [
  (args: CreatePotentialFraudDecisionArgs) => Promise<MutationResponse>,
  UseGenericMutationResult<MutationResponse>
];

export interface CreatePotentialFraudDecisionArgs {
  alertId: number;
  userId: number;
  status: FraudStatus;
}

export const useCreatePotentialFraudDecision =
  (): UseCreatePotentialFraudDecisionResponse => {
    const [createFn, { error, ...rest }] =
      useCreateApiFraudProtectionDecision();

    const createFunction = async (
      args: CreatePotentialFraudDecisionArgs
    ): Promise<MutationResponse> => {
      return createFn({
        createBody: {
          alert_id: args.alertId,
          user_id: args.userId,
          status: args.status,
        },
      });
    };

    useLogError(error);

    return [
      createFunction,
      {
        error,
        ...rest,
      },
    ];
  };

// Fetch Ineligibilities
export const useIneligibilities = (
  customerUuid?: string
): UseGenericQueryResponse<Ineligibilities> =>
  useGenericFeatureQuery(
    useGetApiIneligibilities,
    (response) => response && toIneligiblities(response.data),
    customerUuid
  );
