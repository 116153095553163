import { pullRelativity6IndustryPrediction } from 'api/3pi/relativity6FetchUtils';
import {
  useGenericMutation,
  UseGenericMutationResponse,
} from 'apiHooks/genericFetchHooks';
import { useCallback } from 'react';
import { Relativity6IndustryPredictionResponseBody } from 'types/api/3pi/types';

export type UsePullRelativity6IndustryPredictionArgs = {
  businessName: string;
  street: string;
  /**
   * 2 character code for a state: "FL" for Florida
   */
  state: string;
  country: string;
  /**
   * The NAICS version to use for the prediction. If not provided, the most
   * recent version will be used. NAICS versions get updated every 5 years.
   */
  naicsVersion?: '2017' | '2022';
  /**
   * An optional key to link submission/customer/etc uuids to a prediction
   * request. This can later be utilized by 3PI to create a search API.
   */
  referenceIds?: string[];
};

export type UseRelativity6IndustryPredictionResponse =
  UseGenericMutationResponse<
    Relativity6IndustryPredictionResponseBody,
    UsePullRelativity6IndustryPredictionArgs
  >;

export const useApiPullRelativity6IndustryPrediction =
  (): UseRelativity6IndustryPredictionResponse => {
    const fetchFunction = useCallback(
      (args: UsePullRelativity6IndustryPredictionArgs) => {
        return pullRelativity6IndustryPrediction({
          business_name: args.businessName,
          street: args.street,
          state: args.state,
          country: args.country,
          naics_version: args.naicsVersion ?? '2017',
          reference_ids: args.referenceIds,
        });
      },
      []
    );

    return useGenericMutation(fetchFunction);
  };
