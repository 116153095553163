import React from 'react';
import { Flex, Table, Text, Tooltip } from '@forward-financing/fast-forward';
import { startCase } from 'lodash';
import { RenewalScoreComposite } from '../DealScoring.types';

const ratingWithColorCode = (
  isMaxScoreThree: boolean,
  rating?: number
): JSX.Element => {
  if (!rating) {
    return <>N/A</>;
  }

  let colorCode = <></>;
  let tooltipContent = '';

  // Ratings are on a scale of either 1-2-3 or 1-3-5
  switch (rating) {
    case 1:
      colorCode = (
        <span role="img" aria-label="red">
          🔴
        </span>
      );
      tooltipContent = 'High Risk';
      break;

    case 2:
      colorCode = (
        <span role="img" aria-label="yellow">
          🟡
        </span>
      );
      tooltipContent = 'Medium Risk';
      break;

    case 3:
      if (isMaxScoreThree) {
        colorCode = (
          <span role="img" aria-label="green">
            🟢
          </span>
        );
        tooltipContent = 'Low Risk';
      } else {
        colorCode = (
          <span role="img" aria-label="yellow">
            🟡
          </span>
        );
        tooltipContent = 'Medium Risk';
      }
      break;

    case 5:
      colorCode = (
        <span role="img" aria-label="green">
          🟢
        </span>
      );
      tooltipContent = 'Low Risk';
      break;
  }

  return (
    <>
      {rating}{' '}
      <Tooltip position="right" content={tooltipContent} trigger={colorCode} />
    </>
  );
};

export interface RenewalCompositeScoreTableProps {
  scoreData?: RenewalScoreComposite;
}

// No test file exists for this component because
// it has 100% coverage from utilization inside the
// RenewalScoringComposite & PastAdvances components
export const RenewalCompositeScoreTable = ({
  scoreData,
}: RenewalCompositeScoreTableProps): JSX.Element => {
  if (!scoreData) {
    return <></>;
  }

  const {
    industryRisk,
    timesFunded,
    ficoChange,
    revenueChange,
    repaidPercentage,
    paymentHistory,
    stacking,
  } = scoreData || {};

  return (
    <Table caption="Renewal Deal Composite Score">
      <Table.Head>
        <Table.ColumnHeader> </Table.ColumnHeader>
        <Table.ColumnHeader>Rating</Table.ColumnHeader>
        <Table.ColumnHeader>Data Point</Table.ColumnHeader>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Number of X&apos;s Renewed
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(false, timesFunded?.score)}
          </Table.Cell>
          <Table.Cell>{timesFunded?.value || 'N/A'}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Repaid %
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(false, repaidPercentage?.score)}
          </Table.Cell>
          <Table.Cell>
            {repaidPercentage?.value
              ? `${repaidPercentage?.value.toFixed(1)}%`
              : 'N/A'}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Industry Risk
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(false, industryRisk?.score)}
          </Table.Cell>
          <Table.Cell>
            {/* startCase: low_risk becomes Low Risk */}
            {industryRisk?.value ? startCase(industryRisk?.value) : 'N/A'}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Payment History
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(false, paymentHistory?.score)}
          </Table.Cell>
          <Table.Cell>
            <Flex flexDirection="column" gap={1}>
              <Text>
                Value:{' '}
                {typeof paymentHistory?.value === 'number'
                  ? `${paymentHistory?.value.toFixed(1)}`
                  : 'N/A'}
              </Text>
            </Flex>
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Revenue Change
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(true, revenueChange?.score)}
          </Table.Cell>
          <Table.Cell>
            {revenueChange?.value
              ? `${revenueChange?.value.toFixed(1)}%`
              : 'N/A'}
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              FICO Change
            </Flex>
          </Table.RowHeader>
          <Table.Cell>
            {ratingWithColorCode(true, ficoChange?.score)}
          </Table.Cell>
          <Table.Cell>{ficoChange?.value || 'N/A'}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.RowHeader>
            <Flex alignItems="center" gap={1}>
              Stacking
            </Flex>
          </Table.RowHeader>
          <Table.Cell>{ratingWithColorCode(true, stacking?.score)}</Table.Cell>
          <Table.Cell>
            <Flex flexDirection="column" gap={1}>
              <Text>
                Pre Gross:{' '}
                {stacking?.preFfGross
                  ? `${stacking?.preFfGross.toFixed(2)}%`
                  : 'N/A'}
              </Text>
            </Flex>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
