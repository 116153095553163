import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Banner,
  Heading,
  Loading,
  PageContainer,
} from '@forward-financing/fast-forward';
import { useGetExperianConsumerDetail } from '../ownerOverviewHooks';
import { ExperianConsumerDetailApplicant } from './ExperianConsumerDetailApplicant';
import { ExperianConsumerDetailPublicRecords } from './ExperianConsumerDetailPublicRecords';
export type ExperianConsumerDetailContainerProps = {
  referenceUuid: string;
};

type Params = {
  referenceUuid: string;
};
export const ExperianConsumerDetailContainer = (): JSX.Element => {
  const { referenceUuid } = useParams<Params>();

  const {
    data: experianConsumerDetailInfo,
    loading,
    error,
  } = useGetExperianConsumerDetail(referenceUuid);

  return (
    <PageContainer>
      <Heading>Experian Consumer Detail</Heading>

      {loading && <Loading />}

      {error && (
        <Banner dismissable={false} variant="error">
          {error.message}
        </Banner>
      )}

      {!loading && !error && !experianConsumerDetailInfo && (
        <Banner dismissable={false} variant="error">
          No Experian Consumer Detail Info found.
        </Banner>
      )}

      {experianConsumerDetailInfo && (
        <>
          <ExperianConsumerDetailApplicant
            experianConsumerDetailInfo={experianConsumerDetailInfo}
          />

          <ExperianConsumerDetailPublicRecords
            publicRecords={experianConsumerDetailInfo.publicRecords}
          />
        </>
      )}
    </PageContainer>
  );
};
