import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { THIRD_PARTY_INTEGRATIONS_BASE_URL } from 'constants/globals';
import {
  Relativity6IndustryPredictionRequestBody,
  Relativity6IndustryPredictionResponseBody,
} from 'types/api/3pi/types';

const threePi = (path: string): URL =>
  new URL(path, THIRD_PARTY_INTEGRATIONS_BASE_URL());

// USED FOR LOCAL TESTING ONLY
// 3PI does not provide an HTTPS protocol in K8s
// Setting it manually here to http://localhost:6001 for local testing
// const threePi = (path: string): URL => new URL(path, 'http://localhost:6001');

export const pullRelativity6IndustryPrediction = async (
  requestBody: Relativity6IndustryPredictionRequestBody
): Promise<Relativity6IndustryPredictionResponseBody> => {
  const url = threePi('/api/v1/industry_prediction');

  const response = await makeInternalAPIRequest<
    Relativity6IndustryPredictionResponseBody,
    Relativity6IndustryPredictionRequestBody
  >(url, 'POST', requestBody);

  if (!response.ok) {
    throw new NetworkError(
      response.status,
      'Failed to pull Relativity6 industry prediction'
    );
  }

  return response.json();
};
