import React, { useMemo } from 'react';
import {
  Banner,
  Box,
  Flex,
  Icon,
  Loading,
  Subheading,
  Text,
} from '@forward-financing/fast-forward';
import { useUserContext } from 'contexts/UserContext';
import { useRenewalComparisons } from './renewalComparisonHooks';
import { CashFlowTable } from './CashFlowTable/CashFlowTable';
import { OwnerRiskTable } from './OwnerRiskTable/OwnerRiskTable';

export type RenewalComparisonProps = {
  submissionUuid: string;
};

export const RenewalComparison = ({
  submissionUuid,
}: RenewalComparisonProps): JSX.Element => {
  const { role: userRole } = useUserContext();

  // Need to memoize this array for the hook to work correctly
  const sections = useMemo(
    () =>
      userRole === 'processing'
        ? ['funding_data']
        : ['funding_data', 'cash_flow_data', 'owner_risk_data'],
    [userRole]
  );

  const {
    data: renewalComparisonsData,
    loading,
    error,
  } = useRenewalComparisons(submissionUuid, sections);

  if (loading) {
    return (
      <Box my={3}>
        <Loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Banner dismissable={false}>
          <Flex alignItems="center" gap={3}>
            <Icon name="exclamation-triangle" color="white" />
            <Text>{error.message}</Text>
          </Flex>
        </Banner>
      </Box>
    );
  }

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Flex justifyContent={'center'}>
        <Subheading>
          {renewalComparisonsData?.current.summaryData?.businessName}
        </Subheading>
      </Flex>

      {/* TODO: Current Submission */}

      {/* TODO: Advance Comparison */}

      <CashFlowTable
        current={renewalComparisonsData?.current.cashFlowData}
        prior={renewalComparisonsData?.previousOne?.cashFlowData}
      />

      <OwnerRiskTable
        current={renewalComparisonsData?.current.ownerRiskData}
        prior={renewalComparisonsData?.previousOne?.ownerRiskData}
      />
    </Flex>
  );
};
